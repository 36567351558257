import { NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import "../Catalog.scss";
import actionIcStore24px from "@nexus/core/dist/assets/icons/places/ic_storefront_24px.svg";
import Service from "../../../service/Service";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/commercialPrefil",
  listName: "platform-capabilities/commercialPrefil",
};

const CommercialPrefill = () => {
  const addressLine1 = "9201 Front Beach Rd";
  const addressLine2 = "Beach Road";
  const zip = "32407";

  const [state, setState] = useState("FL");
  const [city, setCity] = useState("Panama City");
  const [latitude, setLatitude] = useState("72.123456");
  const [longitude, setLongitude] = useState("78.4543534");

  const [prefilData, setPrefilData] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [stateList, setStateList] = useState<any>([]);

  const getStateList = () => {
    const states = ["FL"];
    const stateUI = states.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
    setStateList(stateUI);
  };

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    getStateList();
    service.getToken();
  }, []);

  const reset = () => {
    setPrefilData(null);
  };

  const prefilProperty = () => {
    if (prefilData) {
      reset();
    } else {
      const params = {
        addressLine1,
        addressLine2,
        city,
        latitude,
        longitude,
        postalCode: zip,
        state,
      };

      setLoader(true);
      service
        .getCommercialPrefilData(params)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setPrefilData(data.data);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const card = {
    title: "Commercial Property Risk",
    description:
      "Nexus Commercial Property Prefill provides carriers with the ability to leverage geospatial imagery and analysis to gain further insights on a commercial property during the underwriting and renewal processes.",
    subTitle: "Cape Analytics",
    icon: actionIcStore24px,
    developerDoc: "commercial-property-prefill",
  };

  const leftTab = () => (
    <div className="nexus-row">
      <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
        Step 01
      </div>
      <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
        <div className="form-head font-bold">Enter Address</div>
        <div className="mt-2 mb-4">
          <div className="italicText mb-2" style={{ width: "auto" }}>
            Commercial Property Address:
          </div>
          <div className="input-container" style={{ height: "78px" }}>
            <textarea
              data-testid="address"
              value={`${addressLine1},${"\n"}${addressLine2},${"\n"}${zip}`}
              className="line-input-box"
              style={{ paddingTop: "10px", height: "78px" }}
              disabled
            />
          </div>
        </div>
        <div className="mt-2 mb-3">
          <div className="italicText mb-2">City:</div>
          <div className="input-container">
            <input
              type="text"
              value={city}
              data-testid="city"
              onChange={(event) => setCity(event.target.value)}
              placeholder="Panama City"
              className="line-input-box"
              disabled
            />
          </div>
        </div>

        <div className="mt-2 mb-3">
          <div className="italicText mb-2">State:</div>
          <div className="input-container">
            {!state && (
              <div
                className="select-placeholder"
                key="placeholder"
                data-placeholder="placeholder"
              >
                eg FL
              </div>
            )}
            <select
              value={state}
              className="line-input-box"
              data-testid="state"
              onChange={(event) => setState(event.target.value)}
              disabled
            >
              {stateList}
            </select>
          </div>
        </div>

        <div className="mt-2 mb-3">
          <div className="italicText mb-2">Latitude:</div>
          <div className="input-container">
            <input
              type="text"
              value={latitude}
              data-testid="latitude"
              onChange={(event) => setLatitude(event.target.value)}
              placeholder="72.123456"
              className="line-input-box"
              disabled
            />
          </div>
        </div>

        <div className="mt-2 mb-3">
          <div className="italicText mb-2">Longitude:</div>
          <div className="input-container">
            <input
              type="text"
              value={longitude}
              data-testid="longitude"
              onChange={(event) => setLongitude(event.target.value)}
              placeholder="e.g. 78.4543534"
              className="line-input-box"
              disabled
            />
          </div>
        </div>

        <div>
          <button
            type="button"
            className="confirm-button"
            data-testid="submit-btn"
            onClick={() => {
              prefilProperty();
            }}
          >
            {prefilData ? "Reset" : "Try with Sample Property"}
          </button>
        </div>
      </div>
    </div>
  );
  const rightTab = () => (
    <>
      {!prefilData && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Commercial Property Risk Analysis with Sample Property
          </div>
        </div>
      )}
      {prefilData && (
        <div className="test-result-container nexus-ml-5">
          <div className="nexus-mb-2 test-result">Test Result</div>
          <div className="test-result-box nexus-p-4">
            <div className="property-summary">
              <div className="prefil-title">Property Summary</div>
              <div className="prefil-content-wrapper">
                <div className="prefil-content nexus-row">
                  <div className="nexus-col interstate-regular nexus-mr-1">
                    Parcel Area:
                  </div>
                  <div className="nexus-col">
                    {prefilData.propertySummary.parcelArea.value} square feet
                  </div>
                </div>
                <div className="prefil-content nexus-row">
                  <div className="nexus-col interstate-regular nexus-mr-1">
                    Structure Count:
                  </div>
                  <div className="nexus-col">
                    {prefilData.structures.length}
                  </div>
                </div>
                <div className="prefil-content nexus-row">
                  <div className="nexus-col interstate-regular nexus-mr-1">
                    Pool Count:
                  </div>
                  <div className="nexus-col">{prefilData.pools.length}</div>
                </div>
                <div className="prefil-content nexus-row">
                  <div className="prefil-content interstate-regular nexus-col">
                    Aerial Image:
                  </div>
                </div>
                <div className="prefil-content nexus-row">
                  <img
                    src={prefilData.lotDebris.image.url}
                    alt="areal"
                    className="prefil-image"
                  />
                </div>
              </div>
            </div>

            {prefilData.structures.map((item: any, index: number) => (
              <div key={index} className="address-row">
                <div className="prefil-title">Structure {index + 1}:</div>
                <div className="prefil-content-wrapper">
                  <div className="prefil-content nexus-row">
                    <div className="nexus-col interstate-regular nexus-mr-1">
                      Structure Footprint:
                    </div>
                    <div className="nexus-col">
                      {item.structureFootprint?.value} square feet
                    </div>
                  </div>
                  <div className="prefil-content nexus-row">
                    <div className="nexus-col interstate-regular nexus-mr-1">
                      Roof Condition Rating:
                    </div>
                    <div className="nexus-col">
                      {item.attributes?.commercialRoofConditionRating?.label}
                    </div>
                  </div>
                  <div className="prefil-content nexus-row">
                    <div className="nexus-col interstate-regular nexus-mr-1">
                      Roof Condition Confidence Score:
                    </div>
                    <div className="nexus-col">
                      {
                        item.attributes?.commercialRoofConditionRating
                          ?.confidenceScore
                      }
                    </div>
                  </div>
                  <div className="prefil-content nexus-row">
                    <div className="prefil-content interstate-regular nexus-col">
                      Aerial Image:
                    </div>
                  </div>
                  <div className="prefil-content nexus-row">
                    <img
                      src={
                        item.attributes?.commercialRoofConditionRating?.image
                          ?.url
                      }
                      alt="areal"
                      className="prefil-image"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Commercial Property Risk Analysis"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default CommercialPrefill;
