import React from "react";
import { NexusRadio, NexusProgressBar, NexusSelect } from "@nexus/react";
import CardDescription from "../../../CardDescriptions/CardDescription";
import { DOCUMNET_GENERATION } from "../../../Constants";
import {
  PhysicianStatement,
  EmployerStatement,
  DocumentGenerate
} from "../../../../../assets/images/index";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";

const ADOBE_DATA = {
  pageName: "platform-capabilities/document-generator",
  listName: "platform-capabilities/document-generator"
};

interface State {
  result: any;
  buttonValue: string;
  loader: boolean;
  searchFor: string;
  disability: boolean;
}

const { description } = new CardDescription();

const service = new Service();
class DocumentGeneration extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      result: null,
      buttonValue: "Try with Sample Data",
      loader: false,
      searchFor: "Select One",
      disability: false
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  setSearchFor(val: string) {
    this.setState({
      searchFor: val,
      buttonValue: "Try with Sample Data",
      result: null
    });
  }

  disability(val: boolean) {
    this.setState({
      disability: val
    });
  }

  apiCall() {
    if (this.state.buttonValue === "Reset") {
      this.setState({ buttonValue: "Try with Sample Data" });
      this.setState({ searchFor: "Select One" });
      this.setState({ result: null });
    }
    else {
      this.setState({ loader: true });
      // this.setState({ searchFor: 'Physician Statement' });
      this.setState({ buttonValue: "Reset" });
      setTimeout(() => {
        this.setState({ loader: false });
        this.setState({ result: "Not Null" });
      }, 1000);
    }
  }

  buttonSubmit() {
    return (
      <button
        disabled={this.state.searchFor === "Select One"}
        type="button"
        className="confirm-button"
        onClick={() => this.apiCall()}
        data-testid="submit-btn"
      >
        {this.state.buttonValue}
      </button>
    );
  }

  directionOption() {
    return (
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Select Document type</div>
        </div>
      </div>
    );
  }

  forward() {
    const { searchFor } = this.state;

    return (
      <div className="nexus-ml-1">
        {this.directionOption()}
        <div className="nexus-row nexus-mt-1">
          <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="italicText nexus-mb-1">Document Type:</div>
            <div className="input-fields-styles">
              <NexusSelect
                value={searchFor}
                disabled={this.state.buttonValue === "Reset"}
                data-testid="select-opt"
                onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setSearchFor(event.target.value)
                }
              >
                {DOCUMNET_GENERATION.map((item, index) => <option
                  key={index}
                  value={item}
                  disabled={item === "Select One"}
                >
                  {item}
                </option>)}
              </NexusSelect>
            </div>
          </div>
        </div>

        {this.state.searchFor !== "Select One" && <div className="nexus-row nexus-my-4">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
              Step 02
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="form-head">Policy Details</div>
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">Policy Number:</div>
              <div className="input-container">
                <input
                  type="text"
                  className="line-input-box"
                  data-testid="policy-number"
                  value={
                    this.state.buttonValue === "Try with Sample Data" ? `e.g.${"LF143546523457"}` : "LF143546523457"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">Insured Name:</div>
              <div className="input-container">
                <input
                  type="text"
                  className="line-input-box"
                  data-testid="insured-name"
                  value={
                    this.state.buttonValue === "Try with Sample Data" ? `e.g.${"John Smith"}` : "John Smith"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">Patient Name:</div>
              <div className="input-container">
                <input
                  type="text"
                  className="line-input-box"
                  data-testid="patient-name"
                  value={
                    this.state.buttonValue === "Try with Sample Data" ? `e.g.${" Diana John"}` : " Diana John"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">
                  Relation to Insured:
              </div>
              <div className="input-container">
                <input
                  type="text"
                  data-testid="relation-to-insured"
                  className="line-input-box"
                  value={
                    this.state.buttonValue === "Try with Sample Data" ? `e.g.${"Daughter"}` : "Daughter"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">Disability</div>
              <div className="radio-container">
                <NexusRadio
                  name="yes"
                  disabled
                  data-testid="disability-yes"
                  checked={this.state.disability}
                  onInput={() => this.disability(true)}
                >
                    Yes
                </NexusRadio>
                <NexusRadio
                  className="ml-5"
                  disabled
                  data-testid="disability-no"
                  name="no"
                  checked={!this.state.disability}
                  onInput={() => this.disability(false)}
                >
                    No
                </NexusRadio>
              </div>
            </div>
          </div>
        </div>
        }

        <div className="nexus-row nexus-mt-1">
          <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            {this.buttonSubmit()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const leftTab = () => this.forward();

    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">Try with Sample data</div>
      </div>
      }
      {this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="nexus-col-lg-10"
          style={{ background: "#f46906",
            padding: 0 }}
        >
          {this.state.searchFor === "Physician Statement" && <img
            src={PhysicianStatement}
            alt={this.state.searchFor}
            style={{ opacity: 0.9,
              width: "100%" }}
          />
          }
          {this.state.searchFor === "Employer Statement" && <img
            src={EmployerStatement}
            alt={this.state.searchFor}
            style={{ opacity: 0.9,
              width: "100%" }}
          />
          }
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Document Generation - Eform",
      description: description[" Document Generation"],
      subTitle: "Open Text",
      svg: DocumentGenerate,
      developerDoc: "eform"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} breadcrum="Document Generator" />
        <CatalogDetailsContainer
          title="Document Generation Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default DocumentGeneration;
