import React from "react";
import { NexusProgressBar } from "@nexus/react";
import moment from "moment";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import Service from "../../../service/Service";
import { DateRange, Warning, TaxiAlert } from "../../../../../assets/images";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/driver-violation",
  listName: "platform-capabilities/driver-violation"
};

interface State {
  firstName: string;
  lastName: string;
  dob: any;
  licenceState: string;
  licenceNumber: string;
  result: any;
  loader?: boolean;
}

const service = new Service();
class DriverViolation extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      dob: "",
      licenceState: "",
      licenceNumber: "",
      result: null
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit = () => {
    if (this.state.result) {
      this.setState({
        result: null
      });
    }
    else {
      this.setState(
        {
          firstName: "Jhon",
          lastName: "King",
          dob: new Date(2002, 7, 15),
          licenceNumber: "908765432",
          licenceState: "VA"
        },
        () => {
          const params = {
            /* eslint-disable prefer-template  */
            dateOfBirth:
              moment(this.state.dob).format("yyyy-MM-DD") + "T00:00:00Z",
            driverLicenseState: this.state.licenceState,
            drivingLicenseNumber: this.state.licenceNumber,
            firstName: this.state.firstName,
            lastName: this.state.lastName
          };

          this.setState({
            loader: true
          });

          service
            .getDriverViloation(params)
            .then((response) => response.json())
            .then((data) => {
              if (data.data) {
                // this.setState({ result: data.data, error: false });
                this.setState({ result: data.data,
                  loader: false });
              }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log("error", error);
              this.setState({
                loader: false
              });
            });
        }
      );
    }
  };

  capitalizeFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  render() {
    const leftTab = () => <div className="nexus-row">
      <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
      </div>
      <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
        <div className="form-head">Pick One</div>
        <div className="address-form">
          <div className="nexus-mt-1 nexus-mb-2 align-items-center">
            <div className="italicText nexus-mb-1">First Name:</div>
            <div className="input-container">
              <input
                type="text"
                value={this.state.firstName}
                className="line-input-box"
                placeholder="e.g. Jolie"
                data-testid="firstname"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 nexus-mb-2 align-items-center">
            <div className="italicText nexus-mb-1">Last Name:</div>
            <div className="input-container">
              <input
                type="text"
                value={this.state.lastName}
                placeholder="e.g. Angelina"
                className="line-input-box"
                data-testid="lastname"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 nexus-mb-2 align-items-center">
            <div className="italicText nexus-mb-1">Date of Birth:</div>
            <div className="input-container">
              <input
                type="text"
                value={
                  this.state.dob ? moment(this.state.dob).format("DD-MM-yyyy") : ""
                }
                placeholder="06-04-1975"
                className="line-input-box"
                data-testid="dob"
                disabled
              />
              <img
                src={DateRange}
                alt="date"
                className="calendar-icon nexus-mr-2"
              />
            </div>
          </div>
          <div className="mt-2 nexus-mb-2 align-items-center">
            <div className="italicText nexus-mb-1">License State:</div>
            <div className="input-container">
              <input
                type="text"
                value={this.state.licenceState}
                placeholder="e.g. WA"
                className="line-input-box"
                data-testid="state"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 nexus-mb-2 align-items-center">
            <div className="italicText nexus-mb-1">License Number:</div>
            <div className="input-container">
              <input
                type="text"
                value={this.state.licenceNumber}
                placeholder="e.g. 331314141"
                className="line-input-box"
                data-testid="licence"
                disabled
              />
            </div>
          </div>
          <div className="nexus-mb-4">
            <button
              type="button"
              data-testid="submit-btn"
              className="confirm-button"
              onClick={() => {
                this.submit();
              }}
            >
              {this.state.result ? "Reset" : "Try with Sample Data"}
            </button>
          </div>
        </div>
      </div>
    </div>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample Driver Information
        </div>
      </div>
      }

      {this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-4"
          style={{ width: "auto" }}
        >
          <div className="address-data-container">
            {this.state.result.processingStatus && <div className="nexus-row">
              <div>
                <img
                  className="verified-icons"
                  src={Warning}
                  alt="Warning"
                />
              </div>
              <div
                className="right-tab-details nexus-ml-2"
                style={{ paddingTop: "5px" }}
              >
                {this.state.result.processingStatus}
              </div>
            </div>
            }
            {this.state.result.mvrViolations.length > 0 && <div className="right-tab-title nexus-my-3 nexu-ml-4 nexus-pl-4">
                    Driver Violation History:
            </div>
            }
            {this.state.result && this.state.result.mvrViolations.map(
              (data: any, index: number) => <div key={index} className="nexus-pl-4">
                <div className="right-tab-title nexus-mb-2 nexus-mt-3">
                          Violation {index + 1}:
                </div>
                <div className="nexus-my-2">
                  <div className="nexus-row nexus-mb-2">
                    <div className="nexus-col-lg-3 nexus-col-md-7 nexus-col-xs-3 result-label">
                              Violation
                    </div>
                    <div className="nexus-col result-label nexus-mr-1">
                              :
                    </div>
                    <div className="nexus-col result-value mobile-margin-left">
                      {data.svcDescription}
                    </div>
                  </div>
                  <div className="nexus-row nexus-mb-2">
                    <div className="nexus-col-lg-3 nexus-col-md-7 nexus-col-xs-3 result-label">
                              Violation Date
                    </div>
                    <div className="nexus-col result-label nexus-mr-1">
                              :
                    </div>
                    <div className="capitalize result-value nexus-col mobile-margin-left">
                      {data.violationDate}
                    </div>
                  </div>
                  <div className="nexus-row nexus-mb-2">
                    <div className="nexus-col-lg-3 nexus-col-md-7 nexus-col-xs-3 result-label">
                              Speed
                    </div>
                    <div className="nexus-col result-label nexus-mr-1">
                              :
                    </div>
                    <div className="capitalize result-value nexus-col mobile-margin-left">
                      {data.speed}
                    </div>
                  </div>
                  <div className="nexus-row nexus-mb-2">
                    <div className="nexus-col-lg-3 nexus-col-md-7 nexus-col-xs-3 result-label">
                              Classification
                    </div>
                    <div className="nexus-col result-label nexus-mr-1">
                              :
                    </div>
                    <div className="capitalize result-value nexus-col mobile-margin-left">
                      {data.classification}
                    </div>
                  </div>
                  <div className="nexus-row nexus-mb-2">
                    <div className="nexus-col-lg-3 nexus-col-md-7 nexus-col-xs-3 result-label">
                              Case Number
                    </div>
                    <div className="nexus-col result-label nexus-mr-1">
                              :
                    </div>
                    <div className="capitalize result-value nexus-col mobile-margin-left">
                      {data.driverRecordDescription.replace(
                        "Case Number",
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

            )}
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Driver Violations",
      description:
        "Provide carriers with the ability to extract Driver Violation History based on driver information. Driver Violation History is leveraged across underwriting, policy issuance, renewal and claim lifecycles.",
      subTitle: "Lexis Nexis",
      svg: TaxiAlert,
      developerDoc: "driver-violations"
    };

    return (
      <div className="nexus-container driver-violation-container updated-design padding-margin-0">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Driver Violations Report"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default DriverViolation;
