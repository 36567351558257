import React from "react";
import "../Catalog.scss";
import { Warning } from "../../../../../assets/images";
import moment from "moment";

class RecallPremium extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};

    return (
      <div>
        {vehicleData && <div>
          <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            <div className="row">
              <div
                className="col-sm-7 vehicle-details-title nexus-pb-2"
                style={{ paddingBottom: "10px" }}
              >
                  Vehicles
              </div>
              <div className="col-sm-7 vehicle-details-title nexus-pb-2">
                  Vehicle 1
              </div>
            </div>
            <div className="row vehicle-details-desc">
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  VIN
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vin}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Country
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.country}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Make
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.make}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Model
              </div>
              <div className="col-sm-7 vehicle-details-desc">
                  : {vehicleData.vinspec.model}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Vehicle Type
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.vehicleType}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Fuel Type
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.fuelType}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Drive Type
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.driveLineType}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Vehicle Class
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.vehicleClass}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Engine Code
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.engineType}
              </div>
              <div className="col-sm-5 validated-address-value nexus-pb-1">
                  Service Code
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.salesName}
              </div>
              <div className="nexus-col-xs-4 mt-4">
                <div className="nexus-row">
                  <div
                    className="nexus-col-xs-2"
                    style={{ maxWidth: "43px" }}
                  >
                    <img
                      className="verified-icons"
                      src={Warning}
                      alt="CheckCircle"
                    />
                  </div>
                  <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
                    {`Vehicle ${vehicleData.vin} is Recalled ${vehicleData.recallCount} Time`}
                  </div>
                </div>
                {vehicleData?.recallEvents?.event && <>
                  <div
                    className="nexus-col-xs-4 mt-4 vehicle-details-title"
                    style={{ paddingBottom: "10px" }}
                  >
                        Event History
                  </div>
                  <div className="nexus-col-xs-4">
                    {vehicleData?.recallEvents?.event.map(
                      (item: any, index: number) => <div key={index}>
                        <div className="row">
                          <div className="col-sm-7 vehicle-details-title nexus-pb-1">
                                  Event {index + 1}
                          </div>
                        </div>
                        <div className="row vehicle-details-desc">
                          <div className="col-sm-5 validated-address-value nexus-pb-1">
                                  Recalled Date
                          </div>
                          <div className="col-sm-7 validated-address-text">
                                  : {moment(item.date).format("yyyy-MM-DD")}
                          </div>
                          <div className="col-sm-5 validated-address-value">
                                  Reason{" "}
                          </div>
                          <div className="col-sm-7 validated-address-text">
                                  : {item.text}
                          </div>
                        </div>
                      </div>

                    )}
                  </div>
                </>
                }
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

export default RecallPremium;
