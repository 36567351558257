/* eslint-disable no-negated-condition */
import {
  NexusProgressBar,
  NexusSelect,
  NexusInput,
  NexusRadio,
  NexusLabel
} from "@nexus/react";
import React from "react";
import "../CatalogStyle.scss";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { POINT_OF_INTEREST, STATES } from "../../../Constants";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/location-services",
  listName: "platform-capabilities/location-services"
};

const service = new Service();
class LocationService extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      analysedData: [],
      setLoader: false,
      addressLine1: "528 W 39th St",
      city: "New York",
      country: "US",
      postalCode: "10016",
      state: "NY",
      limit: "10",
      radius: 1000,
      searchFor: "Towing Service",
      setAlreadySeached: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  capitalizeFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  searchForLocationAndTowing = () => {
    const {
      setAlreadySeached,
      addressLine1,
      city,
      country,
      postalCode,
      state,
      limit,
      radius,
      searchFor
    } = this.state;

    if (!setAlreadySeached) {
      const params = {
        limit,
        location: {
          addressLine1,
          city,
          country,
          postalCode,
          state
        },
        radius,
        searchFor
      };
      this.setState({
        loader: true
      });
      service
        .fetchLocationAndTowingData(params)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            setAlreadySeached: true
          });
          this.setState({
            analysedData: data?.data?.response?.venues
          });
          setTimeout(() => {
            this.setState({
              loader: false
            });
          }, 100);
        })
        .catch(() => {
          this.setState({
            loader: false
          });
        });
    }
    else {
      this.setState({
        setAlreadySeached: false
      });
    }
  };

  setSearchFor = (val: string) => {
    this.setState({
      searchFor: val
    });
  };

  setStateName = (val: string) => {
    this.setState({
      state: val
    });
  };

  // handleOnChange = (val: React.ChangeEvent<HTMLNexusInputElement>) => {
  // const { attrId } = val.target;
  // const { value } = val.target;
  // this.setState({
  //     [attrId]: value
  // });
  // };

  ifvalueIsNA(val: string) {
    return val === "NA" ? { width: "115px" } : { width: "auto" };
  }

  render() {
    const {
      loader,
      setAlreadySeached,
      analysedData,
      addressLine1,
      city,
      country,
      postalCode,
      state,
      radius,
      searchFor
    } = this.state;

    const card = {
      title: "Location Service",
      description:
        "Provide carriers with the ability to identify the closest set of repair shops or tow services within a given address and radius. Simultaneously, location service can be used to identify other points of interest depending on the use case.",
      subTitle: "Precisely, FourSquare",
      icon: mapsIcMyLocation24px,
      developerDoc: "location-services"
    };
    const leftTab = () => <>
      <div className="nexus-row">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head">Search For</div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Point of Interest
            </NexusLabel>
            <NexusSelect
              className="inputFieldText"
              data-testid="PointOFInterset"
              value={searchFor}
              disabled
              /* onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setSearchFor(event.target.value)} */
            >
              {POINT_OF_INTEREST.map((item) => <option key={item.value} value={item.value}>
                {item.name}
              </option>)}
            </NexusSelect>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-4">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2">
            Step 02
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head">Enter Address</div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Address Line 1
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="address-line1"
              attrId="addressLine1"
              value={
                !setAlreadySeached ? `e.g ${addressLine1}` : addressLine1
              }
              disabled
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.handleOnChange(event)} */
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                City
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              attrId="city"
              data-testid="city"
              type="text"
              disabled
              value={!setAlreadySeached ? `e.g ${city}` : city}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.handleOnChange(event)} */
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                State
            </NexusLabel>
            <NexusSelect
              className="inputFieldText"
              value={state}
              data-testid="state"
              attrId="state"
              disabled
              onInput={(
                selectedValue: React.ChangeEvent<HTMLNexusSelectElement>
              ) => this.setStateName(selectedValue.target.value)}
            >
              {STATES.map((item) => <option key={item.value} value={item.value}>
                {item.name}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Zip Code
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              attrId="postalCode"
              data-testid="ZipCode"
              type="text"
              disabled
              value={!setAlreadySeached ? `e.g ${postalCode}` : postalCode}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.handleOnChange(event)} */
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Country
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              attrId="country"
              data-testid="country"
              type="text"
              disabled
              value={!setAlreadySeached ? `e.g ${country}` : country}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.handleOnChange(event)} */
            />
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-4">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2">
            Step 03
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head">Within Radius of</div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Radius
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              attrId="radius"
              data-testid="radiusOf"
              disabled
              value={!setAlreadySeached ? `e.g ${radius}` : radius}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.handleOnChange(event)} */
            />
          </div>
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            data-testid="validateAddress"
            className="confirm-button"
            onClick={() => this.searchForLocationAndTowing()}
          >
            {setAlreadySeached ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!setAlreadySeached && <div
        className="nexus-row nexus-ml-5 align-items-center"
        style={{ height: "100%" }}
      >
        <div className="nexus-col-sm-4 nexus-col-lg-8 nexus-col-lg-10 address-sample">
              Try with Sample Location<br></br> Data & Tow Service Search
        </div>
      </div>
      }

      {setAlreadySeached && <>
        <div className="nexus-row nexus-ml-5">
          <div className="address-head nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result">
                Test Result
          </div>
        </div>
        <div className="nexus-row nexus-ml-5">
          <div
            className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-8 nexus-col-flex nexus-mt-4 nexus-py-4"
            style={{ backgroundColor: "#FFD7BA" }}
          >
            <div className="nexus-row nexus-pl-4 result-label">
                  Towing Services within 1000 meters of <br></br> given address
            </div>
            <div className="nexus-row nexus-pl-4">
              {analysedData && analysedData.map((item: any, index: number) => <>
                <div
                  key={index}
                  className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result nexus-mt-3 nexus-mb-1"
                >
                  {item?.name}
                </div>
                <div className="nexus-row nexus-pb-2 nexus-pl-2">
                  <div
                    className="validated-address-value"
                    style={{ width: "115px" }}
                  >
                            Phone Number
                  </div>
                  <div className="validated-address-value nexus-px-1">
                            :
                  </div>
                  <div
                    className="validated-address-text"
                    style={this.ifvalueIsNA(
                      item?.contact?.phoneNumber || "NA"
                    )}
                  >
                    {item?.contact?.phoneNumber || "NA"}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-2 nexus-pl-2">
                  <div
                    className="validated-address-value"
                    style={{ width: "115px" }}
                  >
                            Email
                  </div>
                  <div className="validated-address-value nexus-px-1">
                            :
                  </div>
                  <div
                    className="validated-address-text"
                    style={this.ifvalueIsNA(
                      item?.contact?.email || "NA"
                    )}
                  >
                    {item?.contact?.email || "NA"}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-2 nexus-pl-2">
                  <div
                    className="validated-address-value"
                    style={{ width: "115px" }}
                  >
                            Street Address
                  </div>
                  <div className="validated-address-value nexus-px-1">
                            :
                  </div>
                  <div
                    className="validated-address-text"
                    style={this.ifvalueIsNA(
                      item?.location?.addressLine1 || "NA"
                    )}
                  >
                    {item?.location?.addressLine1 || "NA"}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-2 nexus-pl-2">
                  <div
                    className="validated-address-value"
                    style={{ width: "115px" }}
                  >
                            City
                  </div>
                  <div className="validated-address-value nexus-px-1">
                            :
                  </div>
                  <div
                    className="validated-address-text"
                    style={this.ifvalueIsNA(
                      item?.location?.city || "NA"
                    )}
                  >
                    {item?.location?.city || "NA"}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-2 nexus-pl-2">
                  <div
                    className="validated-address-value"
                    style={{ width: "115px" }}
                  >
                    {" "}
                            Post Code
                  </div>
                  <div className="validated-address-value nexus-px-1">
                            :
                  </div>
                  <div
                    className="validated-address-text"
                    style={this.ifvalueIsNA(
                      item?.location?.postalCode || "NA"
                    )}
                  >
                    {item?.location?.postalCode || "NA"}
                  </div>
                </div>
              </>)}
            </div>
          </div>
        </div>
      </>
      }
    </>;

    return (
      <div className="nexus-container padding-margin-0 updated-design notification">
        {loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Location Service Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default LocationService;
