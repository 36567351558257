import React from "react";
import "../CatalogStyle.scss";
import actionIcSearch24px from "@nexus/core/dist/assets/icons/action/ic_search_24px.svg";
import {
  NexusFormField,
  NexusInput,
  NexusLabel,
  NexusProgressBar,
  NexusSelect
} from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/postalcode-lookup",
  listName: "platform-capabilities/postalcode-lookup"
};

interface State {
  loader: boolean;
  apiCallCompleted: boolean;
  postCode: string;
  country: string;
  addressDetails: any;
}

const service = new Service();

class PostalCodeLookup extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      apiCallCompleted: false,
      postCode: "",
      country: "uk",
      addressDetails: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit = () => {
    if (this.state.apiCallCompleted) {
      this.setState({
        apiCallCompleted: false,
        postCode: ""
      });
    }
    else {
      this.setState({
        loader: true
      });
      const params = {
        country: this.state.country,
        postalCode: "W11 2ER"
      };
      service
        .getPostCodeDetails(params)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            addressDetails: data.data.addresses,
            loader: false,
            apiCallCompleted: true,
            postCode: "W11 2ER"
          });
        })
        .catch(() => {
          this.setState({
            loader: false,
            apiCallCompleted: true,
            postCode: "W11 2ER"
          });
        });
    }
  };

  render() {
    const countries = [
      {
        name: "UK",
        value: "uk"
      }
    ];
    const leftTab = () => <>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head mb-2 nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-8 nexus-mt-1">
              Select Country and provide Postcode
          </div>
          <div className="form-head mb-2 nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-8 nexus-mt-1">
            <NexusFormField>
              <NexusLabel>Country</NexusLabel>
              <NexusSelect
                value={this.state.country}
                disabled
                // onChange={(selectedValue) => this.setSearchFor(selectedValue)}
              >
                {countries.map((item) => <option key={item.value} value={item.value}>
                  {item.name}
                </option>)}
              </NexusSelect>
            </NexusFormField>
          </div>
          <div className="form-head mb-2 nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-8 nexus-mt-1">
            <NexusFormField>
              <NexusLabel>Postcode</NexusLabel>
              <NexusInput
                data-testid="input-textbox-maxlen"
                value={this.state.postCode}
                disabled
                placeholder="e.g. W11 2ER"
              ></NexusInput>
            </NexusFormField>
          </div>
          <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-7 nexus-mt-4">
            <button
              type="button"
              className="confirm-button"
              data-testid="submit-btn"
              style={{ width: "230px" }}
              onClick={() => {
                this.submit();
              }}
            >
              {this.state.apiCallCompleted ? "Reset" : "Try with Sample Postcode"}
            </button>
          </div>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!this.state.apiCallCompleted && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample <br></br>Postal code
        </div>
      </div>
      }

      {this.state.apiCallCompleted && <div className="nexus-ml-5 test-result-container nexus-col-lg-8">
        <div className="nexus-mb-2 test-result">Test Result</div>
        {this.state.addressDetails.map((item: any, index: number) => <div
          key={index}
          className="test-result-box nexus-mb-2 nexus-px-2 nexus-pt-1"
          style={{ width: "auto" }}
        >
          <div className="address-head mt-2 pl-4">
                  Address {index + 1}
          </div>
          <div className="address-container1 pl-4">
            <div className="address-row">
              <div className="address-text">{item.addressLine1}</div>
              <div className="address-text">{item.city}</div>
              <div className="address-text">{item.country}</div>
              <div className="address-text">{item.postalCode}</div>
            </div>
          </div>
        </div>)}
      </div>
      }
    </>;
    const card = {
      title: "Postcode Lookup",
      description:
        "Provide carriers with the ability to search for Address based on the postcode. The common example of this is the ability to offer the postcode of an address, and the rest of the address being automatically generated. Address Lookup is leveraged across policy issuance, renewal and claim lifecycles.",
      subTitle: "Experian",
      icon: actionIcSearch24px,
      developerDoc: "postal-code"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design postalcode-lookup">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Postcode Lookup Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default PostalCodeLookup;
