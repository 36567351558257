import { NexusProgressBar } from "@nexus/react";
import React, { useEffect, useState } from "react";
import "../Catalog.scss";
import fileIcCloudOff24px from "@nexus/core/dist/assets/icons/action/ic_cloud_24px.svg";
import Service from "../../../service/Service";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/carbon-emission-calculator",
  listName: "platform-capabilities/carbon-emission-calculator",
};

const service = new Service();
const CarbonEmmision = () => {
  // eslint-disable-next-line global-require

  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [yearlyMileage, setYearlyMileage] = useState("");
  const [engineSize, setEnginesize] = useState("");
  const [overallMileage, setOverallMileage] = useState("");
  const [loader, setLoader] = React.useState(false);

  const years = [2000, 2001, 2012];
  const [carbonEmission, setCarbonEmission] = React.useState<any>(null);

  const [step, setStep] = useState(0);

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const yearOptions = years.map((item: any) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  const submitCarbonEmission = () => {
    if (carbonEmission) {
      setStep(0);
      setCarbonEmission(null);
      setMake("");
      setModel("");
      setYearlyMileage("");
      setYear("");
      setEnginesize("");
      setOverallMileage("");
    } else if (step === 0) {
      setStep(1);
      setMake("BMW");
      setModel("X5");
      setYearlyMileage("10000");
      setYear("2017");
      setEnginesize("2993");
      setOverallMileage("50000");
    } else if (step === 1) {
      const params = {
        co2EmissionFromDvla: "3900",
        engineSize,
        make,
        model,
        overallMileage,
        product: "Auto",
        year,
        yearlyMileage: 800,
      };
      setLoader(true);
      service
        .getCarbonEmission(params)
        .then((response) => response.json())
        .then((data) => {
          setCarbonEmission(data.data);
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const card = {
    title: "Carbon Emission Calculator",
    description:
      "Nexus Carbon Emission Calculator provides the ability to estimate the yearly carbon emissions of a car based on the make, model and yearly mileage estimates. This service is currently available for the UK market only.",
    subTitle: "AQ Greentech",
    icon: fileIcCloudOff24px,
    developerDoc: "carbon-emission",
  };

  const numberWithCommas = (inputText: any) =>
    Intl.NumberFormat().format(inputText);

  const leftTab = () => (
    <div className="nexus-row">
      <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
        Step 01
      </div>
      <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
        <div className="test-result">Car Details</div>
        <div className="address-form">
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Make*:</div>
            <div className="input-container">
              <input
                type="text"
                value={make}
                onChange={(event) => setMake(event.target.value)}
                data-testid="make"
                placeholder="e.g. Mercedes"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Model*:</div>
            <div className="input-container">
              <input
                type="text"
                value={model}
                data-testid="model"
                onChange={(event) => setModel(event.target.value)}
                placeholder="e.g. E "
                className="line-input-box"
                disabled
              />
            </div>
          </div>

          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Year*:</div>
            <div className="input-container">
              {step === 0 && (
                <div
                  className="select-placeholder"
                  key="placeholder"
                  data-placeholder="placeholder"
                >
                  e.g. 2012
                </div>
              )}
              <select
                value={year}
                className="line-input-box"
                data-testid="year"
                onChange={(event) => {
                  setYear(event.target.value);
                }}
                disabled
              >
                {yearOptions}
              </select>
            </div>
          </div>

          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Yearly Mileage*:</div>
            <div className="input-container">
              <input
                type="text"
                value={yearlyMileage ? numberWithCommas(yearlyMileage) : ""}
                onChange={(event) => setYearlyMileage(event.target.value)}
                placeholder="e.g. 10,000"
                data-testid="mileage"
                className="line-input-box"
                disabled
              />
            </div>
          </div>

          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Engine Size*:</div>
            <div className="input-container">
              <input
                type="text"
                value={engineSize}
                onChange={(event) => setEnginesize(event.target.value)}
                placeholder="e.g. 500"
                data-testid="engin-size"
                className="line-input-box"
                disabled
              />
            </div>
          </div>

          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Overall Mileage:</div>
            <div className="input-container">
              <input
                type="text"
                value={overallMileage ? numberWithCommas(overallMileage) : ""}
                onChange={(event) => setOverallMileage(event.target.value)}
                placeholder="e.g. 94,114"
                data-testid="overall-mileage"
                className="line-input-box"
                disabled
              />
            </div>
          </div>

          <div>
            <button
              data-testid="confirm-button"
              type="button"
              style={{ width: step === 0 ? "187px" : "222px" }}
              className="confirm-button"
              onClick={() => {
                submitCarbonEmission();
              }}
            >
              {carbonEmission
                ? "Reset"
                : step === 0
                ? "Try with Sample Data"
                : "See Yearly Carbon Emission"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  const rightTab = () => (
    <>
      {!carbonEmission && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Carbon Emission Calculator with Sample Data
          </div>
        </div>
      )}
      {carbonEmission && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-mb-2 test-result">Test Result</div>
          <div className="test-result-box nexus-p-4">
            <div className="carbon-emmision-text">
              Your BMW X5 is estimated to produce
            </div>
            <div className="carbon-emmision-value">
              {carbonEmission?.predictedAnnualCo2Emissions} tCO2e
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container updated-design padding-margin-0">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Carbon Emission Calculation Tool"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default CarbonEmmision;
