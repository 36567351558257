import {
  acceleratorBannerImg,
  GlobalNet,
  AQGreenTec,
  AzureWeather,
  CapeAnalytics,
  ClaimGenius,
  Coherent,
  Docusign,
  DVLA,
  Experian,
  FourSquare,
  FRISS,
  GoogleMaps,
  GTSoftware,
  Guidewire,
  Hostbridge,
  HumanAPI,
  Imburse,
  Instanda,
  Lapetus,
  Microblink,
  MSDynamics,
  OpenTextExstream,
  PAIHealth,
  Pega,
  Precisely,
  Sentiance,
  SmartyStreets,
  Socotra,
  Twilio,
  PennRiver,
  MoneyGuidePro,
  Conquest,
  Plaid,
  Salesforce,
  Yodlee
} from "./Assets/image";

const ecosystemBanner = {
  image: acceleratorBannerImg,
  title: "Nexus Partner Ecosystem",
  subTitle:
    "Explore the Nexus Partner Ecosystem across the Financial Services Sectors providing various capabilities. Interested in adding something to our Ecosystem?"
};

const ecosystemList = [
  {
    name: "360GlobalNet",
    description:
      "An end-to-end SaaS Claims Management platform, 360GlobalNet’s no-code digital claims technology strips out the cost and delays from traditional IT systems and developments.",
    domain: ["Insurance"],
    subDomain: [],
    icon: GlobalNet,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "AQ Green Tec",
    description:
      "AQ Green Tec provides greenhouse gas (GHG) emissions management services to support companies and their stakeholders to develop and execute an integrated climate strategy. AQ Green also provides Carbon Emission Calculations and a Native Mobile Application to purchase carbon credits and monitor sustainability.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: AQGreenTec,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Cape Analytics",
    description:
      "Cape Analytics uses deep learning and geospatial imagery to provide instant property intelligence for buildings across the United States. Cape Analytics enables firms that protect and finance our homes and businesses to better understand property risk and condition using computer vision and data science.",
    domain: ["Insurance"],
    subDomain: [],
    icon: CapeAnalytics,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Claim Genius",
    description:
      "Uses latest AI technology to allow carriers to make instant decisions on total loss and damage estimates based on photos & videos uploaded from Claim. Claim Genius is easy to integrate and white label into carrier systems and is multilanguage compatible, and available globally ",
    domain: ["Insurance"],
    subDomain: [],
    icon: ClaimGenius,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Coherent",
    description:
      "Coherent is a global, cross-industry business logic platform that instantly converts Excel-based logic into managed APIs—with complementary solutions that add unique functionality on the cloud.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Coherent,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Conquest",
    description:
      "Conquest Planning application empowers advisors & planners to build plans faster, in collaboration with their clients, with a higher degree of accuracy, while furthering financial literacy through a living and continuous action plan. It has a Flexible Platform, leveraged with artificial Intelligence, and purely built on open API (Application Program Interface).",
    domain: ["Wealth & Asset"],
    subDomain: [],
    icon: Conquest,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Docusign",
    description:
      "The DocuSign eSignature solution allows enterprises and indiviudals to collect information, automate data workflows, and sign documentson various devices.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Docusign,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "DVLA",
    description:
      "Maintains the registration and licensing of drivers in Great Britain. Maintains the registration and licensing of vehicles, together with the collection and enforcement of VED, in the UK. This information is used to get the right drivers and vehicles taxed and on the road, as simply, safely and efficiently as possible.",
    domain: ["Insurance"],
    subDomain: [],
    icon: DVLA,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Experian",
    description:
      "Experian provides consumer services including online access to credit history and other products meant to protect from fraud and identity theft. Experian also collects and aggregates information on over 1 billion people and businesses including 235 million individual U.S. consumers and more than 25 million U.S. businesses.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Experian,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Four Square",
    description:
      "Foursquare is a location technology platform offering business solutions and consumer products through a deep understanding of location.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: FourSquare,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "FRISS",
    description:
      "Friss is the provider of analytics software for fraud, risk and compliance for P&C insurers.",
    domain: ["Insurance"],
    subDomain: [],
    icon: FRISS,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Guidewire",
    description:
      "Guidewire is the platform P&C insurers trust to engage, innovate, and grow efficiently. ​Guidewire combines digital, core, analytics, and AI to deliver a platform of core systems to insurer carriers across policy, claims, billing either on prem or in the cloud",
    domain: ["Insurance"],
    subDomain: [],
    icon: Guidewire,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "GT Software",
    description:
      "GT Software delivers a range of integration, modernization, and migration solutions across a variety of platforms. Its mainframe integration solutions include mobile enablement, and applications and data integration and orchestration.",
    domain: ["Insurance"],
    subDomain: [],
    icon: GTSoftware,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Hostbridge",
    description:
      "Hostbridge Technology is a software product for integrating existing and emerging technologies within large organizations.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Hostbridge,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Human API",
    description:
      "Human API is a real-time health data network that gives consumers a simple way to view and share their health data from everywhere.",
    domain: ["Insurance"],
    subDomain: [],
    icon: HumanAPI,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Imburse",
    description:
      "Imburse is a cloud-based payments integration platform. The Imburse “Payments as a Service” enterprise platform offers a single access point to the entire global payments ecosystem, from card collections, to debit orders, e-wallets, push to card transactions, and more.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Imburse,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Instanda",
    description:
      "Instanda is a SaaS insurance software platform that allows insurance companies to build, configure, and launch products online. Its no-code product design platform is built on a powerful set of insurance speciﬁc calculations, processing, and workﬂow capabilities.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Instanda,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Lapetus",
    description:
      "Lapetus Solutions integrates science and technology with machine learning to develop tools and platforms that enable industries relying on life event prediction to reduce and replace their dependence on traditional assessment methods with faster, more cost-effective and increasingly accurate results.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Lapetus,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Microblink",
    description:
      "Microblink is a global leader in AI-powered computer vision software. The company offers a variety of SDKs and APIs for digitizing documents, automating processes, and eliminating manual data entry for mobile and web applications.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Microblink,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "MoneyGuidePro",
    description:
      "MoneyGuidePro is one of the major Financial Planning software focuses on providing assistance to set a Financial Goal and identify an Investment Strategy to achieve the Goals based on the users Risk Profile.",
    domain: ["Wealth & Asset"],
    subDomain: [],
    icon: MoneyGuidePro,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "MS Dynamics",
    description:
      "Microsoft Dynamics CRM is a customer relationship management software package developed by Microsoft focused on enhancing the customer relationship for any organization. A real time bi-directional connectivity has been established between major MSDynamics Domains and Nexus for Wealth.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: MSDynamics,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "OpenText Exstream",
    description:
      "OpenText Exstream is designed for omnichannel customer communication management (CCM), transforming regular customer communications into highly personalized, engaging customer experiences.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: OpenTextExstream,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Pega",
    description:
      "PegaSystems provides business process and customer relationship management solutions for organizations.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Pega,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Plaid",
    description:
      "Plaid gives digital wealth platforms everything they need to instantly fund accounts, deliver tailored advice, and fight fraud–all on a single platform. Plus, with Plaid’s best-in-class conversion, it’s easy to increase customer lifetime value. It consolidates financial data from multiple sources and categorizes transaction data with typically 24 months of history, making it easy to use and analyze.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Plaid,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Precisely",
    description:
      "Precisely helps companies achieve data integrity by ensuring the accuracy, consistency and context of your data. This means companies can make better, faster, more confident decisions based on a deeper understanding of data.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Precisely,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Salesforce",
    description:
      "Salesforce is one of the major customer relationship management (CRM) software focuses on customer relationship during a new Sale or Service. A real time bi-directional data connectivity has been established between major Salesforce Domains and Nexus for Wealth.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Salesforce,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Smarty Streets",
    description:
      "SmartyStreets is a leader in location data intelligence. SmartyStreets provides a suite of address validation APIs for USPS and International addresses, in addition to an address autocomplete API for data entry and geocoding and reverse geocoding APIs.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: SmartyStreets,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Socotra",
    description:
      "Socotra is a cloud-based Policy Administration and Claims platform for technology-driven insurers. Its platform is built using the latest technologies to be transparent, reliable, flexible, and secure. It provides insurers with a enterprise-grade core system that enables them to rapidly develop and distribute products.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Socotra,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Twilio",
    description:
      "Twilio provides programmable communication tools for making and receiving phone calls, sending and receiving text messages, and performing other communication functions using its web service APIs",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Twilio,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Google Maps",
    description:
      "Google Maps a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography, street maps, 360° interactive panoramic views of streets, real-time traffic conditions, and route planning for traveling by foot, car, bike, air and public transportation",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: GoogleMaps,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Sentiance",
    description:
      "Sentiance is an intelligence-driven data science and behavior change company. Sentiance turns motion data into contextual insights and uses behavioral change techniques to personalize engagement for safer and sustainable mobility and wellbeing experiences.",
    domain: ["Insurance"],
    subDomain: [],
    icon: Sentiance,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Azure Weather",
    description:
      "Azure Maps Weather services are a set of RESTful APIs that allows developers to integrate highly dynamic historical, real-time, and forecasted weather data and visualizations into their solutions",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: AzureWeather,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "PAI Health",
    description:
      "PAI Health offers a more scientific approach to population health management, engagement, and analytics through proprietary algorithms that are rooted in the science of cardiorespiratory fitness and validated in the world’s largest health studies.",
    domain: ["Insurance"],
    subDomain: [],
    icon: PAIHealth,
    comingSoon: false,
    inProgress: false
  },
  {
    name: "Yodlee",
    description:
      "Yodlee’s financial aggregation solutions rely on quality, comprehensive data analysis coverage and intelligent insights to meet fast growing digital banking demands – all from a single open banking-ready platform and provider. Automated data dynamically populates, ensuring you have access to up-to-date information. Yodlee adheres to best practices for security, risk, compliance, and consumer privacy",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Yodlee,
    comingSoon: false,
    inProgress: false
  },
  // {
  //   name: 'Shift Technologies',
  //   description: 'Shift Technology is a provider of AI-driven decision automation and optimization technology for the global insurance industry.',
  //   domain: ['Insurance'],
  //   subDomain: [],
  //   icon: ShiftTechnologies,
  //   comingSoon: true,
  //   inProgress: false
  // },
  {
    name: "Penn River",
    description:
      "Penn River offers software solutions including cloud-based annuity and life policy administration systems.",
    domain: ["Insurance"],
    subDomain: [],
    icon: PennRiver,
    comingSoon: false,
    inProgress: false
  }
];

export { ecosystemBanner, ecosystemList };
