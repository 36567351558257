import React, { Component } from "react";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { accordionData, accordionData2 } from "../Assests/const";
import {
  NexusAccordion,
  NexusAccordionTrigger,
  NexusAccordionContent,
} from "@nexus/react";
const ADOBE_DATA = {
  pageName: "support-faq",
  listName: "support-faq",
};

class FAQ extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  openAccodian(id: any) {
    if (this.state.open === id) {
      this.setState({ open: null });
    } else {
      this.setState({ open: id });
    }
  }

  render() {
    return (
      <div className="nexus-container px-5 FnQ">
        <div className="nexus-row nexus-center-xs nexus-mt-4">
          <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 titleFnQ">
            Frequently Asked Questions{" "}
          </div>
          <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-px-5 nexus-my-3 heading">
            Get answers to the frequently asked questions about using our
            products
          </div>
        </div>
        <div className="nexus-row nexus-my-3 px-5">
          <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 fnqType nexus-my-1">
            Core Platform{" "}
          </div>
          {accordionData.map((item: any) => (
            <div
              className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-my-1"
              key={item.title}
            >
              <NexusAccordion
                open={this.state.open === item.title}
                onClick={() => this.openAccodian(item.title)}
                data-testid="nexus-accordion1"
              >
                <NexusAccordionTrigger>
                  <h1 className="nexus-body nexus-pt-2">{item.title}</h1>
                </NexusAccordionTrigger>
                <NexusAccordionContent>{item.content}</NexusAccordionContent>
              </NexusAccordion>
            </div>
          ))}
        </div>
        <div className="nexus-row nexus-mb-6 px-5">
          <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 fnqType nexus-my-1">
            Insurance Products{" "}
          </div>
          {accordionData2.map((item: any) => (
            <div
              className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-my-1"
              key={item.title}
            >
              <NexusAccordion
                open={this.state.open === item.title}
                onClick={() => this.openAccodian(item.title)}
                data-testid="nexus-accordion2"
              >
                <NexusAccordionTrigger>
                  <h1 className="nexus-body nexus-pt-2">{item.title}</h1>
                </NexusAccordionTrigger>
                <NexusAccordionContent>{item.content}</NexusAccordionContent>
              </NexusAccordion>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default FAQ;
