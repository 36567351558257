import React from "react";
import "../Catalog.scss";
import { Carwithorange } from "../../../../../assets/images";
import contenticsend24px from "@nexus/core/dist/assets/icons/media/ic_send_24px.svg";
import { NexusIcon } from "@nexus/react";

class AnalyticsIndicators extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};

    return (
      <div>
        {vehicleData && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
          <div className="nexus-row mt-2">
            {vehicleData.assured === "N" ? <div
              className="ml-3"
              style={{ display: "inline-flex",
                alignItems: "center" }}
            >
              <img
                className="verified-icons"
                src={Carwithorange}
                alt="CheckCircle"
              />
              <div className="ml-2 validated-address-value">
                    Vehicle is not assured
              </div>
            </div> : <div className="ml-2 validated-address-value">
                  Vehicle is assured
            </div>
            }
          </div>

          <div className="nexus-row mt-4 ml-3">
            <div className="nexus-col-xs-4 vehicle-details-title nexus-pl-3">
                Vehicle score Report
            </div>
            <div className="nexus-col-xs-4 nexus-pl-3">
              <div className="nexus-row">
                <div
                  className="nexus-col-xs-2 validated-address-value mt-2 ml-3"
                  style={{ minWidth: "220px" }}
                >
                    Vehicle Score
                </div>
                <div className="nexus-col-xs-1 validated-address-text mt-2">
                    : {vehicleData.autocheckScore}
                </div>
                <div
                  className="nexus-col-xs-2 validated-address-value mt-2 ml-3"
                  style={{ minWidth: "220px" }}
                >
                    Reference Score Range
                </div>
                <div className="nexus-col-xs-1 validated-address-text mt-2">
                    :{" "}
                  {`${vehicleData.scoreRangeLow} - ${vehicleData.scoreRangeHigh}`}
                </div>
                <div className="nexus-col-xs-4 vehicle-details-title mt-4">
                    Positive Factors:
                </div>
                <div className="nexus-col-xs-4 validated-address-text ml-3">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-4 validated-address-text mt-2">
                      <NexusIcon
                        className="bulletpoint"
                        src={contenticsend24px}
                      />
                      <span className="nexus-pl-2">
                          Mileage (actual or estimated)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-4 vehicle-details-title mt-4">
                    Negative Factors:
                </div>
                <div className="nexus-col-xs-4 ml-3">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-4 validated-address-text mt-2">
                      <NexusIcon
                        className="bulletpoint"
                        src={contenticsend24px}
                      />
                      <span className="nexus-pl-2">
                          Title brand or other problem
                      </span>
                    </div>
                    <div className="nexus-col-xs-4 validated-address-text mt-2">
                      <NexusIcon
                        className="bulletpoint"
                        src={contenticsend24px}
                      />
                      <span className="nexus-pl-2">Accident</span>
                    </div>
                    <div className="nexus-col-xs-4 validated-address-text mt-2">
                      <NexusIcon
                        className="bulletpoint"
                        src={contenticsend24px}
                      />
                      <span className="nexus-pl-2">
                          Calculated number of owners
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

export default AnalyticsIndicators;
