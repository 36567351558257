import { NexusRadio, NexusProgressBar, NexusIcon } from "@nexus/react";
import React, { useState, useEffect } from "react";
import actionIcSearch24px from "@nexus/core/dist/assets/icons/action/ic_search_24px.svg";
import colse24px from "@nexus/core/dist/assets/icons/action/ic_close_24px.svg";
import Service from "../../../service/Service";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/vehicle-info",
  listName: "platform-capabilities/vehicle-info",
};

const VehicleInfo = () => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [countryOption, setCountryOption] = useState("us");
  const [validatedAddress, setValidatedAddress] = useState<any>(null);
  const [loader, setLoader] = useState(false);

  const step = 0;

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);
  const reset = () => {
    setVehicleNumber("");
    setValidatedAddress(null);
  };

  const vehicleInfo = () => {
    if (validatedAddress) {
      reset();
    } else {
      const vinNumber = countryOption === "us" ? "VIN12345" : "MW67AGO";
      setVehicleNumber(vinNumber);
      setLoader(true);
      if (countryOption === "us") {
        const vNumber = {
          vin: vinNumber,
        };
        service
          .vehicleInfoByVIN(vNumber)
          .then((response) => response.json())
          .then((data) => {
            if (data.data) {
              setValidatedAddress(data.data);
            }
            setTimeout(() => {
              setLoader(false);
            }, 100);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        const regNumber = {
          registrationNumber: vinNumber,
        };
        service
          .vehicleInfoByREG(regNumber)
          .then((response) => response.json())
          .then((data) => {
            if (data.data) {
              setValidatedAddress(data.data);
            }
            setTimeout(() => {
              setLoader(false);
            }, 100);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    }
  };

  const card = {
    title: "Vehicle Info",
    description:
      "Provide carriers with the ability to search for details of the Vehicle based on the VIN/Plate number. The most common example of this is the ability to offer the number of the vehicle, and the rest of the vehicle details being automatically generated. VIN/Plate is leveraged across policy issuance, renewal and claim lifecycles.",
    subTitle: "DVLA, Lexis Nexis",
    icon: actionIcSearch24px,
    developerDoc: "vehicle-info",
  };
  const renderUSAddress = () => (
    <div className="nexus-ml-5 test-result-containe">
      <div className="nexus-mb-2 test-result">Test Result</div>
      <div className="test-result-box nexus-p-4" style={{ width: "auto" }}>
        <div className="row">
          <div className="col col-6">
            <div className="row">
              <div className="col col-12 vehicleInfo-details-title-us-2">
                {validatedAddress.make}
              </div>
              <div className="col col-12 vehicleInfo-details-desc-us">Make</div>
              <div
                className="col col-12 vehicleInfo-details-title-us-2"
                style={{ marginTop: "25px" }}
              >
                {validatedAddress.modelYear}
              </div>
              <div className="col col-12 vehicleInfo-details-desc-us">Year</div>
            </div>
          </div>
          <div className="col col-6">
            <div className="row">
              <div className="col col-12 vehicleInfo-details-title-us-2">
                {validatedAddress.model}
              </div>
              <div className="col col-12 vehicleInfo-details-desc-us">
                Model
              </div>
              <div
                className="col col-12 vehicleInfo-details-title-us-2"
                style={{ marginTop: "25px" }}
              >
                {validatedAddress.modelType}
              </div>
              <div className="col col-12 vehicleInfo-details-desc-us">Type</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const renderUKAddress = () => (
    <div className="nexus-ml-5 test-result-containe">
      <div className="nexus-mb-2 test-result">Test Result</div>
      <div className="test-result-box nexus-p-4">
        <div
          className="vehicleInfo-details-title-uk-1"
          style={{
            display: "flex",
            placeContent: "center",
          }}
        >
          <div className="vehicle-card"> {vehicleNumber} </div>
        </div>
        <div className="nexus-row nexus-mt-2">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
            <div className="red-container nexus-row nexus-p-2">
              <div className="nexus-mr-2">
                <NexusIcon src={colse24px}></NexusIcon>
              </div>
              <div className="nexus-col">
                <div className="vehicleInfo-details-title-uk-1 white">
                  Untaxed
                </div>
                <div className="vehicleInfo-details-desc-uk-1 white">
                  Tax Due:
                </div>
                <div className="vehicleInfo-details-desc-uk-1 white">
                  2007-01-01
                </div>
              </div>
            </div>
          </div>
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
            <div
              className="red-container nexus-row nexus-p-2"
              style={{ marginLeft: "auto" }}
            >
              <div className="nexus-mr-2">
                <NexusIcon src={colse24px}></NexusIcon>
              </div>
              <div className="nexus-col">
                <div className="vehicleInfo-details-title-uk-1 white">MOT</div>
                <div className="vehicleInfo-details-desc-uk-1 white">
                  Expired:
                </div>
                <div className="vehicleInfo-details-desc-uk-1 white">
                  2007-01-01
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nexus-row nexus-mt-2 nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Vehicle Make
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.make}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Date of first registration
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.monthOfFirstRegistration}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Year of Manufacture
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.yearOfManufacture}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Cylinder Capacity
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.engineCapacity}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            CO2 Emission
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.co2Emissions}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Fuel type
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.fuelType}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Euro Status
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.euroStatus}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Real Driving Emissions (RDE)
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.motStatus}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Export marker
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.markedForExport}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Vehicle Colour
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.colour}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Vehicle type approval
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.typeApproval}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Wheelplan
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.wheelplan}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Revenue weight
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.revenueWeight}
          </div>
        </div>
        <div className="nexus-row nexus-mb-1">
          <div className="nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-3 result-label">
            Date of Last V5C Issued
          </div>
          <div className="nexus-col result-value nexus-ml-2">:</div>
          <div className="nexus-col result-value nexus-ml-2 mobile-margin-left">
            {validatedAddress.dateOfLastV5CIssued}
          </div>
        </div>
      </div>
    </div>
  );
  const leftTab = () => (
    <>
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="form-head">Pick One</div>
          <div className="nexus-row radio-container nexus-pl-1">
            <NexusRadio
              name="countryOption"
              id="us"
              value="us"
              data-testid="country-opt-us"
              checked={countryOption === "us"}
              onClick={() => {
                reset();
                setCountryOption("us");
              }}
            >
              US
            </NexusRadio>
            <NexusRadio
              className="ml-5"
              name="countryOption"
              id="uk"
              data-testid="country-opt-uk"
              value="uk"
              checked={countryOption === "uk"}
              onClick={() => {
                reset();
                setCountryOption("uk");
              }}
            >
              UK
            </NexusRadio>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-2">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
          Step 02
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="form-head">Enter VIN/Plate details</div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">VIN/Plate Number:</div>
            <div className="input-container">
              <input
                type="text"
                data-testid="vin-number"
                value={vehicleNumber}
                onChange={(event) => setVehicleNumber(event.target.value)}
                placeholder={
                  countryOption === "us" ? "e.g. VIN12345" : "e.g. MW67AGO"
                }
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="nexus-my-4">
            <button
              type="button"
              className="confirm-button"
              data-testid="submit-btn"
              onClick={() => {
                vehicleInfo();
              }}
            >
              {validatedAddress
                ? "Reset"
                : step === 0
                ? "Try with Sample Data"
                : "Validate Address"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!validatedAddress && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample" style={{ maxWidth: "289px" }}>
            Try with Sample VIN Number
          </div>
        </div>
      )}
      {validatedAddress &&
        (countryOption === "us" ? renderUSAddress() : renderUKAddress())}
    </>
  );

  return (
    <div className="nexus-container updated-design padding-margin-0">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Vehicle Info Connector"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default VehicleInfo;
