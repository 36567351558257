import React from "react";
import Row from "./Row";
import { Temperature, Wind } from "../Assets/Image";
import { directionMapping, descriptionMapping, mapIcons } from "./helpers";

/* eslint-disable no-confusing-arrow */
const currentCondition = (data: any) => {
  const table = {
    title: "",
    rows: [
      {
        image: {
          src: mapIcons(data.weatherDescription),
          label: "Forecasts"
        },
        coloums: [
          {
            label: "Weather",
            value: descriptionMapping(data.weatherDescription)
          },
          {
            label: "Humidity",
            value: `${data.humidity}%`
          },
          {
            label: "Pressure mb",
            value: data.pressure.value
          }
        ]
      },
      {
        image: {
          src: Temperature,
          label: "Temperature"
        },
        coloums: [
          {
            label: "Current",
            value: data.temperature[0].value,
            unit: "°C"
          },
          {
            label: "Feels Like",
            value: data.realFeelTemperature ? data.realFeelTemperature[0].value : "NA",
            unit: data.realFeelTemperature ? "°C" : ""
          },
          {
            label: "Dew Point",
            value: data.dewPoint[0].value,
            unit: "°C"
          }
        ]
      },
      {
        image: {
          src: Wind,
          label: "Wind"
        },
        coloums: [
          {
            label: "Speed Km/h",
            value: data.windSpeed[0].value
          },
          {
            label: directionMapping(data.windDirection.direction),
            value: data.windDirection.degrees,
            unit: "°"
          },
          {
            label: "Wind Chil",
            value: data.windChillTemperature[0].value,
            unit: "°C"
          }
        ]
      }
    ]
  };

  return <Row data={table} />;
};

export default currentCondition;
