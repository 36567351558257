/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { banner, list } from "./Utils";
import SharedLandingPage from "../SharedComponent/SharedLandingPage";
import EnableAdobeLaunch from "../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-business-accelerators",
  listName: "platform-business-accelerators"
};

class BusinessAccelerator extends Component<any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  redirectTo = (name: string, url: string) => {
    this.props.history.push(`${url}`);
  };

  render() {
    return (
      <SharedLandingPage
        redirectFunction={this.redirectTo}
        bannerDetails={banner}
        hideExploreBtn={true}
        cardList={list}
        showFilters={true}
      />
    );
  }
}
export default BusinessAccelerator;
