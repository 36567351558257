import React from "react";
import Row from "./Row";
import { Temperature, Wind } from "../Assets/Image";
import {
  directionMapping,
  descriptionMapping,
  mapIcons,
  mapIconFromIconName
} from "./helpers";

/* eslint-disable no-confusing-arrow */
const weatherForcast = (data: any, location: string) => {
  let intencity;
  if (data.precipitation.dbz <= 10) {
    intencity = "Light mist";
  }
  else if (data.precipitation.dbz > 10 && data.precipitation.dbz <= 15) {
    intencity = "Mist";
  }
  else if (data.precipitation.dbz > 15 && data.precipitation.dbz <= 25) {
    intencity = "Light";
  }
  else if (data.precipitation.dbz > 25 && data.precipitation.dbz <= 40) {
    intencity = "Moderate";
  }
  else if (data.precipitation.dbz > 40 && data.precipitation.dbz <= 50) {
    intencity = "Heavy";
  }
  else if (data.precipitation.dbz > 50 && data.precipitation.dbz <= 55) {
    intencity = "Small Hail";
  }
  else if (data.precipitation.dbz > 55 && data.precipitation.dbz <= 60) {
    intencity = "Moderate Hail";
  }
  else if (data.precipitation.dbz > 60 && data.precipitation.dbz <= 65) {
    intencity = "Large hail";
  }

  const table = {
    title: `Location: ${location}`,
    rows: [
      {
        image: {
          src: mapIcons(data.weatherDescription),
          label: "Forecasts"
        },
        coloums: [
          {
            label: "Weather",
            value: descriptionMapping(data.weatherDescription)
          },
          {
            label: "Cloud Cover",
            value: `${data.cloudCover} %`
          },
          {
            label: "Lightning Count",
            value: data.lightningCount
          }
        ]
      },
      {
        image: {
          src: Wind,
          label: "Wind"
        },
        coloums: [
          {
            label: "Wind Speed",
            value: `${data.windSpeed[0].value}  Km/h`
          },
          {
            label: "Gust Speed",
            value: `${data.windGustSpeed[0].value}  Km/h`
          },
          {
            label: directionMapping(data.windDirection.direction),
            value: data.windDirection.degrees,
            unit: "°C"
          }
        ]
      },
      {
        image: {
          src: Temperature,
          label: "Temperature"
        },
        coloums: [
          {
            label: "Celcius",
            value: data.temperature[0].value,
            unit: "°C"
          },
          {
            label: "Fahrenheit",
            value: data.temperature[1].value,
            unit: "°F"
          },
          {
            label: "",
            value: ""
          }
        ]
      },
      {
        image: {
          src: mapIconFromIconName(data.precipitation.type),
          label: "Percipitation"
        },
        coloums: [
          {
            label: `${data.precipitation.type} dbz`,
            value: data.precipitation.dbz
          },
          {
            label: "Intensity",
            value: intencity
          },
          {
            label: "",
            value: ""
          }
        ]
      }
    ]
  };

  return <Row data={table} />;
};
export default weatherForcast;
