import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Catalog from "./Catalog";
import FacialAnalysis from "./FacialAnalysis/FacialAnalysis";
import CarbonEmmision from "./CarbonEmissionCalculator/CarbonEmmision";
import DocumentScan from "./DocumentScan/DocumentScan";
import CommercialPrefill from "./CommercialPropertyPrefill/CommercialPrefil";
import LocationService from "./LocationService/LocationService";
import VehicleHistory from "./VehicleHistory/VehicleHistory";
import VehicleInfo from "./VehicleInfo/VehicleInfo";
import PostalCodeLookup from "./PostalCodeLookup/PostalCodeLookup";
import EmailandPhoneVerification from "./EmailPhoneValidator/EmailandPhoneVerification";
import AddressParsing from "./AddressParsing/AddressParsing";
import DriverViolation from "./Drver Violation/DriverViolation";
import GeoCoding from "./GeoCoding/GeoCoding";
import customerFeedBack from "./customerFeedBack/customerFeedBack";
import AddressTypeahead from "./AddressTypehead/AddressTypehead";
import HazardRisk from "./HazardRisk/HazardRisk";
import Notification from "./Notification/Notification";
import HumanHealth from "./HumanHealth/HumanHealth";
import DocumentGeneration from "./DocumentGeneration/DocumentGeneration";
import WearableData from "./Wearable/WearableData";
import NextBestAction from "./NextBestAction/NextBestAction";
import VehicleDamage from "./VehicleDamage/VehicleDamage";
import Rating from "./Rating/Rating";
import Payment from "./Payment/Payment";
import ClueReportPage from "./ClueReport/ClueReport";
import CreditReport from "./CreditReport/CreditReport";
import CatalogAddressValidation from "./AddressValidation/CatalogAddressValidation";
import DocumentSignatureComp from "./DocumentSignature/DocumentSignature";
import CrossSellOffering from "./CrossSellOffering/CrossSellOffering";
import FraudDetection from "./FraudDetection/FraudDetection";
import PersonalPropertyPrefill from "./PersonalPropertyPrefill/PersonalPropertyPrefill";
import HealthScore from "./HealthScore/HealthScore";
import NotificationBinding from "./NotificationBinding/NotificationBinding";
import ProducerLookup from "./ProducerLookup/ProducerLookup";
import WeatherReport from "./WeatherReport/WeatherReport";
import UnderWrittingRules from "./UnderwrittingRules/UnderwrittingRules";
import Verify from "./Verify/Verify";
import VehicleSymbol from "./VehicleSymbol/VehicleSymbol";
import VehicleTelematics from "./VehicleTelematics/VehicleTelematics";
import { paths } from "../../../../utils/PathsUtils";

const {
  catalog,
  catalogAddressValidation,
  facialAnalysis,
  carbonEmission,
  documentScan,
  commercialPrefil,
  locationServices,
  vehicleInfoXdzone,
  vehicleHistory,
  postalCodeLookup,
  emailandPhoneVerification,
  addressParsing,
  driverViolation,
  geocoding,
  customerfeedback,
  addresstypeahead,
  hazardRisk,
  notification,
  humanHealth,
  documentGeneration,
  wearable,
  nextbestaction,
  vehicleDamage,
  rating,
  capabilitiesPayment,
  ClueReport,
  creditReport,
  DocumentSignature,
  crossSellOffering,
  fraudDetection,
  PersonalPropertyPefill,
  Healthscore,
  notificationBinding,
  producerLookup,
  weatherReport,
  UnderwrittingRules,
  verify,
  vehicleSymbol,
  vehicleTelematics
  // carbonOffsets
} = paths;
const CatalogRoutes = () => <Switch>
  <>
    <Route exact path={`${catalog}`} component={Catalog} />
    <Route
      exact
      path={`${catalog}${catalogAddressValidation}`}
      component={CatalogAddressValidation}
    />
    <Route
      exact
      path={`${catalog}${facialAnalysis}`}
      component={FacialAnalysis}
    />
    <Route
      exact
      path={`${catalog}${carbonEmission}`}
      component={CarbonEmmision}
    />
    <Route
      exact
      path={`${catalog}${documentScan}`}
      component={DocumentScan}
    />
    <Route
      exact
      path={`${catalog}${commercialPrefil}`}
      component={CommercialPrefill}
    />
    <Route
      exact
      path={`${catalog}${locationServices}`}
      component={LocationService}
    />
    <Route
      exact
      path={`${catalog}${vehicleInfoXdzone}`}
      component={VehicleInfo}
    />
    <Route
      exact
      path={`${catalog}${vehicleHistory}`}
      component={VehicleHistory}
    />
    <Route
      exact
      path={`${catalog}${postalCodeLookup}`}
      component={PostalCodeLookup}
    />
    <Route
      exact
      path={`${catalog}${emailandPhoneVerification}`}
      component={EmailandPhoneVerification}
    />
    <Route
      exact
      path={`${catalog}${addressParsing}`}
      component={AddressParsing}
    />
    <Route
      exact
      path={`${catalog}${driverViolation}`}
      component={DriverViolation}
    />
    <Route exact path={`${catalog}${geocoding}`} component={GeoCoding} />
    <Route
      exact
      path={`${catalog}${customerfeedback}`}
      component={customerFeedBack}
    />
    <Route
      exact
      path={`${catalog}${addresstypeahead}`}
      component={AddressTypeahead}
    />
    <Route exact path={`${catalog}${hazardRisk}`} component={HazardRisk} />
    <Route
      exact
      path={`${catalog}${notification}`}
      component={Notification}
    />
    <Route exact path={`${catalog}${humanHealth}`} component={HumanHealth} />
    <Route
      exact
      path={`${catalog}${documentGeneration}`}
      component={DocumentGeneration}
    />
    <Route exact path={`${catalog}${wearable}`} component={WearableData} />
    <Route
      exact
      path={`${catalog}${nextbestaction}`}
      component={NextBestAction}
    />
    <Route
      exact
      path={`${catalog}${vehicleDamage}`}
      component={VehicleDamage}
    />
    <Route exact path={`${catalog}${rating}`} component={Rating} />
    <Route
      exact
      path={`${catalog}${capabilitiesPayment}`}
      component={Payment}
    />
    <Route
      exact
      path={`${catalog}${ClueReport}`}
      component={ClueReportPage}
    />
    <Route
      exact
      path={`${catalog}${creditReport}`}
      component={CreditReport}
    />
    <Route
      exact
      path={`${catalog}${DocumentSignature}`}
      component={DocumentSignatureComp}
    />
    <Route
      exact
      path={`${catalog}${crossSellOffering}`}
      component={CrossSellOffering}
    />
    <Route
      exact
      path={`${catalog}${fraudDetection}`}
      component={FraudDetection}
    />
    <Route
      exact
      path={`${catalog}${PersonalPropertyPefill}`}
      component={PersonalPropertyPrefill}
    />
    <Route exact path={`${catalog}${Healthscore}`} component={HealthScore} />
    <Route
      exact
      path={`${catalog}${notificationBinding}`}
      component={NotificationBinding}
    />
    <Route
      exact
      path={`${catalog}${producerLookup}`}
      component={ProducerLookup}
    />
    <Route
      exact
      path={`${catalog}${weatherReport}`}
      component={WeatherReport}
    />
    <Route
      exact
      path={`${catalog}${UnderwrittingRules}`}
      component={UnderWrittingRules}
    />
    <Route exact path={`${catalog}${verify}`} component={Verify} />
    <Route
      exact
      path={`${catalog}${vehicleSymbol}`}
      component={VehicleSymbol}
    />
    <Route
      exact
      path={`${catalog}${vehicleTelematics}`}
      component={VehicleTelematics}
    />
  </>
</Switch>;
export default CatalogRoutes;
