/* eslint-disable camelcase */
import React from "react";
import "../CatalogStyle.scss";
import {
  NexusFormField,
  NexusInput,
  NexusLabel,
  NexusProgressBar,
  NexusSelect
} from "@nexus/react";
import CardDescription from "../../../CardDescriptions/CardDescription";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { AccessibleForward, LocationPin } from "../../../../../assets/images";
import { formatDate } from "../../../../../utils/FunctionsUtils";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/human-health",
  listName: "platform-capabilities/human-health"
};

interface Address {
  userID: string;
}

interface State {
  result: any;
  buttonValue: string;
  loader: boolean;
  address: Address;
}

const service = new Service();
const { description } = new CardDescription();

class HumanHealth extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      address: {
        userID: "Nexus_1"
      },
      result: null,
      buttonValue: "Try with Sample Data",
      loader: false
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    if (this.state.buttonValue === "Reset") {
      this.setState({ buttonValue: "Try with Sample Data",
        result: null });
    }
    else {
      this.setState({ loader: true });
      this.setState({ buttonValue: "Reset" });
      service
        .getHumanHealth({ client_user_id: this.state.address.userID })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.data.medicalData);
          if (data.data) {
            this.setState({ result: data.data.medicalData });
            this.setState({ loader: false });
          }
          // setTimeout(() => {}, 100);
        })
        .catch();
    }
  }

  buttonSubmit() {
    return (
      <div className="d-flex justify-content-center mb-4">
        <button
          type="button"
          className="confirm-button"
          onClick={() => this.apiCall()}
        >
          {this.state.buttonValue}
        </button>
      </div>
    );
  }

  forward() {
    const { userID } = this.state.address;
    const user = [
      {
        name: "Nexus_1001",
        value: "Nexus_1001"
      }
    ];

    return (
      <>
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 1
          </div>
          <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-5 nexus-flex-col">
            <div className="form-head nexus-mb-2">Input the User ID</div>
            <NexusFormField>
              <NexusLabel>User ID</NexusLabel>
              <NexusSelect value={userID} disabled>
                <option data-testid="select-opt1" value={userID}>
                  {this.state.buttonValue === "Try with Sample Data" ? `e.g.${userID}` : userID}
                </option>
                {user.map((item) => <option key={item.value} value={item.value}>
                  {item.name}
                </option>)}
              </NexusSelect>
            </NexusFormField>
          </div>
        </div>
        <div className="nexus-row">
          <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
          <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
            <button
              type="button"
              className="confirm-button"
              data-testid="submit-btn"
              onClick={() => {
                this.apiCall();
              }}
            >
              {this.state.result ? "Reset" : "Try with Sample Data"}
            </button>
          </div>
        </div>
      </>
    );
  }

  commonResponse() {
    const { instructions, allergies, issues, immunizations, plansOfCare } = this.state.result;
    // const PlansOfCare = this.state.result.plansOfCare;

    return (
      <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="test-result-box nexus-p-4 nexus-col-lg-12">
          <div className="nexus-row nexus-mt-2 nexus-ml-1 nexus-mt-3">
            <div className="nexus-col-sm-1 nexus-col-md-1 nexus-col-lg-1">
              <img
                style={{ maxWidth: "20px",
                  maxHeight: "28px" }}
                src={LocationPin}
                alt="CheckCircle"
              />
            </div>
            <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10 address-matched-HumanHealth">
              {instructions ? instructions[0].text : ""}
            </div>
          </div>
          {allergies.length > 0 && <>
            <div className="nexus-row network-head nexus-mt-2 nexus-ml-2">
              <strong>Active Alergies </strong>
            </div>
            <div className="nexus-row nexus-mt-2 nexus-ml-2 address-matched-HumanHealth">
              <div
                className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 nexus-py-1"
                style={{ backgroundColor: "#F0F0F0" }}
              >
                <div className="nexus-mb-1">
                  <strong>Allergen: </strong> {allergies[0].name}
                </div>
                <div className="nexus-mb-1">
                  <strong>Symptom: </strong>{" "}
                  {allergies[0].reactionsFull[0].name}
                </div>
                <div className="">
                  <strong>Start Date: </strong>{" "}
                  {formatDate(allergies[0].createdAt)}
                </div>
              </div>
              {allergies.length > 1 && <>
                <div className="nexus-col-md-1 nexus-col-lg-1"></div>
                <div
                  className="nexus-col-sm-4 nexus-col-md-3 nexus-col-lg-5 nexus-py-1"
                  style={{ backgroundColor: "#F0F0F0" }}
                >
                  <div className="nexus-mb-1">
                    <strong>Allergen: </strong> {allergies[1].name}
                  </div>
                  <div className="nexus-mb-1">
                    <strong>Symptom: </strong>{" "}
                    {allergies[1].reactionsFull[0].name}
                  </div>
                  <div className="">
                    <strong>Start Date: </strong>{" "}
                    {formatDate(allergies[1].createdAt)}
                  </div>
                </div>
              </>
              }
            </div>
          </>
          }
          {issues && <>
            <div className="nexus-row network-head nexus-py-2 nexus-ml-2">
              <strong>Issues Reported </strong>
            </div>
            <div className="nexus-row nexus-ml-2 nexus-pb-1">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Issue
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">{issues[0].name}</div>
            </div>
            <div className="nexus-row nexus-ml-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Reported on
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">
                {formatDate(issues[0].createdAt)}
              </div>
            </div>
          </>
          }
          {immunizations && <>
            <div className="nexus-row network-head nexus-py-2 nexus-ml-2">
              <strong>Immunizations </strong>
            </div>
            <div className="nexus-row nexus-ml-2 nexus-pb-1">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Vaccine Name
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">
                {immunizations[0].name}
              </div>
            </div>
            <div className="nexus-row nexus-ml-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Vaccinated On
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">
                {formatDate(immunizations[0].createdAt)}
              </div>
            </div>
          </>
          }
          {plansOfCare && <>
            <div className="nexus-row network-head nexus-py-2 nexus-ml-2">
              <strong>Plans of care </strong>
            </div>
            <div className="nexus-row nexus-ml-2 nexus-pb-1">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Name
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">
                {plansOfCare[0].name}
              </div>
            </div>
            <div className="nexus-row nexus-ml-2 nexus-pb-1">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Reported on
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">
                {" "}
                {formatDate(plansOfCare[0].createdAt)}
              </div>
            </div>
            <div className="nexus-row nexus-ml-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                  Department Name
              </div>
              <div className="validated-address-value nexus-px-1">:</div>
              <div className="validated-address-text">
                {plansOfCare[0].provider.departmentName}
              </div>
            </div>
          </>
          }
        </div>
      </div>
    );
  }

  render() {
    const leftTab = () => this.forward();

    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample<br></br> data
        </div>
      </div>
      }

      {this.state.result && <>{this.commonResponse()}</>}
    </>;
    const card = {
      title: "Human Health",
      description: description["Human Health API"],
      subTitle: "Human Health",
      svg: AccessibleForward,
      developerDoc: "human-health-record"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Human Health Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default HumanHealth;
