/* eslint id-length: 'error' */
const InputArray = [
  {
    labelTestid: "addressLine",
    labelTittle: "Address Line 1",
    inputTestid: "addressLine-input",
    value: "200 George street",
    isDisabled: true,
    placeholder: "200 George street",
  },
  {
    labelTestid: "addressLine2",
    labelTittle: "Address Line 2",
    inputTestid: "addressLine2-input",
    value: "PO Box 20426",
    isDisabled: true,
    placeholder: "PO Box 20426",
  },
  {
    labelTestid: "city",
    labelTittle: "City",
    inputTestid: "city-input",
    value: "Tampa",
    isDisabled: true,
    placeholder: "Tampa",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "FL",
    isDisabled: true,
    placeholder: "FL",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "33622",
    isDisabled: true,
    placeholder: "33622",
  },
];
const DropdownOption = [
  {
    id: "AirHurricaneHazard",
    name: "Air Hurricane Hazard",
    disabled: false,
  },
  {
    id: "CitizenTerritory",
    name: "Citizen Territory",
    disabled: false,
  },
  {
    id: "DistanceToCoast",
    name: "Distance To Coast",
    disabled: false,
  },
  {
    id: "HailDamage",
    name: "Hail Damage",
    disabled: false,
  },
  {
    id: "PublicProtectionClass",
    name: "Public Protection Class",
    disabled: false,
  },
  {
    id: "Sinkhole",
    name: "Sinkhole",
    disabled: false,
  },
];
const arapiReqParams = {
  address: {
    addressLine1: "200 George street",
    addressLine2: "PO BOX 20426",
    city: "Tampa",
    state: "FL",
    postalCode: "33622",
  },
  quoteBack: "quote back",
};
export { InputArray, DropdownOption, arapiReqParams };
