import React from "react";
import paths from "../../../../../utils/PathsUtils";
import FSSolutionExploreMoreCommonComponent from "../../FSSolutions/FSSolutionsExploreMore/FSSolutionExploreMoreCommonComponent";
import {
  DSKeyAssetsImage,
  DSStrategicValue,
  DSBannerImage
} from "../Assets/image";

const ConfigurationStudio = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Configure modular, event-enabled, cloud-ready components in an intuitive user interface accessible to technical and non-technical user types",
        "Capture, analyze and create stronger user experiences from system and process feedback data",
        "Simplify and strengthen organization interactions with personalized support and outreach for each user",
        "Define, orchestrate and interact with users across all channels and partners in real-time, based on simple and complex events",
        "Drive data processing consistency across an organization and different data types"
      ],
      img: DSKeyAssetsImage,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Bring EY solutions to market faster through a simple interface that allows technical and non-technical users to develop EY Nexus Components ",
        "Visualize the EY Nexus platform to display how components and an event streaming architecture can be composed to solve for organizational changes ",
        "Accelerate clients’ build for clients who purchase EY Nexus component packages allowing employees to configure and manage EY Nexus components on their own"
      ],
      img: DSStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: DSBannerImage,
    heading: "Configuration Studio",
    subHeading:
      "Configuration Studio is an interactive web portal for users to define and orchestrate end to end data experiences.  Through Configuration Studio, clients can configure experiences across organization systems and third parties to orchestrate cohesive user experiences in a no-code interface.",
    previousLinkPath: paths.tools,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default ConfigurationStudio;
