import React from "react";
import paths from "../../../../../utils/PathsUtils";
import FSSolutionExploreMoreCommonComponent from "../../FSSolutions/FSSolutionsExploreMore/FSSolutionExploreMoreCommonComponent";
import { NDSbanner, NDSKeyAssets, NDSStrategicValues } from "../Assets/image";

const NexusDesignSystem = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Design Tokens for rapid prototyping interfaces with Sketch, Figma, and Adobe XD",
        "Component Library with configurable Typography, Iconography, and Theming capabilities",
        "Out-of-the-box support for Accessibility, Responsiveness, and Motion guidelines",
        "Documentation of design vocabulary, implementation guidelines, and editorial governance",
        "Built on open web standards for framework-agnostic support using Web Components"
      ],
      img: NDSKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Brand consistency across a portfolio of products, teams, and applications",
        "Future-proof building blocks abstracted away from framework churn",
        "Centralized governance for socialization of best practices for digital maturity and culture"
      ],
      img: NDSStrategicValues,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: NDSbanner,
    heading: "Nexus Design System​",
    subHeading:
      "To accelerate UI design to code, the Design System is comprised of a set of principles and patterns reflected by a collection of corresponding design tokens and code artifacts which are utilized to maintain consistency across team and application implementations",
    previousLinkPath: paths.tools,
    contentClass: "banner-content-black"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default NexusDesignSystem;
