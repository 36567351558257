import React from "react";
import "../CatalogStyle.scss";
import { NexusProgressBar } from "@nexus/react";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { InputArray, apiRequestParams, formatedResData } from "./inputList";
import ResponseComponent from "./Response";
import StepOne from "./StepOne";
import { ResponseData } from "../Utils";

const ADOBE_DATA = {
  pageName: "platform-capabilities/waterline",
  listName: "platform-capabilities/waterline"
};

interface State {
  loader: boolean;
  result: boolean;
  formatedResponse: ResponseData;
}
interface Props {}

const service = new Service();
class Waterline extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loader: false,
      result: false,
      formatedResponse: formatedResData
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit() {
    const { result } = this.state;
    if (result === false) {
      this.apiCall();
    }
    else {
      this.setState({ formatedResponse: formatedResData,
        result: false });
    }
  }

  apiCall() {
    this.setState({ loader: true });
    service
      .getWaterLineReport(apiRequestParams)
      .then((_res: any) => _res.json())
      .then((data: any) => {
        const resData = data.data.body.getResponseResponse.getResponseReturn.iSO.passportSvcRs
          .reports.report.reportData.waterLineReport;
        this.simplyfyResData(resData);
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error("Error fetching data:", error);
      });
  }

  simplyfyResData(res: ResponseData) {
    this.setState({ formatedResponse: res,
      result: true });
    this.setState({ loader: false });
  }

  render() {
    const { formatedResponse, result } = this.state;
    const leftTab = () => <>
      <StepOne InputArray={InputArray} />
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            className="confirm-button"
            onClick={() => {
              this.submit();
            }}
          >
            {result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "70%" }}
      >
        <div className="address-sample">
              Try Waterline Report with Sample Data
        </div>
      </div>
      }

      {result && <ResponseComponent responseData={formatedResponse} />}
    </>;
    const card = {
      title: "Waterline Report",
      description:
        "Provide carriers with the ability assesses flood risk across the contiguous United States, providing a relative risk score—ranging from 0 to 100—that uses advanced probabilistic simulations based on hydrologic and hydraulic engineering.",
      subTitle: "Verisk",
      icon: mapsIcMyLocation24px,
      developerDoc: "waterline"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Waterline Report"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default Waterline;
