const fromInformation = [
  {
    labelTestid: "full-name",
    labelTittle: "Full Name",
    inputTestid: "full-name-input",
    value: "John Doe",
    isDisabled: true,
    placeholder: "John Doe",
  },
  {
    labelTestid: "addressLine",
    labelTittle: "Address Line",
    inputTestid: "addressLine-input",
    value: "Waterfront Corporate Center II",
    isDisabled: true,
    placeholder: "Waterfront Corporate Center II",
  },
  {
    labelTestid: "city",
    labelTittle: "City",
    inputTestid: "city-input",
    value: "Hoboken",
    isDisabled: true,
    placeholder: "Hoboken",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "NJ",
    isDisabled: true,
    placeholder: "NJ",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "07030",
    isDisabled: true,
    placeholder: "07030",
  },
];

const toInformation = [
  {
    labelTestid: "to-full-name",
    labelTittle: "Full Name",
    inputTestid: "to-full-name-input",
    value: "Nexus for Insurance",
    isDisabled: true,
    placeholder: "Nexus for Insurance",
  },
  {
    labelTestid: "to-addressLine",
    labelTittle: "Address Line 1",
    inputTestid: "to-addressLine-input",
    value: "Seneca One, 1 W Seneca Street",
    isDisabled: true,
    placeholder: "Seneca One, 1 W Seneca Street",
  },
  {
    labelTestid: "to-city",
    labelTittle: "City",
    inputTestid: "to-city-input",
    value: "Buffalo",
    isDisabled: true,
    placeholder: "Buffalo",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "NY",
    isDisabled: true,
    placeholder: "NY",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "14203",
    isDisabled: true,
    placeholder: "14203",
  },
];

const mailInformation = [
  {
    labelTestid: "letter-template",
    labelTittle: "Letter Template",
    inputTestid: "letter-template-input",
    value: "33356",
    isDisabled: true,
    placeholder: "33356",
  },
  {
    labelTestid: "mail-type",
    labelTittle: "Mail Type",
    inputTestid: "mail-type-input",
    value: "USPS First Class",
    isDisabled: true,
    placeholder: "USPS First Class",
  },
  {
    labelTestid: "claims-number",
    labelTittle: "Claims Number",
    inputTestid: "claims-number-input",
    value: "CH7644",
    isDisabled: true,
    placeholder: "CH7644",
  },
  {
    labelTestid: "policy-number",
    labelTittle: "Policy Number",
    inputTestid: "policy-number-input",
    value: "LH125634",
    isDisabled: true,
    placeholder: "LH125634",
  },
];

export { fromInformation, toInformation, mailInformation };
