import React from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  IIPBannerImage,
  IIPKeyAssets,
  IIPStrategicValue
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const InsuranceInsightsPlatform = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Real-time data ingestion and orchestration",
        "Integration of third party/external data",
        "IOT capabilities: connected devices , smart homes, industrial sensors",
        "Unified insurance data model based on Acord.",
        "Integration of business rules in workflows"
      ],
      img: IIPKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "AI powered analytics for optimization and insights",
        "Real time visualization of KPIs on Ops, Sales, Finance etc.",
        "Unified analytics around structured, semi structured and unstructured data to deliver operational efficiency"
      ],
      img: IIPStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: IIPBannerImage,
    heading: "Insurance Insights Platform",
    subHeading:
      "The Insurance Insights Platform is a configurable operational insights and analytics solution that provides insights and analytics across functions of an insurance company",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-black"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default InsuranceInsightsPlatform;
