import React from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  IDBanner,
  IDKeyAssets,
  IDStrategicValue,
  MOIBannerImage,
  WIKeyAssets,
  WIStrategicValue
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const IntelligentDisputes = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Data Enrichment: transaction data enrichment (supported by 3rd party data providers) allows a cardholder to view clear and easy to understand details, reducing cardholder confusion",
        "Optimized self-service intake: a digital, configurable, intake experience based off network standards providing cardholders and agent a self-service experience; captures all required claim information and uses data to minimize manual inputs",
        "Intelligent Processing: use machine learning models and business-driven rules engines to assess claim information and determine case outcome without manual intervention"
      ],
      img: IDKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Deflect unnecessary disputes caused by customer confusion and provide cardholders with enriched transaction data to minimize transaction confusion (such as unclear merchant name and details)",
        "Eliminates manual intervention by leveraging a customer-centric self-service intake process to collect all the necessary details to raise a dispute and deploys ML-models and business-rules to evaluate, process and determine the case outcome",
        "Decrease in operational costs, specifically targeting cost reduction for issuers throughout the claims lifecycle and achieves 30-40% reduction in claims volumes and 40-50% reduction in friendly fraud",
        "Decrease time to process with straight through processing"
      ],
      img: IDStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: IDBanner,
    heading: "Intelligent Disputes",
    subHeading:
      "Removing cost and friction in the dispute lifecycle through data enrichment and AI-enabled decisioning models",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default IntelligentDisputes;
