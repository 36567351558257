import { NexusProgressBar } from "@nexus/react";
import React, { useEffect } from "react";
import "../Catalog.scss";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import { Document } from "../../../../../assets/images";
import Service from "../../../service/Service";
import DocumentResponse from "../mock_document";
import licenceFront from "../../../../../assets/images/licenceFront.jpg";
import licenceBack from "../../../../../assets/images/licenceBack.jpg";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/document-scan",
  listName: "platform-capabilities/document-scan"
};

const service = new Service();

const DocumentScan = () => {
  const [analysisCompleted,
    setAnalysisCompleted] = React.useState(false);
  const [loader,
    setLoader] = React.useState(false);

  const [firstName,
    setFirstName] = React.useState("");
  const [lastName,
    setLastName] = React.useState("");
  const [streetAddress,
    setStreetAddress] = React.useState("");
  const [country,
    setCountry] = React.useState("");
  const [city,
    setCity] = React.useState("");
  const [zip,
    setZip] = React.useState("");
  const [licenceNumber,
    setLicence] = React.useState("");
  const [dob,
    setDOB] = React.useState("");

  const card = {
    title: "Document Scan",
    description:
      "Nexus Document Scan provides the ability to scan any ID and Driver’s License across the globe to extract personal information automatically, removing the manual input requirements for customers.",
    subTitle: "Microblink",
    svg: Document,
    developerDoc: "document-scan"
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const camelize = (str: any) => str
    .split(" ")
    .map((item: any) => item.trim())
    .map((item: any) => item.toLowerCase())
    .map((item: any) => item[0].toUpperCase() + item.substring(1))
    .join(" ");

  const analysisFix = () => {
    if (analysisCompleted) {
      setAnalysisCompleted(false);
    }
    else {
      const params = DocumentResponse;
      setLoader(true);
      service
        .scanDocument(params)
        .then((response) => response.json())
        .then((data) => {
          setAnalysisCompleted(true);
          const licenceData = data.data.result;

          setFirstName(camelize(licenceData.firstName));
          setLastName(camelize(licenceData.lastName));
          setStreetAddress(camelize(licenceData.address.addressLine1));
          setCity(camelize(licenceData.address.city));
          setZip(licenceData.address.postalCode);
          // setCountry(camelize(licenceData.address.country));
          setCountry("United Kingdom");
          setLicence(licenceData.documentNumber);
          setDOB(licenceData.dateOfBirth.split("T")[0].toString());
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const leftTab = () => <div className="nexus-row">
    <div className="form-head step nexus-col-sm-4  nexus-col-md-2 nexus-col-lg-2 step step-0">
        Step 01
    </div>
    <div className="nexus-col-sm-4  nexus-col-md-6 nexus-col-lg-10">
      <div className="form-head">Driver’s License Front</div>
      <img src={licenceFront} alt="licenceFront" className="licenceImg" />
      <div className="form-head">Driver’s License Back</div>
      <img src={licenceBack} alt="licenceBack" className="licenceImg" />
      <div style={{ marginBottom: "20px" }}>
        <button
          type="button"
          data-testid="confirm-button"
          className="confirm-button"
          onClick={() => analysisFix()}
        >
          {analysisCompleted ? "Reset" : "Try with Sample License"}
        </button>
      </div>
    </div>
  </div>;
  const rightTab = () => <>
    {!analysisCompleted && <div
      className="nexus-row align-items-center nexus-ml-5"
      style={{ height: "100%" }}
    >
      <div className="address-sample">
            Try Document Scan with a Sample Driver’s License
      </div>
    </div>
    }
    {analysisCompleted && <div className="nexus-ml-5 test-result-container">
      <div className="nexus-mb-2 test-result">Test Result</div>
      <div className="test-result-box nexus-p-4 nexus-pr-5">
        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                First Name:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-value">
            {firstName}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                Last Name:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6  result-value">
            {lastName}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                Date of Birth:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6  result-value">
            {dob}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                Street Address:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6  result-value">
            {streetAddress}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                City:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6  result-value">
            {city}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                Post Code:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6  result-value">
            {zip}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-label">
                Country:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6  result-value">
            {" "}
            {country}
          </div>
        </div>

        <div className="nexus-row mt-2 mb-3">
          <div className="result-label nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6">
                License Number:
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-6 result-value">
            {licenceNumber}
          </div>
        </div>
      </div>
    </div>
    }
  </>;

  return (
    <div className="nexus-container updated-design padding-margin-0">
      {loader && <div className="loader-wrapper">
        <NexusProgressBar circle indeterminate className="loader-div" />
      </div>
      }
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Document Scan Tool"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default DocumentScan;
