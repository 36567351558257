/* eslint-disable react/prop-types */
/* eslint-disable quotes */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import "../SharedComponent/SharedLandingPage.scss";
import "./EcoSystem.scss";
import { ecosystemBanner } from "./Utils";
import EnableAdobeLaunch from "../../../adobeAnalytics/EnableAdobeLaunch";
import SharePointService from "../../service/SharePointService";
import { authProvider } from "../../../azureAuth/authConfig";
import SharedLandingPage from "../SharedComponent/SharedLandingPage";

const ADOBE_DATA = {
  pageName: "platform-ecosystem",
  listName: "platform-ecosystem"
};

const spService = new SharePointService();

class Ecosystem extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      itemsEcoSystems: []
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    // If logged in user found call SharePoint API to get the eco system data from SharePoint
    if (authProvider.getAccount()) {
      this.prepareEcoSystemItems();      
    }    
  }

  prepareEcoSystemItems = () => {
    spService.getEcoSystems().then((res) => res.json())
      .then((data) => {
        const ecoSystemList:any = [];
        // Convert SharePoint Object into UI specific object
        data?.value?.map((element:any) => {
          ecoSystemList.push({
            name: element.Title,
            description: element.Description,
            icon: '',
            domain: element.Category,
            id: element.ID
          });
          // Check if icon is avaiable. If Yes call sharepoint Api to get the icon
          if (element.Icon) {            
            spService.getAttachments(spService.ListNames.ECO_SYSTEM, element.ID, encodeURI(JSON.parse(element.Icon).fileName)).then((imgData) => {
              const updatedItems = [...this.state.itemsEcoSystems];
              const currentItem = updatedItems.find((item) => item.id === element.ID);
              currentItem.icon = imgData;
              this.setState({               
                itemsEcoSystems: ecoSystemList
              });
            });
          }
        });
        this.setState({          
          itemsEcoSystems: ecoSystemList
        });        
      });
  };  

  redirectToFunc = (url: string) => {
    this.props.history.push(`${url}`);
  };

  render() {
    return (
      <SharedLandingPage
        redirectFunction={this.redirectToFunc}
        bannerDetails={ecosystemBanner}
        cardList={this.state.itemsEcoSystems}
        showFilters={true}
        hideExploreBtn={true}
      />
    );
  }
}
export default Ecosystem;
