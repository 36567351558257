export const dateFormat = (date: Date) => {
  const fullDate = new Date(date);
  let day;
  day = fullDate.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;
  let month;
  month = (fullDate.getMonth() + 1).toString();
  month = month.length > 1 ? month : `0${month}`;
  const year = fullDate.getFullYear();
  const dateParse = `${year}-${month}-${day}T00:00:00Z`;

  return dateParse;
};

export const formatDate = (date: string) => {
  let value;
  value = "";
  if (typeof date !== "undefined") {
    if (date.length > 0) {
      value = date.slice(0, 10);
    }
  }

  return value;
};

export const dateInDMYformate = (date: string) => {
  let dmy;
  if (typeof date !== "undefined") {
    if (date.length > 0) {
      dmy = `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}`;
    }
  }

  return dmy;
};

export const camel2title = (camelCase: string) => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim();

export const pipeParser = (parseItem: string) => parseItem
  .toString()
  .replace(/,/g, "|")
  .replace(/\|\|/g, "|")
  .replace(/^\|/, '')    
  .replace(/\|$/, '')    
  .replace(/\|\|/g, "|")
  .replace(/^\|/, '') 
  .replace(/\|$/, '');