import React from "react";
import {
  NexusProgressBar,
  NexusFormField,
  NexusLabel,
  NexusInput
} from "@nexus/react";
import Service from "../../../service/Service";
import {
  CheckCircle,
  DocumentSignatureLogo
} from "../../../../../assets/images";
import CardDescription from "../../../CardDescriptions/CardDescription";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";

const ADOBE_DATA = {
  pageName: "platform-capabilities/document-signature",
  listName: "platform-capabilities/document-signature"
};

interface State {
  fullName: string;
  emailAddress: string;
  doucmentType: string;
  result: any;
  buttonValue?: string;
  loader?: boolean;
}

const service = new Service();
const { description } = new CardDescription();

class DocumentSignature extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      fullName: "",
      emailAddress: "",
      doucmentType: "",
      result: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    const { fullName, emailAddress, doucmentType } = this.state;

    if (this.state.buttonValue === "Reset") {
      this.setState({
        buttonValue: "Pay Now",
        result: null,
        fullName: "",
        emailAddress: "",
        doucmentType: ""
      });
    }
    else {
      this.setState({
        buttonValue: "Reset",
        fullName: fullName || "Lexis Ford",
        emailAddress: emailAddress || "nexusforinsurance@gds.ey.com",
        doucmentType: doucmentType || "Physician statement",
        loader: true
      });
      setTimeout(() => {
        this.setState({
          loader: false,
          result: "Not Null"
        });
      }, 1000);
    }
  }

  render() {
    const { fullName, emailAddress, doucmentType } = this.state;

    const leftTab = () => <div className="nexus-row">
      <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
      </div>
      <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
        <div className="form-head mb-4">Input Signer details</div>
        <div className="nexus-mb-2">
          <div className="italicText nexus-mb-1">Full Name</div>
          <div className="input-container">
            <NexusFormField>
              <NexusInput
                style={{ borderBottom: "1px solid #2e2e38" }}
                value={fullName}
                data-testid="full-name"
                placeholder="e.g. Lexis Ford"
                onInput={(
                  event: React.ChangeEvent<HTMLNexusInputElement>
                ) => {
                  this.setState({
                    fullName: event.target.value
                  });
                }}
                disabled
              />
            </NexusFormField>
          </div>
        </div>
        <div className="nexus-mb-2">
          <div className="italicText nexus-mb-1">Email Address</div>
          <div className="input-container">
            <NexusFormField>
              <NexusInput
                style={{ borderBottom: "1px solid #2e2e38" }}
                data-testid="email"
                value={emailAddress}
                placeholder="e.g. xyz@abc.com"
                onInput={(
                  event: React.ChangeEvent<HTMLNexusInputElement>
                ) => {
                  this.setState({
                    emailAddress: event.target.value
                  });
                }}
                disabled
              />
            </NexusFormField>
          </div>
        </div>
        <div className="nexus-mb-2">
          <div className="italicText nexus-mb-1">Document Type</div>
          <div className="input-container">
            <NexusFormField>
              <NexusInput
                style={{ borderBottom: "1px solid #2e2e38" }}
                value={doucmentType}
                data-testid="document-type"
                placeholder="e.g. Doctor statement"
                onInput={(
                  event: React.ChangeEvent<HTMLNexusInputElement>
                ) => {
                  this.setState({
                    doucmentType: event.target.value
                  });
                }}
                disabled
              />
            </NexusFormField>
          </div>
        </div>
        <div className="mb-4">
          <button
            type="button"
            data-testid="submit-btn"
            className="confirm-button"
            onClick={() => {
              this.apiCall();
            }}
          >
            {this.state.result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </div>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample
          <br />
              data
        </div>
      </div>
      }

      {this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-4 nexus-col-lg-10"
          style={{ width: "auto" }}
        >
          <div className="nexus-row">
            <img
              className="verified-icons"
              src={CheckCircle}
              alt="CheckCircle"
            />
            <div className="nexus-col-lg-11 nexus-col-md-6 nexus-col-xs-3">
              <div className="address-matched-text">
                    Digital Signature link generated succesfully and sent to
                    your nexusforinsurance@gds.ey.com.
              </div>
              <div className="address-matched-text nexus-mt-2 interstate-light">
                    Next Steps:
              </div>
              <div className="address-matched-text nexus-mt-2 interstate-light">
                    Open the link from your email to provide your digital
                    consent!
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Document Signature",
      description: description["Document Signature"],
      subTitle: "Docu Sign",
      svg: DocumentSignatureLogo,
      developerDoc: "document-signature"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Signature Validation Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default DocumentSignature;
