import { NexusProgressBar, NexusSelect } from "@nexus/react";
import React from "react";
import "../Catalog.scss";
import alertIcWarning24px from "@nexus/core/dist/assets/icons/alert/ic_warning_24px.svg";
import CatalogHeader from "../Header";
import Service from "../../../service/Service";
import { HAZARD_RISK } from "../../../Constants";
import CatalogDetailsContainer from "../DetailsContainer";
import HazardRiskByFire from "./Fire/HazardRiskByFire";
import FireRightView from "./Fire/FireRightView";
import HazardRiskByFlood from "./Flood/HazardRiskByFlood";

import FireStationRightView from "./FireStation/FireStationRightView";
import FireStationRiskByAddress from "./FireStation/FireStationRiskByAddress";

import FloodRightView from "./Flood/FloodRightView";
import {
  EarthquakeHazardRisk,
  EarthquakeHazardRiskRightView
} from "./EarthQuake";
import { CrimeHazardRisk, CrimeHazardRiskRightView } from "./Crime";
import {
  DistanceToFloodHazardRisk,
  DistanceToFloodHazardRiskRightView
} from "./DistanceToFlood";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/hazard-risk",
  listName: "platform-capabilities/hazard-risk"
};

interface State {
  loader: boolean;
  setAlreadySeached: boolean;
  searchFor: string;
  VINNumber: string;
  sampleVINNumber: string;
  plateNumber: string;
  samplePlateNumber: string;
  stateName: string;
  sampleStateName: string;
  result: any;
}

const service = new Service();

class HazardRisk extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      setAlreadySeached: false,
      searchFor: "",
      VINNumber: "",
      sampleVINNumber: "KL1TD526X5B4XXXXX",
      plateNumber: "",
      samplePlateNumber: "HJXXXX",
      stateName: "",
      sampleStateName: "IA",
      result: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
    this.setSearchFor("Fire");
  }

  setSearchFor(val: string) {
    this.setState({
      searchFor: val,
      setAlreadySeached: true,
      result: null
    });
  }

  sendDataToLeftView = (props: any) => {
    this.setState({ result: props });
  };

  enableLoader = () => {
    this.setState({ loader: true });
  };

  disableLoader = () => {
    this.setState({ loader: false });
  };

  render() {
    const { loader, searchFor, setAlreadySeached, result } = this.state;

    const card = {
      title: "Hazard Risk",
      description:
        "Provide carriers with the ability to search for any Crime, Earthquake, Fire, Flood, Firestation and distance to Flood zone Hazard Risks located nearby address or location.",
      subTitle: "Precisely",
      icon: alertIcWarning24px,
      developerDoc: "hazard-risk"
    };

    const leftTab = () => <div className="nexus-ml-1">
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Hazard Type</div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mt-2">
            <NexusSelect
              data-testid="searchFor"
              value={searchFor}
              disabled={result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setSearchFor(event.target.value)
              }
            >
              {HAZARD_RISK.map((item) => <option
                key={item}
                value={item}
                disabled={item === "Select One"}
              >
                {item}
              </option>)}
            </NexusSelect>
          </div>
        </div>
      </div>
      <div className="nexus-mt-2">
        {setAlreadySeached && <>
          {this.state.searchFor === "Fire" && <HazardRiskByFire
            apiResFromFire={this.sendDataToLeftView}
            enableLoader={this.enableLoader}
            disableLoader={this.disableLoader}
          />
          }
          {this.state.searchFor === "Flood" && <HazardRiskByFlood
            apiResFromFire={this.sendDataToLeftView}
            enableLoader={this.enableLoader}
            disableLoader={this.disableLoader}
          />
          }
          {this.state.searchFor === "Earthquake" && <EarthquakeHazardRisk
            apiResFromFire={this.sendDataToLeftView}
            enableLoader={this.enableLoader}
            disableLoader={this.disableLoader}
          />
          }
          {this.state.searchFor === "Crime" && <CrimeHazardRisk
            apiResFromFire={this.sendDataToLeftView}
            enableLoader={this.enableLoader}
            disableLoader={this.disableLoader}
          />
          }
          {this.state.searchFor === "Distance to Flood" && <DistanceToFloodHazardRisk
            apiResFromFire={this.sendDataToLeftView}
            enableLoader={this.enableLoader}
            disableLoader={this.disableLoader}
          />
          }
          {this.state.searchFor === "Firestation" && <FireStationRiskByAddress
            apiResFromFire={this.sendDataToLeftView}
            enableLoader={this.enableLoader}
            disableLoader={this.disableLoader}
          />
          }
        </>
        }
      </div>
    </div>;
    const rightTab = () => <>
      {!result && <div
        className="col-12 d-flex justify-content-center align-items-center try-address-text"
        style={{ height: "100%" }}
      >
        <div className="address-sample">Try with Sample Data</div>
      </div>
      }

      {result && <>
        {this.state.searchFor === "Fire" && <FireRightView result={result} />
        }
        {this.state.searchFor === "Flood" && <FloodRightView result={result} />
        }
        {this.state.searchFor === "Earthquake" && <EarthquakeHazardRiskRightView result={result} />
        }
        {this.state.searchFor === "Crime" && <CrimeHazardRiskRightView result={result} />
        }
        {this.state.searchFor === "Distance to Flood" && <DistanceToFloodHazardRiskRightView result={result} />
        }
        {this.state.searchFor === "Firestation" && <FireStationRightView result={result} />
        }
      </>
      }
    </>;

    return (
      <div className="nexus-container padding-margin-0 updated-design hazard-risk">
        {loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Hazard Risk"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default HazardRisk;
