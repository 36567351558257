import React, { Component } from "react";
import { CheckCircle } from "../../../../../../assets/images";

class FireStation extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    // const fireStationResponse = {
    //   fireDepartment: {
    //     type: 'ssss',
    //     name: 'ssss',
    //     numberOfStations: 'sssss',
    //     contactDetails: {
    //       address: { formattedAddress: 'sssss' },
    //       phone: 'ssssss',
    //       fax: 'ssssss'
    //     }
    //   },
    //   geometry: { coordinates: [] },
    //   travelDistance: { value: 1000 },
    //   travelTime: { value: 1000 }
    // };
    const {
      fireDepartment,
      geometry,
      travelDistance,
      travelTime
      // } = fireStationResponse;
    } = this.props.result.fireStationResponse.fireStation[0];

    const { type, name, numberOfStations, contactDetails } = fireDepartment;

    return (
      <div className="fire-station-ctr">
        <strong>Test Result</strong>
        <div className="fire-station-result">
          <div style={{ display: "flex" }}>
            <div>
              <img src={CheckCircle} alt="CheckCircle" />
            </div>
            <div className="check-txt" style={{ marginLeft: "10px" }}>
              Fire Station is located within {travelDistance.value} Miles with a
              travel Distance of {travelTime.value} Minutes
            </div>
          </div>
          <div style={{ margin: "20px 0px 0px 40px" }}>
            <div className="">
              <strong>Fire Department Details </strong>
            </div>
            <div className="">
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Name</div>
                  <div>:{name}</div>
                </div>
              </div>
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Type</div>
                  <div>:{type}</div>
                </div>
              </div>
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Number of stations</div>
                  <div>:{numberOfStations}</div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <strong>Contact Information </strong>
            </div>
            <div className="">
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Address</div>
                  <div>:{contactDetails.address.formattedAddress}</div>
                </div>
              </div>
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Phone No</div>
                  <div>:{contactDetails.phone}</div>
                </div>
              </div>
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Fax</div>
                  <div>:{contactDetails.fax}</div>
                </div>
              </div>
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Lattitude</div>
                  <div>:{geometry.coordinates[0]}</div>
                </div>
              </div>
              <div className="">
                <div className="result-txt">
                  <div className="hd-txt">Longitude</div>
                  <div>:{geometry.coordinates[1]}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FireStation;
