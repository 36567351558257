import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./FSSolutionsExploreMore.scss";
import backLink from "@nexus/core/dist/assets/icons/action/ic_arrow_left_alt_24px.svg";
import arrowForward from "@nexus/core/dist/assets/icons/action/ic_arrow_right_alt_24px.svg";
import { NexusIcon } from "@nexus/react";

interface IFSSolutions {
  pillarContent: any;
  bannerContent: any;
  setShowModal?: (val: boolean) => void;
}
const FSSolutionExploreMoreCommonComponent = ({
  pillarContent,
  bannerContent,
  setShowModal
}: IFSSolutions) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backLinkCallBack = () => {
    history.push(bannerContent.previousLinkPath);
  };
  const bannerContentBtn = bannerContent.bannerButtonDetails;
  const imageClass = bannerContent.heading === "Interaction Utility" ? "keypillar-img solution-details-image" : "keypillar-img";
  const bannerButtonClass = bannerContentBtn && bannerContentBtn.isBgColorWhite ? "banner-btn-white banner-btn-text-black" : "banner-btn-black banner-btn-text-white";
  const merchantClass = bannerContent.heading === "Merchants Offers & Insights" ? "merchant-offers" : "";
  const idClass = bannerContent.heading === "Intelligent Disputes" ? "intelligent-disputes" : "";

  const redirectFun = () => {
    if (setShowModal) {
      setShowModal(true);
    }
    else {
      window.open(bannerContent.bannerButtonDetails.url);
    }
  };

  return (
    <>
      <div className="nexus-container home-page p-0">
        <div
          className="nexus-row mb-4 p-1 fs-solutions-page-header py-4"
          style={{ backgroundImage: `url(${bannerContent.img})` }}
        >
          <div
            className={`nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-12 nexus-col-xl-12 nexus-mt-1 nexus-ml-5 back-link ${bannerContent.contentClass}`}
            onClick={() => backLinkCallBack()}
          >
            <NexusIcon src={backLink} />
            <span className="nexus-pl-2">Back</span>
          </div>
          <div
            className={`nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-5 nexus-col-xl-5 nexus-ml-5 nexus-mt-4`}
          >
            <div
              className={`nexus-h2 banner-header ${bannerContent.contentClass}`}
            >
              {bannerContent.heading}
            </div>
            <div
              className={`nexus-mt-3 nexus-body banner-sub-header ${bannerContent.contentClass} nexus-mb-2`}
            >
              {bannerContent.subHeading}
            </div>
            {bannerContent.bannerButtonDetails && <div>
              <button
                className={`nexus-btn ${bannerButtonClass} nexus-btn-medium nexus-body`}
                onClick={() => redirectFun()}
              >
                {bannerContent.bannerButtonDetails.text}
              </button>
            </div>
            }
          </div>
        </div>
        <div>
          {pillarContent.map((item: any, index: number) => <div
            className="nexus-row m-0"
            key={item.title}
            style={{ backgroundColor: index % 2 > 0 ? "#FAFAFA" : "#ffffff" }}
          >
            {index % 2 === 0 && <div
              className={`${merchantClass} nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 nexus-col-xl-6 nexus-center-xl nexus-center-lg nexus-center-sm nexus-center-md nexus-p-8`}
            >
              <img src={item.img} alt="img" className={imageClass} />
            </div>
            }
            <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 nexus-col-xl-6 nexus-middle-xs nexus-pl-8">
              <div>
                <div className="nexus-h3 nexus-mb-2">{item.title}</div>
                {item.desc.map((value: string, descIndex: number) => <div
                  className="nexus-flex-row nexus-row nexus-pb-2"
                  key={`${value}-${descIndex}`}
                >
                  <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-1 nexus-col-xl-1">
                    <NexusIcon
                      src={arrowForward}
                      className={`pillar-desc-arrow-color`}
                    />
                  </div>
                  <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-9 nexus-col-xl-9 nexus-subtitle">
                    {value}
                  </div>
                </div>)}
              </div>
            </div>
            {index % 2 > 0 && <div
              className={`${idClass} nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 nexus-p-8 nexus-center-xl nexus-center-lg nexus-center-sm nexus-center-md`}
            >
              <img src={item.img} alt="img" className="keypillar-img" />
            </div>
            }
          </div>)}
        </div>
      </div>
    </>
  );
};
export default FSSolutionExploreMoreCommonComponent;
