import React from "react";
import paths from "../../../../../utils/PathsUtils";
import { WIBannerImage, WIKeyAssets, WIStrategicValue } from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const WealthInteroperability = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Aggregate siloed data on a single platform",
        "Bi-directional data flow so prospect information can update from CRM to advisor desktop and financial planning applications",
        "Unified wealth model gives advisors real-time access to holistic data and enables them to provide value-added services to clients",
        "Comprehensive dashboard enables enhanced trading"
      ],
      img: WIKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "360 degree view of the customer enables wealth managers to connect the dots and contextualize advice ",
        "Reduce time to market by leveraging EY Nexus’ capabilities and accelerators",
        "Advisors can better understand when customers are missing their personal investment targets and adjust portfolios accordingly",
        "Reduce time and effort spent entering data in multiple capability applications and thereby increase advisor efficiency"
      ],
      img: WIStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: WIBannerImage,
    heading: "Wealth Interoperability",
    subHeading:
      "Wealth Interoperability can aggregate previously siloed data on a single platform and helps financial advisors access client data across multiple applications (CRM, financial planning, trading, client onboarding, account servicing, reports, etc.) for a comprehensive view of the customer.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-black"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default WealthInteroperability;
