import { NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/vehicle-symbol",
  listName: "platform-capabilities/vehicle-symbol",
};

const VehicleSymbol = () => {
  // eslint-disable-next-line global-require

  const [vin, setVin] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [type, setType] = useState("");

  const [result, setResult] = React.useState<any>(null);
  const [loader, setLoader] = React.useState(false);

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
  }, []);

  const reset = () => {
    setResult(null);
    setMake("");
    setModel("");
    setYear("");
    setType("");
    setVin("");
  };
  const submit = () => {
    if (result) {
      reset();
    } else {
      setVin("VIN12345");
      setMake("Honda");
      setModel("CADI");
      setYear("1980");
      setType("Antique");

      setLoader(true);

      setTimeout(() => {
        setLoader(false);
        setResult(true);
      }, 2000);
    }
  };

  const card = {
    title: "Vehicle Symbol",
    description:
      "Provide carriers with the ability to retrieve the vehicle symbol associated to a VIN number. Symbols are numeric representations of a vehicle’s relative propensity for loss, they are based on the retail price of each vehicle and then examined against actual loss experience for further refinement",
    subTitle: "Verisk",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "vehicle-symbol",
  };

  const leftTab = () => (
    <>
      <div className="nexus-pr-4">
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">Enter VIN/Plate details</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">VIN/Plate Number</div>
              <div className="input-container">
                <input
                  type="text"
                  value={vin}
                  data-testid="vin"
                  onChange={(event) => setVin(event.target.value)}
                  placeholder={"e.g. VIN12345"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="nexus-row nexus-mt-4">
          <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 02
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">Enter Vehicle details</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Make</div>
              <div className="input-container">
                <input
                  type="text"
                  value={make}
                  data-testid="make"
                  onChange={(event) => setMake(event.target.value)}
                  placeholder={"e.g. Toyota"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Model</div>
              <div className="input-container">
                <input
                  type="text"
                  value={model}
                  data-testid="model"
                  onChange={(event) => setModel(event.target.value)}
                  placeholder={"e.g. Camry"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Year</div>
              <div className="input-container">
                <input
                  type="text"
                  value={year}
                  data-testid="year"
                  onChange={(event) => setMake(event.target.value)}
                  placeholder={"e.g. 2000"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Type</div>
              <div className="input-container">
                <input
                  type="text"
                  value={type}
                  data-testid="type"
                  onChange={(event) => setType(event.target.value)}
                  placeholder={"e.g. Regular"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className="confirm-button"
                data-testid="submit-btn"
                onClick={() => {
                  submit();
                }}
              >
                {result ? "Reset" : "Try with Sample Data"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!result && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Vehicle Symbol with sample data
          </div>
        </div>
      )}
      {result && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-my-2 test-result">Test Result</div>
          <div>
            <div
              className="test-result-box nexus-mb-2 nexus-pl-3 nexus-py-2"
              style={{ width: "90%" }}
            >
              <div className="nexus-my-2 test-result">Vehicle Symbol:</div>
              <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
                <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                  Physical Damage
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                  KK
                </div>
              </div>
              <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
                <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                  Bodily-Injury
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                  KK
                </div>
              </div>
              <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
                <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                  Collision
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                  4K
                </div>
              </div>
              <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
                <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                  Comprehensive
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                  4K
                </div>
              </div>
              <div className="nexus-row" style={{ width: "100%" }}>
                <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                  Medical Payments
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                  4K
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Vehicle Symbol"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default VehicleSymbol;
