import React from "react";
import "../CatalogStyle.scss";
import {
  NexusProgressBar,
  NexusFormField,
  NexusInput,
  NexusLabel
} from "@nexus/react";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/address-parsing",
  listName: "platform-capabilities/address-parsing"
};

interface State {
  loader: boolean;
  addressText: string;
  result: boolean;
}

const service = new Service();
class AddressParsing extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      addressText: "",
      result: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit = () => {
    if (this.state.result) {
      this.setState({
        result: false,
        addressText: ""
      });
    }
    else {
      this.setState({
        loader: true,
        addressText: "1 Infinite Loop, Cupertino, CA"
      });
      setTimeout(() => {
        this.setState({
          loader: false,
          result: true
        });
      }, 1000);
    }
  };

  render() {
    const leftTab = () => <>
      <div className="nexus-row">
        <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 01
        </div>
        <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head">Enter Text</div>
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6 nexus-mt-1">
          <NexusFormField>
            <NexusLabel data-testid="label-first-name">
                Address Text
            </NexusLabel>
            <NexusInput
              data-testid="input-textbox-maxlen"
              value={this.state.addressText}
              disabled
              placeholder="e.g. 1 Infinite Loop, Cupertino, CA"
            ></NexusInput>
          </NexusFormField>
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            className="confirm-button"
            onClick={() => {
              this.submit();
            }}
          >
            {this.state.result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try Extracting Address with Sample Data
        </div>
      </div>
      }

      {this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="test-result-box nexus-p-4">
          <div className="address-head my-3 pl-4">Addressess</div>
          <div className="address-container pl-4">
            <div className="address-row">
              <div className="validated-address-value">1 Infinite loop</div>
              <div className="validated-address-text">Address Line 1</div>
            </div>
            <div className="address-row">
              <div className="validated-address-value">
                    Cupertino CA 95014-5723
              </div>
              <div className="validated-address-text">
                    City, State, ZIP Code, 4-Dash
              </div>
            </div>
            <div className="address-row">
              <div className="validated-address-value">37.350509</div>
              <div className="validated-address-text">Latitude</div>
            </div>
            <div className="address-row">
              <div className="validated-address-value">-122.025284</div>
              <div className="validated-address-text">Longitude</div>
            </div>
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Address Parsing",
      description:
        "Provide carriers with the ability to extract detailed address based on a given text.",
      subTitle: "Smarty Streets",
      icon: mapsIcMyLocation24px,
      developerDoc: "address-parsing"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Address Parsing Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default AddressParsing;
