import React from "react";
import paths from "../../../../../utils/PathsUtils";
import { keyAssetsIU, IUBannerImage, IUStrategicValue } from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const InteractionUtility = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Insights and event-driven approach to determine how to personalize an interaction with a customer",
        "Aggregation of customer events, patterns, and behavioral patterns",
        "Real-time, contextually relevant interactions at the right time and right channel"
      ],
      img: keyAssetsIU,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Increases customer engagement by providing hyper-personalized and contextual outreach",
        "Leverages the Nexus Nudge Model Framework to accelerate insights model development"
      ],
      img: IUStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: IUBannerImage,
    heading: "Interaction Utility",
    subHeading:
      "Interaction Utility is an insight and event-driven tool that utilizes stream-based processing to determine how to personalize an interaction with a customer based on an aggregate of customer events, patterns inferred, and behavioral preferences. Through IU, we are able to provide real-time, contextually relevant interactions at the right time, right moment and right channel to drive engagement and influence customer behavior.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default InteractionUtility;
