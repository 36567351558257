import React, { Component } from "react";
import PricingHeading from "../SharedComp/PricingHeading";
import ModulePricing from "../SharedComp/ModulePricing";
import ContactUs from "../SharedComp/ContactUs";
import "./style.scss";
import { light, dark, darkplus } from "../CorePlatformPricing/Assets";
import { paths } from "../../../../../utils/PathsUtils";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "core-platform-pricing",
  listName: "core-platform-pricing"
};
const coreplatformdeck = "https://eyus.sharepoint.com/sites/FSNexus/Shared%20Documents/Forms/FS%20Nexus%20Architecture.aspx?csf=1&web=1&e=jsGKfO&cid=087f0054%2D891d%2D4a75%2Dbca9%2De39fa05fe2cc&RootFolder=%2Fsites%2FFSNexus%2FShared%20Documents%2FGeneral&FolderCTID=0x01200053ECD5421A064C4CAF727513A77CECD6";
const { commercialNPricing } = paths;
class CorePlatformPricing extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      modulePricing: [
        {
          col1: "Signal acquisition and event discovery",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Streaming data pipelines",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Event driven orchestration",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Microservice choreography",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Platform manager as a service",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Insight led customer experiences",
          col2: "",
          col3: dark,
          col4: dark
        },
        {
          col1: "Insight enabled preference tooling",
          col2: "",
          col3: dark,
          col4: dark
        },
        {
          col1: "Real time analytics",
          col2: "",
          col3: dark,
          col4: dark
        },
        {
          col1: "Customer journey analytics",
          col2: "",
          col3: dark,
          col4: dark
        },
        {
          col1: "Acquisition nudging",
          col2: "",
          col3: "",
          col4: darkplus
        },
        {
          col1: "Intelligent product matching",
          col2: "",
          col3: "",
          col4: darkplus
        },
        {
          col1: "Predictive monitoring & communication",
          col2: "",
          col3: "",
          col4: darkplus
        },
        {
          col1: "Complex event processing",
          col2: "",
          col3: "",
          col4: darkplus
        }
      ]
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <>
        <div className="breadcrums">
          <div className="nexus-container">
            <div className="nexus-row">
              <div className="nexus-col-sm-4">
                <a
                  data-testid="back-btn"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "#2E2E38"
                  }}
                  onClick={() => {
                    this.props.history.push(commercialNPricing);
                  }}
                >
                  Core Platform
                </a>
                <strong className="m-2">{" > "}</strong>
                <strong style={{ fontWeight: "bold" }}>Platform Pricing</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="nexus-container" style={{ color: "black" }}>
          <div className="nexus-row">
            <PricingHeading
              title="Core Platform"
              description="Leverage a versatile, cloud
              ready framework, with ready
              to use building blocks and
              real time intelligence. Launch
              customer centric services
              globally, reliably, and faster
              than ever before."
            />
            <div className="nexus-col-xs-4 nexus-mt-2 cpp-table-heading">
              Core Platform Tiers & Pricing{" "}
            </div>
            <div className="nexus-col-xs-4 table-border">
              <div className="nexus-row">
                <div
                  className="nexus-col-xs-1 our-product grey-box"
                  style={{ paddingLeft: "16px" }}
                >
                  Our Packages
                </div>
                <div className="nexus-col-xs-1 white-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 cpp-heading">Bronze</div>
                    <div className="nexus-col-xs-4 cpp-heading-2">
                      component driven
                    </div>
                    <div className="nexus-col-xs-4 cpp-heading-3 nexus-mt-2">
                      Platform Base Price
                    </div>
                    <div className="nexus-col-xs-4 cpp-price nexus-mt-1">
                      $1.75M
                    </div>
                    <div className="nexus-col-xs-4 plus-sign nexus-mt-2">+</div>
                    <div className="nexus-col-xs-4 cpp-heading-4 nexus-mt-2">
                      Annual Maintenance
                    </div>
                    <div className="nexus-col-xs-4 cpp-price-2 nexus-mt-1">
                      $350K
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-1 grey-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 cpp-heading">Silver</div>
                    <div className="nexus-col-xs-4 cpp-heading-2">
                      insight driven
                    </div>
                    <div className="nexus-col-xs-4 cpp-heading-4 nexus-mt-2">
                      Market Adjusted Tiered Base Price
                    </div>
                    <div className="nexus-col-xs-4 cpp-price-2 nexus-mt-1">
                      $3.80M
                    </div>
                    <div className="nexus-col-xs-4 cpp-heading-4 nexus-mt-2">
                      Annual Maintenance
                    </div>
                    <div className="nexus-col-xs-4 cpp-price nexus-mt-1">
                      $760K
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-1 white-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 cpp-heading">Gold</div>
                    <div className="nexus-col-xs-4 cpp-heading-2">
                      business demand driven
                    </div>
                    <div className="nexus-col-xs-4 cpp-heading-4 nexus-mt-2">
                      Market Adjusted Tiered Base Price
                    </div>
                    <div className="nexus-col-xs-4 cpp-price-2 nexus-mt-1">
                      $7.20M
                    </div>
                    <div className="nexus-col-xs-4 cpp-heading-4 nexus-mt-2">
                      Annual Maintenance
                    </div>
                    <div className="nexus-col-xs-4 cpp-price nexus-mt-1">
                      $1.44M
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-4 table-head nexus-pb-3">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-1 category-head">
                      Capabilities delivered
                    </div>
                    <div className="nexus-col-xs-1 cpp-center">
                      <div className="includes">Includes:</div>
                    </div>
                    <div className="nexus-col-xs-1 cpp-center">
                      <div className="includes">Includes:</div>
                    </div>
                    <div className="nexus-col-xs-1 cpp-center">
                      <div className="includes">Includes:</div>
                    </div>
                  </div>
                </div>
                {/* <div className="nexus-col-xs-4 nexus-my-2">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-1 category-head">Capabilities delivered</div>
                    <div className="nexus-col-xs-1 category-head">Bronze - component driven</div>
                    <div className="nexus-col-xs-1 category-head">SILVER - insight driven</div>
                    <div className="nexus-col-xs-1 category-head">gold business demand driven</div>
                  </div>
                </div> */}
                <ModulePricing moduleData={this.state.modulePricing} />
              </div>
            </div>
            <ContactUs />
          </div>
        </div>
      </>
    );
  }
}
export default CorePlatformPricing;
