/* eslint-disable no-undef */
/* eslint-disable no-process-env */

import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import 'regenerator-runtime/runtime';

export const msalConfig = {
  auth: {
    clientId: '98279c58-d2a4-4eb1-a5c2-de65675cc773',
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: false
  },
  cache: {
    CacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loadFrameTimeout: 10000
  }
};
// const client = new Msal.UserAgentApplication(msalConfig);
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid',
    'profile',
    'User.Read',
    'Sites.Read.All',
    'AllSites.Read'
  ]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_EY_AD_GRAPH_END_POINT
};

export const spApiTokenReqConfig = {
  scopes: ['https://sites.ey.com/.default'],
  redirectUri: window.location.origin
};

export const graphApiTokenReqConfig = {
  scopes: ['https://graph.microsoft.com/Sites.Read.All'],
  redirectUri: window.location.origin
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${window.location.origin}/auth.html`
};
export const authProvider = new MsalAuthProvider(
  msalConfig,
  loginRequest,
  options
);
