/* eslint-disable no-process-env */
import * as Encryption from "./Encryption.js";
declare const _satellite: any;
const digitaldata = {
  page: {
    environment:
      process.env.REACT_APP_BASE_ENV === "demo" ? "UAT" : process.env.REACT_APP_BASE_ENV?.toUpperCase(),
    eYProduct: "Nexus for Insurance",
    eYApplication: "Nexus Business Portal",
    serviceLine: "Technology Consulting",
    hierarchy1: "Adobe Analytics",
    language: "en"
  },
  engagement: {
    serviceLine: "Technology Consulting",
    subServiceLine: "NexusForInsurance"
  },
  user: {
    userId: Encryption.hex_sha256(
      localStorage.getItem("loggedInUser") ? localStorage.getItem("loggedInUser") : "test_dev@gds.ey.com"
    )
  }
};

declare global {
  interface Window {
    digitaldata: any;
  }
}

const EnableAdobeLaunch = (data: any) => {
  if (
    // eslint-disable-next-line no-restricted-globals
    typeof location.hostname !== "undefined" && location.hostname.indexOf("localhost") === -1
  ) {
    window.digitaldata = digitaldata;
    window.digitaldata.page.pageName = data.pageName;
    window.digitaldata.page.listName = data.listName;

    if (
      typeof _satellite !== "undefined" && typeof _satellite.track === "function"
    ) {
      _satellite.track("pageTrack");
    }
  }
};

export default EnableAdobeLaunch;
