import React, { useState } from "react";
import {
  NexusModal,
  NexusModalFooter,
  NexusModalBody,
  NexusModalHeader,
  NexusModalHeaderSubtitle,
} from "@nexus/react";

const PlayDemoVideo = (props: any) => {
  const { showmodel, closeModel, videoUrl, title } = props;
  const [show, setShow] = useState(showmodel);

  const closeClickHandler = () => {
    setShow(false);
    closeModel();
  };

  return (
    // <div><h1>showmodel</h1></div>
    <NexusModal data-testid="btn-show-modal" size="lg" id="modal" show={show}>
      <NexusModalHeader
        data-testid="modal-header-text"
        onCloseEvent={() => closeClickHandler()}
      >
        {title}
      </NexusModalHeader>
      <NexusModalBody>
        {videoUrl === "" && <h6>Please reach out Nexus team for demo</h6>}
        {videoUrl !== "" && (
          <video
            role="presentation"
            controlsList="nodownload"
            width={"100%"}
            height={"100%"}
            autoPlay={true}
            controls={true}
            src={videoUrl}
          ></video>
        )}
      </NexusModalBody>
    </NexusModal>
  );
};

export default PlayDemoVideo;
