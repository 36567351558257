/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import { NexusRadio, NexusProgressBar } from "@nexus/react";
import CardDescription from "../../../CardDescriptions/CardDescription";
import Service from "../../../service/Service";
import { CheckCircle, geocoding } from "../../../../../assets/images";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/geocoding",
  listName: "platform-capabilities/geocoding"
};

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  stateProvince: string;
  zip: string;
}

interface Axis {
  latitude: string;
  longitude: string;
}

interface State {
  address: Address;
  reverseInputsSmaple: Axis;
  result?: any;
  buttonValue: string;
  forward: boolean;
  loader: boolean;
}

const service = new Service();
const { description } = new CardDescription();

class GeoCoding extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      address: {
        addressLine1: "3100 Hansen Way",
        addressLine2: "Apartment 10",
        city: "Palo Alto",
        country: "US",
        stateProvince: "CA",
        zip: "94304"
      },
      reverseInputsSmaple: {
        latitude: "40.018301",
        longitude: "-71.987666"
      },
      result: null,
      buttonValue: "Try with Sample Data",
      forward: true,
      loader: false
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    if (this.state.buttonValue === "Reset") {
      this.setState({ buttonValue: "Try with Sample Data" });
      this.setState({ result: null });
    }
    else {
      this.setState({ loader: true });
      this.setState({ buttonValue: "Reset" });
      const endpont = this.state.forward ? "forward" : "reverse";
      const requestParam = this.state.forward ? this.state.address : this.state.reverseInputsSmaple;
      service
        .geoCoding(requestParam, endpont)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            this.setState({ result: data.data });
            this.setState({ loader: false });
          }
          // setTimeout(() => {
          // }, 100);
        })
        .catch();
    }
  }

  buttonSubmit() {
    return (
      <>
        <div className="nexus-mb-4">
          <button
            type="button"
            className="confirm-button"
            data-testid="submit-btn"
            onClick={() => this.apiCall()}
          >
            {this.state.buttonValue}
          </button>
        </div>
      </>
    );
  }

  changeRirectionOption(option: boolean) {
    if (this.state.forward !== option) {
      this.setState({ buttonValue: "Try with Sample Data",
        result: null });
      // this.state.result = null;
    }
    this.setState({ forward: option });
  }

  directionOption() {
    return (
      <>
        <div className="step-1">
          <div className="form-head">Pick One</div>
          <div className="radio-container nexus-row nexus-pl-1">
            <NexusRadio
              name="option"
              id="forward"
              data-testid="forward-opt"
              checked={this.state.forward}
              disabled={this.state.result}
              onInput={() => this.changeRirectionOption(true)}
            >
              Forward
            </NexusRadio>
            <NexusRadio
              className="ml-5"
              name="option"
              id="reverse"
              data-testid="reverse-opt"
              checked={!this.state.forward}
              disabled={this.state.result}
              onInput={() => this.changeRirectionOption(false)}
            >
              Reverse
            </NexusRadio>
          </div>
        </div>
      </>
    );
  }

  forward() {
    const { addressLine1, addressLine2, city, stateProvince, country, zip } = this.state.address;

    return (
      <>
        <div className="nexus-row">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            {this.directionOption()}
          </div>
        </div>
        <div className="nexus-row nexus-mt-2">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
            Step 02
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="form-head">Enter Address</div>
            <div className="address-form">
              <div className="nexus-mb-2">
                <div className="italicText nexus-mb-1">Address Line 1:</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    data-testid="address-line1"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${addressLine1}` : addressLine1
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="nexus-mt-2 nexus-mb-2">
                <div className="italicText nexus-mb-1">Address Line 2:</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    data-testid="address-line2"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${addressLine2}` : addressLine2
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="nexus-mt-2 nexus-mb-2">
                <div className="italicText nexus-mb-1">City:</div>
                <div className="input-container">
                  <input
                    type="text"
                    data-testid="city"
                    className="line-input-box"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${city}` : city
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="nexus-mt-2 nexus-mb-2">
                <div className="italicText nexus-mb-1">Country:</div>
                <div className="input-container">
                  <input
                    type="text"
                    data-testid="country"
                    className="line-input-box"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${country}` : country
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="nexus-mt-2 nexus-mb-2">
                <div className="italicText nexus-mb-1">State:</div>
                <div className="input-container">
                  <input
                    type="text"
                    data-testid="state"
                    className="line-input-box"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${stateProvince}` : stateProvince
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="nexus-mt-2 nexus-mb-2">
                <div className="italicText nexus-mb-1">Zip Code:</div>
                <div className="input-container">
                  <input
                    type="text"
                    data-testid="zip"
                    className="line-input-box"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${zip}` : zip
                    }
                    disabled
                  />
                </div>
              </div>
              {this.buttonSubmit()}
            </div>
          </div>
        </div>
      </>
    );
  }

  commonResponse() {
    const {
      addressLine1,
      stateProvince,
      city,
      country,
      zip,
      latitude,
      longitude,
      highHouse,
      lowHouse,
      postalCode
    } = this.state.forward ? this.state.result : this.state.result.addresses[0];

    return (
      <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="test-result-box nexus-p-4">
          <div>
            <div className="address-row">
              <div className="result-label">{addressLine1}</div>
              <div className="result-value result-value-sm">
                Delivery Line 1
              </div>
            </div>
            <div className="address-row">
              <div className="result-label">
                {city}
                {", "}
                {stateProvince}
                {", "}
                {zip ? `${zip}` : `${postalCode} - 0001`}
              </div>
              <div className="result-value result-value-sm">
                City, State, ZIP Code, 4-Dash
              </div>
            </div>
            <div className="address-row">
              <div className="result-label">{country}</div>
              <div className="result-value result-value-sm">Country</div>
            </div>
            <div className="address-row">
              <div className="result-label">{latitude}</div>
              <div className="result-value result-value-sm">Latitude</div>
            </div>
            <div className="address-row">
              <div className="result-label">{longitude}</div>
              <div className="result-value result-value-sm">Longitude</div>
            </div>
            <div className="address-row">
              <div className="result-label">
                Range {lowHouse} - {highHouse} is on the LEFT side of the street{" "}
              </div>
              <div className="result-value result-value-sm">
                Low Range - High Range
              </div>
            </div>
            <div className="address-row">
              <div className="result-label">Range has EVEN house numbers</div>
              <div className="result-value result-value-sm">House Numbers</div>
            </div>
          </div>
          <div className="adress-status-container">
            <div className="address-status-row">
              <div>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="address-matched-text">
                Match point located at the house location.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  reverse() {
    const { latitude, longitude } = this.state.reverseInputsSmaple;

    return (
      <>
        <div className="nexus-row">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            {this.directionOption()}
          </div>
        </div>
        <div className="nexus-row nexus-mt-2">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
            Step 02
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="form-head">Enter Address</div>
            <div className="address-form">
              <div className="nexus-mt-1 nexus-mb-2">
                <div className="italicText nexus-mb-1">Lattitude :</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${latitude}` : latitude
                    }
                    data-testid="latitude"
                    disabled
                  />
                </div>
              </div>
              <div className="mt-2 nexus-mb-2">
                <div className="italicText nexus-mb-1">Longitude :</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${longitude}` : longitude
                    }
                    disabled
                    data-testid="longitude"
                  />
                </div>
              </div>
              {this.buttonSubmit()}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const leftTab = () => {
      if (this.state.forward) {
        return this.forward();
      }

      return this.reverse();
    };

    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try Extracting Address with Sample Data
        </div>
      </div>
      }

      {this.state.result && <div style={{ width: "100%",
        height: "530px" }}>
        {this.commonResponse()}
      </div>
      }
    </>;
    const card = {
      title: "Geocoding",
      description: description.Geocoding,
      subTitle: "Google, Mapbox, Precisely, Verisk",
      svg: geocoding,
      developerDoc: "geocoding"
    };

    return (
      <div className="nexus-container updated-design padding-margin-0">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Geocoding Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default GeoCoding;
