import React, { useEffect, useState } from "react";
import "../CatalogStyle.scss";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import { NexusProgressBar, NexusRadio } from "@nexus/react";
import CatalogHeader from "../Header";
import moment from "moment";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/health-score",
  listName: "platform-capabilities/health-score"
};

const HealthScore = () => {
  const service = new Service();
  const [loader,
    setLoader] = useState(false);
  const [result,
    setResult] = useState<any[]>([]);
  const [healthScoreType,
    setHealthScoreType] = useState("HeathScore");
  const [buttonName,
    seButtonName] = useState("Try with Sample Data");
  const [userId,
    setUserId] = useState("");
  const card = {
    title: "Health Score",
    description:
      "Provide carriers to manage biometrics, health advice, health summary, VO2 max, and user data by integrating with the leading health score providers.",
    subTitle: "PAI",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "health-score"
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const getHealthData = () => {
    setUserId("3081978003");
    const params = {
      userId: "3081978003",
      limit: 5,
      startDate: "2021-01-01T16:35:00Z",
      endDate: "2021-12-31T16:35:00Z",
      sort: "ASCENDING"
    };
    if (healthScoreType === "HeathScore") {
      service
        .getHealthSummary(params)
        .then((response) => response.json())
        .then((data) => {
          setResult(data.data.items);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
    else {
      service
        .getVo2MaxSummary(params)
        .then((response) => response.json())
        .then((data) => {
          setResult(data.data.items);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const trywithSampleButtonHandler = () => {
    if (buttonName === "Try with Sample Data") {
      setLoader(true);
      getHealthData();
      seButtonName("Reset");
    }
    else {
      setResult([]);
      seButtonName("Try with Sample Data");
      setHealthScoreType("HeathScore");
      setUserId("");
    }
  };

  const leftTab = () => <>
    <div className="nexus-row">
      <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
          Step 01
      </div>
      <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-7">
        <div className="form-head">Pick One</div>
      </div>
    </div>
    <div className="nexus-row">
      <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
      <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10">
        <div className="nexus-mt-2 nexus-mb-3">
          <NexusRadio
            name="option"
            id="Healthscore"
            data-testid="Healthscore"
            disabled={buttonName === "Reset"}
            checked={healthScoreType === "HeathScore"}
            onInput={() => setHealthScoreType("HeathScore")}
          >
              Health Score
          </NexusRadio>
          <NexusRadio
            className="ml-5"
            name="option"
            id="Vo2max"
            data-testid="Vo2max"
            disabled={buttonName === "Reset"}
            checked={healthScoreType === "vo2Max"}
            onInput={() => setHealthScoreType("vo2Max")}
          >
              vo2 Max summary
          </NexusRadio>
        </div>
      </div>
    </div>
    <div className="nexus-row">
      <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
          Step 02
      </div>
      <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
          Enter User Id
      </div>
    </div>
    <div className="nexus-row">
      <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
      <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6">
        <div className="nexus-mt-2 nexus-mb-3">
          <div className="italicText mb-2">User Id</div>
          <div className="input-container">
            <input
              type="text"
              value={userId}
              data-testid="user-id"
              placeholder="e.g. 3081978003"
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className="confirm-button"
            data-testid="validatePersonal"
            onClick={() => trywithSampleButtonHandler()}
          >
            {buttonName}
          </button>
        </div>
      </div>
    </div>
  </>;
  const rightTab = () => <>
    {result.length === 0 && <div
      className="nexus-row align-items-center nexus-ml-5"
      style={{ height: "100%" }}
    >
      <div className="address-sample">
            Try Extracting Address with Sample Data
      </div>
    </div>
    }
    {result.length > 0 && <>
      <div className="address-head nexus-ml-5 nexus-mb-3">Test Result</div>
      <div
        className="accident-container nexus-ml-5"
        style={{ width: "80%" }}
      >
        <div className="nexus-flex-col">
          {healthScoreType === "HeathScore" && result.map((item: any, index: any) => <div key={index} className="vehicle-details-desc">
            <div className="nexus-row nexus-pb-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                        Recorded On
              </div>
              <div className="validated-address-value nexus-px-1">
                        :
              </div>
              <div className="validated-address-text">
                {moment(item.date).format("YYYY-MM-DD")}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                        Health Score:
              </div>
              <div className="validated-address-value nexus-px-1">
                        :
              </div>
              <div className="validated-address-text">
                {item.healthScore}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                        Activity Score:
              </div>
              <div className="validated-address-value nexus-px-1">
                        :
              </div>
              <div className="validated-address-text">
                {item.activityScore}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div
                className="validated-address-value"
                style={{ width: "150px" }}
              >
                        7 day Health Score
              </div>
              <div className="validated-address-value nexus-px-1">
                        :
              </div>
              <div className="validated-address-text">
                {item.sevenDaysHealthScore}
              </div>
            </div>
          </div>)}
          {healthScoreType === "vo2Max" && <>
            <div className="vehicle-details-title nexus-pb-2">
                    Assessment Summary
            </div>
            <div className="vehicle-details-desc nexus-pl-2">
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "150px" }}
                >
                        Assessment Date
                </div>
                <div className="validated-address-value nexus-px-1">
                        :
                </div>
                <div className="validated-address-text">
                  {moment(result[0].date).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "150px" }}
                >
                        Fitness Age
                </div>
                <div className="validated-address-value nexus-px-1">
                        :
                </div>
                <div className="validated-address-text">
                  {result[0].fitnessAge}
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "150px" }}
                >
                        Source
                </div>
                <div className="validated-address-value nexus-px-1">
                        :
                </div>
                <div className="validated-address-text">
                  {result[0].source}
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "150px" }}
                >
                        vo2 MaxRating
                </div>
                <div className="validated-address-value nexus-px-1">
                        :
                </div>
                <div className="validated-address-text">
                  {result[0].vo2maxRating}
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "150px" }}
                >
                        vo2 Max Score
                </div>
                <div className="validated-address-value nexus-px-1">
                        :
                </div>
                <div className="validated-address-text">
                  {result[0].vo2maxScore}
                </div>
              </div>
            </div>
          </>
          }
        </div>
      </div>
    </>
    }
  </>;

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && <div className="loader-wrapper">
        <NexusProgressBar circle indeterminate className="loader-div" />
      </div>
      }
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Health Score"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default HealthScore;
