import React from "react";
import paths from "../../../../../utils/PathsUtils";
import FSSolutionExploreMoreCommonComponent from "../../FSSolutions/FSSolutionsExploreMore/FSSolutionExploreMoreCommonComponent";
import {
  MAPbanner,
  MAPKeyAsstes,
  MAPStrategicValue,
  NDSbanner,
  NDSKeyAssets,
  NDSStrategicValues
} from "../Assets/image";

const MicroServiceAccelerator = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Create/Update APIs with Service Definition (WSDL, CRF, Swagger or OpenAPI formats)​",
        "Appy Design Pattern (Access Control, Circuit Breaker, Service Discovery, X-Cutting Concerns)​",
        "Built-in Adapters and Third-Party Integrations (Event Hub, Kafka, Data Lake, Storage, AD)​",
        "Deployment and API Gateway Integration (Publishes to WSO2 API Gateway)​"
      ],
      img: MAPKeyAsstes,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Low Code tool to generate microservices with embedded metrics and health checks​",
        "Generation of build and release pipelines on CI/CD architecture​",
        "API generation on open specifications and creation of an API catalog​"
      ],
      img: MAPStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: MAPbanner,
    heading: "Microservices Acceleration Project​​",
    subHeading:
      "To accelerate API development, microservices are created using a command-line interface, which generates a project scaffolding based on code/container templatization. This enables the developer to focus their efforts on custom business logic whilst ignoring any boiler-plate code.​",
    previousLinkPath: paths.tools,
    contentClass: "banner-content-black"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default MicroServiceAccelerator;
