import React from "react";
import paths from "../../../../../utils/PathsUtils";
import { ASBannerImage, ASKeyAssets, ASStrategicValue } from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const ActuarialStudio = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​",
      desc: [
        "Build out and tune a machine learning model in a no-code environment",
        "Feed structured data into a trained model in milliseconds",
        "Perform closer to real-time analysis/review of multiple experiments with the ability to change variables almost real time, speed up Iteration cycle to make changes in scenarios and variables for trained models almost instantly",
        "Modern cloud-based infrastructure",
        "Ability to take a trained model through the approval process"
      ],
      img: ASKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Increase competitiveness and profitability with better management action as a result of better and real-time analytics",
        "Gain a better understanding of how sales emerge over time and the impact of management actions",
        "Eliminate the need to have actuarial resources manually run models on a regular basis by producing the profitability metrics through analytics"
      ],
      img: ASStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: ASBannerImage,
    heading: "Actuarial Studio",
    subHeading:
      "EY’s proprietary design studio built only for Actuaries to leverage AI/ML capabilities in actuarial sciences.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-black"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default ActuarialStudio;
