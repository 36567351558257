/* eslint-disable */
import React from "react";
import { CheckCircle } from "../../../../../assets/images";

const TestResultsRoofData = (props: any) => {
  const { result, isTopRiskData } = props;
  const getFormatedDate = (snapdate: any) =>
    [
      snapdate.slice(0, 4),
      "-",
      snapdate.slice(4, 6),
      "-",
      snapdate.slice(6),
    ].join("");

  if (isTopRiskData) {
    return (
      <div className="test-result-box nexus-p-4" style={{ width: "35em" }}>
        <div className="nexus-flex-row">
          <>
            <img
              className="verified-icons"
              src={CheckCircle}
              alt="CheckCircle"
            />
          </>
          <>
            <div className="address-head mb-3 pl-4">
              {result?.processingMessage}
            </div>
          </>
        </div>
        <div className="address-container pl-4">
          <div className="address-row">
            <div className="validated-address-value">
              Snap Date : {getFormatedDate(result?.snapdate)}
            </div>
            <div className="validated-address-value">
              Local Relief : {result?.localRelief}
            </div>
            <div className="validated-address-value">
              Profile Type : {result?.profileType}
            </div>
            <div className="validated-address-value">
              Slope : {result?.slope}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="test-result-box nexus-p-4 nexus-pr-8">
      <div className="address-head my-3 pl-4">
        <img className="verified-icons" src={CheckCircle} alt="CheckCircle" />
        <span style={{ paddingLeft: "0.5em" }}>
          Confidence score is {result?.data?.confidenceScore}
        </span>
      </div>
      <div className="address-container pl-4">
        <div className="address-row">
          <div className="validated-address-value">
            Roof Age : {result?.data?.roofAge}
          </div>
          <div className="validated-address-value">
            Built Year : {result?.data?.roofYear}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestResultsRoofData;
