import { NexusProgressBar } from "@nexus/react";
import React, { useEffect } from "react";
import "../Catalog.scss";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import { Document } from "../../../../../assets/images";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/producer-lookup",
  listName: "platform-capabilities/producer-lookup",
};

const service = new Service();

const ProducerLookup = () => {
  const [result, setResult] = React.useState<any>(null);
  const [loader, setLoader] = React.useState(false);

  const [reportType, setReportType] = React.useState("");
  const [licenceNumber, setLicenceNumber] = React.useState("");

  const card = {
    title: "Producer Lookup",
    description:
      "Provides carriers to search for a producer, also known as an agent, based on the given contact details. Using this API, carriers can obtain information about a producer, including their license number, associated agency, and Federal Employer Identification Number (FEIN).",
    subTitle: "NIPR",
    svg: Document,
    developerDoc: "producer-lookup",
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const submit = () => {
    if (result) {
      setResult(null);
      setReportType("");
      setLicenceNumber("");
    } else {
      const params = {
        reportType: "ZERO",
        firstName: "Robert",
        middleName: "William",
        lastName: "Smith",
        idFEIN: "121234567",
        license: "7081185",
        organizationName: "Nexus",
        agency: "Nexus Insurance",
        entityId: "568451",
        state: "TX",
        ssn: "078051120",
      };
      setLoader(true);
      setReportType("Entity Report");
      setLicenceNumber("7081185");
      service
        .getProducerLookup(params)
        .then((response) => response.json())
        .then((data) => {
          setResult(data.data);

          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const leftTab = () => (
    <>
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4  nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
        </div>
        <div className="nexus-col-sm-4  nexus-col-md-6 nexus-col-lg-7">
          <div>
            <div className="nexus-mb-3 font-bold">Select Report Type</div>
            <div className="italicText mb-2">Report Type</div>
            <div className="input-container">
              {!reportType && (
                <div
                  className="select-placeholder"
                  key="placeholder"
                  data-placeholder="placeholder"
                  style={{ width: "150px" }}
                >
                  {"e.g. Entity Report"}
                </div>
              )}
              <select
                value={reportType}
                className="line-input-box"
                data-testid="report-type"
                onChange={(event) => setReportType(event.target.value)}
                disabled
              >
                <option key={"1"} value="Entity Report">
                  Entity Report
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-3">
        <div className="form-head step nexus-col-sm-4  nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 02
        </div>
        <div className="nexus-col-sm-4  nexus-col-md-6 nexus-col-lg-7">
          <div>
            <div className="nexus-mb-3 font-bold">Enter License Number</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">License Number:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={licenceNumber}
                  data-testid="licence-number"
                  onChange={(event) => setLicenceNumber(event.target.value)}
                  placeholder={"e.g. 0596197"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="confirm-button"
            data-testid="submit-btn"
            onClick={() => {
              submit();
            }}
          >
            {result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!result && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Producer Lookup with sample data
          </div>
        </div>
      )}
      {result && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-mb-2 test-result">Test Result</div>
          <div className="test-result-box nexus-p-4 nexus-pr-5">
            <div className="nexus-mb-2 test-result">Producer Information:</div>
            <div className="nexus-mb-2">
              <div className="result-label">
                {result.producerDetails.firstName}
              </div>
              <div className="result-value result-value-sm">First Name</div>
            </div>

            <div className="nexus-mb-2">
              <div className="result-label">
                {result.producerDetails.lastName}
              </div>
              <div className="result-value result-value-sm">Last Name</div>
            </div>

            <div className="nexus-mb-2">
              <div className="result-label">
                {result.producerDetails.address.address1}
              </div>
              <div className="result-value result-value-sm">Address Line 1</div>
            </div>

            <div className="nexus-mb-2">
              <div className="result-label">{`${result.producerDetails.address.administrativeArea}, ${result.producerDetails.address.locality}, ${result.producerDetails.address.postalCode}`}</div>
              <div className="result-value result-value-sm">
                City, State, ZIP Code
              </div>
            </div>

            <div className="nexus-mb-2">
              <div className="result-label">
                {result.producerDetails.dateOfBirth.split("T")[0]}
              </div>
              <div className="result-value result-value-sm">Date of Birth</div>
            </div>

            <div className="nexus-mb-2">
              <div className="result-label">{result.producerDetails.email}</div>
              <div className="result-value result-value-sm">Email address</div>
            </div>

            <div className="nexus-mb-2">
              <div className="result-label">
                {result.producerDetails.phoneNumber}
              </div>
              <div className="result-value result-value-sm">Business Phone</div>
            </div>
            <div className="nexus-mb-2 test-result">License Information:</div>
            <div className="nexus-mb-2">
              <div className="result-label">
                {result.licenseInformation.licenseNumber}
              </div>
              <div className="result-value result-value-sm">License Number</div>
            </div>
            <div className="nexus-mb-2">
              <div className="result-label">
                {result.licenseInformation.licenseState}
              </div>
              <div className="result-value result-value-sm">License State</div>
            </div>
            <div className="nexus-mb-2">
              <div className="result-label">
                {result.licenseInformation.licenseType}
              </div>
              <div className="result-value result-value-sm">License Type</div>
            </div>
            <div className="nexus-mb-2">
              <div className="result-label">{`${result.producerAppointmentInformation.lineofBusiness}, ${result.producerAppointmentInformation.businessUnit}`}</div>
              <div className="result-value result-value-sm">
                Line of Business, Business Unit
              </div>
            </div>
            <div className="nexus-mb-2">
              <div className="result-label">
                {result.licenseInformation.expirationDate.split("T")[0]}
              </div>
              <div className="result-value result-value-sm">
                License Expiry Date
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container updated-design padding-margin-0">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Producer Lookup"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default ProducerLookup;
