/* eslint-disable id-length */
import React from "react";
import "../CatalogStyle.scss";
import {
  NexusRadio,
  NexusProgressBar,
  NexusFormField,
  NexusLabel,
  NexusInput,
  NexusTextarea
} from "@nexus/react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { CheckCircle, Warning } from "../../../../../assets/images";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { PushNotification } from "../Assets/Image";

const ADOBE_DATA = {
  pageName: "platform-capabilities/notification",
  listName: "platform-capabilities/notification"
};

interface State {
  loader?: boolean;
  email: string;
  phoneNumber: string;
  deviceId: string;
  notificationMsg: string;
  indentity: string;
  option: string;
  result: any;
  error: any;
}

const service = new Service();

class Notifications extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      phoneNumber: "",
      deviceId: "",
      notificationMsg: "",
      indentity: "",
      option: "email",
      result: null,
      error: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  // ApiCall() {
  //   const params = this.state.option === 'email' ? {
  //     from: 'nexusforinsurance@ey.com',
  //     subject: 'Nexus Notification API Test',
  //     to: this.state.email ? this.state.email : 'nexusforinsurance@ey.com',
  //     data: {
  //       additionalProp1: {},
  //       additionalProp2: {},
  //       additionalProp3: {}
  //     },
  //     templateId: 'd-6f02a7f45b9c42afbfd185508023d5ea',
  //     attachments: null
  //   } : {
  //     from: '18126491317',
  //     body: 'From Nexus',
  //     to: this.state.phoneNumber ? this.state.phoneNumber : '5594126044'
  //   };

  //   service
  //     .sendSMSOrEmail(params, this.state.option)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.data) {
  //         this.setState({ result: data.data,
  //           loader: false,
  //           error: null });
  //       }
  //       else if (data.error) {
  //         this.setState({ result: data.error,
  //           loader: false });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       this.setState({
  //         loader: false,
  //         error: true
  //       });
  //     });
  // }

  submit() {
    if (this.state.result) {
      this.setState({
        result: null,
        email: "",
        phoneNumber: "",
        deviceId: "",
        notificationMsg: "",
        indentity: "",
        error: null
      });
    }
    else {
      if (!this.state.email) {
        this.setState({ email: "nexusforinsurance@ey.com" });
      }
      if (!this.state.phoneNumber) {
        this.setState({ phoneNumber: "5594126044" });
      }
      if (this.state.option === "push") {
        this.setState({
          deviceId: "IS91a0bc98796ca4cb524598607e426c77",
          notificationMsg:
            "Car Crash Detected Hope you’re Ok. Click here to confirm crash. For emergency, please call 911 immediately.",
          indentity: "Nexus"
        });
      }

      this.setState({
        loader: true
      });

      if (this.state.option === "push") {
        setTimeout(() => {
          this.setState({
            result:
              "You will be notified when the application detects a car crash and you can swipe the alert to view and confirm the crash and report the vehicle damage.",
            loader: false
          });
        }, 1000);
      }
      else if (this.state.option === "email") {
        setTimeout(() => {
          this.setState({
            result: { message: "SENTSUCCESSFULLY" },
            loader: false
          });
        }, 1000);
      }
      else {
        setTimeout(() => {
          this.setState({
            result: { validations: null },
            loader: false
          });
        }, 1000);
      }
    }
  }

  capitalizeFirstLetter(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  changeOption(opt: string) {
    this.setState({
      option: opt,
      result: null,
      phoneNumber: "",
      email: ""
    });
  }

  render() {
    const leftTab = () => <>
      <div className="nexus-row notification">
        <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 1
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head nexus-mb-3">Pick One</div>
          <div className="radio-container radioLebel">
            <NexusRadio
              name="option"
              data-testid="email"
              id="email"
              value="email"
              checked={this.state.option === "email"}
              onInput={() => {
                this.changeOption("email");
              }}
            >
                Email
            </NexusRadio>
            <NexusRadio
              className="nexus-pl-2"
              data-testid="sms"
              name="option"
              id="phone"
              value="phone"
              checked={this.state.option === "phone"}
              onInput={() => {
                this.changeOption("phone");
              }}
            >
                SMS
            </NexusRadio>
            <NexusRadio
              className="nexus-pl-2"
              data-testid="push"
              name="option"
              id="push"
              value="push"
              checked={this.state.option === "push"}
              onInput={() => {
                this.changeOption("push");
              }}
            >
                Push
            </NexusRadio>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-my-4">
        <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 2
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          {this.state.option === "email" && <>
            <div className="form-head nexus-mb-2">
                  Enter your Email Address
            </div>
            <NexusFormField>
              <div
                className="italicText mb-2"
                data-testid="label-first-name"
              >
                    Email Address
              </div>
              <NexusInput
                type="text"
                data-testid="input-textbox-email"
                value={this.state.email}
                onInput={(event: any) => this.setState({ email: event.target.value })
                }
                placeholder="e.g. nexus4insurance@ey.com"
                disabled
              ></NexusInput>
            </NexusFormField>
          </>
          }
          {this.state.option === "phone" && <>
            <div className="form-head nexus-mb-2">
                  Enter your Phone Number
            </div>
            <NexusFormField>
              <div
                className="italicText mb-2"
                data-testid="label-first-name"
              >
                    Phone Number
              </div>
              <NexusInput
                type="text"
                data-testid="input-textbox-sms"
                value={this.state.phoneNumber}
                onInput={(event: any) => this.setState({ phoneNumber: event.target.value })
                }
                placeholder="e.g. 15853042473"
                disabled
              ></NexusInput>
            </NexusFormField>
          </>
          }
          {this.state.option === "push" && <>
            <div className="form-head nexus-mb-2">
                  Enter your Device Information
            </div>
            <div className="nexus-flex-column">
              <div>
                <NexusFormField>
                  <div className="italicText mb-2">Device Id</div>
                  <NexusInput
                    type="text"
                    value={this.state.deviceId}
                    placeholder="e.g. IS91a0bc98796ca4cb524598607e426c77"
                    disabled
                  ></NexusInput>
                </NexusFormField>
              </div>
              <div>
                <NexusFormField>
                  <div className="italicText mb-2">
                        Notification Message
                  </div>
                  <NexusTextarea
                    data-testid="input-textbox-Notification"
                    value={this.state.notificationMsg}
                    placeholder="Car Crash Detected Hope you’re Ok. Click here to confirm crash. For emergency, please call 911 immediately."
                    disabled
                  ></NexusTextarea>
                </NexusFormField>
              </div>
              <div>
                <NexusFormField>
                  <div className="italicText mb-2">Identity</div>
                  <NexusInput
                    type="text"
                    value={this.state.indentity}
                    placeholder="e.g. Nexus"
                    disabled
                  ></NexusInput>
                </NexusFormField>
              </div>
            </div>
          </>
          }
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            className="confirm-button"
            data-testid="submit-btn"
            onClick={() => {
              this.submit();
            }}
          >
            {this.state.result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample
          <br />
          {this.state.option === "phone" ? "Phone Number" : this.state.option === "email" ? "Email Address" : "Push Notification"}
        </div>
      </div>
      }

      {this.state.option !== "push" && this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="test-result-box nexus-p-2 nexus-col-lg-10">
          <div className="address-status-row">
            <div>
              <img
                className="verified-icons"
                src={
                  this.state.option === "email" && this.state.result.message === "FAILEDTOSENT" || this.state.option === "phone" && this.state.result.validations !== null || this.state.option === "phone" && this.state.error !== null ? Warning : CheckCircle
                }
                alt="CheckCircle"
              />
            </div>
            {(this.state.option === "email" && this.state.result.message === "SENTSUCCESSFULLY" || this.state.option === "phone" && this.state.result.validations === null) && <div
              style={{ marginLeft: "10px" }}
              className="notification-matched-text"
            >
              {this.state.option === "email" ? "Email" : "Notification"}{" "}
                    has been sent to
              <br />
              <label className="address-matched-text-highlight mr-1">
                {this.state.option === "email" ? this.state.email : this.state.phoneNumber}
              </label>
                    successfully!
            </div>
            }
            {(this.state.result.message === "FAILEDTOSENT" && this.state.option === "email" || this.state.option === "phone" && this.state.result.validations !== null) && <div
              className="address-matched-text"
              style={{ fontSize: "22px" }}
            >
                    Please check the{" "}
              {this.state.option === "email" ? "Email Address" : "Phone Number"}
              <label className="address-matched-text-highlight mx-2">
                {this.state.option === "email" ? this.state.email : this.state.phoneNumber}
              </label>
            </div>
            }
          </div>
        </div>
      </div>
      }
      {this.state.option === "push" && this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="validated-address-value nexus-pb-4"
          style={{ width: "85%" }}
        >
          {this.state.result}
        </div>
        <img src={PushNotification}></img>
      </div>
      }
    </>;
    const card = {
      title: "Notifications",
      description:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API.",
      subTitle: "Twilio",
      icon: actionIcBookmarkBorder24px,
      developerDoc: "notification"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Notification Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default Notifications;
