const getDomainColor = (name: string) => {
  switch (name) {
  case "Banking":
    return "banking";
  case "Insurance":
    return "insurance";
  case "Cross Sector":
    return "universal";
  case "Wealth & Asset":
    return "wealth";
  case "Life":
    return "life";
  case "Group Benefits":
    return "groupBenefits";
  case "Claims":
    return "claims";
  case "P&C":
    return "PAndC";
  default:
    return "banking";
  }
};

const simpleChipFilterNames = [
  "All",
  "Banking",
  "Insurance",
  "Wealth & Asset",
  "Cross Sector"
];

// eslint-disable-next-line no-confusing-arrow
const sortData = (objs: any, property: any) => objs.sort((first: any, second: any) => first[property] > second[property] ? 1 : second[property] > first[property] ? -1 : 0);
export { getDomainColor, simpleChipFilterNames, sortData };
