/* eslint-disable id-length */
/* eslint-disable no-confusing-arrow */
import {
  Ice,
  Cloudy,
  PartiallyCloudy,
  Snow,
  LightRain,
  HeavyRain,
  Sunny
} from "../Assets/Image";

const directionMapping = (string: any) => {
  const directions: { [key: string]: any } = {
    E: "East",
    S: "South",
    N: "North",
    W: "West"
  };
  const map = string
    .split("")
    .map((item: string, index: number) => index === 1 ? ` ${directions[item]}` : directions[item]);

  return map.join("");
};

const descriptionMapping = (description: string) => {
  const opts = [
    "Partly Cloudy",
    "Cloudy",
    "Thuderstorm",
    "Showers",
    "Rain",
    "Sunny"
  ];

  const weather = opts.reduce(
    (first: any, second: any) => description?.includes(first) ? first : second,
    "Sunny"
  );

  return weather;
};

const mapIconFromIconName = (text: string) => {
  switch (text) {
  case "Cloudy":
    return Cloudy;
  case "Partly Cloudy":
    return PartiallyCloudy;
  case "Showers":
    return Snow;
  case "SNOW":
  case "Snow":
    return Snow;
  case "Ice":
    return Ice;
  case "Rain":
    return LightRain;
  case "Thuderstorm":
    return HeavyRain;
  case "Sunny":
    return Sunny;
  default:
    return Cloudy;
  }
};

const mapIcons = (description: string) => {
  const text = descriptionMapping(description);

  return mapIconFromIconName(text);
};

export { directionMapping, descriptionMapping, mapIcons, mapIconFromIconName };
