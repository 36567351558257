import React, { useState } from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  PLMStrategicValue,
  PLMKeyAssets,
  PLMBannerImage,
  UWBBannerImage,
  UWBKeyAssets,
  UWBStrategicValue,
  UnderwritingWorkbenchDemo
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";
import ModalComponent from "./ModalComponent";

const UnderWritingWorkbench = () => {
  const [showModal,
    setShowModal] = useState(false);
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Manage new business, renewals and endorsements with an integrated CRM",
        "Risk insights and cross/upsell opportunities via predictive analytics and third-party data",
        "Full audit and compliance control",
        "Low-code microservice architecture",
        "Pricing visualization and operational reporting"
      ],
      img: UWBKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Enhanced underwriter productivity",
        "Improved insights for underwriters regarding submissions and renewals",
        "Enablement of straight-through processing capabilities"
      ],
      img: UWBStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: UWBBannerImage,
    heading: "Underwriting Workbench",
    subHeading:
      "EY’s Next Generation Underwriting Workbench is a digital control panel and collaboration tool that offers a one-stop-shop which streamlines processes, distributes work, accesses digitalized documents or emails, and brings in data streams and analysis for underwriters across P&C and Life",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white",
    bannerButtonDetails: {
      text: "View Demo",
      isBgColorWhite: true
    }
  };
  const nexusModalBody = <video controls={true} width="800" height="600" autoPlay={true}>
    <source src={UnderwritingWorkbenchDemo} />
  </video>;

  return (
    <>
      {showModal && <ModalComponent
        modalBody={nexusModalBody}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      }
      <FSSolutionExploreMoreCommonComponent
        pillarContent={pillarContent}
        bannerContent={bannerContent}
        setShowModal={setShowModal}
      />
      ;
    </>
  );
};

export default UnderWritingWorkbench;
