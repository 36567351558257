/* eslint-disable max-lines */
import {
  AddressValidation,
  CreditReport,
  AddressParsing,
  ClueReport,
  NextBestAction,
  RatingConnector,
  VehicleDamageConnector,
  Wearables,
  Notification,
  HazardRisk,
  AddressTypeahead,
  CustomerFeedback,
  VehicleInfo,
  PostcodeLookup,
  Geocoding,
  EmailPhoneNumber,
  PaymentGateway,
  LocationService,
  DocumentSignature,
  VehicleSymbol,
  HumanHealth,
  VehicleHistory,
  DocumentGenerator,
  CommercialPropertyRisk,
  DocumentScan,
  CarbonEmission,
  FacialAnalysis,
  DriverViolations,
  CapabilitiesCatalogImg,
  HealthScore,
  PersonalProperty,
  WeatherReport,
  Underwrittingrules,
  verify,
  Telematics,
  CrossSellOffering,
  FraudDetection,
  NotificationBinding,
  ProducerLookup,
  flood,
  accountDomain,
  partyDomain,
  autoInsurance,
  geoLocation,
  income,
  pet,
  replacementCost,
  roofData,
  smallMedBusIns,
  storesbi,
  propertyClaims,
  propertyEssential,
  paperMailIcon
} from "./Assets/Image";

const capabilitiesCatalogBanner = {
  image: CapabilitiesCatalogImg,
  title: "Connect Catalogue",
  subTitle:
    "Explore the list of third party capabilities offered by the Nexus Platform. Built for the Financial Services industry, Nexus capabilities accelerate the development of new business and customer-facing applications."
};

const capabilitiesCatalogList = [
  {
    name: "Address Validation",
    description:
      "Nexus Address Validation Connector provides carriers with the ability to check and validate any customer provided address details both in the US...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/address-validation",
    icon: AddressValidation,
    comingSoon: false
  },
  {
    name: "Facial Analysis",
    description:
      "Nexus Facial Analysis Connector provides carriers with the ability to combine a selfie with facial analytics, biodemographic information and life...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/facial-analysis",
    icon: FacialAnalysis,
    comingSoon: false
  },
  {
    name: "Carbon Emission Calculator",
    description:
      "Nexus Carbon Emission Calculator provides the ability to estimate the yearly carbon emissions of a car based on the make, model and yearly mileage..",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/carbon-emission-calculator",
    icon: CarbonEmission,
    comingSoon: false
  },
  {
    name: "Document Scan",
    description:
      "Nexus Document Scan provides the ability to scan any ID and Driver’s License across the globe to extract personal information automatically...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/document-scan",
    icon: DocumentScan,
    comingSoon: false
  },
  {
    name: "Commercial Property Risk",
    description:
      "Nexus Commercial Property Prefill provides carriers with the ability to leverage geospatial imagery and analysis to gain further insights on a commercial...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/commercial-property-risk",
    icon: CommercialPropertyRisk,
    comingSoon: false
  },
  {
    name: "Document Generator",
    description:
      "Provide carriers with the ability to generate a document based on the Templates. Based on the details provided on the request and the name ...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/document-generator",
    icon: DocumentGenerator,
    comingSoon: false
  },
  {
    name: "Vehicle History",
    description:
      "Provide carriers with the ability to extract car details and history based on a Vehicle Identification Number. Vehicle History information is leveraged...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/vehicle-history",
    icon: VehicleHistory,
    comingSoon: false
  },
  {
    name: "Human Health",
    description:
      "Provide carriers with the ability to access customer medical record from a comprehensive selection of health care providers in the US. Obviously, only...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/human-health",
    icon: HumanHealth,
    comingSoon: false
  },
  {
    name: "Vehicle Symbol",
    description:
      "Provide carriers with the ability to retrieve the vehicle symbol associated to a VIN number. Vehicle symbol information is leveraged across policy...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/vehicle-symbol",
    icon: VehicleSymbol,
    comingSoon: false
  },
  {
    name: "Vehicle Telematics",
    description:
      "The Vehicle Telematics API provides detailed user and driver behavior metrics. Vehicle telematics combines GPS systems, onboard vehicle...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/vehicle-telematics",
    icon: Telematics,
    comingSoon: false
  },
  {
    name: "Document Signature",
    description:
      "Provide carriers with the ability to manage agreements electronically, whether it be internal facing approvals or customer facing policy...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/document-signature",
    icon: DocumentSignature,
    comingSoon: false
  },
  {
    name: "Location Service",
    description:
      "Provide carriers with the ability to identify the closest set of repair shops or tow services within a given address and radius. Simultaneously, location...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/location-services",
    icon: LocationService,
    comingSoon: false
  },
  {
    name: "Payments",
    description:
      "Provide carriers with the ability to streamline and automate payment issuance and receival across multiple countries, currencies and with any...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/payment",
    icon: PaymentGateway,
    comingSoon: false
  },
  {
    name: "Email & Phone Number Validator",
    description:
      "Nexus Email and Phone Number Validator Connector provides carriers with the ability to correct and validate any customer provided Email or Phone...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/email-phone-number-validator",
    icon: EmailPhoneNumber,
    comingSoon: false
  },
  {
    name: "Geocoding",
    description:
      "Provide carriers with the ability to extract detailed address details including lattitude and longitude both in US and Internationally...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/geocoding",
    icon: Geocoding,
    comingSoon: false
  },
  {
    name: "Postcode Lookup",
    description:
      "Provide carriers with the ability to search for Address based on the postcode. The most common example of this is the ability to offer the postcode...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/postalcode-lookup",
    icon: PostcodeLookup,
    comingSoon: false
  },
  {
    name: "Vehicle Info",
    description:
      "Provide carriers with the ability to search for details of the Vehicle based on the VIN/Plate number. The most common example of this is the ability to...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/vehicle-info",
    icon: VehicleInfo,
    comingSoon: false
  },
  {
    name: "Driver Violations",
    description:
      "Provide carriers with the ability to extract Driver Violation History based on driver information. Driver Violation History is leveraged across underwriting,..",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/driver-violation",
    icon: DriverViolations,
    comingSoon: false
  },
  {
    name: "Customer Feedback",
    description:
      "Provide carriers with the ability to receive and process valuable feedbacks and comments on their customer services..",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/customer-feedback",
    icon: CustomerFeedback,
    comingSoon: false
  },
  {
    name: "Address Typeahead",
    description:
      "Provide carriers with the ability to choose the exact address based on a given text..",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/address-typeahead",
    icon: AddressTypeahead,
    comingSoon: false
  },
  {
    name: "Hazard Risk",
    description:
      "Provide carriers with the ability to search for any Crime, Earthquake, Fire, Flood, Firestation and distance to Flood zone Hazard Risks located...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/hazard-risk",
    icon: HazardRisk,
    comingSoon: false
  },
  {
    name: "Notification",
    description:
      "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/notification",
    icon: Notification,
    comingSoon: false
  },
  {
    name: "Wearables",
    description:
      "Provide carriers with the ability to serve their customers and users by aggregating 30,000+ health and wellness data sources into one platform...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/wearable",
    icon: Wearables,
    comingSoon: false
  },
  {
    name: "Vehicle Damage Connector",
    description:
      "Provide carriers with the ability to by simply uploading photos of a damaged vehicle, Nexus can provide an indication of damage, damage cost...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/Vehicle-Damage",
    icon: VehicleDamageConnector,
    comingSoon: false
  },
  {
    name: "Rating Connector",
    description:
      "Provide carriers with the ability to rate the policy based on the various parameters of the policy..",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/rating",
    icon: RatingConnector,
    comingSoon: false
  },
  {
    name: "Next Best Action",
    description:
      "The Nexus Next Best Action connector leverages an integration with Pega to provide an indication of the next best action during Disability Claims...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/next-best-action",
    icon: NextBestAction,
    comingSoon: false
  },
  {
    name: "Clue Report",
    description:
      "Provide carriers with the ability to retrieve the Comprehensive Loss Underwriting Exchange (CLUE) which helps the Insurance Actuaries to calculate...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/clue-report",
    icon: ClueReport,
    comingSoon: false
  },
  {
    name: "Address Parsing",
    description:
      "Provide carriers with the ability to extract detailed address based on a given text..",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/address-parsing",
    icon: AddressParsing,
    comingSoon: false
  },
  {
    name: "Credit Report",
    description:
      "The Nexus Credit Report connector leverages integrations with the leading credit profile providers to enable access to consumer credit data...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/credit-report",
    icon: CreditReport,
    comingSoon: false
  },
  {
    name: "Cross Sell Offering",
    description:
      "Provide carriers with a list of cross sell offers from the service provider's system. Cross-selling occurs when customers get offerings that complement...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/cross-sell-offering",
    icon: CrossSellOffering,
    comingSoon: false
  },
  {
    name: "Fraud Detection",
    description:
      "Provide carriers an accurate estimation of the fraud and risks with the aid of expert rules, profiles, predictive models, and link analysis with...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/fraud-detection",
    icon: FraudDetection,
    comingSoon: false
  },
  {
    name: "Personal Property Prefill",
    description:
      "Enables carriers to retrieve the key property attributes, including address details and parcel boundaries, for a given address...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/personal-property-prefill",
    icon: PersonalProperty,
    comingSoon: false
  },
  {
    name: "Health Score",
    description:
      "Provide carriers to manage biometrics, health advice, health summary, VO2 max, and user data by integrating with the leading health score..",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/health-score",
    icon: HealthScore,
    comingSoon: false
  },
  {
    name: "Notification Binding",
    description:
      "Enables carrier to control their notifications workflow from their server and binds an identity to the address used by a notification channel...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/notification-binding",
    icon: NotificationBinding,
    comingSoon: false
  },
  {
    name: "Producer Lookup",
    description:
      "Provides carriers to search for a producer, also known as an agent, based on the given contact details. Using this API, carriers can obtain...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/producer-lookup",
    icon: ProducerLookup,
    comingSoon: false
  },
  {
    name: "Underwritting Rules",
    description:
      "Provide carriers with the ability to create a activity task for an underwriter automatically based on pre-configured business rules...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/underwritting-rules",
    icon: Underwrittingrules,
    comingSoon: false
  },
  {
    name: "Verify",
    description:
      "Enables carrier to verify the users have any claimed device in their possession by requesting a verification code via SMS, Email...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-capabilities/verify",
    icon: verify,
    comingSoon: false
  },
  {
    name: "Weather Report",
    description:
      "Nexus Weather API gets the hourly or daily weather forecasts, the current weather conditions, the severe weather alerts, and the weather along...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-capabilities/weather-report",
    icon: WeatherReport,
    comingSoon: false
  },
  {
    name: "Account Domain",
    description:
      "Account Domain Service manages customer account data, including individuals and organizations involved in financial transactions...",
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-accelerators/account-domain",
    icon: accountDomain,
    comingSoon: true
  },
  {
    name: "Party Domain",
    description:
      "A party is a person or organization who owns one or more policies and/or the payer of premium charges...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/party-domain",
    icon: partyDomain,
    comingSoon: true
  },
  {
    name: "Auto Insurance",
    description:
      "Auto  Insurance provides financial protection against physical damage and/or bodily injury resulting from traffic collisions and against liability...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/auto-insurance",
    icon: autoInsurance,
    comingSoon: true
  },
  {
    name: "Life Insurance",
    description:
      "Life insurance covers the financial loss of an insured upon death and promises to pay a sum of money in exchange for a premium...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/life-insurance",
    icon: NextBestAction,
    comingSoon: true
  },
  {
    name: "Pet Insurance",
    description:
      "Pet insurance, a form of healthcare coverage for pets, is designed to address expenses related to specific illnesses or veterinary treatments...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/pet-insurance",
    icon: pet,
    comingSoon: true
  },
  {
    name: "Income Protection Insurance",
    description:
      "The Income Protection Submission API provides income security to an insured person under circumstances such as inability to work...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/income-protection-insurance",
    icon: income,
    comingSoon: true
  },
  {
    name: "Small Business Insurance",
    description:
      "The Commercial Small Business Insurance product offers coverage for General Liability and Professional Liability...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/commercial-small-business-insurance",
    icon: storesbi,
    comingSoon: true
  },
  {
    name: "Small Medium Enterprise Insurance",
    description:
      "The Commercial insurance product serves small and medium-sized businesses with coverage for Cyber Liability, Public Liability...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/commercial-small-medial-enterprise-insurance",
    icon: smallMedBusIns,
    comingSoon: true
  },
  {
    name: "Location Suite",
    description:
      "From community resiliency and safety data, to natural hazard and catastrophe risk, Location suite provides carriers a set of solutions...",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/location-suite",
    icon: geoLocation,
    comingSoon: false
  },
  {
    name: "Replacement Cost",
    description:
      "The Replacement Cost - Property API generates reliable replacement cost estimates for properties insured, reflecting real-world prices for labor and materials, based on extensive research, direct data feeds, claims analyses, and communication with 92,000 claims and building contractors.",
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/replacement-cost-property",
    icon: replacementCost,
    comingSoon: false
  },
  {
    name: "Roof Data",
    description: `Provide carriers with the ability to retrieve key property's roof age attributes, including the year and a confidence score, for a given address...`,
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/roof-data-property",
    icon: roofData,
    comingSoon: false
  },
  {
    name: "WaterLine",
    description: `Provide carriers with the ability to assesses flood risk across the contiguous United States, providing a relative risk score—ranging from 0 to 100..`,
    domain: ["Insurance"],
    subDomain: [],
    URL: "/platform-accelerators/waterline",
    icon: flood,
    comingSoon: false
  },
  {
    name: "Property Essential",
    description: `Provide carriers with the data to help homeowners insurers identify high-risk exposures along the policy lifecycle. This can help insurers more accurately...`,
    domain: ["Insurance"],
    subDomain: [],
    URL: "",
    icon: propertyEssential,
    comingSoon: true
  },
  {
    name: "Property Claims",
    description: `Enables carriers to retrieve reports of all insurance claims and loss history associated with homes, auto or personal property...`,
    domain: ["Insurance"],
    subDomain: [],
    URL: "",
    icon: propertyClaims,
    comingSoon: true
  },
  {
    name: "Paper Mail Delivery",
    description: `Enables carriers to automate direct mail by triggering on-demand postcards, letters, and checks directly from your CRM, replacing out-of-date...`,
    domain: ["Cross Sector"],
    subDomain: [],
    URL: "/platform-accelerators/paper-mail-delivery",
    icon: paperMailIcon,
    comingSoon: false
  }
];

export interface ResponseData {
  distanceToRiverUnit: string;
  flashFloodHazardWeightage: string;
  distanceFrom100yrInlandFloodHazard: string;
  distanceFrom100yrInlandFloodHazardUnit: string;
  distanceFrom100yrStormSurgeHazard: string;
  address: {
    zip: string;
    state: string;
    postalCity: string;
    zip4: string;
    city: string;
    county: string;
    streetAddress1: string;
  };
  relativeElevationFromNearestRiverOrCoastline: string;
  staticMapUrl: string;
  distanceToCoastUnit: string;
  relativeElevation100yrInlandFloodHazard: string;
  floodHazardScore: string;
  stormSurgeHazardWeightage: string | null;
  frequencyOfHurricanes: string;
  elevation: string;
  precipitation: string;
  fEMA: {
    communityNumber: string;
    communityEffectiveDate: string;
    floodZone: string;
    distanceToSFHA: string | null;
    communityName: string;
    baseFloodElevation: string;
  };
  distanceToRiver: string;
  riverineHazardWeightage: string;
  xmlnsi: string | null;
  standardOfProtection: string;
  relativeElevation100yrStormSurgeHazard: string;
  distanceToCoast: string;
  distanceFrom100yrStormSurgeHazardUnit: string;
}

export { capabilitiesCatalogList, capabilitiesCatalogBanner };
