import React, { Component } from "react";
import ComingSoon from "../../SharedComponent/ComingSoon/ComingSoon";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "fs-solutions-active-campaigns",
  listName: "fs-solutions-active-campaigns"
};

class ActiveCampaigns extends Component {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  render() {
    return <ComingSoon />;
  }
}
export default ActiveCampaigns;
