import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Accelators from "./Accelerators";
import DetailsPage from "./DetailsPage/DetailsPage";
import { paths } from "../../../../utils/PathsUtils";

const {
  accelerators,
  autoInsurance,
  businessOwnerInsurance,
  cardTranscationDispute,
  groupBenefits,
  incomeProtevtionInsuarance,
  lifeInsuarnce,
  mortgage,
  personalProertyInsurance,
  petInsurance,
  renterInsurance
} = paths;

const NexusXDZoneRoutes = () => <Switch>
  <Route exact path={`${accelerators}`} component={Accelators} />
  <Route path={`${accelerators}${autoInsurance}`} component={DetailsPage} />
  <Route
    path={`${accelerators}${businessOwnerInsurance}`}
    component={DetailsPage}
  />
  <Route
    path={`${accelerators}${cardTranscationDispute}`}
    component={DetailsPage}
  />
  <Route path={`${accelerators}${groupBenefits}`} component={DetailsPage} />
  <Route
    path={`${accelerators}${incomeProtevtionInsuarance}`}
    component={DetailsPage}
  />
  <Route path={`${accelerators}${lifeInsuarnce}`} component={DetailsPage} />
  <Route path={`${accelerators}${mortgage}`} component={DetailsPage} />
  <Route
    path={`${accelerators}${personalProertyInsurance}`}
    component={DetailsPage}
  />
  <Route path={`${accelerators}${petInsurance}`} component={DetailsPage} />
  <Route path={`${accelerators}${renterInsurance}`} component={DetailsPage} />
</Switch>;
export default NexusXDZoneRoutes;
