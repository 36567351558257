/* eslint-disable camelcase */
/* eslint-disable max-lines */
import React, { ChangeEvent } from "react";
import "../CatalogStyle.scss";
import {
  NexusProgressBar,
  NexusInput,
  NexusLabel,
  NexusSelect
} from "@nexus/react";
import moment from "moment";
import CatalogDetailsContainer from "../DetailsContainer";
import { paymentIcon } from "../../../../../assets/images/index";
import CatalogHeader from "../Header";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/clue-report",
  listName: "platform-capabilities/clue-report"
};

interface State {
  result: any;
  buttonValue: string;
  firstName: string;
  lastName: string;
  DOB: string;
  SSNNumber: string;
  VIN: string;
  licenceState: string;
  driverLicence: string;
  loader: boolean;
}

const service = new Service();
class ClueReport extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      result: null,
      buttonValue: "Try with Sample Data",
      firstName: "",
      lastName: "",
      DOB: "",
      SSNNumber: "",
      VIN: "",
      licenceState: "",
      driverLicence: "",
      loader: false
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    const {
      firstName,
      lastName,
      DOB,
      SSNNumber,
      VIN,
      licenceState,
      driverLicence
    } = this.state;
    if (this.state.buttonValue === "Reset") {
      this.setState({
        buttonValue: "Try with Sample Data",
        result: null,
        firstName: "",
        lastName: "",
        DOB: "",
        SSNNumber: "",
        VIN: "",
        licenceState: "",
        driverLicence: ""
      });
    }
    else {
      this.setState({
        buttonValue: "Reset",
        firstName: firstName || "Lois",
        lastName: lastName || "Wanger",
        DOB: DOB || "1954-07-12",
        SSNNumber: SSNNumber || "778628144",
        VIN: VIN || "1RKFFEAG8L1000113",
        licenceState: licenceState || "NC",
        driverLicence: driverLicence || "1HGCM82633A004352",
        loader: true
      });
      service
        .getClueReports({
          clueAutorequest: {
            isCurrentCarrierRequired: "false",
            isSubjectSearchRequired: "true",
            isVinSearchRequired: "true",
            subjects: [
              {
                addresses: [
                  {
                    street1: "1472 LINDEN RD",
                    street2: "",
                    city: "ABERDEEN",
                    state: "NC",
                    country: "US",
                    postalCode: "28315",
                    type: "RESIDENCE"
                  }
                ],
                birthdate: DOB ? moment(DOB).utc(true).format() : moment("1954-07-12").utc(true).format(),
                driversLicense: {
                  history: "current",
                  number: driverLicence || "P420513820440",
                  state: licenceState || "NC",
                  type: "PERSONAL"
                },
                prefix: "MR",
                firstName: firstName || "Lois",
                middleName: "Ford",
                lastName: lastName || "Wanger",
                gender: "FEMALE",
                ssn: SSNNumber || "778628144",
                suffix: "JR",
                type: "PRIMARY"
              }
            ],
            vehicles: [
              {
                model_make: "ROCKWOOD",
                model_year: "1990",
                vin: VIN || "1RKFFEAG8L1000113"
              }
            ]
          }
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            this.setState({ result: data.data });
            this.setState({ loader: false });
          }
        })
        .catch();
    }
  }

  // conversion(val: string) {
  // return val.replace(
  //     /(\w)(\w*)/g,
  //     (value1, value2) => value1.toUpperCase() + value2.toLowerCase()
  // );
  // }

  buttonSubmit() {
    return (
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            data-testid="validateAddress"
            className="confirm-button"
            onClick={() => this.apiCall()}
          >
            {this.state.buttonValue}
          </button>
        </div>
      </div>
    );
  }

  commonResponse() {
    return (
      <div className="nexus-row nexus-ml-5" style={{ height: "40%" }}>
        <div className="nexus-col-sm-4 nexus-col-lg-8 nexus-col-lg-10 address-sample">
          Try with Sample <br></br>Data
        </div>
      </div>
    );
  }

  render() {
    const leftTab = () => {
      const {
        firstName,
        lastName,
        DOB,
        SSNNumber,
        VIN,
        licenceState,
        driverLicence,
        result
      } = this.state;

      return (
        <>
          <div className="nexus-row">
            <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
              Step 01
            </div>
            <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
              <div className="form-head">Input Insured Details</div>
              <div className="radio-container radioLebel nexus-mt-8">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  First Name
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="firstName"
                  attrId="firstName"
                  placeholder="e.g. Lexis"
                  value={firstName}
                  disabled
                  /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ firstName: event.target.value })} */
                />
              </div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  Last Name
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="lasttName"
                  attrId="lastName"
                  disabled
                  placeholder="e.g. Green"
                  value={lastName}
                  /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ lastName: event.target.value })} */
                />
              </div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  D.O.B
                </NexusLabel>
                <NexusInput
                  type="date"
                  data-testid="dateOfBirth"
                  value={DOB}
                  // onInput={(event: React.ChangeEvent<HTMLNexusInputElement>) => {
                  // this.setState({ DOB: event.target.value });
                  // }}
                  disabled
                />
              </div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  SSN Number
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="ssnNumber"
                  attrId="lastName"
                  disabled
                  placeholder="e.g. 084564977"
                  value={SSNNumber}
                  /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ SSNNumber: event.target.value })} */
                />
              </div>
            </div>
          </div>
          <div className="nexus-row nexus-mt-4">
            <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2">
              Step 02
            </div>
            <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
              <div className="form-head">Input Licence & Vehicle details</div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  Driver Licence
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="dlNumber"
                  attrId="dlNumber"
                  disabled
                  placeholder="e.g. W426545307610"
                  value={driverLicence}
                  /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ driverLicence: event.target.value })} */
                />
              </div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  Licence State
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="licenceNum"
                  attrId="licenceNum"
                  disabled
                  placeholder="e.g. FL"
                  value={licenceState}
                  /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ licenceState: event.target.value })} */
                />
              </div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  VIN Name
                </NexusLabel>
                <NexusInput
                  type="text"
                  data-testid="vin"
                  placeholder="e.g. 1HGCM82633A004352"
                  value={VIN}
                  /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ VIN: event.target.value })} */
                  disabled
                />
              </div>
            </div>
          </div>
          {this.buttonSubmit()}
        </>
      );
    };
    const rightTab = () => {
      const { result } = this.state;

      return (
        <>
          {!this.state.result && this.commonResponse()}
          {this.state.result && <>
            <div className="nexus-row nexus-ml-5 nexus-mb-3">
              <div className="address-head nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result">
                  Test Result
              </div>
            </div>
            <div
              className="accident-container nexus-ml-5"
              style={{ width: "80%" }}
            >
              <div className="nexus-row">
                <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result nexus-mb-1">
                    Driving Licence Status
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "170px" }}
                >
                    Driver Licence
                </div>
                <div className="validated-address-value nexus-px-1">:</div>
                <div className="validated-address-text">
                  {
                    result?.clueAutoResponse?.product_results
                      ?.clue_personal_auto[0]?.report?.search_dataset
                      ?.subjects?.driversLicense?.number
                  }
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "170px" }}
                >
                    Type
                </div>
                <div className="validated-address-value nexus-px-1">:</div>
                <div className="validated-address-text">
                  {
                    result?.clueAutoResponse?.product_results
                      ?.clue_personal_auto[0]?.report?.search_dataset
                      ?.subjects?.driversLicense?.type
                  }
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "170px" }}
                >
                    History
                </div>
                <div className="validated-address-value nexus-px-1">:</div>
                <div className="validated-address-text">
                  {
                    result?.clueAutoResponse?.product_results
                      ?.clue_personal_auto[0]?.report?.search_dataset
                      ?.subjects?.driversLicense?.history
                  }
                </div>
              </div>
              <div className="nexus-row">
                <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result nexus-mb-1">
                    Clue Report
                </div>
              </div>
              <div className="nexus-row nexus-pb-2">
                <div
                  className="validated-address-value"
                  style={{ width: "170px" }}
                >
                    Total Claims Reported
                </div>
                <div className="validated-address-value nexus-px-1">:</div>
                <div className="validated-address-text">
                  {
                    result?.clueAutoResponse?.product_results
                      ?.clue_personal_auto[0]?.report?.results_dataset
                      ?.claims_history[0]?.claims.length
                  }
                </div>
              </div>
              {result?.clueAutoResponse?.product_results?.clue_personal_auto[0]?.report?.results_dataset?.claims_history[0]?.claims.map(
                (claim: any, index: any) => <>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Claim Number {index + 1}
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.claim_number}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Policy Number
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.policy.policy_number}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Policy Type
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.policy.auto_type}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Claim Date
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.claim_date}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Claim Type
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.scope_of_claim}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Claim Amount
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.claimPayment.amount_paid}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Payment Status
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.claimPayment.disposition}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Reported VIN
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.Vehicle.vin}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Vehicle Make
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.Vehicle.model_make}
                    </div>
                  </div>
                  <div className="nexus-row nexus-pb-2">
                    <div
                      className="validated-address-value"
                      style={{ width: "170px" }}
                    >
                          Model Year
                    </div>
                    <div className="validated-address-value nexus-px-1">
                          :
                    </div>
                    <div className="validated-address-text">
                      {claim.Vehicle.model_year}
                    </div>
                  </div>
                </>

              )}
            </div>
          </>
          }
        </>
      );
    };

    const card = {
      title: "Clue Report",
      description:
        "Provide carriers with the ability to retrieve the Comprehensive Loss Underwriting Exchange (CLUE) which helps the Insurance Actuaries to calculate premium rates and Insurance Underwriters to categorize the risk standards during underwritting process.",
      subTitle: "Lexis Nexis",
      svg: paymentIcon,
      developerDoc: "clue-report"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design notification clue-report">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Clue Report Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default ClueReport;
