import React from "react";
import {
  NexusIcon,
  NexusFooter,
  NexusFooterBottom,
  NexusFooterLogo
} from "@nexus/react";
import Logo from "@nexus/core/dist/assets/images/nexus-footer-logo.svg";
import "./Footer.scss";

const Footer = () => <div
  className="nexus-container customer-footer-container px-3"
  style={{ backgroundColor: "#19191A" }}
>
  <div className="nexus-row">
    <div className="nexus-col-xs-4">
      <NexusFooter class="nexus-footer-sticky">
        <NexusFooterLogo className="nexus-px-4" style={{ height: "100px" }}>
          <a href="#">
            <NexusIcon src={Logo} size="xl" />
            <span className="nexus-visually-hidden">Home</span>
          </a>
        </NexusFooterLogo>
        <NexusFooterBottom>
          <p className="nexus-px-4 nexus-body">
              Copyright© Ernst & Young, LLC. All rights reserved
          </p>
        </NexusFooterBottom>
      </NexusFooter>
    </div>
  </div>
</div>;
export default Footer;
