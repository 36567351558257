import React, { useEffect, useState } from "react";
import "../CatalogStyle.scss";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import { NexusProgressBar, NexusRadio } from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import underwrittingrequest from "./underwrittingrequest";
import { CheckCircle } from "../../../../../assets/images";

const ADOBE_DATA = {
  pageName: "platform-capabilities/underwritting-rules",
  listName: "platform-capabilities/underwritting-rules",
};

const DUMMAY_INPUTFILED = {
  productType: "",
  coverageTerm: "",
  sumAssured: "",
  primaryInsured: "",
  dob: "",
  medicalCondition: "",
};
const UnderWrittingRules = () => {
  const service = new Service();
  const [loader, setLoader] = useState(false);
  const [inputFieldValue, setInputFieldValue] =
    useState<any>(DUMMAY_INPUTFILED);
  const [underwritting, setunderwritting] = useState<any>({});
  const card = {
    title: "Underwriting Rules",
    description:
      "Provide carriers with the ability to create a activity task for an underwriter automatically based on pre-configured business rules in any underwriting work bench system.",
    subTitle: "Drools",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "underwriting",
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const reset = () => {
    setInputFieldValue(DUMMAY_INPUTFILED);
    setunderwritting({});
  };

  const getUnderwritting = () => {
    setLoader(true);
    const fieldvalue = {
      productType: "Term Life",
      coverageTerm: "30",
      sumAssured: "50,000",
      primaryInsured: "John Miler",
      dob: "1971-05-01",
      medicalCondition: "Heart Disease",
    };
    setInputFieldValue(fieldvalue);
    const params = underwrittingrequest;
    service
      .getUnderwrittingReport(params)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setunderwritting(data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const buttonClickHandler = () => {
    if (inputFieldValue.productType === "") {
      getUnderwritting();
    } else {
      reset();
    }
  };

  const leftTab = () => (
    <>
      <div className="nexus-row">
        <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
          Step 01
        </div>
        <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head">Select Policy Details</div>
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6">
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Product Type</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue?.address1}
                placeholder="e.g. Term Life"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Coverage Term</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue?.address2}
                placeholder="e.g. 25"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Sum Assured</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue?.city}
                placeholder="e.g. 1,000,000"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Primary Insured</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue?.state}
                placeholder="e.g. John Doe"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Insured D.O.B</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue?.zipcode}
                placeholder="e.g. 1985-05-03"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Insured Smoker Class</div>
            <div className="radio-container">
              <NexusRadio
                name="SmokerStatus"
                id="Smoker"
                value="Smoker"
                checked
                disabled
              >
                Yes
              </NexusRadio>
              <NexusRadio
                className="nexus-ml-5"
                name="SmokerStatus"
                id="NonSmoker"
                value="NonSmoker"
                disabled
              >
                No
              </NexusRadio>
            </div>
          </div>
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">Medical Condition</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue?.zipcode}
                placeholder="e.g. Diabetic"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="confirm-button"
              onClick={() => buttonClickHandler()}
              data-testid="validateUnderwritting"
            >
              {inputFieldValue?.productType === ""
                ? "Try with Sample Data"
                : "Reset"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {inputFieldValue?.productType === "" && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Creating UW task with sample data
          </div>
        </div>
      )}
      {underwritting?.applicationId?.length > 0 && (
        <>
          <div className="address-head nexus-ml-5 nexus-mb-3">Test Result</div>
          <div className="accident-container nexus-p-4 nexus-col-lg-10 nexus-ml-5">
            <div className="nexus-row nexus-pb-2">
              <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
                <div>
                  Task{" "}
                  <span style={{ fontSize: "22px" }}>
                    {underwritting.applicationId}
                  </span>{" "}
                  was succesfully created and assigned to an Underwritter
                </div>
              </div>
            </div>
            <div className="nexus-row">
              <div className="nexus-col-xs-4 validated-address-value">
                Next Step: Underwritter Decision
              </div>
              <div className="nexus-col-xs-4 radio-container">
                <NexusRadio
                  name="nextAction"
                  id="Approve"
                  value="Approve"
                  checked
                >
                  Approve
                </NexusRadio>
                <NexusRadio
                  className="nexus-ml-5"
                  name="nextAction"
                  id="Reject"
                  value="Reject"
                >
                  Reject
                </NexusRadio>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Underwriting Rules"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default UnderWrittingRules;
