export const POINT_OF_INTEREST = [
  {
    name: "Towing Service",
    value: "Towing Service",
    key: "towing"
  }
];

export const STATES = [
  {
    name: "NY",
    value: "NY",
    key: "NY"
  },
  {
    name: "MI",
    value: "MI",
    key: "MI"
  }
];

export const HAZARD_RISK = [
  "Select One",
  "Fire",
  "Flood",
  "Firestation",
  "Distance to Flood",
  "Earthquake",
  "Crime"
];

export const DOCUMNET_GENERATION = [
  "Select One",
  "Physician Statement",
  "Employer Statement"
];
export const VEHICLE_HISTORY_SEARCH_CATEGORY = [
  {
    name: "Select One",
    value: "select_one",
    key: "select_one",
    url: "select_one"
  },
  {
    name: "Accident Plus",
    value: "accident_plus",
    key: "accident_plus",
    url: "accidentplus"
  },
  {
    name: "Accident Premium",
    value: "accident-premium",
    key: "accident-premium",
    url: "premium/accident"
  },
  {
    name: "Accidents",
    value: "accidents",
    key: "accidents",
    url: "accidents"
  },
  {
    name: "Analytics Indicator",
    value: "analytics-indicators",
    key: "analytics-indicators",
    url: "indicators/analytics"
  },
  {
    name: "Assured Indicator",
    value: "assured_indicator",
    key: "assured_indicator",
    url: "indicators/assured"
  },
  {
    name: "Auto Score",
    value: "auto_score",
    key: "auto_score",
    url: "auto/score"
  },
  {
    name: "Brand",
    value: "brand",
    key: "brand",
    url: "brand"
  },
  {
    name: "Buyback Protection",
    value: "buyback_protection",
    key: "buyback_protection",
    url: "buybackprotection"
  },
  {
    name: "Condition Indicator",
    value: "condition-indicator",
    key: "condition-indicator",
    url: "indicators/condition"
  },
  {
    name: "Financial Indicator",
    value: "financial_indicator",
    key: "financial_indicator",
    url: "indicators/financial"
  },
  {
    name: "Flood Risk",
    value: "flood_risk",
    key: "flood_risk",
    url: "indicators/floodrisk"
  },
  {
    name: "Mileage",
    value: "mileage",
    key: "mileage",
    url: "mileage"
  },
  {
    name: "Mileage Premium",
    value: "premium_mileage",
    key: "premium_mileage",
    url: "premium/mileage"
  },
  {
    name: "Ownership",
    value: "ownership",
    key: "ownership",
    url: "ownership"
  },
  {
    name: "Ownership Premium",
    value: "ownership-premium",
    key: "ownership-premium",
    url: "premium/ownership"
  },
  {
    name: "Problem",
    value: "problem",
    key: "problem",
    url: "problem"
  },
  {
    name: "Recall",
    value: "recall",
    key: "recall",
    url: "recall"
  },
  {
    name: "Recall Plus",
    value: "recall_plus",
    key: "recall_plus",
    url: "recallplus"
  },
  {
    name: "Recall Premium",
    value: "recall_premium",
    key: "recall_premium",
    url: "premium/recall"
  },
  {
    name: "VIN Specification",
    value: "vin_specification",
    key: "vin_specification",
    url: "retrievespecifications"
  },
  {
    name: "Salvage",
    value: "Salvage",
    key: "Salvage",
    url: "salvage"
  }
];

export const defaultModal = {
  header: 'Welcome to Nexus',
  usernameText: 'Username',
  emailText: 'Email Address',  
  name: 'John Doe',
  emailAddress: 'john.doe@email.com',
  confirm: 'Confirm',
  cancel: 'Cancel',
  buttonText: 'Show Modal',
  closeableTrue: 'Closeable - True',
  closeableFalse: 'Closeable - False'
};