import React from "react";
import "../Catalog.scss";
import { Warning } from "../../../../../assets/images";

class Recall extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  formatDate(date: string) {
    const slicedate = date.slice(0, 9);

    return slicedate;
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};

    return (
      <div>
        {vehicleData && <div
          className="test-result-box nexus-p-4 nexus-col-lg-10"
          style={{ fontSize: "18px",
            lineHeight: "20px" }}
        >
          <div className="nexus-row nexus-pb-3">
            <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
              <img className="verified-icons" src={Warning} alt="Warning" />
            </div>
            <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
              <div>{`Vehicle ${vehicleData.vin} is Recalled ${vehicleData.recallCount} Time`}</div>
            </div>
          </div>
          <div className="flex-row d-flex">
            <p className="vehicle-details-title">Event History</p>
          </div>
          {vehicleData.recallEvents.event.map((data: any, index: number) => <div className="nexus-pl-3" key={1 + index}>
            <div className="flex-row d-flex">
              <p className="vehicle-details-title">Event {index + 1}:</p>
            </div>
            <div>
              <div className="row vehicle-details-desc">
                <div className="col-sm-5">
                  <p className="validated-address-value"> Recalled Date</p>
                </div>
                <div className="col-sm-7">
                  <p className="validated-address-text">
                        : {this.formatDate(data.date)}
                  </p>
                </div>
                <div className="col-sm-5">
                  <p className="validated-address-value">Reason</p>
                </div>
                <div className="col-sm-7">
                  <p className="validated-address-text">: {data.text}</p>
                </div>
              </div>
            </div>
          </div>)}
        </div>
        }
      </div>
    );
  }
}

export default Recall;
