import React from "react";
import paths from "../../../../../utils/PathsUtils";
import FSSolutionExploreMoreCommonComponent from "../../FSSolutions/FSSolutionsExploreMore/FSSolutionExploreMoreCommonComponent";
import {
  NGTPKeyAssets,
  NGTPStrategicValue,
  NGTPBannerImage
} from "../Assets/image";

const NextGenTestingPlatform = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Design Tokens for rapid prototyping interfaces with Sketch, Figma, and Adobe XD",
        "Component Library with configurable Typography, Iconography, and Theming capabilities",
        "Out-of-the-box support for Accessibility, Responsiveness, and Motion guidelines",
        "Documentation of design vocabulary, implementation guidelines, and editorial governance",
        "Built on open web standards for framework-agnostic support using Web Components"
      ],
      img: NGTPKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Brand consistency across a portfolio of products, teams, and applications",
        "Future-proof building blocks abstracted away from framework churn",
        "Centralized governance for socialization of best practices for digital maturity and culture",
        "Gherkin-based automation engine enables rapid deployment of automated test assets for reusable components and seamless integration of new tests into pipeline"
      ],
      img: NGTPStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: NGTPBannerImage,
    heading: "NextGen Testing Platform",
    subHeading:
      "The Next Generation Testing Platform can be used to test web and desktop applications, APIs and microservices, data and ETL functions, mainframes and more, making NGTP ideal for end-to-end testing",
    previousLinkPath: paths.tools,
    contentClass: "banner-content-black",
    bannerButtonDetails: {
      text: "Learn More",
      url: "https://pub.ey.com/public/2020/2008/2008-3563189/nextgen-testing-platform-marketing/index/capabilities.html",
      isBgColorWhite: null
    }
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default NextGenTestingPlatform;
