import {
  Bronze,
  Silver,
  Gold,
  Star,
  BluePrint,
  Dexlus,
  Template
} from "./image/index";

const packageType = [
  {
    tier: "TIER 1",
    tierType: "Bronze",
    decription1:
      "Acquire new customers by identifying desirable event triggers.",
    decription2:
      "Understand customer base based on customer engagement with the native application.",
    decription3:
      "Learn more about customer base from customer motives, behaviors and preferences.",
    tierColor: Bronze
  },
  {
    tier: "TIER 2",
    tierType: "Silver",
    decription1:
      "Embed insights into existing propositions to increase consumer engagement.",
    decription2:
      "Create real time analytics for customers and understand their effectiveness in real time.",
    decription3:
      "Increase engagement while minimizing administrative overhead by streamlining user preferences, consent and etc.",
    tierColor: Silver
  },
  {
    tier: "TIER 3",
    tierType: "Gold",
    decription1:
      "Identify and acquire new customers by proactively “nudging” them towards priority products.",
    decription2:
      "Intelligently match customers to product segments based on their needs and behavior. ",
    decription3:
      "Provide enterprise customers with the infrastructure to securely bundle events into predictive journey templates.",
    tierColor: Gold
  }
];

const InsuranceProduct = [
  {
    titleLogo: Star,
    titile: "Add-on",
    logo: BluePrint,
    heading: "BluePrints",
    description: [
      "Go-to-Market with demonstrable, working software built to the highest standards. These cloud native Blueprints focus on specific customer or user journeys that are built to help engagement teams communicate solutions to client that feel real and solve real business problems. These Blueprints are built to the requirements of Engagement teams, and typically encompass an engagement layer (built in React), Nexus Connect APIs, and configured third party backend systems."
    ],
    price: "$25K"
  },
  {
    titleLogo: Star,
    titile: "Add-on",
    logo: Template,
    heading: "Templates",
    description: [
      "Set of line of business specific accelerators that help engagement teams stand up insurance products at speed."
    ],
    price: "$65K"
  },
  {
    titleLogo: Star,
    titile: "Deluxe",
    logo: Dexlus,
    heading: "Industry Solutions",
    description: [
      "Standalone software products, each with their unique commercialization model, with functionality that targets specific areas of the insurance value chain. These solutions are proprietary and built on top of the Nexus stack, with managed services provided to clients throughout the lifecycle of the application."
    ],
    price: "$100K"
  }
];
const accordionData = [
  {
    title: "Section 1",
    content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
    laborum cupiditate possimus labore, hic temporibus velit dicta earum
    suscipit commodi eum enim atque at? Et perspiciatis dolore iure
    voluptatem.`
  },
  {
    title: "Section 2",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`
  },
  {
    title: "Section 3",
    content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
    quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
    dolor ut sequi minus iste? Quas?`
  }
];
const accordionData2 = [
  {
    title: "Section 4",
    content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
    laborum cupiditate possimus labore, hic temporibus velit dicta earum
    suscipit commodi eum enim atque at? Et perspiciatis dolore iure
    voluptatem.`
  },
  {
    title: "Section 5",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`
  },
  {
    title: "Section 6",
    content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
    quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
    dolor ut sequi minus iste? Quas?`
  }
];
export { packageType, InsuranceProduct, accordionData, accordionData2 };
