import React from "react";
import "./Catalog.scss";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

interface Card {
  title: string;
  description: string;
  subTitle: string;
  icon?: string;
  developerDoc: string;
  svg?: string;
}

// const history = useHistory();
const docBaseURL = "https://nexusforinsurance-demo.sbp.eyclienthub.com/nexusforinsurance/ni-apidocumentation-slate/demo/index.html#";

const CatalogHeader = ({
  card,
  breadcrum
}: {
  card: Card;
  breadcrum?: string;
}) => {
  const history = useHistory();

  return (
    <>
      <div className="nexus-row nexus-p-3">
        <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-12">
          <a
            data-testid="back-btn"
            style={{
              textDecoration: "none",
              color: "#2E2E38",
              fontFamily: "EYInterstate Light",
              cursor: "pointer"
            }}
            onClick={() => {
              history.push("/platform-capabilities");
            }}
          >
            Capabilities{" "}
          </a>
          <strong className="nexus-m-1">{" > "}</strong>
          <strong
            style={{
              fontFamily: "EYInterstate Regular",
              fontWeight: "bold"
            }}
          >
            {breadcrum ? breadcrum : card.title}
          </strong>
        </div>
      </div>
      <div className="nexus-container padding-margin-0">
        <div className="nexus-p-2 nexus-py-4 address-top-container nexus-px-3">
          <div className="nexus-row">
            <div className="nexus-col-sm-3 nexus-col-md-5 nexus-col-lg-9">
              <div className="address-title nexus-mb-3">{card.title}</div>
              <div className="card-description" style={{ color: " #19191A" }}>
                {card.description}
                <div className="card-description-bottom">
                  <label className="highlighted">Powered by: </label>
                  <label style={{ marginLeft: "5px" }}>{card.subTitle}</label>
                </div>
              </div>
            </div>
            <div className="nexus-col-sm-1 nexus-col-md-3 nexus-col-lg-3 nexus-flex-col justify-content-center align-items-center">
              <button
                className="address-title-right nexus-btn nexus-btn-auto"
                onClick={() => window.open(`${docBaseURL}${card.developerDoc}`, "_blank")
                }
              >
                Developer Documentation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
CatalogHeader.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    icon: PropTypes.string,
    svg: PropTypes.string,
    developerDoc: PropTypes.string
  }).isRequired,
  breadcrum: PropTypes.string
};
export default CatalogHeader;
