import React from "react";
import "../Catalog.scss";
import { Warning } from "../../../../../assets/images";

class AccidentPremium extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};

    return (
      <div>
        {vehicleData && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
          <div>
            {vehicleData.accidentCount >= 1 ? <div className="nexus-row">
              <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
                <img
                  className="verified-icons"
                  src={Warning}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">{`Vehicle's ${vehicleData.lastReportedAccidentEvent} was reported damaged on ${vehicleData.lastReportedAccidentDate}.`}</div>
            </div> : <div className="ml-3 vehicle-details-desc">
                  No damage report
            </div>
            }
          </div>

          <div className="nexus-row mt-4 ml-2">
            <div className="nexus-col-xs-4 nexus-pl-4  vehicle-details-title">
                Vehicle Accident History
            </div>
            <div className="nexus-col-xs-4 ml-4">
              <div className="nexus-row">
                {vehicleData.reportedAccidents
                  .map((items: any) => <div
                    className="nexus-row ml-2"
                    key={items.accidentSeqNum}
                  >
                    <div className="nexus-col-xs-4">
                      <div className="nexus-row">
                        <div className="vehicle-details-title mt-3">
                              Vehicle Accident :{items.accidentSeqNum}
                        </div>
                      </div>
                    </div>
                    <div className="nexus-col-xs-4 mt-2">
                      <div className="nexus-row">
                        <div
                          className="vehicle-details-desc capitalize"
                          style={{ minWidth: "130px" }}
                        >
                          {" "}
                              Accident Reported
                        </div>
                        <div className="validated-address-text">
                          {` :${items.type.split(":")[1]}`}
                        </div>
                      </div>
                    </div>
                    <div className="nexus-col-xs-4 mt-2">
                      <div className="nexus-row">
                        <div
                          className="validated-address-value"
                          style={{ minWidth: "130px" }}
                        >
                              Reported Date
                        </div>
                        <div className="validated-address-text">{`: ${items.reportedDate}`}</div>
                      </div>
                    </div>
                    <div className="nexus-col-xs-4 mt-2">
                      <div className="nexus-row">
                        <div
                          className="validated-address-value"
                          style={{ minWidth: "130px" }}
                        >
                              City
                        </div>
                        <div className="validated-address-text capitalize">{`: ${items.city}`}</div>
                      </div>
                    </div>
                    <div className="nexus-col-xs-4 mt-2">
                      <div className="nexus-row">
                        <div
                          className="validated-address-value"
                          style={{ minWidth: "130px" }}
                        >
                              State
                        </div>
                        <div className="validated-address-text">{`: ${items.state}`}</div>
                      </div>
                    </div>
                  </div>)
                  .reverse()}
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

export default AccidentPremium;
