/* eslint-disable no-dupe-keys */

class CardDescription {
  description = {
    " Document Generation":
      "Provide carriers with the ability to Generate a document based on the Templates. Based on the details provided on the request and the name of the Document.",
    "Human Health API":
      "Provide carriers with the ability to access customer medical record from a comprehensive selection of health care providers in the US. Obviously, only upon explicit customer consent, in a CCPA, GDPR compliant way!",
    "Address Typeahead":
      "Provide carriers with the ability to choose the exact address based on a given text.",
    "Vehicle History":
      "Provide carriers with the ability to extract car details and history based on a Vehicle Identification Number. Vehicle History information is leveraged across underwriting, policy issuance, renewal and claim lifecycles.",
    "Vehicle Info":
      "Provide carriers with the ability to search for details of the Vehicle based on the VIN/Plate number. The most common example of this is the ability to offer the number of the vehicle, and the rest of the vehicle details being automatically generated. VIN/Plate is leveraged across policy issuance, renewal and claim lifecycles.",
    "Vehicle Telematics":
      "As innovation continues to permeate through the Personal Auto Insurance space, Vehicle Telematics becomes an increasingly crucial technology. The Nexus Vehicle Telematics connector enables access to driver behavior information from leading solution providers, providing clients with the customer driving data they need to go to market with new Usage Based Insurance product offerings.",
    "Vehicle Symbols":
      "The Nexus Vehicle Symbols connector enables access to ISO Symbol data. Symbols are numeric representations of a vehicle’s relative propensity for loss. Symbols are based on the retail price of each vehicle and then examined against actual loss experience for that vehicle for further refinement and to develop more accurate premium.",
    "ID Scan":
      "Customers continue to demand innovation in their digital insurance journeys; capabilities like ID scanning are becoming table stakes for every insurance direct-to-consumer channel. The Nexus ID Scan connector brings this capability to our clients through our integrations with leading ID scan solution providers.",
    "Credit Report":
      "The Nexus Credit Report connector leverages integrations with the leading credit profile providers to enable access to consumer credit data; this is a key integration for nearly every insurance customer journey.",
    "Address Validation":
      "Address validation and standardization are critical pieces of any business operation not just to ensure engagement with customers, but to also provide business intelligence and predictive analytics teams with the high-quality data that they need.",
    "International Address Validation":
      "International address verification has its challenges. It is expensive to acquire the data, whether first-hand or second-hand. It is costly to check against the database in the first place, and it can be difficult to write the code that teaches the program how to differentiate and sort through all the different address standards involved in standardizing an international address. The Nexus International Address Validation connector solves this problem for clients by leveraging integrations with leading solution providers, streamlining the process for validating International Addresses.",
    "Address Parsing":
      "Oftentimes, important customer data, such as address information, is embedded within large blocks of text that are generated through activities such as call center interactions. Enabling clients with access to this data can prove crucial towards analytics and other business functions. The Nexus Address Parsing connector consumes nearly any data string and is able to accurately parse and validate address data that is found within that data string.",
    "PostCode Lookup":
      "A crucial component of nearly every UK insurance customer journey, postal code lookup capabilities allow customers to lookup their addresses based on their UK postal codes. ",
    "Geocoding":
      "Provide carriers with the ability to extract detailed address details including lattitude and longitude both in US and Internationally",
    // 'Email and Phone Number Validation': 'In order to reduce fraud and improve communications, it is crucial to verify phone numbers and emails provided by customers. The Nexus Email and Phone Number Validation connector interfaces with industry-leading solution providers and provides clients with confidence around the ingestion of their customers’ contact information.',
    // 'Carbon Emission Calculator': 'Provide carriers with the ability to calculate how much Carbon a customer’s current car would emit on a yearly basis.',
    "Payments":
      "Nexus has developed integrations with Stripe to provide clients with cutting-edge, PCI complaint customer payment solutions.",
    "Driver Violations":
      "The Nexus Driver Violations connector leverages an integration with LexisNexis to provide Motor vehicle records (MVR) from all 50 states and the District of Columbia, with additional service for several Canadian provinces and Puerto Rico.",
    "Facial Analysis":
      "The Nexus Facial Analysis connector leverages an integration with Lapetus Services to provide a digital solution that enables non-invasive underwriting for Life Insurance products. By consuming an uploaded customer “selfie”, Lapetus can provide an indication of life expectancy, smoker status, and other crucial health attributes that can be leveraged for quick-quote underwriting.",
    "Human Data":
      "The Nexus Human Data connector leverages an integration Human API. Human API enables companies to better serve their customers and users by aggregating 30,000+ health and wellness data sources into one platform, streamlining the process to acquire digital customer medical records.",
    "Wearables Data ":
      "The Nexus Wearables connector leverages an integration with Human API to provide data from over 300 different activity trackers, wearables, and fitness apps.",
    "Vehicle Domain Service":
      "The Nexus Vehicle domain service is a middleware API that captures, validates, and persists Vehicle data into a core backend system.",
    "Driver Domain Service":
      "The Nexus Driver domain service is a middleware API that captures, validates, and persists Driver data into a core backend system. ",
    "Personal Auto Submission Domain Service":
      "The Nexus Personal Auto Submission domain service is a middleware API that captures, validates, and persists Auto Submission data into a core backend system.",
    "Personal Auto Claims Document Domain Service": "",
    "Personal Auto Policy Submission Document Domain Service": "",
    "Personal Auto: Account Domain Service":
      "The Nexus Account domain service is a middleware API that captures, validates, and persists Account-level data into a core backend system.",
    "Group Domain Service":
      "The Nexus Group domain service is a middleware API that captures, validates, and persists data related to a Group Benefits organization into a core backend system.",
    "Group Census Domain Service":
      "The Nexus Group Census domain service is a middleware API that captures, validates, and persists Group Benefits Census data into a core backend system.",
    "Group Proposal Domain Service":
      "The Nexus Group Proposal domain service is a middleware API that captures, validates, and persists Group Benefits Proposal data into a core backend system.",
    "Group Member Domain Service":
      " The Nexus Group Member domain service is a middleware API that captures, validates, and persists Group Benefits Member data into a core backend system.",
    "Group Offering Domain Service":
      "The Nexus Group Offering domain service is a middleware API that captures, validates, and persists Group Benefits insurance offering data into a core backend system.",
    "Group Enrollment Domain Service":
      "The Nexus Group Enrollment domain service is a middleware API that captures, validates, and persists Group Benefits employee enrollment data into a core backend system.",
    "Group Dependent Domain Service":
      "The Nexus Group Dependent domain service is a middleware API that captures, validates, and persists Group Benefits enrollee dependents data into a core backend system.",
    "Group Provider Domain Service":
      "The Nexus Group Provider domain service is a middleware API that captures, validates, and persists Group Benefits provider data into a core backend system.",
    "Group Contract Administration Domain Service":
      "The Nexus Group Contract Administration domain service is a middleware API that captures, validates, and persists Group Benefits contract administration data into a core backend system.",
    "Group Billing Domain Service":
      "The Nexus Group Billing domain service is a middleware API that captures, validates, and persists Group Benefits Billing data into a core backend system.",
    "Group Medical Underwriting Domain Service":
      "The Nexus Medical Underwriting domain service is a middleware API that captures, validates, and persists Group Benefits-related medical underwriting data into a core backend system.",
    "Term Life Party Domain Service":
      "The Nexus Term Life Party domain service is a middleware API that captures, validates, and persists Party data into a core backend CRM system.",
    "Term Life Submission":
      "The Nexus Term Life Submission domain service is a middleware API that captures, validates, and persists Term Life Submission data into a core backend system.",
    "Term Life Policy":
      "The Nexus Term Life Policy domain service is a middleware API that captures, validates, and persists Term Life Policy data into a core Policy backend system.",
    "Document Signature":
      "Provide carriers with the ability to manage agreements electronically, whether it be internal facing approvals or customer facing policy documentation or more.",
    "eForms":
      "Create ultra-personalized customer documents, on-demand, and from any channel. The Nexus eForms connector provides this capability to clients through an integration with OpenText’s Exstream software. This capability is a crucial component of the Nexus Disability Claims Intake industry solution.",
    "Next Best Action":
      "The Nexus Next Best Action connector leverages an integration with Pega to provide an indication of the next best action during Disability Claims processing.",
    "Customer Feedback":
      "The Nexus Customer Feedback connector provides a simple API that captures customer feedback data.",
    "Pet Submission":
      "The Nexus Pet Submission domain service is a middleware API that captures, validates, and persists Pet Insurance Submission data into a core backend system.",
    "Pet Policy":
      "The Nexus Pet Policy domain service is a middleware API that captures, validates, and persists Pet Insurance Policy data into a core Policy backend system.",
    "Commercial Property Prefill":
      "The Nexus Commercial Property Prefill connector provide valuable commercial property attributes that allow insurers to better select properties, evaluate risk, and streamline underwriting processes.",
    "Residential Property Prefill":
      "The Nexus Residential Property Prefill connector provide valuable commercial property attributes that allow insurers to better select properties, evaluate risk, and streamline underwriting processes.",
    "Notifications":
      "Send SMS and Email notifications to customers seamlessly through a single Nexus API.",
    "Push Notifications":
      "Send push notifications to iOS & Android devices (APNs, GCM, FCM) and web browsers through a single Nexus API.",
    "Customer Verification":
      "Built to help clients fight fraud before it starts, the Nexus Customer Verification connector adds verification to customer journeys by validating users with SMS, Voice, Email and Push notification.",
    "Vehicle Damage Recognition":
      "Provide carriers with the ability to by simply uploading photos of a damaged vehicle, Nexus can provide an indication of damage, damage cost, total loss, and fraud. This enables efficient routing of claims to the appropriate teams, in addition to enabling STP for low-severity claims.",
    "Fraud Detection":
      "Safely enable STP underwriting and touchless claims. Assess risks in real time, payout legitimate claims faster, and improve the customer experience. Obtain a holistic view on risks by plugging in external data sources. Onboard good customers in a split second. Be compliant with rules and regulations. Clients can tap into these capabilities by leveraging the Nexus Fraud Detection connector, which is integrated with the leading fraud assessment service providers.",
    "CLUE": "Provide carriers with the ability to retrieve the Comprehensive Loss Underwriting Exchange (CLUE) which helps the Insurance Actuaries to calculate premium rates and Insurance Underwriters to categorize the risk standards during underwritting process.",
    "Local Services":
      " The Nexus Location Services connector enables access to location services from leading vendors. Location Services are a crucial component in contemporary Auto insurance customer claims journeys as they provide customers with the flexibility and convenience to find nearby auto repair shops.",
    /* Ecosystem */

    "360GlobalNet":
      "An end-to-end SaaS Claims Management platform, 360GlobalNet’s no-code digital claims technology strips out the cost and delays from traditional IT systems and developments, handing the power, agility and flexibility back to the business practitioners by no longer requiring IT development teams to make changes to customer interfaces and claims processes.",
    "CCC": "CCC is a technology solution provider that power insurers, automotive manufacturers, collision repairers, parts suppliers, lenders, fleet operators and more through their cloud-based SaaS platform of digital and data services. CCC has specialized insurance-specific capabilities in AI, IoT, network management, customer experience, and digital workflows.",
    "Cape Analytics":
      "Cape Analytics uses deep learning and geospatial imagery to provide instant property intelligence for buildings across the United States. Cape Analytics enables insurers and other property stakeholders to access valuable property attributes at the time of underwriting—with the accuracy and detail that traditionally required an on-site inspection, but with the speed and coverage of property record prefill.",
    "Experian":
      "In addition to its function as a credit reporting agency, Experian also collects and aggregates information on over 1 billion people and businesses including 235 million individual U.S. consumers and more than 25 million U.S. businesses. In addition to its credit services, Experian also sells decision analytic and marketing assistance to businesses, including individual fingerprinting and targeting. Its consumer services include online access to credit history and products meant to protect from fraud and identity theft.",
    "Formotiv":
      "Formotiv is an end-to-end platform for reducing online risk and improving customer experience. Formotiv’s solution measures end user behavioral biometrics in real-time to detect future outcomes like fraud, delinquency, profitability, and abandonment as users engage with digital applications.",
    "Hostbridge":
      "For organizations that have a continuing investment in CICS applications, the HostBridge JavaScript Engine (HB.js) enables creation of JavaScript/JSON-based integration scripts and APIs. HB.js scripts work with all types of CICS applications without relying on screen scraping. HB.js is a complete solution for rapidly developing and deploying reusable Web services and/or scripts. ",
    "Human API":
      " Human API is a consumer-controlled health data platform that gives consumers/users a simple way to connect and share their health data with health businesses, platforms, or applications. Human API’s business customers (both health enterprises & start-ups) use their product to build and deliver consumer-centric health apps and services. Their network includes data recorded in medical records from hospitals’ patient portals, test results from labs, medications from pharmacies, and wellness data from consumer-grade wearable devices & fitness apps. Human API takes care of the data integrations with third party data sources and user management for identities across all data sources. Human API also work to normalize the data over a secure, HIPAA compliant, RESTful API.",
    "Lapetus":
      " Lapetus Solutions integrates science and technology with machine learning to develop tools and platforms that enable industries relying on life event prediction to reduce and replace their dependence on traditional assessment methods with faster, more cost-effective and increasingly accurate results. ",
    "Microblink":
      "Microblink is a global leader in AI-powered computer vision software. The company offers a variety of SDKs mand APIs for digitizing documents, automating processes, and eliminating manual data entry for mobile and web applications. The company’s global customer base spans 60 countries with enterprises in financial services, market research, insurance, telecom, consumer packaged goods, and retail. ",
    "Precisely":
      "Precisely was formed when Syncsort and Pitney Bowes Software & Data combined, bringing together decades of experience and expertise in handling, processing and transforming data. Syncsort specialized in optimizing, integrating and advancing data, enabling customers to extract value from their information wherever and whenever they needed it. Pitney Bowes Software & Data brought unmatched proficiency in customer information management, location intelligence, data enrichment and customer engagement solutions. Nexus is leveraging Precisely for a variety of connectors, including address validation, email & phone number validation, and geocoding.  ",
    "Sentiance":
      "Sentiance is an intelligence-driven data science and behavior change company. Sentiance turns motion data into contextual insights and uses behavioral change techniques to personalize engagement for safer and sustainable mobility and wellbeing experiences. Sentiance empowers the world's leading companies to create personalized engagement services and products through intelligent contextual recommenders, delivering technology for a new human-centric economy where the user is in control of data. ",
    "SmartyStreets":
      "SmartyStreets is a leader in location data intelligence. SmartyStreets provides a suite of address validation APIs for USPS and International addresses, in addition to an address autocomplete API for data entry and geocoding and reverse geocoding APIs. SmartyStreets APIs will standardize, validate and enrich address data for any address around the globe. ",
    "Socotra":
      " Socotra is a technology platform that builds a modern cloud-based Policy Administration and Claims platform for technology-driven insurers. Its platform is built from the ground-up using the latest technologies to be transparent, reliable, flexible, and secure. It aims to provide insurers with a modern, enterprise-grade core system that enables them to rapidly develop and distribute products that better serve their customers. Socotra was founded in 2014 and is headquartered in San Francisco, California. ",
    "Stripe":
      " Stripe is a global technology company that builds economic infrastructure for the internet. Businesses of every size from new startups to public companies like Salesforce and Facebook use the company’s software to accept online payments and run complex global operations. The company combines economic infrastructure with a set of applications for new business models like crowdfunding and marketplaces, fraud prevention, analytics, and more. Stripe navigates global regulatory uncertainty and partners closely with internet leaders like Apple, Google, Alipay, Tencent, Facebook, Twitter to launch new capabilities. ",
    "Twilio":
      "Twilio is the developer platform for communications is reinventing telecom by merging the worlds of cloud computing, web services, and telecommunications. With Twilio, developers and businesses make communications more contextual by embedding voice, video, messaging, and authentication directly into applications. ",
    "DVLA": "DVLA (Driver and Vehicle Licensing Agency), is a UK Government entity that is responsible for holding more than 49 million driver records and more than 40 million vehicle records. DVLA digitizes these records and exposes the data via Restful API. ",
    "EIS": "EIS Group is focused on providing IT solutions that enable companies to convert their technology into strategic assets. Its solutions leverage companies existing IT infrastructure to automate and streamline operations to enable straight-through processing for Insurance, banking, communication, and public sector enterprises. ",
    "Fadata":
      "Fadata is a privately owned company established in 1990. The Company has a strong profile in information system development and implementation software related projects. Its reputation was built by providing high quality engineering solutions and services to companies from the Financial, Public, and Utilities sector. INSIS, the flagship product of Fadata, is a flexible state-of-the-art modular software solution that provides full coverage of all aspects of insurance business area both horizontally and vertically. ",
    "GT Software":
      "GT Software delivers a range of integration, modernization, and migration solutions across a variety of platforms. Its mainframe integration solutions include mobile enablement, and applications and data integration and orchestration. GT Software offers Ivory Service Architect, a solution that enables developers to integrate the mainframe with new technology solutions such as mobility, web-based portals, business intelligence, business process management, and packaged applications; Ivory Data Hub is able to optimize the Mainframe data access for agile IT; NeoKicks, is able to convert CICS BMS map screens into ASP.NET web pages; NeoBatch is able to reduce batch processing time; NeoData is able to transform VSAM to SQL; NetCOBOL, is a COBOL compiler that provides security solutions for legacy data. GT Software was founded in March 1976 and is based in Atlanta, Georgia. ",
    "Hazard Risk":
      "Provide carriers with the ability to search for any Crime, Earthquake, Fire, Flood, Firestation and distance to Flood Hazard Risks located nearby address or location.",
    "Imburse":
      "The Imburse “Payments as a Service” enterprise platform offers a single access point to the entire global payments ecosystem, from card collections, to debit orders, e-wallets, push to card transactions, and more.",
    "Instanda":
      "Instanda is a SaaS insurance software platform that allows insurance companies to build, configure, and launch products online. Its no-code product design platform is built on a powerful set of insurance speciﬁc calculations, processing, and workﬂow capabilities. Instanda works with the carriers, MGAs, and brokers in the UK, Europe, US, Canada, LATAM and Australia. ",
    "Microsoft Dynamics 365":
      "Dynamics 365 is a set of interconnected, modular Software-as-a-Service (SaaS) applications and services designed to both transform and enable core customers, employees, and business activities. It combines CRM and ERP capabilities, integrating data, business logic, and processes.",
    "Document Scan":
      "Provide financial services firms with the ability to automate personal information collection process by scanning IDs and driver’s licenses.",
    "Email and Phone Number Validation":
      "Provide financial services firms with the ability to automate personal information collection process by scanning IDs and driver’s licenses.",
    "Carbon Emission Calculator":
      "Provide carriers with the ability to calculate how much Carbon a customer’s current car would emit on a yearly basis.",
    "Wearables Data":
      "Provide carriers with the ability to serve their customers and users by aggregating 30,000+ health and wellness data sources into one platform, streamlining the process to acquire digital customer medical records",
    " Rating Connector":
      "Provide carriers with the ability to by simply uploading photos of a damaged vehicle, Nexus can provide an indication of damage, damage cost, total loss, and fraud. This enables efficient routing of claims to the appropriate teams, in addition to enabling STP for low-severity claims."
  };
}

export default CardDescription;
