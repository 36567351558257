import React, { Component } from "react";
import { NexusFormField, NexusLabel, NexusInput } from "@nexus/react";

interface InputList {
  labelTestid: string;
  labelTittle: string;
  inputTestid: string;
  value: string;
  isDisabled: boolean;
  placeholder: string;
}
interface Props {
  InputArray: InputList[];
}
class DisabledInput extends Component<any, Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      InputArray: this.props.InputArray
    };
  }

  render() {
    return (
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6 nexus-mt-1">
          {this.state.InputArray.map((_item: InputList) => <NexusFormField key={_item.labelTestid}>
            <NexusLabel data-testid={_item.labelTestid}>
              {_item.labelTittle}
            </NexusLabel>
            <NexusInput
              key={_item.inputTestid}
              data-testid={_item.inputTestid}
              value={_item.value}
              disabled
              placeholder={_item.value}
            ></NexusInput>
          </NexusFormField>)}
        </div>
      </div>
    );
  }
}

export default DisabledInput;
