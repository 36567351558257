import React from "react";
import "../CatalogStyle.scss";
import {
  NexusFormField,
  NexusLabel,
  NexusProgressBar,
  NexusSelect
} from "@nexus/react";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { InputArray, DropdownOption } from "./inputList";
import StepOne from "./StepOne";
import AirHurricaneHazard from "./API/AirHurricaneHazard";
import DistanceToCoast from "./API/DistanceToCoast";
import HailDamage from "./API/HailDamage";
import PublicProtection from "./API/PublicProtection";
import CitizenTerritory from "./API/CitizenTerritory";
import Sinkhole from "./API/Sinkhole";

const ADOBE_DATA = {
  pageName: "platform-capabilities/locationsuite",
  listName: "platform-capabilities/locationsuite"
};

interface InputList {
  labelTestid: string;
  labelTittle: string;
  inputTestid: string;
  value: string;
  isDisabled: boolean;
  placeholder: string;
}

interface State {
  loader: boolean;
  resetButton: boolean;
  inputArray: InputList[];
  selectedReportType: string;
  enableResComponent: string;
}

interface Props {}

const service = new Service();
class LocationSuite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loader: false,
      resetButton: false,
      inputArray: InputArray,
      selectedReportType: "Please Select",
      enableResComponent: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit() {
    const { resetButton, selectedReportType } = this.state;
    if (resetButton) {
      this.setState({
        enableResComponent: "Please Select",
        resetButton: false,
        selectedReportType: "Please Select"
      });
    }
    else {
      this.setState({
        enableResComponent: selectedReportType,
        resetButton: true,
        loader: true
      });
    }
  }

  disableLoader(_action: boolean): void {
    const middleOfPage = Math.max(
      0,
      (document.documentElement.scrollHeight || document.body.scrollHeight) / 4 - window.innerHeight / 3
    );
    this.setState({ loader: _action });
    // bring page scroll to middle
    window.scrollTo(0, middleOfPage);
  }

  render() {
    const { enableResComponent, resetButton, selectedReportType } = this.state;
    const leftTab = () => <>
      <div className="nexus-row nexus-mb-3">
        <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 01
        </div>
        <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-6">
          <div className="form-head nexus-mb-2">Select Report Type</div>
          <NexusFormField>
            <NexusLabel>Report Type</NexusLabel>
            <NexusSelect
              key="locationSuite"
              value={selectedReportType}
              disabled={resetButton}
              data-testid="locationSuite"
              onInput={(event: any) => this.setState({ selectedReportType: event.target.value })
              }
            >
              <option value="Please Select" disabled>
                  Please Select
              </option>
              {DropdownOption.map((_list) => <option
                key={_list.id}
                value={_list.id}
                disabled={_list.disabled}
              >
                {_list.name}
              </option>)}
            </NexusSelect>
          </NexusFormField>
        </div>
      </div>
      <StepOne InputArray={InputArray} />
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            data-testid="my-btn"
            type="button"
            className="confirm-button"
            disabled={selectedReportType === "Please Select"}
            style={{
              cursor:
                  selectedReportType === "Please Select" ? "not-allowed" : "pointer"
            }}
            onClick={() => {
              this.submit();
            }}
          >
            {resetButton ? "Reset" : "Try with Sample Address"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!resetButton && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "70%" }}
      >
        <div className="address-sample">
              Try Location risk report with sample address
        </div>
      </div>
      }

      {enableResComponent === "AirHurricaneHazard" && <AirHurricaneHazard
        disableLoader={(_action) => this.disableLoader(_action)}
      />
      }
      {enableResComponent === "DistanceToCoast" && <DistanceToCoast
        disableLoader={(_action) => this.disableLoader(_action)}
      />
      }
      {enableResComponent === "HailDamage" && <HailDamage
        disableLoader={(_action) => this.disableLoader(_action)}
      />
      }
      {enableResComponent === "PublicProtectionClass" && <PublicProtection
        disableLoader={(_action) => this.disableLoader(_action)}
      />
      }
      {enableResComponent === "CitizenTerritory" && <CitizenTerritory
        disableLoader={(_action) => this.disableLoader(_action)}
      />
      }
      {enableResComponent === "Sinkhole" && <Sinkhole disableLoader={(_action) => this.disableLoader(_action)} />
      }
    </>;
    const card = {
      title: "Location Suite",
      description:
        "From community resiliency and safety data, to natural hazard and catastrophe risk, Location suite provides carriers a set of solutions that can put much of the data you need in one place, strengthened by analytics for an even clearer picture of the risks you face.",
      subTitle: "Verisk",
      icon: mapsIcMyLocation24px,
      developerDoc: "location-suite"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper" data-testid="loader">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Location Suite"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default LocationSuite;
