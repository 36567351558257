import React from "react";
import { Route, Switch } from "react-router-dom";
import Waterline from "./Waterline/Waterline";
import ReplacementCost from "./ReplacementCost/ReplacementCost";
import { paths } from "../../../../utils/PathsUtils";
import RoofDataDemo from "./RoofData/RoofDataDemo";
import PaperMailDelivery from "./PaperMailDelivery/PaperMailDelivery";
import LocationSuite from "./LocationSuite/LocationSuite";
const {
  platformaccelerators,
  waterline,
  replacementcost,
  roofData,
  paperMailDelivery,
  locationsuite
} = paths;
const PlatformAccRoutes = () => <Switch>
  <>
    <Route
      exact
      path={`${platformaccelerators}${waterline}`}
      component={Waterline}
    />
    <Route
      exact
      path={`${platformaccelerators}${replacementcost}`}
      component={ReplacementCost}
    />
    <Route
      exact
      path={`${platformaccelerators}${roofData}`}
      component={RoofDataDemo}
    />
    <Route
      exact
      path={`${platformaccelerators}${locationsuite}`}
      component={LocationSuite}
    />
    <Route
      exact
      path={`${platformaccelerators}${paperMailDelivery}`}
      component={PaperMailDelivery}
    />
  </>
</Switch>;
export default PlatformAccRoutes;
