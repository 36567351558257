import React from "react";
import "../CatalogStyle.scss";
import { NexusProgressBar } from "@nexus/react";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { fromInformation, toInformation, mailInformation } from "./FormFields";
import { PaperMailImg } from "../../../../../assets/images";
import DisabledInput from "../DisabledInput";
import Step from "../Step";

const ADOBE_DATA = {
  pageName: "/platform-accelerators/paper-mail-delivery",
  listName: "/platform-accelerators/paper-mail-delivery"
};

interface IPaperMailDeliveryState {
  loader: boolean;
  showResults: boolean;
}

const card = {
  title: "Paper Mail Delivery",
  description: `Enables carriers to automate direct mail by triggering on-demand postcards,
     letters, and checks directly from your CRM or customer data systems, replacing out-of-date, manual print-and-send procedures.`,
  subTitle: "LOB",
  icon: mapsIcMyLocation24px,
  developerDoc: "paper-mail-delivery"
};
const service = new Service();

class PaperMailDelivery extends React.Component<any, IPaperMailDeliveryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      showResults: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  resetAll = () => {
    this.setState({
      loader: false,
      showResults: false
    });
  };

  submit() {
    this.setState({ loader: true });
    setTimeout(() => {
      this.setState({
        loader: false,
        showResults: true
      });
    }, 3000);
  }

  rightTab = () => {
    const { showResults } = this.state;

    return (
      <>
        {!showResults && <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "25%" }}
        >
          <div className="address-sample">
              Try Paper Mail Delivery with sample address
          </div>
        </div>
        }

        {showResults && <div className="nexus-ml-5 test-result-container">
          <div className="nexus-mb-2 test-result">Test Result</div>
            Following sample letter will be mailed via USPS and user will be
            notified with the certified return receipt
          <div
            className="nexus-col-lg-10 nexus-mt-2"
            style={{
              background: "#f46906",
              padding: 0
            }}
          >
            <img
              src={PaperMailImg}
              alt="paper-mail-result"
              style={{
                opacity: 0.9,
                width: "100%"
              }}
            />
          </div>
        </div>
        }
      </>
    );
  };

  leftTab = () => <>
    <Step stepNumber={"Step 01"} header={"From Information"}></Step>
    <DisabledInput InputArray={fromInformation} />
    <Step stepNumber={"Step 02"} header={"To Information"}></Step>
    <DisabledInput InputArray={toInformation} />
    <Step stepNumber={"Step 03"} header={"Mail Information"}></Step>
    <DisabledInput InputArray={mailInformation} />
    <div className="nexus-row">
      <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
      <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
        {this.state.showResults ? <button
          type="button"
          data-testid="reset-btn"
          className="confirm-button"
          onClick={() => {
            this.resetAll();
          }}
        >
              Reset
        </button> : <button
          data-testid="getdata-btn"
          type="button"
          className="confirm-button"
          onClick={() => {
            this.submit();
          }}
        >
              Try with Sample Template
        </button>
        }
      </div>
    </div>
  </>;

  render() {
    const { loader } = this.state;

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Paper Mail Delivery"
          leftTab={this.leftTab()}
          rightTab={this.rightTab()}
        />
      </div>
    );
  }
}

export default PaperMailDelivery;
