export default {
  insured: {
    role: "insured",
    fullName: "John Miller",
    firstName: "John",
    lastName: "Miller",
    smokerClass: "Smoker",
    occupation: "Business",
    avocation: "Avocation",
    gender: "MALE",
    citizenship: "US",
    annualIncomeLastYear: "$5,869,00",
    dateOfBirth: "1971-05-01T00:00:00Z",
    familyHistory: [
      {
        relationship: "FATHER",
        illness: ["Heart disease"],
        diedBefore60: false,
      },
    ],
  },
  medicalHistory: {
    medicalCondition: "Hospitalized",
    comments: "comment",
    medications: "medication",
  },
  proposedPolicy: {
    productName: "Life",
    coverageYears: 30,
    branch: "Chicago",
  },
  existingInsurance: {
    existingInsuranceDetails: [
      {
        company: "Alpha Life Insurance",
        amount: "$5,00,000",
        issuedDate: "2008-04-01T00:00:00Z",
      },
    ],
  },
  beneficiary: [
    {
      fullName: "Marck Jacobs",
      relationship: "FATHER",
      percentageOfProceeds: "20",
    },
  ],
  uwRequirements: [
    {
      requirements: "EKG",
      receiptDate: "06/02/2021",
      reportAvailable: false,
      requestDate: "06/02/2021",
      action: "Approve",
      dueBy: "dueBy",
      questionType: "Medical",
      requestedBy: "System",
    },
  ],
  coverageInfo: [
    {
      sumAssured: "$500000",
      coverageName: "30 Year Term",
      riskClass: "Preferred",
      comments: "Test",
      automatedUWDecision: "Refer to UW",
      isPrimary: "Yes",
      approvedSumAssured: "99999",
      reInsuranceInfo: "SwissRe-10X",
      exclusions: "Sky Diving",
      uwDecision: "Approve",
      loading: "Test",
    },
  ],
  producer: {
    producerContactName: "Mark Willson",
    producerCode: "AG-0014",
    producerStatusCode: "Active",
    contactInfo: {
      primaryEmail: "Mark.Willson@testmail.com",
      faxNumber: "1817292",
      phoneNumbers: [
        {
          type: "CELL",
          number: "8476750000",
        },
      ],
      postalAddress: {
        addressLine1: "5400 N. Lakewood Avenue ",
        city: "Chicago",
        state: "IL",
        postalCode: "60640",
      },
    },
  },
  agency: {
    agencyName: "Johnson and Johnson",
    code: "Uy27287",
    contactInfo: {
      primaryEmail: "Mark.Willson@testmail.com",
      faxNumber: "1817292",
      phoneNumbers: [
        {
          type: "CELL",
          number: "8476750000",
        },
      ],
      postalAddress: {
        addressLine1: "5400 N. Lakewood Avenue ",
        city: "Chicago",
        state: "IL",
        postalCode: "60640",
      },
    },
  },
};
