import React, { Component } from "react";
import ComingSoon from "../../SharedComponent/ComingSoon/ComingSoon";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "about-technology-stack",
  listName: "about-technology-stack"
};

class TechnologyStack extends Component {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  render() {
    return <ComingSoon />;
  }
}
export default TechnologyStack;
