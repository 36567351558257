import {
  Person,
  PersonOutline,
  LocalATM,
  Note,
  BusinessAccelarator
} from "./Assets/Image";

const banner = {
  image: BusinessAccelarator,
  title: "Business Accelerator Catalogue",
  subTitle: "TBD"
};

const list = [
  {
    name: "Account Domain Service",
    description:
      "Ability to manage customer information across the insurance value chain",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: Person,
    comingSoon: false
  },
  {
    name: "Party Domain Service",
    description:
      "A party is a person or organization who owns one or more policies and/or the payer of premium charges. This Nexus service manages a party for an Insurer carrier.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: PersonOutline,
    comingSoon: false
  },
  {
    name: "Payment Domain Service",
    description:
      "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: LocalATM,
    comingSoon: false
  },
  {
    name: "Underwriting Domain Service",
    description:
      "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow",
    domain: ["Insurance"],
    subDomain: [],
    icon: Note,
    comingSoon: false
  },
  {
    name: "Policy Documents Domain Service",
    description:
      "Ability to manage additional paper documents (i.e. policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes",
    domain: ["Insurance"],
    subDomain: [],
    icon: Note,
    comingSoon: false
  },
  {
    name: "Claim Documents Domain Service",
    description:
      "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process ",
    domain: ["Insurance"],
    subDomain: [],
    icon: Note,
    comingSoon: false
  }
];

export { banner, list };
