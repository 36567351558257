import React, { Component } from "react";
import { CheckCircle } from "../../../../../../assets/images";

class Fire extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      type,
      frequency,
      pastFires,
      severity,
      distanceToFireStation,
      crownFire,
      waterDist
    } = this.props.result.fireResponse.fireShed.risk;

    return (
      <>
        <div>
          <strong style={{ marginLeft: "79px" }}>Test Result</strong>
          <div className="hazard-risk-cont">
            <div className="address-status-row">
              <div>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="address-matched-text">
                <strong>Low Fire risk category </strong>
              </div>
            </div>
            <div className="nexus-row">
              <div
                className="nexus-col-sm-4 test-result"
                style={{
                  marginLeft: "47px",
                  marginTop: "30px"
                }}
              >
                Fireshed Information
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Type</div>
                <div>: {type}</div>
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Frequency </div>
                <div>: {frequency}</div>
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Past Fires </div>
                <div>: {pastFires}</div>
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Severity </div>
                <div>: {severity}</div>
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Crown Fire </div>
                <div>: {crownFire}</div>
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Water Distance </div>
                <div>: {waterDist}</div>
              </div>
              <div
                className="nexus-col-sm-4 result-label mt-3"
                style={{
                  marginLeft: "35px",
                  display: "flex"
                }}
              >
                <div style={{ width: "185px" }}>Distance to Fire Station </div>
                <div>: {distanceToFireStation}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Fire;
