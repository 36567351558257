import React, { Component } from "react";
import "./style.scss";

class ModulePricing extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  getRowColor(index: any) {
    if (index % 2 === 0) {
      return "grey-row";
    }

    return "white-row";
  }

  render() {
    return (
      <div>
        <div className="nexus-col-xs-4">
          <div className="nexus-row">
            {this.props.moduleData.map((item: any, index: any) => <>
              <div className={`nexus-col-xs-4 ${this.getRowColor(index)}`}>
                <div className="nexus-row cpp-vertical">
                  <div className="nexus-col-xs-1">{item.col1}</div>
                  <div className="nexus-col-xs-1 nexus-center-xs">
                    {item.col2 ? <img src={item.col2} alt="Check" /> : ""}
                  </div>
                  <div className="nexus-col-xs-1 nexus-center-xs">
                    {item.col3 ? <img src={item.col3} alt="Check" /> : ""}
                  </div>
                  <div className="nexus-col-xs-1 nexus-center-xs">
                    {item.col4 ? <img src={item.col4} alt="Check" /> : ""}
                  </div>
                </div>
              </div>
            </>)}
          </div>
        </div>
      </div>
    );
  }
}
export default ModulePricing;
