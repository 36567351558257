import React from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  PLMStrategicValue,
  PLMKeyAssets,
  PLMBannerImage
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const ProducerLifeCycleManagement = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Producer onboarding process that is consistent, structured and comprehensive",
        "Structured training module with categorization to help producers be field ready and options to track their training status and completion dates",
        "On-time automated appointment renewals; assistance with terminations and retirement process",
        "Extensible data model",
        "Ability to send notifications tied to process and sub-process covering license validation, training tracking and appointment management"
      ],
      img: PLMKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Facilitates aggressive producer/agency-driven growth and speed to revenue",
        "Improves producer/agency onboarding experience",
        "Ensures regulatory compliance requirements around licensing and appointment status submissions, re-checks prior to binding new business and producer and agency hires",
        "Provides platform for future-ready distributed management system that does not require a change in strategy for delivery"
      ],
      img: PLMStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: PLMBannerImage,
    heading: "Producer Lifecycle Management",
    subHeading:
      "The Producer Lifecycle and compliance management solution streamlines producer onboarding, regulatory risk management in distribution. PLM drives agency/producer driven growth while allowing carriers to manage regulatory requirements for producers through advanced workflow, rules and automation.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default ProducerLifeCycleManagement;
