import React, { Component } from "react";
import "./NexusComponent.scss";
import {
  readyToUse,
  collaboration,
  proposition,
  newTechnologies
} from "./componentData";
import ColorCard from "./colorCard";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "about-nexus-components",
  listName: "about-nexus-components"
};

class NexusComponent extends Component {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  render() {
    return (
      <div className="nexus-container nexus-component">
        <div className="top-wrapper">
          <div className="title-text nexus-mt-4 nexus-row">
            <div className="nexus-col-sm-4 nexus-col-md-12 nexus-col-lg-12">
              Nexus Components
            </div>
          </div>
          <div className="title-desc nexus-mt-1 nexus-row">
            <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-8">
              Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras
              ullamcorper bibendum bibendum. Duis tincidunt urna non pretium
              porta. Cras quis nulla commodo, aliquam lectus sed, blandit augue.
              Cras ullamcorper bibendum bibendum. Duis tincidunt urna non
              pretium porta.
            </div>
          </div>
        </div>
        <div className="component-card nexus-flex-col">
          <div className="card-title">Ready-to-use Components</div>
          <div className="nexus-row nexus-mt-4">
            {readyToUse.map((item) => <div
              key={item.title}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 nexus-mb-2"
            >
              <div className="ele-title">{item.title}</div>
              <div className="ele-desc  nexus-mt-4">{item.description}</div>
            </div>)}
          </div>
        </div>
        <div className="nexus-mt-4">
          <div className="main-title">Collaboration is the new innovation</div>
          <div className="main-title-desc nexus-mt-2">
            Discover how we‘re accelerating digital transformation through our
            global alliances
          </div>
          <div className="nexus-row nexus-mt-4 nexus-p-4" style={{ margin: 0 }}>
            {collaboration.map((item) => <div
              key={item.title}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 nexus-mb-2"
            >
              {" "}
              <ColorCard data={item} />
            </div>)}
          </div>
        </div>
        <div className="new-tech-bg">
          <div className="main-title">
            New technologies driving growth and delivering value
          </div>
          <div className="main-title-desc nexus-mt-2">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </div>
          <div
            className="nexus-row nexus-mt-2 nexus-pt-4"
            style={{ margin: 0 }}
          >
            {newTechnologies.map((item) => <div
              key={item.title}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-3 nexus-mb-2"
            >
              {" "}
              <ColorCard data={item} />
            </div>)}
          </div>
          <div className="nexus-btn-primary nexus-btn-medium nexus-mt-4">
            Call to action
          </div>
        </div>
        <div className="nexus-mt-4 proposition-cards">
          <div className="main-title">
            Overall Value proposition headline here
          </div>
          <div className="main-title-desc nexus-mt-2">
            Discover how we‘re accelerating digital transformation through our
            global alliances
          </div>
          <div className="nexus-row nexus-mt-4 nexus-p-4" style={{ margin: 0 }}>
            {proposition.map((item) => <div
              key={item.title}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 nexus-mb-2"
            >
              {" "}
              <ColorCard data={item} />
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}
export default NexusComponent;
