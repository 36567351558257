import React, { useState } from "react";
import "./Catalog.scss";
import { NexusTabBar } from "@nexus/react";
import PropTypes from "prop-types";

const CatalogDetailsContainer = ({
  title,
  leftTab,
  rightTab
}: {
  title: string;
  leftTab: React.ReactNode;
  rightTab: React.ReactNode;
}) => {
  const [tabBar,
    setTabBar] = useState(1);
  // eslint-disable-next-line no-confusing-arrow
  const handleActiveTab = (index: number) => tabBar === index ? "nexus-link nexus-active" : "nexus-link";

  return (
    <div className="nexus-container padding-margin-0">
      <div className="address-tool-wrapper nexus-px-3 py-3">
        <div className="address-tool-title">{title}</div>
        <div className="nexus-row steps-container nexus-pb-4">
          <div className="nexus-col-lg-6  nexus-col-sm-4 nexus-col-md-4 align-items-center left-container">
            {leftTab}
          </div>
          <div className="nexus-col-lg-6  nexus-col-sm-4 nexus-col-md-4 right-tab">
            {rightTab}
          </div>
        </div>
      </div>
    </div>
  );
};

CatalogDetailsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  leftTab: PropTypes.element.isRequired,
  rightTab: PropTypes.element.isRequired
};

export default CatalogDetailsContainer;
