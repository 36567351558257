import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Header.scss";
import {
  NexusHeader,
  NexusHeaderMenu,
  NexusMenuItem,
  NexusHeaderLogo,
  NexusIcon
} from "@nexus/react";
import { paths } from "../../../../utils/PathsUtils";
import { NexusLogo } from "../../../../assets/images";

const Header = () => {
  const {
    nexusXDZone,
    catalog,
    accelerators,
    ecosystem,
    home,
    FsSolutions,
    support,
    contact,
    faq,
    resources,
    caseStudies,
    marketingMeterials,
    technicalMessagingGuide,
    nexusComponent,
    businessEnablement,
    commercialNPricing,
    clientOnboarding,
    sowNIPLanguage,
    technologyStack,
    activeCampaigns,
    about,
    buildingBlocks,
    ourTeam,
    tools,
    businessAccelarator
  } = paths;
  const [selectedTab,
    setSelectedTab] = useState(0);
  const [subMenuSelected,
    setsubMenuSelected] = useState(0);
  const [selectedSubTab,
    setSelectedSubTab] = useState("");
  const [showTab,
    setShowTab] = useState(false);
  const history = useHistory();
  const tabOption = [
    {
      title: "Home",
      value: [],
      path: `${home}`
    },
    {
      title: "Industry Solutions",
      value: [{ name: "Solutions",
        path: `${FsSolutions}` }]
    },
    {
      title: "Operating System",
      value: [
        { name: "Nexus Adopt",
          path: `${tools}` },
        { name: "Nexus Connect",
          path: `${catalog}` },
        { name: "Nexus Event",
          path: `${buildingBlocks}` },
        { name: 'Nexus Data',
          path: '#' }
      ]
    },
    {
      title: "Ecosystem",
      value: [{ name: "Ecosystems",
        path: `${ecosystem}` }]
    },
    // {
    // title: 'Business Enablement',
    // value: [
    //     { name: 'Commercials & Pricing',
    //       path: `${commercialNPricing}` }
    // ]
    // },
    {
      title: "Resources",
      value: [{ name: "Our Team",
        path: `${ourTeam}` }]
    }
  ];

  useEffect(() => {
    const path = window.location.href.split("/").pop() || "";
    setSelectedSubTab(`/${path}`);
    if (path === "/" || path === "/home") {
      setsubMenuSelected(0);
    }
    else {
      tabOption.forEach((ele, index) => {
        ele.value.forEach((pathName) => {
          if (`/${path}` === pathName.path) {
            setsubMenuSelected(index);
          }
        });
      });
    }
  });
  const calculateLeft = () => {
    const seletabElement = document.getElementById(`tabItem${selectedTab}`);
    const nexusMenuElement = document.getElementsByClassName(
      "nexus-menu-nav"
    ) as HTMLCollectionOf<HTMLElement>;
    let mainMenuLeft;
    if (nexusMenuElement.length > 0) {
      mainMenuLeft = nexusMenuElement[0].offsetLeft;
    }
    else {
      mainMenuLeft = 0;
    }
    const tabLeft = seletabElement ? seletabElement.offsetLeft : 0;
    const clientWidth = seletabElement ? seletabElement.clientWidth : 0;
    const adj = 200 / 2 - clientWidth / 2;

    // adj used to center align the menu with respect to the tab option; menu width = 200
    return mainMenuLeft + tabLeft - adj;
  };

  const onTabPress = (index: number, optlength: number) => {
    setSelectedTab(index);
    // setsubMenuSelected();
    if (optlength > 0) {
      setShowTab(true);
    }
    else {
      setSelectedSubTab("");
      // eslint-disable-next-line no-restricted-globals
      window.location.href = `${location.origin}/`;
    }
  };

  const handleMenuMouseOver = (index: number, opLen: number) => {
    setShowTab(false);
    onTabPress(index, opLen);
  }

  return (
    <div className="custom-header">
      <NexusHeader className="nexus-header-sticky px-4 nexus-container">
        <NexusHeaderLogo>
          <NexusIcon
            data-testid="btn-logo"
            style={{ cursor: "pointer" }}
            src={NexusLogo}
            onClick={() => {
              history.push("/");
              setsubMenuSelected(0);
              setSelectedTab(0);
            }}
          />
        </NexusHeaderLogo>

        <NexusHeaderMenu>
          {tabOption.map((option, index) => <NexusMenuItem
            key={index}
            data-testid={`tabItem${index}`}
            id={`tabItem${index}`}
            className={`${subMenuSelected === index && "active-menu"}`}
          >
            <a
              data-testid={`headerlink${option.title}`}
              onMouseEnter={option.title === 'Home' ? () => setShowTab(false) : () => handleMenuMouseOver(index, option.value.length)}
              onClick={option.title === 'Home' ? () => onTabPress(index, option.value.length) : () => {}}
            >
              {option.title}
            </a>
          </NexusMenuItem>)}
        </NexusHeaderMenu>
      </NexusHeader>
      <div className="nexus-container px-3">
        <div style={{ position: "relative" }}>
          <div
            data-testid="dropdownmenu"
            onMouseLeave={() => setShowTab(false)}
            style={{
              left: selectedTab && calculateLeft(),
              maxWidth:
                selectedTab === tabOption.length - 1 ? "160px" : "unset"
            }}
            className={`menu-item ${showTab && "active-menu-item"} `}
          >
            <ul className="header-ul-block">
              {tabOption[selectedTab].value.map((item, index) => <li
                key={index}
                className={`${
                  selectedSubTab === item.name && "active-sub-tab"
                }`}
              >
                <a
                  data-testid={`dropdownMenu${item.name}`}
                  className={ item.name === 'Nexus Data' ? 'pointer-evt-none platform-links' : 'platform-links cursor-point' }
                  onClick={() => {
                    setSelectedSubTab(item.name);
                    setsubMenuSelected(selectedTab);
                    history.push(item.path);
                  }}
                >
                  {item.name}
                </a>
              </li>)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
