import React, { Component } from "react";
import PricingHeading from "../SharedComp/PricingHeading";
import ModulePricing from "../SharedComp/ModulePricing";
import ContactUs from "../SharedComp/ContactUs";
import "./style.scss";
import { light, dark } from "../InsuranceProductsPricing/Assets";
import { paths } from "../../../../../utils/PathsUtils";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "insurance-products-pricing",
  listName: "insurance-products-pricing"
};
const coreplatformdeck = "https://eyus.sharepoint.com/sites/FSNexus/Shared%20Documents/Forms/FS%20Nexus%20Architecture.aspx?csf=1&web=1&e=jsGKfO&cid=087f0054%2D891d%2D4a75%2Dbca9%2De39fa05fe2cc&RootFolder=%2Fsites%2FFSNexus%2FShared%20Documents%2FGeneral&FolderCTID=0x01200053ECD5421A064C4CAF727513A77CECD6";
const { commercialNPricing } = paths;
class InsuranceProductsPricing extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      capabilitiesDelivered: [
        {
          col1: "CI/CD Pipelines",
          col2: light
        },
        {
          col1: "Environments",
          col2: light
        },
        {
          col1: "Deployable AZURE",
          col2: light
        },
        {
          col1: "APIM dashboards for monitoring",
          col2: light
        },
        {
          col1: "IAD",
          col2: light
        },
        {
          col1: "Automated Testing Frameworks",
          col2: light
        }
      ],
      modulePricing: [
        {
          col1: "Front End Application",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Prebuilt Connectors & Domain Services",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "1 Core System Interaction",
          col2: light,
          col3: light,
          col4: light
        },
        {
          col1: "Line of Business Services Library & Connectors",
          col2: "",
          col3: dark,
          col4: light
        },
        {
          col1: "Workflows",
          col2: "",
          col3: dark,
          col4: light
        },
        {
          col1: "API Documentation",
          col2: "",
          col3: dark,
          col4: light
        },
        {
          col1: "Application Support",
          col2: "",
          col3: dark,
          col4: light
        },
        {
          col1: "Everything in Solution Template",
          col2: "",
          col3: "",
          col4: dark
        },
        {
          col1: "Dedicated Application Support & Feature Enhancements",
          col2: "",
          col3: "",
          col4: dark
        }
      ]
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    window.scrollTo({ top: 0 });
  }

  getRowColor(index: any) {
    if (index % 2 === 0) {
      return "grey-row";
    }

    return "white-row";
  }

  render() {
    return (
      <>
        <div className="breadcrums">
          <div className="nexus-container">
            <div className="nexus-row">
              <div className="nexus-col-sm-4">
                <a
                  data-testid="back-btn"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "#2E2E38"
                  }}
                  onClick={() => {
                    this.props.history.push(commercialNPricing);
                  }}
                >
                  Core Platform
                </a>
                <strong className="m-2">{" > "}</strong>
                <strong style={{ fontWeight: "bold" }}>Platform Pricing</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="nexus-container" style={{ color: "black" }}>
          <div className="nexus-row">
            <PricingHeading
              title="Insurance Sector Products"
              description="Leverage a versatile, cloud ready framework, with ready to use building blocks and real time intelligence. Launch customer centric services globally, reliably, and faster than ever before."
            />
            <div className="nexus-col-xs-4 nexus-mt-2 ipp-table-heading">
              Insurance Sectors & Pricing
            </div>
            <div className="nexus-col-xs-4 nexus-mt-2">
              <div className="nexus-row engagement-cntr nexus-center-xs">
                <div className="nexus-col-xs-4 engagement-head">
                  Engagement Starter Kit
                </div>
                <div className="nexus-col-xs-4 nexus-mt-1 engagement-dsc">
                  Technology Standardization
                </div>
                <div className="nexus-col-xs-4 nexus-mt-2 engagement-sub-dsc">
                  Start building day 1 with proven & working standards across
                  development, testing, devOps , information Security, hosting
                </div>
              </div>
            </div>

            <div className="nexus-col-xs-4 ipp-engagement-table">
              <div className="nexus-row nexus-mt-2">
                <div className="nexus-col-xs-3 nexus-pb-2 ipp-capa-deliv">
                  Capabilities delivered
                </div>
                <div className="nexus-col-xs-1 nexus-pb-2 ipp-center">
                  <div className="includes">Includes:</div>
                </div>
                {this.state.capabilitiesDelivered.map(
                  (item: any, index: any) => <>
                    <div
                      className={`nexus-col-xs-4 ${this.getRowColor(index)}`}
                    >
                      <div className="nexus-row ipp-vertical">
                        <div className="nexus-col-xs-3">{item.col1}</div>
                        <div className="nexus-col-xs-1 ipp-center">
                          <img src={item.col2} alt="Check" />
                        </div>
                      </div>
                    </div>
                  </>

                )}
              </div>
            </div>

            <div className="nexus-col-xs-4 table-border">
              <div className="nexus-row">
                <div className="nexus-col-xs-1 grey-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 ipp-heading">
                      Add on Products
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-2 nexus-mt-5">
                      GTM & Sales Activation
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-1 white-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 ipp-heading">
                      Solution Blueprint
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-3 nexus-mt-2">
                      GTM & Sales Activation
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-2 nexus-mt-2">
                      Increase sales conversion by bringing real capabilities
                      into conversations and use capabilities in sold
                      engagements
                    </div>
                    <div className="nexus-col-xs-4 ipp-price nexus-mt-2">
                      $25K
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-1 grey-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 ipp-heading">
                      Solution Template
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-3 nexus-mt-2">
                      sales activation
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-2 nexus-mt-2">
                      Take advantage of line of business specific libraries and
                      applications to speed up implementation timeline
                    </div>
                    <div className="nexus-col-xs-4 ipp-price nexus-mt-2">
                      $65K
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-1 white-box">
                  <div className="nexus-row nexus-center-xs">
                    <div className="nexus-col-xs-4 ipp-heading">
                      Industry Solution
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-3 nexus-mt-2">
                      sales activation
                    </div>
                    <div className="nexus-col-xs-4 ipp-heading-2 nexus-mt-2">
                      Replicate comprehensive solutions with minimum
                      customization, and increase margins
                    </div>
                    <div className="nexus-col-xs-4 ipp-price nexus-mt-2">
                      $100K
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-4 table-head">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-1"></div>
                    <div className="nexus-col-xs-1 ipp-center">
                      <div className="includes">Includes:</div>
                    </div>
                    <div className="nexus-col-xs-1 ipp-center">
                      <div className="includes">Includes:</div>
                    </div>
                    <div className="nexus-col-xs-1 ipp-center">
                      <div className="includes">Includes:</div>
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-4 nexus-my-2">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-1 category-head">
                      Capabilities delivered
                    </div>
                    <div className="nexus-col-xs-1 category-head">
                      Solution blueprint
                    </div>
                    <div className="nexus-col-xs-1 category-head">
                      Solution template
                    </div>
                    <div className="nexus-col-xs-1 category-head">
                      industry solution
                    </div>
                  </div>
                </div>
                <ModulePricing moduleData={this.state.modulePricing} />
              </div>
            </div>
            <ContactUs />
          </div>
        </div>
      </>
    );
  }
}
export default InsuranceProductsPricing;
