import React from "react";
import "../CatalogStyle.scss";
import { NexusProgressBar } from "@nexus/react";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { InputArray, apiRequestParams } from "./inputList";
import StepOne from "../Waterline/StepOne";

const ADOBE_DATA = {
  pageName: "platform-capabilities/replacementcost",
  listName: "platform-capabilities/replacementcost"
};

interface InputList {
  labelTestid: string;
  labelTittle: string;
  inputTestid: string;
  value: string;
  isDisabled: boolean;
  placeholder: string;
}

interface FormatedRes {
  calculatedValue: string;
  desc: string;
}

interface State {
  loader: boolean;
  result: boolean;
  inputArray: InputList[];
  formatedResponse: FormatedRes[];
  totalCalucaltedValue: number;
}

interface Props {}

const service = new Service();
class ReplacementCost extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loader: false,
      result: false,
      inputArray: InputArray,
      formatedResponse: [],
      totalCalucaltedValue: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit() {
    const { formatedResponse } = this.state;
    if (formatedResponse.length === 0) {
      this.apiCall();
    }
    else {
      this.setState({ formatedResponse: [] });
    }
  }

  apiCall() {
    this.setState({ loader: true });
    service
      .getReplacementCostData(apiRequestParams)
      .then((_res: any) => _res.json())
      .then((data: any) => {
        this.simplyfyResData(
          data.data.body.calculateRecalculatableValuationResponse.return.reports
            .residentialReport.costBreakdown
        );
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error("Error fetching data:", error);
      });
  }

  simplyfyResData(res: any) {
    const formatedResponse = res.answer.map((item: any) => ({
      desc: item.desc,
      calculatedValue: item.calculatedValue
    }));
    this.setState({ formatedResponse });
    this.setState({ loader: false });
    this.totalCalculatedValue(formatedResponse);
  }

  totalCalculatedValue(formatedResponse: FormatedRes[]) {
    const totalCalucaltedValue = formatedResponse.reduce(
      (total, item) => total + parseFloat(item.calculatedValue),
      0
    );
    this.setState({ totalCalucaltedValue });
  }

  render() {
    const { formatedResponse } = this.state;
    const leftTab = () => <>
      <StepOne InputArray={InputArray} />
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            className="confirm-button"
            onClick={() => {
              this.submit();
            }}
          >
            {formatedResponse.length > 0 ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {formatedResponse.length === 0 && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "70%" }}
      >
        <div className="address-sample">
              Try Replacement cost estimation with sample address
        </div>
      </div>
      }

      {formatedResponse.length > 0 && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="test-result-box nexus-p-4" style={{ width: "90%" }}>
          <div className="nexus-mb-2 test-result">
                Replacement cost Breakdown
          </div>
          {formatedResponse.map((item: any, index: number) => <div className="nexus-row" key={item.desc}>
            <div className="result-label nexus-col-lg-7 nexus-col-md-6 nexus-col-xs-2">
              {item.desc}
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-2 nexus-col-xs-2">
                    $ {item.calculatedValue}
            </div>
          </div>)}
          <div className="nexus-row nexus-mt-2">
            <div className="result-label nexus-col-lg-7 nexus-col-md-6 nexus-col-xs-2">
              <b>Total Calculative Value</b>
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-2 nexus-col-xs-2">
              <b>$ {this.state.totalCalucaltedValue}</b>
            </div>
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Replacement Cost",
      description:
        "Provide carriers with the reliable replacement cost estimates for properties insured, reflecting real-world prices for labor and materials, based on extensive research, direct data feeds, claims analyses, and communication with 92,000 claims and building contractors.",
      subTitle: "Verisk",
      icon: mapsIcMyLocation24px,
      developerDoc: "replacement-cost"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper" data-testid="loader">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Replacement Cost"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default ReplacementCost;
