import React from "react";
import "../CatalogStyle.scss";
import {
  NexusProgressBar,
  NexusFormField,
  NexusLabel,
  NexusInput
} from "@nexus/react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/credit-report",
  listName: "platform-capabilities/credit-report"
};

interface State {
  buttonValue?: string;
  result: any;
  firstName: string;
  lastName: string;
  dob: string;
  ssnNumber: string;
  licenceState: string;
  driverLicence: string;
  employerName: string;
  loader?: boolean;
}

const service = new Service();

class CreditReport extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      dob: "",
      ssnNumber: "",
      employerName: "",
      driverLicence: "",
      licenceState: "",
      result: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    const {
      firstName,
      lastName,
      dob,
      ssnNumber,
      employerName,
      driverLicence,
      licenceState
    } = this.state;

    if (this.state.buttonValue === "Reset") {
      this.setState({
        buttonValue: "Try with Sample Postal Code",
        result: null,
        firstName: "",
        lastName: "",
        dob: "",
        ssnNumber: "",
        employerName: "",
        driverLicence: "",
        licenceState: ""
      });
    }
    else {
      this.setState({
        buttonValue: "Reset",
        firstName: firstName || "Lexis",
        lastName: lastName || "Green",
        dob: dob || "1981-11-08",
        ssnNumber: ssnNumber || "084564977",
        employerName: employerName || "EY GDS LLP",
        driverLicence: driverLicence || "W426545307610",
        licenceState: licenceState || "FL",
        loader: true
      });
      setTimeout(() => {
        this.setState({
          loader: false,
          result: "Not Null"
        });
      }, 1000);
    }
  }

  buttonSubmit() {
    return (
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            data-testid="validateAddress"
            className="confirm-button"
            onClick={() => this.apiCall()}
          >
            {this.state.result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      firstName,
      lastName,
      dob,
      ssnNumber,
      employerName,
      driverLicence,
      licenceState
    } = this.state;

    const leftTab = () => <>
      <div className="nexus-row">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head">Input Applicant Details</div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                First Name
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="firstName"
              attrId="firstName"
              placeholder="e.g. Lexis"
              value={firstName}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ firstName: event.target.value })} */
              disabled
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Last Name
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="lasttName"
              attrId="lastName"
              placeholder="e.g. Green"
              value={lastName}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ lastName: event.target.value })} */
              disabled
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                D.O.B
            </NexusLabel>
            <NexusInput
              type="date"
              data-testid="dateOfBirth"
              value={dob}
              // onInput={(event: React.ChangeEvent<HTMLNexusInputElement>) => {
              // this.setState({ dob: event.target.value });
              // } }
              disabled
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                SSN Number
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="ssnNumber"
              attrId="ssnNumber"
              placeholder="e.g. 084564977"
              value={ssnNumber}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ ssnNumber: event.target.value })} */
              disabled
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Employee Name
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="empname"
              attrId="lastName"
              placeholder="e.g. ABC Ltd."
              value={employerName}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ employerName: event.target.value })} */
              disabled
            />
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-4">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2">
            Step 02
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head">Input Applicant Licence Details</div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Driver Licence
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="dlNumber"
              attrId="dlNumber"
              placeholder="e.g. W426545307610"
              value={driverLicence}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ driverLicence: event.target.value })} */
              disabled
            />
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Licence State
            </NexusLabel>
            <NexusInput
              className="inputFieldText"
              type="text"
              data-testid="licenceNumber"
              attrId="licenceNumber"
              placeholder="e.g. FL"
              value={licenceState}
              /* onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => this.setState({ licenceState: event.target.value })} */
              disabled
            />
          </div>
        </div>
      </div>
      {this.buttonSubmit()}
    </>;
    const rightTab = () => <>
      {!this.state.result && <div className="nexus-row nexus-ml-5" style={{ height: "40%" }}>
        <div className="nexus-col-sm-4 nexus-col-lg-8 nexus-col-lg-10 address-sample">
              Try with Sample
          <br />
              data
        </div>
      </div>
      }

      {this.state.result && <>
        <div className="nexus-row nexus-ml-5">
          <div className="address-head nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result">
                Test Result
          </div>
        </div>
        <div className="nexus-row nexus-ml-5">
          <div
            className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-10 nexus-col-flex nexus-mt-4 nexus-py-3"
            style={{ backgroundColor: "#FFD7BA" }}
          >
            <div className="nexus-row nexus-pl-4">
              <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result nexus-mb-1">
                    Address Information
              </div>
              <div className="nexus-col-sm-3 nexus-col-md-5 nexus-col-lg-6 result-label nexus-pl-4">
                <div className="nexus-pb-1">Reported Count</div>
                <div className="nexus-pb-1">Address Line</div>
                <div className="nexus-pb-1">City</div>
                <div className="nexus-pb-1">State</div>
                <div className="nexus-pb-1">Postal Code</div>
                <div className="nexus-pb-1">Reported Date</div>
                <div className="nexus-pb-1">Updated Date</div>
              </div>
              <div className="nexus-col-sm-1 nexus-col-md-3 nexus-col-lg-6 result-label">
                <div className="nexus-pb-1">: 31</div>
                <div className="nexus-pb-1">: 236 N Walnut St</div>
                <div className="nexus-pb-1">: Bensenville</div>
                <div className="nexus-pb-1">: IL</div>
                <div className="nexus-pb-1">: 61958</div>
                <div className="nexus-pb-1">: 16-10-2008</div>
                <div className="nexus-pb-1">: 07-05-2009</div>
              </div>
            </div>
            <div className="nexus-row nexus-pl-4 nexus-mt-2">
              <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result nexus-mb-1">
                    Profile Summary:
              </div>
              <div className="nexus-col-sm-3 nexus-col-md-5 nexus-col-lg-6 result-label nexus-pl-4">
                <div className="nexus-pb-1">Real Estate Balance</div>
                <div className="nexus-pb-1">Real Estate Payment</div>
                <div className="nexus-pb-1">Revolving Balance</div>
                <div className="nexus-pb-1">Monthly Payment</div>
              </div>
              <div className="nexus-col-sm-1 nexus-col-md-3 nexus-col-lg-6 result-label">
                <div className="nexus-pb-1">: $ 664280</div>
                <div className="nexus-pb-1">: $ 5834</div>
                <div className="nexus-pb-1">: $ 322985</div>
                <div className="nexus-pb-1">: $ 672</div>
              </div>
            </div>
            <div className="nexus-row nexus-pl-4 nexus-mt-2">
              <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result nexus-mb-1">
                    Risk Evaluation:
              </div>
              <div className="nexus-col-sm-3 nexus-col-md-5 nexus-col-lg-6 result-label nexus-pl-4">
                <div className="nexus-pb-1">Evaluation</div>
                <div className="nexus-pb-1">Credit Score</div>
                <div className="nexus-pb-1">Model Indicator</div>
              </div>
              <div className="nexus-col-sm-1 nexus-col-md-3 nexus-col-lg-6 result-label">
                <div className="nexus-pb-1">: P</div>
                <div className="nexus-pb-1">: 783</div>
                <div className="nexus-pb-1">: RC</div>
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </>;
    const card = {
      title: "Credit Report",
      description:
        "The Nexus Credit Report connector leverages integrations with the leading credit profile providers to enable access to consumer credit data; this is a key integration for nearly every insurance customer journey.",
      subTitle: "Experian, Lexis Nexis",
      icon: actionIcBookmarkBorder24px,
      developerDoc: "credit-report"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design notification">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Credit Report Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default CreditReport;
