import React from "react";
/* eslint-disable space-infix-ops */
const Row = (props: any) => <>
  <div className="test-result nexus-my-2">{props.data.title}</div>
  {props.data.rows.map((row: any, index: number) => <div className="nexus-row colored-row nexus-mb-2 col-border" key={index}>
    <div className="icon-bg nexus-col-lg-2 nexus-px-2">
      <img src={row.image.src} alt="img" className="row-icon" />
      <div className="icon-text">{row.image.label}</div>
    </div>
    {row.coloums.map((col: any, key: number) => <>
      <div
        key={key}
        className={`nexus-col-lg-3 nexus-col-md-8 nexus-col-xs-4 text-center nexus-py-1 center-content`}
      >
        <div className="row-label">
          {col.value}
          {col.unit && <label className="row-unit">{col.unit}</label>}
        </div>
        <div>{col.label}</div>
      </div>
      {key !== 2 && "col-border" && <div className="center-content nexus-py-1">
        <div className="col-border" />
      </div>
      }
    </>)}
  </div>)}
</>;
export default Row;
