import { NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import Service from "../../../service/Service";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/fraud-detection",
  listName: "platform-capabilities/fraud-detection",
};

const FraudDetection = () => {
  const [screeningId, setScreeningId] = useState("");
  const [screeningResult, setScreeningResult] = useState<any>(null);
  const [loader, setLoader] = React.useState(false);

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);
  const reset = () => {
    setScreeningResult(null);
    setScreeningId("");
  };
  const submitScreenId = () => {
    let payload;
    if (screeningResult) {
      reset();
    } else {
      setScreeningId("xsiiqfg6-hyuu-4rll-2e2e-ocnixs6yb043");
      payload = {
        screeningId: "xsiiqfg6-hyuu-4rll-2e2e-ocnixs6yb043",
      };
      setLoader(true);
      service
        .fraudDetection(payload)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setScreeningResult(data.data);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const card = {
    title: "Fraud Detection",
    description:
      "Provide carriers an accurate estimation of the fraud and risks with the aid of expert rules, profiles, predictive models, and link analysis with the information in external data sources, it can detect suspicious claims, reveals networks, and leverages predictive models and text mining",
    subTitle: "FRISS",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "fraud-detection",
  };

  const leftTab = () => (
    <>
      <div className="nexus-pr-4">
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">Enter screening Id</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Screening Id</div>
              <div className="input-container">
                <input
                  type="text"
                  value={screeningId}
                  data-testid="screen-id"
                  onChange={(event) => setScreeningId(event.target.value)}
                  placeholder={"e.g. xsiiqfg6-hyuu-4rll-2e2e-ocnixs6yb043"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className="confirm-button"
                data-testid="submit-btn"
                onClick={() => {
                  submitScreenId();
                }}
              >
                {screeningResult ? "Reset" : "Try with Sample Data"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!screeningResult && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Fraud detection with sample data
          </div>
        </div>
      )}
      {screeningResult && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-my-2 test-result">Test Result</div>
          <div>
            <div
              className="test-result-box nexus-mb-2 nexus-pl-4 nexus-pb-2"
              style={{ width: "90%" }}
            >
              <div className="nexus-pb-2 nexus-pt-3 test-result">
                Screening Results:
              </div>
              <div className="nexus-row nexus-mb-1">
                <div className="result-label nexus-col-lg-4 nexus-col-md-6 nexus-col-xs-3">
                  Fraudulent Score
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4">
                  {screeningResult.score}
                </div>
              </div>
              <div className="nexus-row nexus-mb-1">
                <div className="result-label nexus-col-lg-4 nexus-col-md-6 nexus-col-xs-3">
                  Category
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4 upper-camel-case">
                  {screeningResult.category.toLowerCase()}
                </div>
              </div>
              <div className="nexus-row nexus-mb-1">
                <div className="result-label nexus-col-lg-4 nexus-col-md-6 nexus-col-xs-3">
                  Indicator Name
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4 upper-camel-case">
                  {screeningResult.indicators[0].name.toLowerCase()}
                </div>
              </div>
              <div className="nexus-row nexus-mb-1">
                <div className="result-label nexus-col-lg-4 nexus-col-md-6 nexus-col-xs-3">
                  Argumentation
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4 upper-camel-case">
                  {screeningResult.indicators[0].argumentation.toLowerCase()}
                </div>
              </div>
              <div className="nexus-row nexus-mb-1">
                <div className="result-label nexus-col-lg-4 nexus-col-md-6 nexus-col-xs-3">
                  Value
                </div>
                <div className="result-label">:</div>
                <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4">
                  {screeningResult.indicators[0].value}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Fraud Detection"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default FraudDetection;
