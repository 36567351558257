import React from "react";
import "../Catalog.scss";
import { NexusProgressBar, NexusRadio } from "@nexus/react";
import Service from "../../../service/Service";
import { CheckCircle, Warning, Phone } from "../../../../../assets/images";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/email-phone-number-validator",
  listName: "platform-capabilities/email-phone-number-validator"
};

interface State {
  step: number;
  email?: string;
  phoneNumber?: string;
  option: string;
  result: any;
  loader?: boolean;
}

const service = new Service();

class EmailandPhoneVerification extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      email: "",
      phoneNumber: "",
      option: "email",
      result: null
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit = () => {
    if (this.state.result) {
      this.setState({
        result: null,
        step: 0
      });
    }
    else if (this.state.step === 1) {
      const params = this.state.option === "email" ? {
        email: this.state.email
      } : {
        phoneNumber: this.state.phoneNumber
      };

      this.setState({
        loader: true
      });

      service
        .validatePhoneOrEmail(params, this.state.option)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            // this.setState({ result: data.data, error: false });
            this.setState({ result: data.data,
              loader: false });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error", error);
          this.setState({
            loader: false
          });
        });
    }
    else if (this.state.option === "email") {
      this.setState({ step: 1,
        email: "nexusforinsurance@ey.com" });
    }
    else {
      this.setState({ step: 1,
        phoneNumber: "5594126044" });
    }
  };

  capitalizeFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  changeOption = (opt: string) => {
    this.setState({
      option: opt,
      step: 0,
      result: null,
      phoneNumber: "",
      email: ""
    });
  };

  render() {
    const leftTab = () => <>
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4  nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-4  nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Pick One</div>
          <div className="radio-container">
            <NexusRadio
              name="option"
              id="email"
              data-testid="opt-email"
              value="email"
              disabled={this.state.result}
              checked={this.state.option === "email"}
              onInput={() => {
                this.changeOption("email");
              }}
            >
                Email
            </NexusRadio>
            <NexusRadio
              className="ml-5"
              name="option"
              data-testid="opt-phone"
              id="phone"
              value="phone"
              disabled={this.state.result}
              checked={this.state.option === "phone"}
              onInput={() => {
                this.changeOption("phone");
              }}
            >
                Phone Number
            </NexusRadio>
          </div>
        </div>
      </div>
      <div className="step2  nexus-pr-4">
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-4  nexus-col-md-2 nexus-col-lg-2 step">
              Step 02
          </div>
          <div className="form-head nexus-col-sm-4  nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">
                Enter Verification details
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Email Address:</div>
              <div className="input-container">
                <input
                  type="text"
                  data-testid="email"
                  value={this.state.email}
                  className="line-input-box"
                  placeholder={
                    this.state.option === "email" ? "e.g. unkonwn@xyz.com" : "Not Applicable"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Phone Number:</div>
              <div className="input-container">
                <input
                  type="text"
                  data-testid="phone"
                  value={this.state.phoneNumber}
                  placeholder={
                    this.state.option === "phone" ? "e.g. 3203838092" : "Not Applicable"
                  }
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div>
              <button
                data-testid="submit"
                type="button"
                className="confirm-button"
                onClick={() => {
                  this.submit();
                }}
              >
                {this.state.result ? "Reset" : this.state.step === 0 ? "Try with Sample Data" : `Validate ${this.capitalizeFirstLetter(
                  this.state.option
                )}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample Email Address/Phone Number
        </div>
      </div>
      }
      {this.state.result && this.state.option === "email" && <div className="nexus-ml-5 test-result-container">
        <div className="test-result nexus-mb-2">Test Result</div>
        {this.state.result.suggEmail === "" && this.state.result.suggComment === "" && <div className="nexus-px-2 nexus-py-4 test-result-box nexus-row">
          <img
            className="verified-icons"
            src={CheckCircle}
            alt="CheckCircle"
          />
          <div className="address-matched-text nexus-col">
                    Email address
            <br />
            {`${this.state.result.email} is ${this.state.result.comment}`}
          </div>
        </div>
        }
      </div>
      }

      {this.state.result && this.state.option === "phone" && <div className="nexus-ml-4 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        {this.state.result.locatable === "False" && <div className="test-result-box nexus-p-4">
          <div className="nexus-row">
            <img
              className="verified-icons nexus-mr-1"
              src={Warning}
              alt="Warning"
            />
            <div className="address-matched-text nexus-col">
                    Privacy Consent required from Client
              <br />
                    to locate
              <label className="address-matched-text-highlight ml-2">
                {this.state.result.phoneNumber}
              </label>
            </div>
          </div>
          <div className="network-head mb-3 mt-4">
                  Network Carrier Details
          </div>
          <div className="network-details-container">
            <div className="mb-3 d-flex flex-row">
              <div>Network Provider:</div>
              <div className="ml-2 interstate-light">
                {this.state.result.carrier}
              </div>
            </div>
            <div className="mb-3 d-flex flex-row">
              <div>Network Type:</div>
              <div className="ml-2 capitalize interstate-light">
                {this.state.result.callType.toLowerCase()}
              </div>
            </div>
            <div className="mb-3 d-flex flex-row">
              <div>National Number:</div>
              <div className="ml-2 capitalize interstate-light">
                {this.state.result.nationalNumber}
              </div>
            </div>
            <div className="mb-3 d-flex flex-row">
              <div>Country:</div>
              <div className="ml-2 capitalize interstate-light">
                {this.state.result.countryName.toLowerCase()}
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      }
    </>;
    const card = {
      title: "Email and Phone Number Validator",
      description:
        "Nexus Email and Phone Number Validator Connector provides carriers with the ability to correct and validate any customer provided Email or Phone Number details both in the US and Internationally.",
      subTitle: "Experian, Precisely",
      svg: Phone,
      developerDoc: "email-phone-validator"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Email and Phone Number Verification Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default EmailandPhoneVerification;
