import React, { useEffect, useState } from "react";
import "../CatalogStyle.scss";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import { NexusProgressBar } from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { CheckCircle } from "../../../../../assets/images";

const ADOBE_DATA = {
  pageName: "platform-capabilities/verify",
  listName: "platform-capabilities/verify",
};

const Verify = () => {
  const [loader, setLoader] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState<any>("");
  const card = {
    title: "Verify",
    description:
      "Enables carrier to verify the users have any claimed device in their possession by requesting a verification code via SMS, Email, Whatsapp or via Phone call.",
    subTitle: "Twilio",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "verify",
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
  }, []);

  const reset = () => {
    setInputFieldValue("");
  };

  const buttonClickHandler = () => {
    if (inputFieldValue === "") {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        setInputFieldValue("Nexus Verify");
      }, 1000);
    } else {
      reset();
    }
  };

  const leftTab = () => (
    <>
      <div className="nexus-row">
        <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
          Step 01
        </div>
        <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head">Enter Friendly Name</div>
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6">
          <div className="nexus-mt-2 nexus-mb-3">
            <div className="italicText mb-2">User Id</div>
            <div className="input-container">
              <input
                type="text"
                value={inputFieldValue}
                placeholder="e.g. Nexus Verify"
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="confirm-button"
              onClick={() => buttonClickHandler()}
              data-testid="validateVerify"
            >
              {inputFieldValue === "" ? "Try with Sample Data" : "Reset"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {inputFieldValue === "" && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Verification service with sample data
          </div>
        </div>
      )}
      {inputFieldValue !== "" && (
        <>
          <div className="address-head nexus-ml-5 nexus-mb-3">Test Result</div>
          <div className="accident-container nexus-p-4 nexus-col-lg-10 nexus-ml-5">
            <div className="nexus-row nexus-pb-2">
              <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
                <div>
                  6 Digit verification code has been sent to the registered
                  Mobile via SMS.
                </div>
              </div>
            </div>
            <div className="nexus-row">
              <div className="nexus-col-xs-4 validated-address-value">
                Next Step: Code Verification
              </div>
              <div className="nexus-col-xs-4 validated-address-text">
                User will input the code in their mobile application and the
                same will be verified
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Verify"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default Verify;
