import { NexusRadio, NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import Service from "../../../service/Service";

import { CheckCircle, Warning } from "../../../../../assets/images";
import countries from "../../../../../utils/country_state.json";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/cross-sell-offering",
  listName: "platform-capabilities/cross-sell-offering",
};

const CrossSellOffering = () => {
  // eslint-disable-next-line global-require

  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [country, setCountry] = useState("");
  const [crossSellOffers, setCrossSellOffers] = useState<any>(null);
  const [loader, setLoader] = React.useState(false);
  const [stateList, setStateList] = useState<any>([]);

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);
  const reset = () => {
    setCrossSellOffers(null);
    setaddressLine1("");
    setaddressLine2("");
    setCity("");
    setState("");
    setCountry("");
    setZip("");
  };
  const getStateList = (id: string) => {
    const states = countries.filter(
      (countryItem) => countryItem.countryCode === id
    )[0].stateProvinces;
    const stateUI = states?.map((item) => (
      <option key={item.name} value={item.name}>
        {item.name}
      </option>
    ));
    setStateList(stateUI);
  };
  const validateAddress = () => {
    let payload;
    if (crossSellOffers) {
      reset();
    } else {
      setCountry("US");
      getStateList("US");
      setaddressLine1("276 Castro Street");
      setaddressLine2("Flat #2");
      setState("California");
      setCity("San Francisco");
      setZip("94114");

      payload = {
        accountNumber: "9f343a9f-97a3-ec11-983f-00224831473e",
        address: {
          addressLine1: "276 Castro Street",
          addressLine2: "Flat #2",
          city: "California",
          country: "US",
          postcode: "94114",
          state: "San Francisco",
        },
        addressChange: true,
        eventId: "435345",
        teenDriver: true,
      };

      setLoader(true);
      service
        .crossSellOffering(payload)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setCrossSellOffers(data.data.crossSellOffers);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const countriesList = countries.map((item: any) => (
    <option key={item.name} value={item.countryCode}>
      {item.name}
    </option>
  ));

  const card = {
    title: "Cross Sell Offering",
    description:
      "Provides carriers to retrive a list of cross sell offers from the service provider's system. Cross-selling occurs when customers get offerings that complement or supplement the purchases they have already made.",
    subTitle: "Pega",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "cross-sell-offering",
  };

  const leftTab = () => (
    <>
      <div className="nexus-pr-4">
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">Enter Address</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Address Line 1:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={addressLine1}
                  data-testid="address-line1"
                  onChange={(event) => setaddressLine1(event.target.value)}
                  placeholder={"e.g. 276 Castro Street"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Address Line 2:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={addressLine2}
                  data-testid="address-line2"
                  onChange={(event) => setaddressLine2(event.target.value)}
                  placeholder={"e.g. Flat #2"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">City:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={city}
                  data-testid="city"
                  onChange={(event) => setCity(event.target.value)}
                  placeholder={"e.g. San Francisco"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">State</div>
              <div className="input-container">
                {!state && (
                  <div
                    className="select-placeholder"
                    key="placeholder"
                    data-placeholder="placeholder"
                  >
                    {"e.g. CA"}
                  </div>
                )}
                <select
                  value={state}
                  className="line-input-box"
                  data-testid="state"
                  onChange={(event) => setState(event.target.value)}
                  disabled
                >
                  {stateList}
                </select>
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">{"Zip Code:"}</div>
              <div className="input-container">
                <input
                  type="text"
                  data-testid="zip"
                  value={zip}
                  onChange={(event) => setZip(event.target.value)}
                  placeholder={"e.g. 94114"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Country:</div>
              <div className="input-container">
                {!country && (
                  <div
                    className="select-placeholder"
                    key="placeholder"
                    data-placeholder="placeholder"
                  >
                    e.g US
                  </div>
                )}
                <select
                  value={country}
                  data-testid="country"
                  className="line-input-box"
                  onChange={(event) => {
                    setCountry(event.target.value);
                    getStateList(event.target.value);
                  }}
                  disabled
                >
                  {countriesList}
                </select>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="confirm-button"
                data-testid="validateAddress"
                onClick={() => {
                  validateAddress();
                }}
              >
                {crossSellOffers ? "Reset" : "Try with Sample Data"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!crossSellOffers && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Retrive various offering with sample address
          </div>
        </div>
      )}
      {crossSellOffers && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-my-2 test-result">Test Result</div>
          <div>
            {crossSellOffers.map((item: any) => (
              <div
                className="test-result-box nexus-mb-2 nexus-pl-3 nexus-py-2"
                key={item.insurancePlanId}
                style={{ width: "90%" }}
              >
                <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
                  <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    Insurance Plan
                  </div>
                  <div className="result-label">:</div>
                  <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4">
                    {item.policyDescription}
                  </div>
                </div>
                <div className="nexus-row" style={{ width: "100%" }}>
                  <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    Benefits
                  </div>
                  <div className="result-label">:</div>
                  <div className="result-value nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4">
                    {item.benefits}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Cross Sell Offering"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default CrossSellOffering;
