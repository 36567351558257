/* eslint-disable max-lines */
/* eslint-disable no-negated-condition */
import React from "react";
import "../Catalog.scss";
import {
  NexusFormField,
  NexusLabel,
  NexusInput,
  NexusProgressBar,
  NexusSelect
} from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { Rating, Dollar } from "../../../../../assets/images";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/rating",
  listName: "platform-capabilities/rating"
};

interface State {
  submissionId: string;
  effectiveDate: string;
  typeOfDisability: string;
  expectedReturnToWork: string;
  age: string;
  incomeAmount: string;
  incomeReducedOrLost: string;
  typeOfPlan: string;
  classificationCodes: string;
  waitingPeriod: string;
  payoutPeriod: string;
  insuredPercentage: string;
  presumptiveDisabilityBenefit: string;
  result: any;
  loader?: boolean;
}

const service = new Service();

class RatingConnector extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      submissionId: "213232",
      effectiveDate: "2022-03-08",
      typeOfDisability: "Total",
      expectedReturnToWork: "90 to 180 days",
      age: "20 - 25",
      incomeAmount: "$20k-30K",
      incomeReducedOrLost: "Reduced Income",
      typeOfPlan: "Bronze",
      classificationCodes: "Drill operators, truck drivers",
      waitingPeriod: "14 Days",
      payoutPeriod: "6 Month",
      insuredPercentage: "65",
      presumptiveDisabilityBenefit: "Sight",
      result: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit() {
    if (this.state.result) {
      this.setState({
        submissionId: "213232",
        effectiveDate: "2022-03-08",
        typeOfDisability: "Total",
        expectedReturnToWork: "90 to 180 days",
        age: "20 - 25",
        incomeAmount: "$20k-30K",
        incomeReducedOrLost: "Reduced Income",
        typeOfPlan: "Bronze",
        classificationCodes: "Drill operators, truck drivers",
        waitingPeriod: "14 Days",
        payoutPeriod: "6 Month",
        insuredPercentage: "65",
        presumptiveDisabilityBenefit: "Sight",
        result: null
      });
    }
    else {
      this.setState(
        (prevState) => ({
          submissionId: prevState.submissionId || "213232",
          effectiveDate: prevState.effectiveDate || "2022-03-08",
          typeOfDisability: prevState.typeOfDisability || "Total",
          expectedReturnToWork:
            prevState.expectedReturnToWork || "90 to 180 days",
          age: prevState.age || "20 - 25",
          incomeAmount: prevState.incomeAmount || "$20k-30K",
          incomeReducedOrLost:
            prevState.incomeReducedOrLost || "Reduced Income",
          typeOfPlan: prevState.typeOfPlan || "Bronze",
          classificationCodes:
            prevState.classificationCodes || "Drill operators, truck drivers",
          waitingPeriod: prevState.waitingPeriod || "14 Days",
          payoutPeriod: prevState.payoutPeriod || "6 Month",
          insuredPercentage: prevState.insuredPercentage || "65",
          presumptiveDisabilityBenefit:
            prevState.presumptiveDisabilityBenefit || "Sight"
        }),
        () => {
          const {
            submissionId,
            effectiveDate,
            typeOfDisability,
            expectedReturnToWork,
            age,
            incomeAmount,
            incomeReducedOrLost,
            typeOfPlan,
            classificationCodes,
            waitingPeriod,
            payoutPeriod,
            insuredPercentage,
            presumptiveDisabilityBenefit
          } = this.state;
          const params = {
            age,
            classificationCodes,
            effectiveDate: `${effectiveDate}T00:00:00Z`,
            expectedReturnToWork,
            incomeAmount,
            incomeReducedOrLost,
            insuredPercentage,
            payoutPeriod,
            presumptiveDisabilityBenefit,
            rehabServices: "NO",
            submissionId,
            typeOfDisability,
            typeOfPlan,
            waitingPeriod
          };

          this.setState({
            loader: true
          });

          service
            .createRating(params)
            .then((response) => response.json())
            .then((data) => {
              if (data.data) {
                this.setState({
                  result: data.data,
                  loader: false
                });
              }
              else if (data.error) {
                this.setState({
                  result: data.error,
                  loader: false
                });
              }
            })
            .catch(() => {
              this.setState({
                loader: false
              });
            });
        }
      );
    }
  }

  buttonSubmit() {
    return (
      <button
        type="button"
        data-testid="tryRating"
        className="confirm-button"
        onClick={() => {
          this.submit();
        }}
      >
        {this.state.result ? "Reset" : "Try with Sample Data"}
      </button>
    );
  }

  render() {
    const ageGroupOptions = [
      {
        label: "20 - 25",
        value: "20 - 25"
      },
      {
        label: "25-30",
        value: "25-30"
      },
      {
        label: "30-35",
        value: "30-35"
      },
      {
        label: "35-40",
        value: "35-40"
      },
      {
        label: "40-45",
        value: "40-45"
      }
    ];

    const disabilityTypeOptions = [
      {
        label: "Partial",
        value: "Partial"
      },
      {
        label: "Total",
        value: "Total"
      },
      {
        label: "Short Term Disability-Less than 90 days",
        value: "Short Term Disability-Less than 90 days"
      }
    ];

    const expectedtoReturnOptions = [
      {
        label: "Less than 90 days",
        value: "Less than 90 days"
      },
      {
        label: "90 to 180 days",
        value: "90 to 180 days"
      },
      {
        label: "Greater than 180 days",
        value: "Greater than 180 days"
      }
    ];

    const annualIncomeOptions = [
      {
        label: "$20k-30K",
        value: "$20k-30K"
      },
      {
        label: "$30K-40K",
        value: "$30K-40K"
      },
      {
        label: "$40K-50K",
        value: "$40K-50K"
      }
    ];

    const reducedIncomeOptions = [
      {
        label: "Reduced Income",
        value: "Reduced Income"
      },
      {
        label: "Lost Entirely",
        value: "Lost Entirely"
      }
    ];

    const typeOfPlanOptions = [
      {
        label: "Bronze",
        value: "Bronze"
      },
      {
        label: "Silver",
        value: "Silver"
      },
      {
        label: "Gold",
        value: "Gold"
      }
    ];

    const classificationCodeOptions = [
      {
        label: "Architect",
        value: "Architect"
      },
      {
        label: "Attorney",
        value: "Attorney"
      },
      {
        label: "Drill operators, truck drivers",
        value: "Drill operators, truck drivers"
      }
    ];

    const waitingPeriodOptions = [
      {
        label: "14 Days",
        value: "14 Days"
      },
      {
        label: "28 Days",
        value: "28 Days"
      },
      {
        label: "60 Days",
        value: "60 Days"
      },
      {
        label: "90 Days",
        value: "90 Days"
      }
    ];

    const disabilityOptions = [
      {
        label: "Sight",
        value: "Sight"
      },
      {
        label: "Speech",
        value: "Speech"
      },
      {
        label: "Hearing",
        value: "Hearing"
      },
      {
        label: "Use of two limbs",
        value: "Use of two limbs"
      }
    ];

    const payoutPeriodOptions = [
      {
        label: "6 Month",
        value: "6 Month"
      },
      {
        label: "12 Month",
        value: "12 Month"
      },
      {
        label: "24 Month",
        value: "24 Month"
      }
    ];

    const insuredPercentageOptions = [
      {
        label: "60",
        value: "60"
      },
      {
        label: "65",
        value: "65"
      },
      {
        label: "75",
        value: "75"
      }
    ];

    const {
      submissionId,
      effectiveDate,
      typeOfDisability,
      expectedReturnToWork,
      age,
      incomeAmount,
      incomeReducedOrLost,
      typeOfPlan,
      classificationCodes,
      waitingPeriod,
      payoutPeriod,
      insuredPercentage,
      presumptiveDisabilityBenefit
    } = this.state;

    const leftTab = () => <div className="nexus-ml-1">
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Select Document type</div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Quote ID</div>
            <NexusFormField className="input-container">
              <NexusInput
                value={submissionId}
                data-testid="quoteID"
                disabled={this.state.result}
                placeholder="e.g. 213232"
                onChange={(
                  event: React.ChangeEvent<HTMLNexusInputElement>
                ) => {
                  this.setState({
                    submissionId: event.target.value
                  });
                }}
              />
            </NexusFormField>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Effective Date</div>
            <NexusInput
              value={effectiveDate}
              data-testid="effectiveDate"
              disabled={this.state.result}
              placeholder="06-04-1975"
              type="date"
              onChange={(event: React.ChangeEvent<HTMLNexusInputElement>) => {
                this.setState({
                  effectiveDate: event.target.value
                });
              }}
            />
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Type of Disability</div>
            <NexusSelect
              value={typeOfDisability}
              data-testid="typeOfDisability"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ typeOfDisability: event.target.value })
              }
            >
              {disabilityTypeOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">
                Expected Return to Work
            </div>
            <NexusSelect
              value={expectedReturnToWork}
              data-testid="expectedReturnToWork"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ expectedReturnToWork: event.target.value })
              }
              placeholder={!expectedReturnToWork ? "e.g. 90-180 days" : ""}
            >
              {expectedtoReturnOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Age</div>
            <NexusSelect
              value={age}
              data-testid="age"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ age: event.target.value })
              }
              placeholder={!age ? "e.g. 20-25" : ""}
            >
              {ageGroupOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Annual Income</div>
            <NexusSelect
              value={incomeAmount}
              data-testid="incomeAmount"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ incomeAmount: event.target.value })
              }
              placeholder={!incomeAmount ? "e.g. 20K - 30K" : ""}
            >
              {annualIncomeOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">
                Income reduced or lost entirely
            </div>
            <NexusSelect
              value={incomeReducedOrLost}
              data-testid="incomeReducedOrLost"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ incomeReducedOrLost: event.target.value })
              }
              placeholder={!incomeReducedOrLost ? "e.g. Reduced" : ""}
            >
              {reducedIncomeOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Type of Plan</div>
            <NexusSelect
              value={typeOfPlan}
              data-testid="typeOfPlan"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ typeOfPlan: event.target.value })
              }
              placeholder={!typeOfPlan ? "e.g. Bronze" : ""}
            >
              {typeOfPlanOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">
                Occupation Class Codes
            </div>
            <NexusSelect
              value={classificationCodes}
              data-testid="classificationCodes"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ classificationCodes: event.target.value })
              }
              placeholder={
                !classificationCodes ? "e.g. Drill Operators, truck Drivers" : ""
              }
            >
              {classificationCodeOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Waiting Period</div>
            <NexusSelect
              value={waitingPeriod}
              data-testid="waitingPeriod"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ waitingPeriod: event.target.value })
              }
              placeholder={!waitingPeriod ? "e.g. 14 Days" : ""}
            >
              {waitingPeriodOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Payout Period</div>
            <NexusSelect
              value={payoutPeriod}
              data-testid="payoutPeriod"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ payoutPeriod: event.target.value })
              }
              placeholder={!payoutPeriod ? "e.g. 6 Months" : ""}
            >
              {payoutPeriodOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Insured percentage</div>
            <NexusSelect
              value={insuredPercentage}
              data-testid="insuredPercentage"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ insuredPercentage: event.target.value })
              }
              placeholder={!insuredPercentage ? "e.g. 65" : ""}
            >
              {insuredPercentageOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">
                Presumptive Disability benefit
            </div>
            <NexusSelect
              value={presumptiveDisabilityBenefit}
              data-testid="presumptiveDisabilityBenefit"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({
                presumptiveDisabilityBenefit: event.target.value
              })
              }
              placeholder={!presumptiveDisabilityBenefit ? "e.g. Sight" : ""}
            >
              {disabilityOptions.map((item) => <option key={item.value} value={item.value}>
                {item.label}
              </option>)}
            </NexusSelect>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          {this.buttonSubmit()}
        </div>
      </div>
    </div>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row justify-content-center align-items-start nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try Extracting
          <br />
              Premium with
          <br />
              Sample Data
        </div>
      </div>
      }

      {this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="nexus-col-lg-10"
          style={{
            background: "#FFD7BA",
            width: "457px",
            height: "137px",
            padding: 0
          }}
        >
          <div className="align-items-start rating-response p-4">
            <img
              className="verified-icons mr-2"
              src={Dollar}
              alt="Dollar"
            />
            <label className="result-text">Your Final Premium is: $</label>
            <label className="result-text">
              {parseFloat(this.state.result.finalPremium).toFixed(2)}
            </label>
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Rating",
      description:
        "Provide carriers with the ability to rate the policy based on the various parameters of the policy.",
      subTitle: "Coherent",
      svg: Rating,
      developerDoc: "income-protection-rating"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} breadcrum="Rating" />
        <CatalogDetailsContainer
          title="Rating Connector"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default RatingConnector;
