/* eslint id-length: "error" */
const InputArray = [
  {
    labelTestid: "addressLine",
    labelTittle: "Address Line",
    inputTestid: "addressLine-input",
    value: "600 CRANDON BLVD",
    isDisabled: true,
    placeholder: "600 CRANDON BLVD",
  },
  {
    labelTestid: "city",
    labelTittle: "City",
    inputTestid: "city-input",
    value: "Key Biscayne",
    isDisabled: true,
    placeholder: "Key Biscayne",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "FL",
    isDisabled: true,
    placeholder: "FL",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "33149",
    isDisabled: true,
    placeholder: "33149",
  },
  {
    labelTestid: "country",
    labelTittle: "Country",
    inputTestid: "country-input",
    value: "US",
    isDisabled: true,
    placeholder: "US",
  },
];

const apiRequestParams = {
  submissionId: "LTT-101",
  groupId: "5958100",
  password: process.env.REACT_APP_API_PWD,
  userData:
    "token_userid=newagent@loggerhead&amp;role_role=agent&amp;user_firstname=New&amp;user_lastname=Agent&amp;user_email=guest@360-value.com",
  ownerData:
    "token_userid=newagent@loggerhead&amp;role_role=agent&amp;user_firstname=New&amp;user_lastname=Agent&amp;user_email=guest@360-value.com",
  ownerGroupId: "5958100",
  structureData: {
    context: {
      residential: {
        owner: {
          street: "1724 UPPER 4TH AVE N",
          city: "JACKSONVILLE BEACH",
          state: "FL",
          zipcode: "32250",
          country: "USA",
        },
        generalInfo: {
          yearBuilt: "string",
          totalFinishedSqft: "string",
          overallQuality: "string",
          stories: "string",
        },
        foundation: {
          foundationType: {
            type: "string",
            value: "string",
          },
        },
        exterior: {
          exteriorWallFinish: {
            type: "string",
            value: "string",
          },
        },
        attachedStructures: {
          garage: {
            garageStyle: "string",
            numberOfCars: "string",
          },
        },
        detachedStructures: {
          op: {
            opt: "string",
            condition: "string",
            sf: "string",
            yearBuilt: "string",
          },
          sl: {
            bop: "string",
            condition: "string",
            sf: "string",
            yearBuilt: "string",
            slh: "string",
          },
        },
        systems: {
          firePlace: {
            type: "string",
          },
        },
      },
    },
  },
  workflowCode: "LTT-101",
  valuationId: "LTT-101",
  reportFormat: "XML",
  locale: "en_US",
  detailedReport: "false",
  callerReferenceId: "LTT-101",
  options: "LTT-101",
};
export { InputArray, apiRequestParams };
