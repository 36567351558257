import React from "react";
import "../Catalog.scss";
import { Warning } from "../../../../../assets/images";
import moment from "moment";

class RecallPlus extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};

    return (
      <div>
        {vehicleData && <div>
          <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            <div className="row">
              <div
                className="col-sm-7 vehicle-details-title"
                style={{ paddingBottom: "10px" }}
              >
                  VIN Specification
              </div>
              <div className="col-sm-7 vehicle-details-title pb-1">
                  Vehicle 1
              </div>
            </div>
            <div className="row vehicle-details-desc">
              <div className="col-sm-5 validated-address-value pb-1">VIN</div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vin}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Year
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.year}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Make
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.make}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Model
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.model}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Class
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.class}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Country
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.country}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Body
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.body}
              </div>
              <div className="col-sm-5 validated-address-value pb-1">
                  Engine
              </div>
              <div className="col-sm-7 validated-address-text">
                  : {vehicleData.vinspec.engine}
              </div>
            </div>

            <div className="nexus-row nexus-py-2">
              <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
                <img className="verified-icons" src={Warning} alt="warning" />
              </div>
              <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
                <div>{`Vehicle ${vehicleData.vin} is Recalled ${vehicleData.recallCount} Time`}</div>
              </div>
            </div>
            {vehicleData?.recallEvents?.event && <>
              <div
                className="motif-col-xs-4 vehicle-details-title"
                style={{ paddingBottom: "10px" }}
              >
                    Event History
              </div>
              {vehicleData?.recallEvents?.event.map(
                (item: any, index: number) => <div key={index}>
                  <div className="row">
                    <div className="col-sm-7 vehicle-details-title pb-1">
                            Vehicle {index + 1}
                    </div>
                  </div>
                  <div className="row vehicle-details-desc">
                    <div className="col-sm-5 validated-address-value pb-1">
                            Recalled Date
                    </div>
                    <div className="col-sm-7 validated-address-text">
                            : {moment(item.date).format("yyyy-MM-DD")}
                    </div>
                    <div className="col-sm-5 validated-address-value pb-1">
                            Reason{" "}
                    </div>
                    <div className="col-sm-7 validated-address-text">
                            : {item.text}
                    </div>
                  </div>
                </div>

              )}
            </>
            }
          </div>
        </div>
        }
      </div>
    );
  }
}

export default RecallPlus;
