import React from "react";
import PropTypes from "prop-types";
import { CheckCircle, Warning } from "../../../../../assets/images";

const FinancialIndicator = ({ data }: { data: any }) => {
  const vehicle = data && data?.vehicle ? data.vehicle[0] : null;

  return (
    <div>
      {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
        {vehicle.lease === "N" && <div className="address-status-row align-items-center">
          <div>
            <img
              className="verified-icons"
              src={CheckCircle}
              alt="CheckCircle"
            />
          </div>
          <div className="nexus-pl-2 validated-address-value">
                Vehicle is not leased
          </div>
        </div>
        }
        {vehicle.lien === "Y" && <div className="address-status-row align-items-center">
          <div>
            <img className="verified-icons" src={Warning} alt="Warning" />
          </div>
          <div className="nexus-pl-2 validated-address-value">
                Vehicle is on lien
          </div>
        </div>
        }
        {vehicle.lease === "Y" && <div className="address-status-row align-items-center">
          <div>
            <img className="verified-icons" src={Warning} alt="Warning" />
          </div>
          <div className="nexus-pl-2 validated-address-value">
                Vehicle is on lease
          </div>
        </div>
        }
        {vehicle.lien === "N" && <div className="address-status-row align-items-center">
          <div>
            <img
              className="verified-icons"
              src={CheckCircle}
              alt="CheckCircle"
            />
          </div>
          <div className="nexus-pl-2 validated-address-value">
                Vehicle is not on loan
          </div>
        </div>
        }
      </div>
      }
    </div>
  );
};
FinancialIndicator.defaultProps = {
  data: null
};
// FinancialIndicator.propTypes = {
//   data: PropTypes.objectOf({
//     vehicle: PropTypes.object
//   })
// };
export default FinancialIndicator;
