import { roofTopRiskFieldsStep2, roofTopRiskFieldsStep3 } from "./inputList";
import React from "react";
import Step from "../Step";
import DisabledInput from "../DisabledInput";

const RoofTopRisk = () => <>
  <Step stepNumber={"Step 02"} header={"Search By"}></Step>
  <DisabledInput InputArray={roofTopRiskFieldsStep2} />
  <Step stepNumber={"Step 03"} header={"Enter Your Location"}></Step>
  <DisabledInput InputArray={roofTopRiskFieldsStep3} />
</>;
export default RoofTopRisk;
