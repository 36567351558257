import React from "react";
import { Wind, Temperature } from "../Assets/Image";
import Row from "./Row";
import moment from "moment";
import {
  directionMapping,
  descriptionMapping,
  mapIcons,
  mapIconFromIconName
} from "./helpers";
/* eslint-disable no-confusing-arrow */
/* eslint-disable space-infix-ops */
/* eslint-disable no-negated-condition */

const dayforcast = (data: any) => {
  let probability;

  if (data?.precipitationDay?.iceProbability !== "0") {
    probability = {
      probability: data.precipitationDay.iceProbability,
      type: "Hours Ice",
      icon: "Ice",
      value: data.precipitationDay.hoursOfIce
    };
  }
  else if (
    data?.precipitationDay?.rainProbability && data?.precipitationDay?.rainProbability !== "0"
  ) {
    probability = {
      probability: data.precipitationDay.rainProbability,
      type: "Hours Rain",
      icon: "Rain",
      value: data.precipitationDay.hoursOfRain
    };
  }
  else {
    probability = {
      probability: data.precipitationDay.snowProbability,
      type: "Hours Snow",
      icon: "Snow",
      value: data.precipitationDay.hoursOfSnow
    };
  }

  const table = {
    title: `Day Forecast: ${moment(data.date).format("DD MMMM")}`,
    rows: [
      {
        image: {
          src: mapIcons(data.weatherDescription),
          label: "Forecasts"
        },
        coloums: [
          {
            label: "Weather",
            value: descriptionMapping(data.weatherDescription)
          },
          {
            label: "Min Humidity",
            value: `${data.humidity.minimum} %`
          },
          {
            label: "Max Humidity",
            value: `${data.humidity.maximum} %`
          }
        ]
      },
      {
        image: {
          src: Temperature,
          label: "Temperature"
        },
        coloums: [
          {
            label: "Current",
            value:
              data?.temperature?.maximum && data?.temperature?.maximum[0]?.value,
            unit: "°C"
          },
          {
            label: "Feels Like",
            value:
              data?.realFeelTemperature?.maximum && data?.realFeelTemperature?.maximum[0]?.value,
            unit: "°C"
          },
          {
            label: "Dew Point",
            value: data?.dewPoint?.maximum && data?.dewPoint?.maximum[0]?.value,
            unit: "°C"
          }
        ]
      },
      {
        image: {
          src: Wind,
          label: "Wind"
        },
        coloums: [
          {
            label: "Speed mi/h",
            value:
              data?.precipitationDay?.windSpeed && data?.precipitationDay.windSpeed[0].value
          },
          {
            label: "Speed Km/h",
            value:
              data?.precipitationDay?.windSpeed && data?.precipitationDay.windSpeed[1].value
          },
          {
            label: directionMapping(
              data?.precipitationDay?.windDirection.direction
            ),
            value: data?.precipitationDay?.windDirection.degrees,
            unit: "°"
          }
        ]
      },
      {
        image: {
          src: mapIconFromIconName(probability.icon),
          label: "Percipitation"
        },
        coloums: [
          {
            label: probability.type,
            value: probability.value
          },
          {
            label: "Intensity",
            value: data?.precipitationDay?.intensity
          },
          {
            label: "Probability",
            value: `${probability.probability} %`
          }
        ]
      }
    ]
  };

  return <Row data={table} />;
};

const hourlyforcast = (data: any) => {
  const table = {
    title: `Hourly Forecast: ${moment(data.date).format("hh:mm A")}`,
    rows: [
      {
        image: {
          src: mapIcons(data.weatherDescription),
          label: "Forecasts"
        },
        coloums: [
          {
            label: "Weather",
            value: descriptionMapping(data.weatherDescription)
          },
          {
            label: "Humidity",
            value: `${data.humidity} %`
          },
          {
            label: "Has Percipitation",
            value: `${data.hasPrecipitation ? "Yes" : "No"}`
          }
        ]
      },
      {
        image: {
          src: Temperature,
          label: "Temperature"
        },
        coloums: [
          {
            label: "Current",
            value: data?.temperature && data?.temperature[0]?.value
          },
          {
            label: "Feels Like",
            value: data?.realFeelTemperature? data?.realFeelTemperature[0].value: "NA"
          },
          {
            label: "Dew Point",
            value: data?.dewPoint && data?.dewPoint[0].value
          }
        ]
      },
      {
        image: {
          src: Wind,
          label: "Wind"
        },
        coloums: [
          {
            label: "Speed mi/h",
            value: data?.windSpeed && data?.windSpeed[0].value
          },
          {
            label: "Speed Km/h",
            value: data?.windSpeed && data?.windSpeed[1].value
          },
          {
            label: directionMapping(data?.windDirection.direction),
            value: data?.windDirection.degrees
          }
        ]
      },
      {
        image: {
          src: mapIconFromIconName("Cloudy"),
          label: "Percipitation"
        },
        coloums: [
          {
            label: "Rain Probability",
            value: `${data?.rainProbability} %`
          },
          {
            label: "Snow Probability",
            value: `${data?.snowProbability} %`
          },
          {
            label: "Ice Prbability",
            value: `${data?.iceProbability} %`
          }
        ]
      }
    ]
  };

  return <Row data={table} />;
};

export { dayforcast, hourlyforcast };
