import React, { Component } from "react";
import ComingSoon from "../../SharedComponent/ComingSoon/ComingSoon";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "support-contact",
  listName: "support-contact"
};

class Contact extends Component {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  render() {
    return <ComingSoon />;
  }
}
export default Contact;
