import { NexusRadio, NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import "../Catalog.scss";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import Service from "../../../service/Service";

import { CheckCircle, Warning } from "../../../../../assets/images";
import countries from "../../../../../utils/country_state.json";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/address-validation",
  listName: "platform-capabilities/address-validation",
};

const App = () => {
  // eslint-disable-next-line global-require

  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [countryOption, setCountryOption] = useState("us");
  const [country, setCountry] = useState("");
  const [validatedAddress, setValidatedAddress] = useState<any>(null);
  const [addressMetaData, setAddressMetaData] = useState<any>(null);
  const [loader, setLoader] = React.useState(false);
  const [stateList, setStateList] = useState<any>([]);

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);
  const reset = () => {
    setValidatedAddress(null);
    setaddressLine1("");
    setaddressLine2("");
    setCity("");
    setState("");
    setCountry("");
    setZip("");
  };
  const getStateList = (id: string) => {
    const states = countries.filter(
      (countryItem) => countryItem.countryCode === id
    )[0].stateProvinces;
    const stateUI = states?.map((item) => (
      <option key={item.name} value={item.name}>
        {item.name}
      </option>
    ));
    setStateList(stateUI);
  };
  const validateAddress = () => {
    let address;
    if (validatedAddress) {
      reset();
    } else {
      if (countryOption === "us") {
        getStateList("US");
        setaddressLine1("276 Castro Street");
        setaddressLine2("Flat #2");
        setState("California");
        setCity("San Francisco");
        setZip("94114");
        address = {
          address1: "276 Castro Street",
          address2: "Flat #2",
          administrativeArea: "California",
          country: "US",
          locality: "San Francisco",
          postalCode: "94114",
        };
      } else {
        getStateList("GB");
        setaddressLine1("3 Chequer Street");
        setaddressLine2("Flat 12");
        setCountry("GB");
        setState("London");
        setCity("London");
        setZip("EC1Y 8PW");

        address = {
          address1: "3 Chequer Street",
          address2: "Flat 12",
          administrativeArea: "London",
          country: "GB",
          locality: "London",
          postalCode: "EC1Y 8PW",
        };
      }

      // setValidatedAddress(address);
      // setAddressMetaData(address);
      setLoader(true);
      service
        .validateAddress(address)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setValidatedAddress(data.data[0].addressComponents);
            setAddressMetaData(data.data[0].addressMetadata);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const countriesList = countries.map((item: any) => (
    <option key={item.name} value={item.countryCode}>
      {item.name}
    </option>
  ));

  const setCountryTab = (opt: any) => {
    if (opt === "us") {
      setCountry("US");
    }
    setCountryOption(opt);
  };

  useEffect(() => {
    setCountry("US");
    getStateList("US");
  }, []);

  const card = {
    title: "Address Validation",
    description:
      "Nexus Address Validation Connector provides carriers with the ability to check and validate any customer provided address details both in the US and Internationally.",
    subTitle: "Smarty Streets, Precisely",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "address-validation",
  };

  const leftTab = () => (
    <>
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Pick One</div>
          <div className="radio-container">
            <NexusRadio
              name="countryOption"
              id="us"
              value="us"
              checked={countryOption === "us"}
              disabled={Boolean(validatedAddress)}
              data-testid="countryUs"
              onInput={() => {
                reset();
                setCountryTab("us");
              }}
            >
              US
            </NexusRadio>
            <NexusRadio
              className="ml-5"
              name="countryOption"
              id="international"
              value="international"
              disabled={Boolean(validatedAddress)}
              checked={countryOption === "international"}
              data-testid="international"
              onInput={() => {
                reset();
                setCountryTab("international");
              }}
            >
              International
            </NexusRadio>
          </div>
        </div>
      </div>
      <div className="step2  nexus-pr-4">
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
            Step 02
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">Enter Address</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Address Line 1:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={addressLine1}
                  data-testid="address-line1"
                  onChange={(event) => setaddressLine1(event.target.value)}
                  placeholder={
                    countryOption === "us"
                      ? "e.g. 276 Castro Street"
                      : "e.g. 3 Chequer Street"
                  }
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Address Line 2:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={addressLine2}
                  data-testid="address-line2"
                  onChange={(event) => setaddressLine2(event.target.value)}
                  placeholder={
                    countryOption === "us" ? "e.g. Flat #2" : "e.g. Flat 12"
                  }
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">City:</div>
              <div className="input-container">
                <input
                  type="text"
                  value={city}
                  data-testid="city"
                  onChange={(event) => setCity(event.target.value)}
                  placeholder={
                    countryOption === "us" ? "e.g. San Francisco" : "e.g London"
                  }
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">
                {countryOption === "us" ? "State:" : "Province:"}
              </div>
              <div className="input-container">
                {!state && (
                  <div
                    className="select-placeholder"
                    key="placeholder"
                    data-placeholder="placeholder"
                  >
                    {countryOption === "us" ? "e.g. CA" : "e.g London"}
                  </div>
                )}
                <select
                  value={state}
                  className="line-input-box"
                  data-testid="state"
                  onChange={(event) => setState(event.target.value)}
                  disabled
                >
                  {stateList}
                </select>
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">
                {countryOption === "us" ? "Zip Code:" : "Post Code:"}
              </div>
              <div className="input-container">
                <input
                  type="text"
                  data-testid="zip"
                  value={zip}
                  onChange={(event) => setZip(event.target.value)}
                  placeholder={
                    countryOption === "us" ? "e.g. 94114" : "e.g. EC1Y 8PW"
                  }
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            {countryOption === "international" && (
              <div className="mt-2 mb-3">
                <div className="italicText mb-2">Country:</div>
                <div className="input-container">
                  {!country && (
                    <div
                      className="select-placeholder"
                      key="placeholder"
                      data-placeholder="placeholder"
                    >
                      e.g UK
                    </div>
                  )}
                  <select
                    value={country}
                    data-testid="country"
                    className="line-input-box"
                    onChange={(event) => {
                      setCountry(event.target.value);
                      getStateList(event.target.value);
                    }}
                    disabled
                  >
                    {countriesList}
                  </select>
                </div>
              </div>
            )}
            <div>
              <button
                type="button"
                className="confirm-button"
                data-testid="validateAddress"
                onClick={() => {
                  validateAddress();
                }}
              >
                {validatedAddress ? "Reset" : "Try with Sample Data"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!validatedAddress && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Address Validation with Sample Data
          </div>
        </div>
      )}
      {validatedAddress && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-mb-2 test-result">Test Result</div>
          <div className="test-result-box nexus-p-4">
            <div>
              <div className="address-row">
                <div className="validated-address-value">
                  {(validatedAddress.building
                    ? `${validatedAddress.building}, `
                    : "") +
                    (validatedAddress.subBuilding
                      ? `${validatedAddress.subBuilding}, `
                      : "") +
                    (validatedAddress.premiseNumber
                      ? `${validatedAddress.premiseNumber} `
                      : "") +
                    validatedAddress.thoroughfare}
                </div>
                <div className="validated-address-text">Delivery Line 1</div>
              </div>
              <div className="address-row">
                <div className="validated-address-value">
                  {validatedAddress.locality}
                  {", "}
                  {validatedAddress.administrativeArea}
                  {", "}
                  {validatedAddress.postalCodeShort +
                    (validatedAddress.postalCodeExtra
                      ? `-${validatedAddress.postalCodeExtra}`
                      : "")}
                </div>
                <div className="validated-address-text">
                  City, State, ZIP Code, 4-Dash
                </div>
              </div>
              <div className="address-row">
                <div className="validated-address-value">
                  {addressMetaData?.latitude}
                </div>

                <div className="validated-address-text">Latitude</div>
              </div>
              <div className="address-row">
                <div className="validated-address-value">
                  {addressMetaData?.longitude}
                </div>
                <div className="validated-address-text">Longitude</div>
              </div>
            </div>
            <div className="adress-status-container">
              <div className="address-status-row">
                <div>
                  <img
                    className="verified-icons"
                    src={CheckCircle}
                    alt="CheckCircle"
                  />
                </div>
                <div className="address-matched-text">
                  Matched Street, City and State
                </div>
              </div>
              <div className="address-status-row">
                <div>
                  <img
                    className="verified-icons"
                    src={CheckCircle}
                    alt="CheckCircle"
                  />
                </div>
                <div className="address-matched-text">
                  Confirmed entire address
                </div>
              </div>
              <div className="address-status-row">
                <div>
                  <img className="verified-icons" src={Warning} alt="Warning" />
                </div>
                <div className="address-matched-text">Fixed Abbreviations</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Address Validation Tool"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default App;
