import React from "react";
import "../Catalog.scss";
import { NexusProgressBar, NexusCheckbox } from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import {
  Wearables,
  Activity,
  Glucose,
  Heart,
  Oxygen,
  Pressure
} from "../../../../../assets/images";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/wearable",
  listName: "platform-capabilities/wearable"
};

const service = new Service();

class WearableData extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      userId: "",
      result: null,
      heartRate: false,
      activitySummary: false,
      bloodOxygen: false,
      bloodPressure: false,
      bloodGlucose: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit() {
    if (this.state.result) {
      this.setState({
        result: false,
        userId: "",
        heartRate: false,
        activitySummary: false,
        bloodOxygen: false,
        bloodPressure: false,
        bloodGlucose: false
      });
    }
    else {
      this.setState({
        loader: true,
        userId: "Nexus_11"
      });
      setTimeout(() => {
        this.setState({
          loader: false,
          result: true
        });
      }, 1000);
    }
  }

  buttonSubmit() {
    return (
      <div style={{ marginTop: "137px" }}>
        <button
          data-testid="tryWearableData"
          type="button"
          className="confirm-button"
          onClick={() => {
            this.submit();
          }}
        >
          {this.state.result ? "Reset" : "Try with Test User"}
        </button>
      </div>
    );
  }

  render() {
    const {
      heartRate,
      activitySummary,
      bloodGlucose,
      bloodPressure,
      bloodOxygen,
      userId
    } = this.state;

    const leftTab = () => <div className="nexus-ml-1">
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Input the User Id</div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">User Id</div>
            <input
              data-testid="userId"
              type="text"
              value={userId}
              disabled
              className="line-input-box"
              placeholder="e.g. Nexus_1001"
            />
          </div>
        </div>
      </div>
      <div className="nexus-row" style={{ marginTop: "40px" }}>
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2">
            Step 02
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">
              Input Applicant Licence Details
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mt-2">
            <NexusCheckbox
              data-testid="heartRate"
              id="heartRate"
              checked={heartRate}
              disabled={this.state.result}
              onInput={() => {
                this.setState({ heartRate: !heartRate });
              }}
            >
                Heart Rate
            </NexusCheckbox>
            <NexusCheckbox
              data-testid="activitySummary"
              id="activitySummary"
              checked={activitySummary}
              disabled={this.state.result}
              onInput={() => {
                this.setState({ activitySummary: !activitySummary });
              }}
            >
                Activities Summary
            </NexusCheckbox>
            <NexusCheckbox
              data-testid="bloodOxygen"
              id="bloodOxygen"
              checked={bloodOxygen}
              disabled={this.state.result}
              onInput={() => {
                this.setState({ bloodOxygen: !bloodOxygen });
              }}
            >
                Blood Oxygen
            </NexusCheckbox>
            <NexusCheckbox
              data-testid="bloodPressure"
              id="bloodPressure"
              checked={bloodPressure}
              disabled={this.state.result}
              onInput={() => {
                this.setState({ bloodPressure: !bloodPressure });
              }}
            >
                Blood Prssure
            </NexusCheckbox>
            <NexusCheckbox
              data-testid="bloodGlucose"
              id="bloodGlucose"
              checked={bloodGlucose}
              disabled={this.state.result}
              onInput={() => {
                this.setState({ bloodGlucose: !bloodGlucose });
              }}
            >
                Blood Glucose
            </NexusCheckbox>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          {this.buttonSubmit()}
        </div>
      </div>
    </div>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="col d-flex justify-content-center align-items-center try-address-text"
        style={{ height: "100%" }}
      >
        <div className="address-sample">Try with Sample data</div>
      </div>
      }

      {this.state.result && <div className="row-content ml-5" style={{ height: "100%" }}>
        {heartRate && <div className="row">
          <div className="left-icon-container nexus-flex-col align-items-center nexus-center-lg nexus-center-md">
            <img
              className="left-icon"
              src={Heart}
              alt="heart"
              style={{ marginRight: "" }}
            />
            <div className="wearable-img-text">Heart Rate</div>
          </div>
          <div className="col">
            <div className="text-center mb-2">114</div>
            <div className="text-center">Max heart rate</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">64</div>
            <div className="text-center">Min heart rate</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">92</div>
            <div className="text-center">Avg heart rate</div>
          </div>
        </div>
        }
        {activitySummary && <div className="row">
          <div className="left-icon-container nexus-flex-col align-items-center nexus-center-lg nexus-center-md">
            <img className="left-icon" src={Activity} alt="heart" />
            <div className="wearable-img-text">Activities</div>
          </div>

          <div className="col">
            <div className="text-center mb-2">64 Miles</div>
            <div className="text-center">Walking</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">30 Miles</div>
            <div className="text-center">Running</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">15 Miles</div>
            <div className="text-center">Cycling</div>
          </div>
        </div>
        }
        {bloodOxygen && <div className="row">
          <div className="left-icon-container  nexus-flex-col align-items-center nexus-center-lg nexus-center-md">
            <img className="left-icon" src={Oxygen} alt="heart" />
            <div className="wearable-img-text">Blood Oxygen</div>
          </div>
          <div className="col">
            <div className="text-center mb-2">96%</div>
            <div className="text-center">Max. SPO2</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">84%</div>
            <div className="text-center">Min. SPO2</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">90%</div>
            <div className="text-center">Avg. SPO2</div>
          </div>
        </div>
        }
        {bloodPressure && <div className="row">
          <div className="left-icon-container  nexus-flex-col align-items-center nexus-center-lg nexus-center-md">
            <img className="left-icon" src={Pressure} alt="heart" />
            <div className="wearable-img-text">Blood Pressure</div>
          </div>

          <div className="col">
            <div className="text-center mb-2">135/85</div>
            <div className="text-center">mmHg High</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">105/70</div>
            <div className="text-center">mmHg Low</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">120/80</div>
            <div className="text-center">mmHg Avg</div>
          </div>
        </div>
        }
        {bloodGlucose && <div className="row">
          <div className="left-icon-container  nexus-flex-col align-items-center nexus-center-lg nexus-center-md">
            <img className="left-icon" src={Glucose} alt="heart" />
            <div className="wearable-img-text">Blood Glucose</div>
          </div>

          <div className="col">
            <div className="text-center mb-2">98 mg/dL</div>
            <div className="text-center">High</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">80 mg/dL</div>
            <div className="text-center">Low</div>
          </div>
          <div className="vetical-line" />
          <div className="col">
            <div className="text-center mb-2">88 mg/dL</div>
            <div className="text-center">Avg.</div>
          </div>
        </div>
        }
      </div>
      }
    </>;
    const card = {
      title: "Wearables",
      description:
        "Provide carriers with the ability to serve their customers and users by aggregating 30,000+ health and wellness data sources into one platform, streamlining the process to acquire digital customer medical records",
      subTitle: "Human API",
      svg: Wearables,
      developerDoc: "wearables"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design wearable">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Wearables Data"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default WearableData;
