/* eslint-disable no-confusing-arrow */
/* eslint-disable max-lines */
/* eslint-disable react/no-unused-state */
import {
  NexusIcon,
  NexusProgressBar,
  NexusSelect,
  NexusInput,
  NexusFormField
} from "@nexus/react";
import React from "react";
import "../Catalog.scss";
import mapsIcDirectionsCar24px from "@nexus/core/dist/assets/icons/places/ic_directions_car_24px.svg";
import contenticsend24px from "@nexus/core/dist/assets/icons/media/ic_send_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { VEHICLE_HISTORY_SEARCH_CATEGORY } from "../../../Constants";
import {
  CheckCircle,
  Warning,
  DateRange,
  Car
} from "../../../../../assets/images";

import {
  AnalyticsIndicators,
  AccidentPremium,
  AutoScore,
  VinSpecification,
  BuybackProtection,
  OwnershipPremium,
  Recall,
  RecallPremium,
  FinancialIndicator,
  RecallPlus,
  Salvage
} from "../LocationHistoryComp";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/vehicle-history",
  listName: "platform-capabilities/vehicle-history"
};

interface State {
  loader: boolean;
  setAlreadySeached: boolean;
  searchFor: string;
  VINNumber: string;
  sampleVINNumber: string;
  plateNumber: string;
  samplePlateNumber: string;
  stateName: string;
  sampleStateName: string;
  responseData: any;
}

const service = new Service();
class VehicleHistory extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      setAlreadySeached: false,
      responseData: [],
      searchFor: "",
      VINNumber: "",
      sampleVINNumber: "KL1TD526X5B4XXXXX",
      plateNumber: "",
      samplePlateNumber: "HJXXXX",
      stateName: "",
      sampleStateName: "IA"
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
    this.setState({
      searchFor: "select_one"
    });
  }

  processAPIResponse = (apiResponse: any) => {
    this.setState({
      responseData: apiResponse
    });
  };

  searchForVehicleHistory = () => {
    const {
      setAlreadySeached,
      searchFor,
      sampleVINNumber,
      sampleStateName,
      samplePlateNumber
    } = this.state;
    if (!setAlreadySeached && searchFor !== "select_one") {
      this.setState(
        {
          setAlreadySeached: true,
          loader: true,
          VINNumber: sampleVINNumber,
          stateName: sampleStateName,
          plateNumber: samplePlateNumber
        },
        () => {
          let params;
          if (searchFor === "recall_plus") {
            params = {
              plate: samplePlateNumber,
              state: sampleStateName
            };
          }
          else {
            params = {
              vinList: [sampleVINNumber]
            };
          }

          // eslint-disable-next-line prefer-destructuring
          const { url } = VEHICLE_HISTORY_SEARCH_CATEGORY.filter(
            (item) => item.value === searchFor
          )[0];
          service
            .getVehicleHistory(params, url)
            .then((response) => response.json())
            .then((data) => {
              this.processAPIResponse(data.data);
              this.setState({
                loader: false
              });
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log("error", error);
              this.setState({
                loader: false
              });
            });
        }
      );
    }
    else {
      this.setState({
        setAlreadySeached: false,
        searchFor: "select_one",
        VINNumber: "",
        stateName: "",
        plateNumber: "",
        responseData: null
      });
    }
  };

  setSearchFor = (val: string) => {
    this.setState({
      searchFor: val,
      setAlreadySeached: false,
      responseData: null
    });
  };

  render() {
    const {
      loader,
      setAlreadySeached,
      responseData,
      VINNumber,
      searchFor,
      stateName,
      plateNumber
    } = this.state;

    const card = {
      title: "Vehicle History",
      description:
        "Provide carriers with the ability to extract car details and history based on a Vehicle Identification Number. Vehicle History information is leveraged across underwriting, policy issuance, renewal and claim lifecycles.",
      subTitle: "Experian",
      icon: mapsIcDirectionsCar24px,
      developerDoc: "vehicle-history"
    };

    const format = (number: number) => {
      const nfObject = new Intl.NumberFormat("en-US");

      return nfObject.format(number);
    };

    const leftTab = () => <>
      <div className="nexus-row">
        <div
          className="nexus-col-xs-2 form-head step step-0"
          style={{ maxWidth: "20%" }}
        >
          {" "}
            Step 1
        </div>
        <div className="nexus-col-xs-2 nexuscolxs2-rt">
          <div className="form-head font-bold">
              Select Vehicle History Search Category
          </div>
          <div className="italicText nexus-pt-3 nexus-pb-1">Search For:</div>
          <div className="VHinput-container">
            <NexusFormField>
              <NexusSelect
                data-testid="tstSelectSearchfor"
                value={searchFor}
                disabled={setAlreadySeached}
                onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setSearchFor(event.target.value)
                }
              >
                {VEHICLE_HISTORY_SEARCH_CATEGORY.map((item) => item.value === "select_one" ? <option
                  key={item.value}
                  value={item.value}
                  disabled
                  hidden
                >
                  {item.name}
                </option> : <option key={item.value} value={item.value}>
                  {item.name}
                </option>)}
              </NexusSelect>
            </NexusFormField>
          </div>
          {this.state.searchFor === "recall_plus" ? <>
            <div className="italicText nexus-pt-1 nexus-pb-1">Plate:</div>
            <div className="VHinput-container">
              <NexusInput
                type="text"
                value={plateNumber}
                placeholder={this.state.samplePlateNumber}
              />
            </div>
            <div className="italicText nexus-pt-1 nexus-pb-1">State:</div>
            <div className="VHinput-container">
              <NexusInput
                type="text"
                value={stateName}
                placeholder={this.state.sampleStateName}
              />
            </div>
          </> : <>
            <div className="italicText nexus-pt-1 nexus-pb-1">
                  VIN Number:
            </div>
            <div className="VHinput-container">
              <NexusInput
                type="text"
                disabled
                placeholder={this.state.sampleVINNumber}
                value={VINNumber}
              />
            </div>
          </>
          }
          <div className="nexus-pt-3 nexus-pb-3">
            <button
              data-testid="tstConfirmButton"
              type="button"
              disabled={searchFor === "select_one" || !searchFor}
              className="confirm-button"
              onClick={() => this.searchForVehicleHistory()}
            >
              {setAlreadySeached ? "Reset" : "Try with Sample Data"}
            </button>
          </div>
        </div>
      </div>
    </>;
    const capitalize = (text: string) => text ? text[0].toUpperCase() + text.substring(1).toLowerCase() : "";

    const formateDate = (date: string) => {
      const givenDate = new Date(date);

      // return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
      return `${givenDate.getDate()}-${`0${givenDate.getMonth() + 1}`.slice(-2)}-${givenDate.getFullYear()}`;
    };

    const rederProblem = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
          <div className="nexus-col-xs-4">
            {vehicle.titleProblemCheck === "Y" && <div className="nexus-row">
              <div className="nexus-col-xs-4">
                <div className="address-status-row">
                  <div>
                    <img
                      className="verified-icons"
                      src={Warning}
                      alt="Warning"
                    />
                  </div>
                  <div className="nexus-px-1 validated-address-value">
                        Problem is Identified with the Vehicle
                  </div>
                </div>
              </div>
              <div className="nexus-col-xs-4 ml-5 mt-2">
                <div className="nexus-row">
                  {vehicle.damageCheck === "Y" && <div className="nexus-col-xs-4 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                            Vehicle has light Damages
                    </span>
                  </div>
                  }
                  {vehicle.structuralDamage === "Y" && <div className="nexus-col-xs-4 mt-2 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                            Vehicle has structural damages
                    </span>
                  </div>
                  }
                  {vehicle.insuranceLoss === "Y" && <div className="nexus-col-xs-4 mt-2 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                            Vehicle has previous loss hisotry
                    </span>
                  </div>
                  }
                </div>
              </div>
            </div>
            }
          </div>
          <div className="nexus-col-xs-4">
            {vehicle.titledToInsurance === "Y" && <div className="nexus-row">
              <div className="nexus-col-xs-4">
                <div className="address-status-row">
                  <div>
                    <img
                      className="verified-icons"
                      src={CheckCircle}
                      alt="CheckCircle"
                    />
                  </div>
                  <div className="nexus-px-1 validated-address-value">
                        Vehicle is titled for Insurance
                  </div>
                </div>
              </div>
              <div className="nexus-col-xs-4 ml-5 mt-2">
                <div className="nexus-row">
                  {vehicle.airbagDeployed === "Y" && <div className="nexus-col-xs-4 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                            Air Bag is Installed
                    </span>
                  </div>
                  }
                  {vehicle.greyMarket === "Y" && <div className="nexus-col-xs-4 mt-2 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                            Vehicle purchased in Grey Market
                    </span>
                  </div>
                  }
                  {vehicle.accidentCheck === "Y" && <div className="nexus-col-xs-4 mt-2 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                      {vehicle.accidentCount}
                      {" prior accidents reported"}
                    </span>
                  </div>
                  }
                  {vehicle.odometerRollback === "Y" && <div className="nexus-col-xs-4 mt-2 ml-4 validated-address-text">
                    <NexusIcon
                      className="bulletpoint"
                      src={contenticsend24px}
                    />
                    <span className="nexus-pl-2">
                            Odometer reading is Rollbacked
                    </span>
                  </div>
                  }
                </div>
              </div>
            </div>
            }
          </div>
        </div>

      );
    };

    const rederConditionIndicator = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
          <div className="nexus-row nexus-p-2">
            <div className="nexus-col-xs-2 validated-address-value">
                Is this a Commercial Vehicle?
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicle.commercialVehicle === "N" ? "No" : "Yes"}{" "}
            </div>
          </div>
          <div className="nexus-row nexus-p-2">
            <div className="nexus-col-xs-2 validated-address-value">
                Is this a Taxi?
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicle.taxiVehicle === "N" ? "No" : "Yes"}{" "}
            </div>
          </div>
          <div className="nexus-row nexus-p-2">
            <div className="nexus-col-xs-2 validated-address-value">
                Is this a Fleet Vehicle?
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicle.fleetVehicle === "N" ? "No" : "Yes"}{" "}
            </div>
          </div>
          <div className="nexus-row nexus-p-2">
            <div className="nexus-col-xs-2 validated-address-value">
                Average Miles Driven:
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {format(vehicle.averageMiles)}
            </div>
          </div>
          <div className="nexus-row nexus-p-2">
            <div className="nexus-col-xs-2 validated-address-value">
                Number of Owners:
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicle.ownerCount}
            </div>
          </div>
        </div>

      );
    };

    const renderBrand = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div
            className="test-result-box nexus-p-4 nexus-col-lg-10"
            style={{ height: "50%" }}
          >
            <div>
              {vehicle.titleBrandCheck === "Y" && <div className="address-status-row ">
                <div>
                  <img
                    className="verified-icons"
                    src={CheckCircle}
                    alt="CheckCircle"
                  />
                </div>
                <div className="nexus-pl-1 validated-address-value">
                      This vehicle is title branded
                </div>
              </div>
              }
              {vehicle.titleBrandCheck === "N" && <div className="address-status-row ">
                <div>
                  <img
                    className="verified-icons"
                    src={Warning}
                    alt="Warning"
                  />
                </div>
                <div className="nexus-pl-1 validated-address-value">
                      This vehicle is not branded
                </div>
              </div>
              }
            </div>
          </div>
          }
        </div>
      );
    };

    const renderMileage = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            {vehicle.rollback === "N" && <div className="nexus-row">
              <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
                <div>Odometer is not rollbacked</div>
              </div>
            </div>
            }
            <div
              className="nexus-flex-col validated-address-text"
              style={{ marginLeft: "43px" }}
            >
              <div>Vehicle</div>
              <div>{vehicle.vin}</div>
              <div>Odometer reading untill</div>
              {vehicle.lastOdometerDate && <div>{`${formateDate(vehicle.lastOdometerDate)} is ${vehicle.lastOdometer} and`}</div>
              }
              <div>Estimated Average Miles</div>
              {vehicle.estimatedAverageMiles && <div>{vehicle.estimatedAverageMiles}</div>
              }
            </div>
          </div>
          }
        </div>
      );
    };

    const renderFloodRisk = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            {vehicle.floodTitleAndProblemCheck === "Y" && <div className="address-status-row align-items-center">
              <div>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-pl-2 validated-address-value">
                    Flood zone and Vehicle Problem check is complete
              </div>
            </div>
            }
            {vehicle.regTitleInFemaDisasterZone === "Y" && <div className="address-status-row align-items-center">
              <div>
                <img
                  className="verified-icons"
                  src={Warning}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-pl-2 validated-address-value">
                    Vehicle registered in FEMA disaster Zone
              </div>
            </div>
            }
            <div>
              <div className="vehicle-details-title nexus-pl-1 nexus-pt-4">
                  Vehicle Registration Details
              </div>
              <div className="vehicle-details-descc nexus-pl-4 nexus-pt-3">
                <div className="nexus-row nexus-pb-1">
                  <div className="validated-address-value nexus-pr-1">
                      VIN :
                  </div>
                  <div className="validated-address-text">{vehicle.vin}</div>
                </div>
                <div className="nexus-row nexus-pb-1">
                  <div className="validated-address-value nexus-pr-1">
                      City :
                  </div>
                  <div className="validated-address-text">
                    {capitalize(vehicle.city)}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-1">
                  <div className="validated-address-value nexus-pr-1">
                      State :
                  </div>
                  <div className="validated-address-text">
                    {vehicle.state}
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      );
    };

    const renderAccidentPlus = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            {vehicle.accidentCount > 0 && <div className="nexus-row">
              <div className="nexus-col-xs-2" style={{ maxWidth: "43px" }}>
                <img
                  className="verified-icons"
                  src={Warning}
                  alt="CheckCircle"
                />
              </div>
              <div className="nexus-col-xs-2 nexuscolxs2-rt validated-address-value">
                {`${capitalize(
                  vehicle.lastReportedAccidentEvent
                )} on ${formateDate(vehicle.lastReportedAccidentDate)}`}
              </div>
            </div>
            }
            {vehicle?.reportedAccidents && vehicle.reportedAccidents.map((item: any, index: number) => <div
              className="nexus-flex-col"
              style={{ marginLeft: "43px" }}
              key={index}
            >
              <div className="vehicle-details-title nexus-mt-4">
                      Vehicle Accident History
              </div>
              <div className="vehicle-details-descc nexus-p-2">
                <div className="nexus-row nexus-pb-2">
                  <div className="validated-address-value">
                          Reported Date :
                  </div>
                  <div className="validated-address-text">
                    {formateDate(item.reportedDate)}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-2">
                  <div className="validated-address-value">City :</div>
                  <div className="validated-address-text">
                    {capitalize(item.city)}
                  </div>
                </div>
                <div className="nexus-row nexus-pb-2">
                  <div className="validated-address-value">State :</div>
                  <div className="validated-address-text">
                    {item.state}
                  </div>
                </div>
              </div>
            </div>)}
          </div>
          }
        </div>
      );
    };

    const renderAssuredIndicator = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            {vehicle.assured === "Y" && <div className="address-status-row vehicle-assured-txt">
              <img className="verified-icons" src={Car} alt="CheckCircle" />
              <div className="nexus-pl-2 validated-address-value">
                    Vehicle is Assured
              </div>
            </div>
            }
            {vehicle.assured === "N" && <div className="address-status-row vehicle-assured-txt">
              <img className="verified-icons" src={Warning} alt="Warning" />
              <div className="nexus-pl-2 validated-address-value">
                    Vehicle is not Assured
              </div>
            </div>
            }
          </div>
          }
        </div>
      );
    };

    const renderAccident = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            <div className="nexus-row nexus-p-2">
              <div className="nexus-col-xs-2 validated-address-value">
                  Number of Previous Accidents:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.accidentCount}{" "}
              </div>
            </div>
            <div className="nexus-row nexus-p-2">
              <div className="nexus-col-xs-2 validated-address-value">
                  Details of the Last Accident:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.lastReportedAccidentEvent}{" "}
              </div>
            </div>
            <div className="nexus-row nexus-p-2">
              <div className="nexus-col-xs-2 validated-address-value">
                  Last Accident Date:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {formateDate(vehicle.lastReportedAccidentDate)}
              </div>
            </div>
            <div className="nexus-row nexus-p-2">
              <div className="nexus-col-xs-2 validated-address-value">
                  Accident Location (City):
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.city}
              </div>
            </div>
            <div className="nexus-row nexus-p-2">
              <div className="nexus-col-xs-2 validated-address-value">
                  Accident Location (State):
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.state}
              </div>
            </div>
          </div>
          }
        </div>
      );
    };

    const premiumMileage = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            <div className="flex-row d-flex mt-2 mb-3">
              <div className="nexus-col-xs-2 validated-address-value">
                  VIN Number:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.vin}
              </div>
            </div>
            <div className="flex-row d-flex mt-2 mb-3">
              <div className="nexus-col-xs-2 validated-address-value">
                  Total number of history events:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.eventCount}
              </div>
            </div>
            <div className="flex-row d-flex mt-2 mb-3">
              <div className="nexus-col-xs-2 validated-address-value">
                  Last Odometer Reading:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.lastOdometer}
              </div>
            </div>
            <div className="flex-row d-flex mt-2 mb-3">
              <div className="nexus-col-xs-2 validated-address-value">
                  Last Odometer Date:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {formateDate(vehicle.lastOdometerDate)}
              </div>
              {/* <img src={DateRange} alt="date" className="calendar" /> */}
            </div>
            <div className="flex-row d-flex mt-2 mb-3">
              <div className="nexus-col-xs-2 validated-address-value">
                  Estimated Average Miles:
              </div>
              <div className="nexus-col-xs-2 validated-address-text">
                {vehicle.estimatedAverageMiles}
              </div>
            </div>
            <div className="flex-row d-flex mt-4 mb-3">
              <div>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
                <span className="nexus-pl-2 validated-address-value">
                    Odometer is not rollbacked
                </span>
              </div>
            </div>
            <div className="flex-row d-flex mt-4">
              <p className="vehicle-details-title">Odometer Reading Report</p>
            </div>
            {vehicle.reportedOdometerReadings
              .slice(-5)
              .reverse()
              .map((data: any, index: number) => <div key={index}>
                <div className="flex-row d-flex">
                  <p
                    className="vehicle-details-title"
                    style={{ marginLeft: "15px" }}
                  >
                        Report {index + 1}:
                  </p>
                </div>
                <div
                  className="row row-cols-2 ml-3 vehicle-details-descc"
                  style={{ marginTop: "-5px",
                    fontWeight: "normal" }}
                >
                  <div className="col-7 mt-1 validated-address-value">
                        Odometer Reading
                  </div>
                  <div className="col-5 mt-1 validated-address-text">
                        : {data.odometer}
                  </div>

                  <div className="col-7 mt-1 validated-address-value">
                        Odometer Reported Date
                  </div>
                  <div className="col-5 mt-1 validated-address-text">
                        : {data.odometer}
                  </div>

                  <div className="col-7 mt-1 validated-address-value">
                        Odometer Reported Type
                  </div>
                  <div className="col-5 mt-1 validated-address-text">
                        : {data.date}
                  </div>

                  <div className="col-7 mt-1 validated-address-value">
                        Odometer Reported City
                  </div>
                  <div className="col-5 mt-1 validated-address-text">
                        : {data.city}
                  </div>

                  <div className="col-7 mt-1 mb-3 validated-address-value">
                        Odometer Reported State
                  </div>
                  <div className="col-5 mt-1 validated-address-text">
                        : {data.state}
                  </div>
                </div>
              </div>)}
          </div>
          }
        </div>
      );
    };

    const renderOwnership = () => {
      const vehicle = this.state.responseData && this.state.responseData?.vehicle ? this.state.responseData.vehicle[0] : null;

      return (
        <div>
          {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
            <div className="nexus-row nexus-pb-2">
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-value">
                  VIN Number:
              </div>
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-text">
                {vehicle.vin}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-value">
                <div>History of Owner’s:</div>
              </div>
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-text">
                {vehicle.ownerCount}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-value">
                  Current Owner start Date:
              </div>
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-text">
                {formateDate(vehicle.currentOwnerStartDt)}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-value">
                  First Title Registered Date:
              </div>
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-text">
                {formateDate(vehicle.firstTitleRegDate)}
              </div>
            </div>
            <div className="nexus-row">
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-value">
                  Last Title Registered Date:
              </div>
              <div className="nexus-col-xs-4 nexus-col-md-4 validated-address-text">
                {formateDate(vehicle.lastTitleRegDate)}
              </div>
            </div>
          </div>
          }
        </div>
      );
    };

    const rightTab = () => <>
      {!setAlreadySeached && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "90%" }}
      >
        <div className="address-sample">Try with Sample VIN Number</div>
      </div>
      }
      {setAlreadySeached && <div className="nexus-ml-5 test-result-container">
        {!loader && <div className="nexus-mb-2 test-result">Test Result</div>
        }
        {this.state.searchFor === "problem" && rederProblem()}
        {this.state.searchFor === "condition-indicator" && rederConditionIndicator()}
        {this.state.searchFor === "brand" && renderBrand()}
        {this.state.searchFor === "mileage" && renderMileage()}
        {this.state.searchFor === "flood_risk" && renderFloodRisk()}
        {this.state.searchFor === "accidents" && renderAccident()}
        {this.state.searchFor === "accident_plus" && renderAccidentPlus()}
        {this.state.searchFor === "assured_indicator" && renderAssuredIndicator()}
        {this.state.searchFor === "premium_mileage" && premiumMileage()}
        {this.state.searchFor === "ownership-premium" && <OwnershipPremium data={responseData} />
        }
        {this.state.searchFor === "analytics-indicators" && <AnalyticsIndicators data={responseData} />
        }
        {this.state.searchFor === "accident-premium" && <AccidentPremium data={responseData} />
        }
        {this.state.searchFor === "ownership" && renderOwnership()}
        {this.state.searchFor === "buyback_protection" && <BuybackProtection data={responseData} />
        }

        {this.state.searchFor === "vin_specification" && <VinSpecification data={responseData} />
        }
        {this.state.searchFor === "auto_score" && <AutoScore data={responseData} />
        }

        {this.state.searchFor === "recall" && <Recall data={responseData} />
        }
        {this.state.searchFor === "financial_indicator" && <FinancialIndicator data={responseData} />
        }
        {this.state.searchFor === "recall_premium" && <RecallPremium data={responseData} />
        }
        {this.state.searchFor === "recall_plus" && <RecallPlus data={responseData} />
        }
        {this.state.searchFor === "Salvage" && <Salvage data={responseData} />
        }
      </div>
      }
    </>;

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Vehicle History Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default VehicleHistory;
