import React from "react";
import paths from "../../../../../utils/PathsUtils";
import { MIBannerImage, MIKeyAssets, MIStrategicValue } from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const MarketingInsightsDashboard = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​",
      desc: [
        "Clearly defined metrics centered around incremental uplift as the key indicator of success",
        "Daily monitoring of campaigns against targeted objectives",
        "Standardized control group selection and application",
        "Visibility of the sales funnel to identify key progression and leakage points between customer journey stages",
        "Campaign optimization prompts to inform decision making and inspire action",
        "Channel entry points through to contribution",
        "Tracking of unintended outcomes to illustrate hidden impacts a given campaign may be having on the business"
      ],
      img: MIKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Supports visibility and enables effective decision making, with reporting for all channels and campaign types consolidated in a centralized location",
        "Allows campaign owners to quickly diagnose and identify optimization opportunities",
        "Provides visibility and justification of marketing budget through to bottom line sales",
        "Enables the ability to identify retargeting opportunities in the sales journey across multiple products and channels",
        "Aligns to the CMO, CFO and COO functions"
      ],
      img: MIStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: MIBannerImage,
    heading: "Marketing Insights Dashboard",
    subHeading:
      "The Marketing Insights Dashboard is a targeted performance reporting solution that enables effective end-to-end reporting. The solution contains two key reporting modules; the Campaign Performance Dashboard and Multi-channel Dashboard. The Campaign Performance Dashboard consolidates campaign reporting, providing reliable measurements of performance aligned to an agreed central measurement framework.  The multi-channel dashboard provides a comprehensive view of the end-to-end sales pathway across multiple products and channels.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default MarketingInsightsDashboard;
