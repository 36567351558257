export const paths = Object.freeze({
  // Main paths NOT IN USE
  roadmap: "/roadmapcapabilities",
  roadmapRedirect: "/roadmap",
  demo: "/democapabilities",
  demoRedirect: "/demo",
  nexusXDZone: "/xdzone",

  // Demo internal paths NOT IN USE
  vehicleInfo: "/vehicleInfo",
  payment: "/paymentInfo2Vendors",
  infrastructure: "/infrastructure",
  updatePaymentVendors: "/paymentInfoUpdateVendor",
  driversInfo: "/driveresInfoScreen",
  addressValidation: "/addressValidation",
  success: "/success",

  // XDZone => Catalog internal paths
  catalog: "/platform-capabilities",
  catalogDetails: "/catalogdetails",
  catalogAddressValidation: "/address-validation",
  requirements: "/requirements",
  ecosystem: "/platform-ecosystem",
  ecosystemDetails: "/ecosystem-details",
  industrySolutions: "/industry-solutions",
  industrySolutionDetails: "/industry-solution-details",
  roadmaps: "/roadmapchart",
  home: "/",
  facialAnalysis: "/facial-analysis",
  carbonEmission: "/carbon-emission-calculator",
  documentScan: "/document-scan",
  commercialPrefil: "/commercial-property-risk",
  valuechain: "/valuechain",
  terminsurance: "/terminsurance",
  locationServices: "/location-services",
  vehicleInfoXdzone: "/vehicle-info",
  vehicleHistory: "/vehicle-history",
  postalCodeLookup: "/postalcode-lookup",
  emailandPhoneVerification: "/email-phone-number-validator",
  addressParsing: "/address-parsing",
  driverViolation: "/driver-violation",
  geocoding: "/geocoding",
  customerfeedback: "/customer-feedback",
  addresstypeahead: "/address-typeahead",
  hazardRisk: "/hazard-risk",
  wearable: "/wearable",
  notification: "/notification",
  humanHealth: "/human-health",
  vehicleSymbol: "/vehicle-symbol",
  vehicleTelematics: "/vehicle-telematics",
  documentGeneration: "/document-generator",
  nextbestaction: "/next-best-action",
  vehicleDamage: "/Vehicle-Damage",
  rating: "/rating",
  capabilitiesPayment: "/payment",
  creditReport: "/credit-report",
  crossSellOffering: "/cross-sell-offering",
  fraudDetection: "/fraud-detection",
  ClueReport: "/clue-report",
  DocumentSignature: "/document-signature",
  PersonalPropertyPefill: "/personal-property-prefill",
  Healthscore: "/health-score",
  UnderwrittingRules: "/underwritting-rules",
  verify: "/verify",

  // XDZone => Catalog => platform-accelerators
  platformaccelerators: "/platform-accelerators",
  waterline: "/waterline",
  replacementcost: "/replacement-cost-property",
  roofData: "/roof-data-property",
  locationsuite: "/location-suite",
  paperMailDelivery: "/paper-mail-delivery",

  // XDZone => Accelerators
  accelerators: "/platform-product-accelerators",
  autoInsurance: "/auto-insurance",
  businessOwnerInsurance: "/business-owners-insurance",
  cardTranscationDispute: "/card-transaction-disputes",
  groupBenefits: "/group-benefits",
  incomeProtevtionInsuarance: "/income-protection-insurance",
  lifeInsuarnce: "/life-Insurance",
  mortgage: "/mortgage",
  personalProertyInsurance: "/personal-property-insurance",
  petInsurance: "/pet-insurance",
  renterInsurance: "/renters-insurance",

  // XDZone => FS Solution
  FsSolutions: "/fs-solutions",
  autoQuoteBind: "/auto-quote-bind",
  carbonNeutralAauto: "/carbon-neutral-auto-coverage",
  directCutomerTermLife: "/direct-customer-term-life-origination",
  disablity: "/disability-claims-intake",
  intligentDispute: "/intelligent-disputes",
  merchantOffersInsght: "/merchants-offers-insights",
  morgageBrowseBook: "/mortgage-browse-bBook",
  petInsuranceQuoteBind: "/pet-insurance-quote-bind",
  singleViewCustomer: "/single-view-customer",
  smallBusinessInsurance: "/small-business-insurance",
  underwitingWorkBench: "/underwriting-workbench",
  nextGenClaim: "/nextGen-claims",
  claimEventHandler: "/claims-event-handler",
  associateWeb: "/associate-web",
  augmentedRelationshipManager: "/augmented-relationship-manager",
  interactionUtility: "/interaction-utility",
  producerLifeCycle: "/producer-lifecycle",
  actuarialStudio: "/actuarial-studio",
  underWritingWorkbench: "/under-writing-workbench",
  consentAndPreference: "/consent-and-preference",
  insuranceInsightsPlatform: "/insurance-insights-platform",
  wealthInteroperability: "/wealth-interoperability",
  marketingInsightsDashboard: "/marketing-insights",

  // XDZone => Support
  support: "/support",
  faq: "/support-faq",
  contact: "/support-contact",

  resources: "/resources",
  caseStudies: "/resources-case-studies",
  marketingMeterials: "/resources-go-to-market",
  technicalMessagingGuide: "/resources-technical-messaging-guide",

  businessEnablement: "/business-enablement",
  clientOnboarding: "/business-enablement-client-onboarding",
  sowNIPLanguage: "/business-enablement-sow-ip-language",
  commercialNPricing: "/business-enablement-commercials-pricing",
  CorePlatformPricing: "/core-platform-pricing",
  InsuranceProductsPricing: "/insurance-products-pricing",
  about: "/about",
  nexusComponent: "/about-nexus-components",
  technologyStack: "/about-technology-stack",
  ourTeam: "/about-our-team",

  activeCampaigns: "/fs-solutions-active-campaigns",
  buildingBlocks: "/platform-building-blocks",
  tools: "/platform-tools",
  webstarterKit: "/web-starter-kit",
  designSystem: "/nexus-design-system",
  nexusTesting: "/nextGen-testing-platform",
  accelaratorPlatform: "/microservices-accelerator-platform",
  notificationBinding: "/notification-binding",
  producerLookup: "/producer-lookup",
  weatherReport: "/weather-report",
  // Business Accelarator
  businessAccelarator: "/platform-business-accelerators",
  configurationStudio: "/configuration-studio"
});

export { paths as default };
