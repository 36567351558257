import React, { Component } from "react";
import "./ComingSoon.scss";
import { NexusInput } from "@nexus/react";

class ComingSoon extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      submitted: false
    };
  }

  componentDidMount() {
    this.setState({
      email: "",
      submitted: false
    });
  }

  notify() {
    if (this.state.email) {
      this.setState({ submitted: true });
    }
  }

  render() {
    const { email, submitted } = this.state;

    return (
      <div className="nexus-container coming-soon nexus-flex-col align-items-center nexus-center-lg nexus-center-md">
        <div className="nexus-row">
          <div className="nexus-col-lg-12 nexus-col-sm-4 nexus-col-md-8 ">
            <div className="nexus-text nexus-mb-4">NEXUS</div>
            <div className="coming-soon-title">
              {submitted ? "Thank you for your Interest!" : "Coming Soon!"}
            </div>
            {!submitted && <>
              <div className="coming-soon-desc nexus-my-4">
                  Nexus Team is working on this at the moment! Enter your email
                  id to find out when it’s ready.
              </div>
              <NexusInput
                placeholder="Enter your Email Id here..."
                value={email}
                onInput={(event: any) => this.setState({ email: event.target.value })
                }
              />
              <button
                type="button"
                className="nexus-btn nexus-mt-2"
                onClick={() => this.notify()}
              >
                  Notify Me!
              </button>
            </>
            }
          </div>
        </div>
      </div>
    );
  }
}
export default ComingSoon;
