import React, { Component } from "react";
import {
  NexusInput,
  NexusCard,
  NexusCardBody,
  NexusAccordion,
  NexusAccordionTrigger,
  NexusAccordionContent
} from "@nexus/react";
import "../BusinessEnablement.scss";
import {
  packageType,
  InsuranceProduct,
  accordionData,
  accordionData2
} from "../Assests/const";
import { StarterKit } from "../Assests/image/index";
import { paths } from "../../../../../utils/PathsUtils";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "business-enablement-commercials-pricing",
  listName: "business-enablement-commercials-pricing"
};

const { CorePlatformPricing, InsuranceProductsPricing } = paths;
class CommercialPricing extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      submitted: false,
      open: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    this.setState({
      email: "",
      submitted: false
    });
  }

  openAccodian(id: any) {
    if (this.state.open === id) {
      this.setState({ open: null });
    }
    else {
      this.setState({ open: id });
    }
  }

  notify() {
    if (this.state.email) {
      this.setState({ submitted: true });
    }
  }

  nextPage(path: string) {
    this.props.history.push(path);
  }

  applyStyle(heading: string) {
    if (heading === "Templates") {
      return { width: "47px" };
    }
    else if (heading === "Industry Solutions") {
      return { width: "74" };
    }
  }

  render() {
    const { email, submitted } = this.state;

    return (
      <>
        <div className="nexus-container px-5 Business-core-platform">
          <div></div>
          <div className="nexus-row nexus-px-6">
            <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-mt-6 textOne">
              {" "}
              Welcome to Nexus
            </div>
            <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-mt-2 corePlatform">
              {" "}
              Core Platform
            </div>
            <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-mt-1 corePlatform_Description">
              {" "}
              Nexus is a tech-forward approach to helping the world’s leading
              firms to re-imagine, create, and run better ways of delivering
              value in the digital economy. Not just to keep pace, but to be
              first. To have the intelligence to lead in a world that is moving
              at light speed. And to keep that edge in an ever-evolving future,
              with built-in flexibility and scale.
            </div>
            <div className="nexus-col-xs-4 nexus-mt-7 plateforPackage">
              {" "}
              Core Platform Packages
            </div>
          </div>
          <div className="nexus-row nexus-px-5 nexus-mt-5">
            {packageType.map((packItem: any, index: any) => <div
              key={packItem.tier}
              className="nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-4 nexus-mb-2"
              style={{}}
            >
              <NexusCard className="package">
                <NexusCardBody>
                  <p className="tier">{packItem.tier}</p>
                  <p className="packageType">{packItem.tierType}</p>
                  <p className="packageDescrition">{packItem.decription1} </p>
                  <p className="packageDescrition">{packItem.decription2}</p>
                  <p className="packageDescrition">{packItem.decription3}</p>
                </NexusCardBody>
              </NexusCard>
              <img
                src={packItem.tierColor}
                alt="Alt Text"
                style={{
                  marginTop: "-40px",
                  width: "-webkit-fill-available"
                }}
              />
            </div>)}
          </div>
          <div className="nexus-row nexus-center-xs nexus-my-3">
            <button
              className="nexus-btn nexus-btn-large nexus-mb-4"
              data-testid="tier-Btn"
              style={{ backgroundColor: "#FFFFFF" }}
              onClick={() => this.nextPage(CorePlatformPricing)}
            >
              Show Pricing Details
            </button>
          </div>
        </div>
        {/* BELOW CODE FOR INSURANCE PRODUCT */}
        <div className="nexus-container px-5 InsuranceProduct">
          <div className="nexus-row nexus-center-sm nexus-mt-4">
            <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 titleText">
              Insurance Products{" "}
            </div>
            <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-px-5 nexus-my-3 titleDes">
              Leverage a versatile, cloudready framework, with readyto- use
              building blocks and real-time intelligence. Launch
              customer-centric services globally, reliably, and faster than ever
              before.{" "}
            </div>
          </div>
          <div className="nexus-row nexus-mt-3 nexus-mb-5 nexus-px-4">
            <div className="nexus-col-lg-3 nexus-col-md-4 nexus-col-xs-4">
              <div
                className="nexus-row nexus-mx-1 nexus-py-2 nexus-my-1"
                style={{
                  backgroundColor: "#24408F",
                  textAlign: "center",
                  overflow: "hidden"
                }}
              >
                <div className="ribbon">
                  <div className="defaultKit">DEFAULT KIT</div>
                </div>
                <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-mt-4 nexus-pt-2">
                  <img src={StarterKit} className="ghar" alt="Home" />
                </div>
                <div
                  className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 gharTiltle nexus-mt-3"
                  style={{ color: "#FFFFFF" }}
                >
                  Engagement Starter Kit
                </div>
                <div
                  className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 cardDes"
                  style={{ color: "#FFFFFF" }}
                >
                  <div
                    className="nexus-my-2"
                    style={{
                      height: "408px",
                      overflow: "hidden"
                    }}
                  >
                    Enable your development team to rapidly deliver cloud native
                    solutions to your clients. The Kit includes everything your
                    development team needs to quickly begin writing application
                    logic, and covers key areas of the development value chain
                    such as the setup of Azure infrastructure, automated
                    instantiation of boilerplate code, automated testing, and
                    design system. Teams will also have unlimited access to our
                    Nexus Connect catalogue of pre-built RESTful third party and
                    domain APIs.
                  </div>
                  <div
                    className="price nexus-my-4"
                    style={{ color: "#FFFFFF" }}
                  >
                    {" "}
                    $100K
                  </div>
                </div>
              </div>
            </div>
            {InsuranceProduct.map((item: any, index: number) => <div
              key={index}
              className="nexus-col-lg-3 nexus-col-md-4 nexus-col-xs-4"
            >
              <div
                className="nexus-row nexus-mx-1 nexus-py-2 nexus-my-1"
                style={{
                  backgroundColor: "#FFFFFF",
                  textAlign: "center"
                }}
              >
                <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 Heading">
                  <img src={item.titleLogo} alt="Star" /> {item.titile}
                </div>
                <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 nexus-pl-2 nexus-my-3">
                  <img
                    src={item.logo}
                    style={this.applyStyle(item.heading)}
                    className="ghar"
                    alt="Home"
                  />
                </div>
                <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 gharTiltle">
                  {item.heading}
                </div>
                <div className="nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 cardDes">
                  {item.description.map((para: string) => <>
                    <div
                      className="nexus-my-2"
                      style={{
                        height: "408px",
                        overflow: "hidden"
                      }}
                    >
                      {" "}
                      {para}
                    </div>
                  </>)}
                  <div className="price nexus-my-4"> {item.price}</div>
                </div>
              </div>
            </div>)}
          </div>
          <div className="nexus-row nexus-center-xs nexus-mb-7">
            <button
              className="nexus-btn nexus-btn-large"
              data-testid="Insurance-Btn"
              style={{ backgroundColor: "#2D2E2F" }}
              onClick={() => this.nextPage(InsuranceProductsPricing)}
            >
              Show Pricing Details
            </button>
          </div>
        </div>
        {/* BELOW CODE 4 COMING SOON PAGE */}
        <div className="nexus-container Business-coming-soon nexus-flex-col">
          <div className="nexus-row">
            <div className="nexus-col-lg-12 nexus-col-xs-4 nexus-col-md-8">
              <div className="nexus-text nexus-mb-4">
                Banking and Wealth Products
              </div>
              <div className="coming-soon-title">
                {submitted ? "Thank you for your Interest!" : "Coming Soon!"}
              </div>
              {!submitted && <>
                <div className="coming-soon-desc nexus-m-4">
                    Nexus Team is working on this at the moment! Enter your
                    email id to find out when it’s ready.
                </div>
                <NexusInput
                  placeholder="Enter your Email Id here..."
                  data-testid="email-input"
                  value={email}
                  onInput={(event: any) => this.setState({ email: event.target.value })
                  }
                />
                <button
                  type="button"
                  className="nexus-btn nexus-mt-2 nexus-mb-8"
                  data-testid="comingSoon-Btn"
                  onClick={() => this.notify()}
                >
                    Notify Me!
                </button>
              </>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CommercialPricing;
