import { roofAgeByPrtsFields } from "./inputList";
import React from "react";
import Step from "../Step";
import DisabledInput from "../DisabledInput";

const RoofAgeByParts = () => <>
  <Step stepNumber={"Step 02"} header={"Enter risk address"}></Step>
  <DisabledInput InputArray={roofAgeByPrtsFields} />
</>;
export default RoofAgeByParts;
