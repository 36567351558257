import React from "react";
import PropTypes from "prop-types";

const VinSpecification = ({ data }: { data: any }) => {
  const vehicle = data && data?.vehicle ? data.vehicle[0] : null;

  return (
    <div>
      {vehicle && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
        <div className="vehicle-details-title mt-4">VIN Specification</div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              VIN
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.vin}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Year
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.year}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Make
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.make}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Model
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.model}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Class
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.class}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Country
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.country}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Body
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.body}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Engine
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.engine}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-3">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "141px" }}
          >
              Vehicle History Events
          </div>
          <div className="nexus-pl-3 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.eventCount}
          </div>
        </div>
      </div>
      }
    </div>
  );
};
VinSpecification.defaultProps = {
  data: null
};
// VinSpecification.propTypes = {
//   data: PropTypes.objectOf({
//     vehicle: PropTypes.object
//   })
// };
export default VinSpecification;
