import React, { Component } from "react";
interface ArrayType {
  rName: string;
  rValue: any;
}
interface Props {}
class ResponseView extends Component<any, Props> {
  render() {
    const { tittle, loop } = this.props;

    return (
      <>
        <div className="nexus-mb-1 nexus-mt-2 validated-address-value">
          <b>{tittle}</b>
        </div>
        {loop.map((_riskVal: ArrayType) => <div
          className="nexus-row"
          key={_riskVal.rName}
          style={{ marginBottom: "4px" }}
        >
          <div className="result-label nexus-col-lg-6 nexus-col-md-6 nexus-col-xs-2">
            {_riskVal.rName}
          </div>
          <div className="result-label">:</div>
          <div className="result-label nexus-col-lg-5 nexus-col-md-2 nexus-col-xs-2">
            {_riskVal.rValue}
          </div>
        </div>)}{" "}
      </>
    );
  }
}

export default ResponseView;
