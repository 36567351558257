/* eslint-disable no-negated-condition */
import { NexusProgressBar } from "@nexus/react";
import React, { useEffect } from "react";
import "../Catalog.scss";
import actionIcAccountCircle24px from "@nexus/core/dist/assets/icons/media/ic_account_circle_24px.svg";

import { SampleFace } from "../../../../../assets/images";
import Service from "../../../service/Service";
import sampleImage from "../sampleImg";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/facial-analysis",
  listName: "platform-capabilities/facial-analysis",
};

const service = new Service();
const FacialAnalysis = () => {
  const [analysisCompleted, setAnalysisCompleted] = React.useState(false);
  const [analysedData, setAnalysedData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState(false);
  const card = {
    title: "Facial Analysis",
    description:
      "Nexus Facial Analysis Connector provides carriers with the ability to combine a selfie with facial analytics, biodemographic information and life event data to determine the smoker status, life expectancy and estimate current age of a customer to support the underwriting process.",
    subTitle: "Lapetus Solutions",
    icon: actionIcAccountCircle24px,
    developerDoc: "facial-analysis",
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const analysisFix = () => {
    if (!analysisCompleted) {
      const params = sampleImage;
      setLoader(true);
      service
        .analyseImage(params)
        .then((response) => response.json())
        .then((data) => {
          setAnalysisCompleted(true);
          setAnalysedData(data.data);
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setAnalysisCompleted(false);
    }
  };

  const leftTab = () => (
    <>
      <div className="nexus-row">
        <div className="form-head nexus-col-sm-4  nexus-col-md-2 nexus-col-lg-2 step-0">
          Step 01
        </div>
        <div className="form-head nexus-col-sm-4  nexus-col-md-6 nexus-col-lg-7">
          {!analysisCompleted && (
            <div className="form-head">
              Click the button below to test the facial analysis integration
              with Emily John’s picture
            </div>
          )}
          <div className="facial-container">
            <img
              src={SampleFace}
              alt="sampleface"
              className="sample-face"
              id="imageid"
            />
            <button
              data-testid="confirm-button"
              type="button"
              className="confirm-button"
              onClick={() => analysisFix()}
            >
              {analysisCompleted ? "Reset" : "Analyze"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!analysisCompleted && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">Try with Sample Picture</div>
        </div>
      )}
      {analysisCompleted && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-mb-2 test-result">Test Result</div>
          <div className="test-result-box nexus-p-4">
            <div className="face-right-title">Emily John’s analysis:</div>
            <div className="analysis-rows">
              <div className="analysis-row">
                <div className="face-analysis-title">Gender</div>
                <div className="face-analysis-content">
                  {analysedData?.gender?.details?.value}
                </div>
              </div>
              <div className="analysis-row">
                <div className="face-analysis-title">Body Mass Index</div>
                <div className="face-analysis-content">{`${analysedData?.bmi?.details?.value} %`}</div>
              </div>
              <div className="analysis-row">
                <div className="face-analysis-title">Estimated Age</div>
                <div className="face-analysis-content">
                  {analysedData?.chronage?.details?.value}
                </div>
              </div>
              <div className="analysis-row">
                <div className="face-analysis-title">Smoker</div>
                <div className="face-analysis-content">
                  {analysedData?.smoker?.details?.value === "NonSmoker"
                    ? "No"
                    : "Yes"}{" "}
                  ({`${analysedData?.smoker?.details?.confidence * 100} %`})
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container  padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Facial Analysis Tool"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default FacialAnalysis;
