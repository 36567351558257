import React from "react";
import { Route, Switch } from "react-router-dom";
import Details from "./Details/Details";
import Tools from "./Tools";
import { paths } from "../../../../utils/PathsUtils";
import ConfigurationStudio from "./AcceleratorDetails/ConfigurationStudio";
import NextGenTestingPlatform from "./AcceleratorDetails/NextGenTestingPlatform";
import NexusDesignSystem from "./AcceleratorDetails/NexusDesignSystem";
import MicroServiceAccelerator from "./AcceleratorDetails/MicroServiceAccelerator";
import WebStarterKit from "./AcceleratorDetails/WebStarterKit";

const {
  tools,
  webstarterKit,
  designSystem,
  nexusTesting,
  accelaratorPlatform,
  configurationStudio
} = paths;

const NexusXDZoneRoutes = () => <Switch>
  <>
    <Route exact path={`${tools}`} component={Tools} />
    <Route path={`${tools}${webstarterKit}`} component={WebStarterKit} />
    <Route path={`${tools}${designSystem}`} component={NexusDesignSystem} />
    <Route
      path={`${tools}${nexusTesting}`}
      component={NextGenTestingPlatform}
    />
    <Route
      path={`${tools}${accelaratorPlatform}`}
      component={MicroServiceAccelerator}
    />
    <Route
      path={`${tools}${configurationStudio}`}
      component={ConfigurationStudio}
    />
  </>
</Switch>;
export default NexusXDZoneRoutes;
