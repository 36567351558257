import React from "react";
import "../../Catalog.scss";
import { NexusRadio } from "@nexus/react";
import Service from "../../../../service/Service";
import { State } from "../Model/State";

const service = new Service();
const stateValueHazardRiskByFlood = {
  imputValuesForAddLocation: {
    address: "115 E 900 N, Richfield, UT 84701",
    logitude: "-81.9571",
    lattitude: "35.0118",
  },
  addressbodyRequest: {
    optionalRiskAttribute: {
      floodAttribute: {
        includeZoneDesc: "Y",
        includeGeometry: "N",
      },
    },
    risksList: ["flood"],
    address: "8503 E COUNTY ROAD 600 S, CAMBRIDGE CITY, IN  47327-9604",
  },
  locationbodyRequest: {
    latitude: 35.011,
    longitude: -81.9571,
    optionalRiskAttribute: {
      floodAttribute: {
        includeZoneDesc: "Y",
        includeGeometry: "N",
      },
    },
    risksList: ["flood"],
  },
  buttonValue: "Try with Sample Data",
  forward: true,
  // loader: false,
  // error: null,
};
class HazardRiskByFlood extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = stateValueHazardRiskByFlood;
  }

  componentDidMount() {
    service.getToken();
  }

  setButtonText(text: string) {
    this.setState({ buttonValue: text });
  }

  /* API Call for Address & Location */
  apiCallHazardRiskByFlood() {
    if (this.state.buttonValue === "Reset") {
      this.setButtonText("Try with Sample Data");
      this.sendDataToParentCompnent(null);
    } else {
      this.props.enableLoader();
      this.setButtonText("Reset");
      const endpont = this.state.forward ? "address" : "location";
      const requestParam = this.state.forward
        ? this.state.addressbodyRequest
        : this.state.locationbodyRequest;
      service
        .getEarthquakeHazardRisk(requestParam, endpont)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            this.sendDataToParentCompnent(data.data);
            this.props.disableLoader();
          }
          if (data.error) {
            this.props.disableLoader();
          }
          // setTimeout(() => {}, 100);
        })
        .catch();
    }
  }

  /* Fun for Address & Location select */
  changeRirectionOption(option: boolean) {
    console.log("---------------------------------");

    console.log("option", option);
    if (this.state.forward !== option) {
      this.setButtonText("Try with Sample Data");
      this.sendDataToParentCompnent(null);
    }
    this.setState({ forward: option });
  }

  sendDataToParentCompnent(data: any) {
    this.props.apiResFromFire(data);
  }

  /* logic to display Input feilds for Address & Location */
  leftView() {
    if (this.state.forward) {
      return this.addressInputFiles();
    }

    return this.locationInputFiles();
  }

  buttonSubmit() {
    return (
      <div className="nexus-row" style={{ marginTop: "137px" }}>
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="nexus-col-md-6 nexus-col-sm-4 nexus-col-lg-10">
          <button
            data-testid="tryFlood"
            type="button"
            className="confirm-button"
            onClick={() => this.apiCallHazardRiskByFlood()}
          >
            {this.state.buttonValue}
          </button>
        </div>
      </div>
    );
  }

  /* Select option for Address & Location */
  directionOptionClick() {
    return (
      <>
        <div className="nexus-row">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2">
            Step 02
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
            <div className="form-head font-bold">Search By</div>
          </div>
        </div>
        <div className="nexus-row nexus-mt-1">
          <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mt-2">
              <NexusRadio
                name="option"
                data-testid="addressTest"
                id="forward"
                style={{ marginRight: "0px" }}
                checked={this.state.forward}
                disabled={this.state.buttonValue === "Reset"}
                onInput={() => this.changeRirectionOption(true)}
              >
                Address
              </NexusRadio>
              <NexusRadio
                className="ml-5"
                name="option"
                id="reverse"
                data-testid="locationTest"
                checked={!this.state.forward}
                onInput={() => this.changeRirectionOption(false)}
                disabled={this.state.buttonValue === "Reset"}
              >
                Location
              </NexusRadio>
            </div>
          </div>
        </div>
      </>
    );
  }

  addressInputFiles() {
    const { address } = this.state.imputValuesForAddLocation;

    return (
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mt-2">
            <div className="italicText nexus-mb-1">Address Text</div>
            <input
              type="text"
              data-testid="addressText"
              className="line-input-box"
              value={
                this.state.buttonValue === "Try with Sample Data"
                  ? `e.g. ${address}`
                  : "121 W Main St, Durant, OK 74701"
              }
              disabled
            />
          </div>
        </div>
      </div>
    );
  }

  locationInputFiles() {
    const { lattitude, logitude } = this.state.imputValuesForAddLocation;

    return (
      <>
        <div className="nexus-row nexus-mt-1">
          <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">Lattitude</div>
              <input
                type="text"
                data-testid="lattitudeTest"
                className="line-input-box"
                value={
                  this.state.buttonValue === "Try with Sample Data"
                    ? `e.g. ${lattitude}`
                    : "41.491036237051865"
                }
                disabled
              />
            </div>
            <div className="nexus-mt-2">
              <div className="italicText nexus-mb-1">Longitude</div>
              <input
                type="text"
                data-testid="longitudeTest"
                className="line-input-box"
                value={
                  this.state.buttonValue === "Try with Sample Data"
                    ? `e.g. ${logitude}`
                    : "-120.54640770212987"
                }
                disabled
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="step2">
        {this.directionOptionClick()}
        <div className="address-form">{this.leftView()}</div>
        {this.buttonSubmit()}
      </div>
    );
  }
}

export default HazardRiskByFlood;
