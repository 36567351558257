const roofAgeByPrtsFields = [
  {
    labelTestid: "addressLine1",
    labelTittle: "Address Line 1",
    inputTestid: "addressLine1-input",
    value: "42 N French Broad Ave",
    isDisabled: true,
    placeholder: "42 N French Broad Ave",
  },
  {
    labelTestid: "addressLine2",
    labelTittle: "Address Line 2",
    inputTestid: "addressLine2-input",
    value: "Washington DC",
    isDisabled: true,
    placeholder: "Washington DC",
  },
  {
    labelTestid: "city",
    labelTittle: "City",
    inputTestid: "city-input",
    value: "Asheville",
    isDisabled: true,
    placeholder: "Asheville",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "NC",
    isDisabled: true,
    placeholder: "NC",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "28801",
    isDisabled: true,
    placeholder: "28801",
  },
];

const roofAgeByLine = [
  {
    labelTestid: "addressLine",
    labelTittle: "Address Line",
    inputTestid: "addressLine-input",
    value: "42 N French Broad Ave, Washington DC, Asheville, NC, 28801",
    isDisabled: true,
    placeholder: "42 N French Broad Ave, Washington DC, Asheville, NC, 28801",
  },
];
const roofTopRiskFieldsStep2 = [
  {
    labelTestid: "subjectfirstname",
    labelTittle: "Subject First Name",
    inputTestid: "subjectfirstname-input",
    value: "Bryan",
    isDisabled: true,
    placeholder: "Bryan",
  },
  {
    labelTestid: "subjectlastname",
    labelTittle: "Subject Last Name",
    inputTestid: "subjectlastname-input",
    value: "Bingham",
    isDisabled: true,
    placeholder: "Bingham",
  },
];

const roofTopRiskFieldsStep3 = [
  {
    labelTestid: "addressLine1",
    labelTittle: "Address Line 1",
    inputTestid: "addressLine1-input",
    value: "6611",
    isDisabled: true,
    placeholder: "6611",
  },
  {
    labelTestid: "addressLine2",
    labelTittle: "Address Line 2",
    inputTestid: "addressLine2-input",
    value: "Stella PL",
    isDisabled: true,
    placeholder: "Stella PL",
  },
  {
    labelTestid: "city",
    labelTittle: "City",
    inputTestid: "city-input",
    value: "Anchorage",
    isDisabled: true,
    placeholder: "Anchorage",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "AK",
    isDisabled: true,
    placeholder: "AK",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "99507",
    isDisabled: true,
    placeholder: "99507",
  },
];
const apiRequestParams = {
  address1: "42 N FRENCH BROAD AVE",
  address2: "Washington, DC",
  city: "ASHEVILLE",
  state: "NC",
  zip: "28801",
  submissionId: "LTT-011",
};
const reqBodyForRiskData = {
  requesterInformation: {
    accountNumber: "587431TST",
    billingCode: "INACT",
  },
  transactionDetails: {
    ruleplanId: 5200,
    maxWaitSeconds: 1000,
    inquiryVersion: 1,
    quoteBack: [
      {
        name: "QB",
        value: "RoofTest",
      },
      {
        name: "QB1",
        value: "Rooftop Risk",
      },
    ],
    sidexInfo: {
      sourceNode: "IP1074900",
    },
  },
  searchBy: {
    subjects: [
      {
        firstName: "BRYAN",
        lastName: "BINGHAM",
        subjectType: "primary",
        qualifiedAddressRef: "A1",
        subjectId: "S1",
      },
    ],
    qualifiedAddress: {
      qualifiedAddrId: "A1",
      streetNumber: 6611,
      streetName: "STELLA PL",
      city: "ANCHORAGE",
      state: "AK",
      zip5: 99507,
      classification: "risk",
    },
  },
  products: {
    inquiryRooftop: {
      searchBy: {
        address: "A1",
        ref: "A1",
      },
      policyNumber: 124589,
      parameters: {
        parameter: "2018-08-08",
        name: "retro_date",
      },
    },
  },
  submissionId: "SubId",
};
const reqBodyForRoofDataByLine = {
  address: "42 N FRENCH BROAD AVE, Washington, DC, ASHEVILLE, NC, 28801",
  submissionId: "LTT-011",
};
export {
  roofAgeByLine,
  roofAgeByPrtsFields,
  roofTopRiskFieldsStep2,
  roofTopRiskFieldsStep3,
  apiRequestParams,
  reqBodyForRoofDataByLine,
  reqBodyForRiskData,
};
