import React from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  CAPKeyAssets,
  CAPStrategicValue,
  CAPBannerImage
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const ConsentAndPreference = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Flexible and dynamic consent master that unifies end-user privacy choices against a single identity and captures fine-grained preferences",
        "Modular solution that is easily callable and integrated with other systems including existing CMPs the organizations might already use",
        "Configuration-driven rules engine eliminates the need to rebuild IT infrastructure every time a new privacy law comes into effect",
        "Consumer profile enrichment (beyond simple contact info) given the sharing of first-party data across multiple channels, business units, products and touchpoints"
      ],
      img: CAPKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Increases consumers’ willingness to volunteer their preferences with the company by providing transparency across all consent collection points and engaging the consumer in the narrative around these practices",
        "Enables personalization of experiences and specific marketing campaigns / use cases with a direct impact on ROI",
        "Drives consumer lifetime value and brand equity in the long term",
        "Reduces risk and penalties surrounding regulatory non-compliance by reducing the time and effort needed to implement policy updates"
      ],
      img: CAPStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: CAPBannerImage,
    heading: "Consent & Preference",
    subHeading:
      "The Consent and Preference solution provides a mechanism to apply and honor unified consumer-provided privacy choices for data-driven marketing campaigns, personalized customer experiences, and leverages automated decision making capabilities and data-sharing with other entities for cross/up-sell purposes.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default ConsentAndPreference;
