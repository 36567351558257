import React, { Component } from "react";
import Service from "../../../../service/Service";
import { arapiReqParams } from "../inputList";
import ResponseView from "./ResponseView";

interface ArrayType {
  rName: string;
  rValue: any;
}
interface State {
  riskProfile: ArrayType[];
  riskHistory: ArrayType[];
  riskProperty: ArrayType[];
  Isresponse: boolean;
}
interface Props {
  disableLoader: (value: boolean) => void;
}
const service = new Service();
class AirHurricaneHazard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      riskProfile: [],
      riskHistory: [],
      riskProperty: [],
      Isresponse: false
    };
  }

  componentDidMount() {
    service
      .locationSuite("airhurricanehazard", arapiReqParams)
      .then((_res: any) => _res.json())
      .then((data: any) => {
        this.props.disableLoader(false);
        this.extractRiskProfile(data.data.hurricaneProfile.risk);
        this.extractHistory(data.data.hurricaneProfile.historyEvents[0]);
        this.extractProperty(data.data.hurricaneProfile);
        this.setState({ Isresponse: true });
      })
      .catch(() => {
        this.props.disableLoader(false);
      });
  }

  extractRiskProfile(risk: any) {
    const _riskData = [
      {
        rName: "Risk outside 100 Yr plan",
        rValue: risk.risk100YR
      },
      {
        rName: "Risk outside 250 Yr plan",
        rValue: risk.risk250YR
      },
      {
        rName: "Annual Hurricane Risk",
        rValue: risk.riskAnnual
      },
      {
        rName: "Relative risk for the county",
        rValue: risk.relRiskCounty
      },
      {
        rName: "Relative risk for the state",
        rValue: risk.relRiskState
      }
    ];
    this.setState({ riskProfile: _riskData });
  }

  extractHistory(history: any) {
    const _history = [
      {
        rName: "Hurricane Name",
        rValue: history.name
      },
      {
        rName: "Occurrence Date",
        rValue: history.date
      },
      {
        rName: "Hurricane distance from the Property",
        rValue: history.distance
      },
      {
        rName: "SSLF Intense Category",
        rValue: history.sslfIntensity
      },
      {
        rName: "SS Intense Category",
        rValue: history.ssIntensity
      }
    ];
    this.setState({ riskHistory: _history });
  }

  extractProperty(property: any) {
    const _property = [
      {
        rName: "Costal County",
        rValue: property.coastalCounty
      },
      {
        rName: "Storm Surge Region",
        rValue: property.stormSurge
      },
      {
        rName: "Distance to Coast",
        rValue: property.distanceToCoast
      },
      {
        rName: "Surface Elevation",
        rValue: property.elevation
      },
      {
        rName: "Surface Terrain Type",
        rValue: property.surfaceTerrain
      },
      {
        rName: "Speed of the Wind Recorded",
        rValue: property.zoneWindSpeed
      }
    ];
    this.setState({ riskProperty: _property });
  }

  render() {
    const { Isresponse, riskProfile, riskHistory, riskProperty } = this.state;

    return (
      <div
        className="nexus-ml-5 test-result-container"
        style={{ display: Isresponse === true ? "" : "none" }}
      >
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-2 nexus-pb-4"
          style={{ width: "90%" }}
        >
          {riskProfile.length > 0 && <ResponseView tittle="Hurricane Risk Profile" loop={riskProfile} />
          }
          {riskHistory.length > 0 && <ResponseView tittle="Events History" loop={riskHistory} />
          }
          {riskProperty.length > 0 && <ResponseView tittle="Property Profile" loop={riskProperty} />
          }
        </div>
      </div>
    );
  }
}

export default AirHurricaneHazard;
