import React, { useEffect, useState } from "react";
import "../CatalogStyle.scss";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import { NexusProgressBar } from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/personal-property-prefill",
  listName: "platform-capabilities/personal-property-prefill"
};

const DUMMAY_INPUTFILED = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: ""
};
const PersonalPropertyPrefill = () => {
  const service = new Service();
  const [loader,
    setLoader] = useState(false);
  const [inputFieldValue,
    setInputFieldValue] = useState<any>(DUMMAY_INPUTFILED);
  const [personalResult,
    setPersonalResult] = useState([]);
  const card = {
    title: "Personal Property Prefill",
    description:
      "Enables carriers to retrieve the key property attributes, including address details and parcel boundaries, for a given address.",
    subTitle: "Cape Analytics, Precisely",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "personal-property-prefill"
  };

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);

  const reset = () => {
    setInputFieldValue(DUMMAY_INPUTFILED);
    setPersonalResult([]);
  };

  const propertyPrefill = () => {
    setLoader(true);
    const fieldvalue = {
      address1: "1805 33rd Street",
      address2: "Unit 1",
      city: "Boulder",
      state: "CO",
      zipcode: "80301"
    };
    setInputFieldValue(fieldvalue);
    const params = {
      addresses: [
        {
          objectId: 1,
          mainAddressLine: fieldvalue.address1,
          stateProvince: fieldvalue.state,
          city: fieldvalue.city,
          locality: "1",
          postCode: fieldvalue.zipcode,
          postCodeExt: "2000",
          country: "US",
          addressNumber: "1",
          streetName: "1",
          unitType: "1",
          unitValue: "1"
        }
      ]
    };
    service
      .getPropertyPrefill(params)
      .then((response) => response.json())
      .then((data) => {
        setPersonalResult(data.data.propertyAttributes[0].individualAttributes);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const buttonClickHandler = () => {
    if (inputFieldValue.address1 === "") {
      propertyPrefill();
    }
    else {
      reset();
    }
  };

  const leftTab = () => <>
    <div className="nexus-row">
      <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
          Step 01
      </div>
      <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
        <div className="form-head">Enter Address</div>
      </div>
    </div>
    <div className="nexus-row">
      <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
      <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6">
        <div className="nexus-mt-2 nexus-mb-3">
          <div className="italicText mb-2">Address 1</div>
          <div className="input-container">
            <input
              type="text"
              value={inputFieldValue?.address1}
              data-testid="address-line1"
              placeholder="e.g. 1805 33rd street"
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="nexus-mt-2 nexus-mb-3">
          <div className="italicText mb-2">Address 2</div>
          <div className="input-container">
            <input
              type="text"
              value={inputFieldValue?.address2}
              data-testid="address-line2"
              placeholder="e.g. Flat #2"
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="nexus-mt-2 nexus-mb-3">
          <div className="italicText mb-2">City</div>
          <div className="input-container">
            <input
              type="text"
              value={inputFieldValue?.city}
              data-testid="address-city"
              placeholder="e.g. Boulder"
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="nexus-mt-2 nexus-mb-3">
          <div className="italicText mb-2">State</div>
          <div className="input-container">
            <input
              type="text"
              value={inputFieldValue?.state}
              data-testid="address-state"
              placeholder="e.g. CO"
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="nexus-mt-2 nexus-mb-3">
          <div className="italicText mb-2">Zipcode</div>
          <div className="input-container">
            <input
              type="text"
              value={inputFieldValue?.zipcode}
              data-testid="address-zipcode"
              placeholder="e.g. 80301"
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className="confirm-button"
            onClick={() => buttonClickHandler()}
            data-testid="validatePersonal"
          >
            {inputFieldValue?.address1 === "" ? "Try with Sample Data" : "Reset"}
          </button>
        </div>
      </div>
    </div>
  </>;
  const rightTab = () => <>
    {personalResult.length === 0 && <div
      className="nexus-row align-items-center nexus-ml-5"
      style={{ height: "100%" }}
    >
      <div className="address-sample">
            Try Extracting Address with Sample Data
      </div>
    </div>
    }
    {personalResult.length > 0 && <>
      <div className="address-head nexus-ml-5 nexus-mb-3">Test Result</div>
      <div
        className="accident-container nexus-ml-5"
        style={{ width: "80%" }}
      >
        <div className="nexus-flex-col">
          <div className="vehicle-details-title nexus-mt-1">
                Property Summary
          </div>
          {personalResult.map((item: any, index: any) => <div
            key={index}
            className="vehicle-details-descc nexus-p-2 nexus-ml-2"
          >
            <div className="nexus-row nexus-pb-2">
              <div className="validated-address-value">
                      Property Features:
              </div>
              <div className="validated-address-text">
                {item.propertyFeatures}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div className="validated-address-value">Name:</div>
              <div className="validated-address-text">{item.name}</div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div className="validated-address-value">Description:</div>
              <div className="validated-address-text">
                {item.description}
              </div>
            </div>
            <div className="nexus-row nexus-pb-2">
              <div className="validated-address-value">Value:</div>
              <div className="validated-address-text">{item.value}</div>
            </div>
            <div className="nexus-row">
              <img
                src={item.imageInformation}
                className="image-preview"
              ></img>
            </div>
          </div>)}
        </div>
      </div>
    </>
    }
  </>;

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && <div className="loader-wrapper">
        <NexusProgressBar circle indeterminate className="loader-div" />
      </div>
      }
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Personal Property Prefill"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default PersonalPropertyPrefill;
