/* eslint-disable max-lines */

import {
  carbonEmissonFS,
  AutoQoute,
  CorporatePCFS,
  CommunityFS,
  FinancialDataFS,
  DVLA,
  AGrenn,
  microblicklogo,
  paymentservice,
  sacotralogo,
  smartySlogo,
  twillio,
  Flag,
  Edit,
  Clock,
  DemoGraphics,
  CustomerExperience,
  Search,
  Card,
  merchantsBanner
} from "../Assets/image/index";

const carbonEmmision = {
  cardName: "Carbon Neutral Auto Coverage",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "“Every individual has the ability to become carbon neutral”. This quote & bind journey enhances the commoditized auto policy products with the ability to redeem carbon credits to offset yearly carbon emissions of the insured car. It enables consumers to engage with personalized and locally relevant projects to redeem carbon credits from, resulting in increased customer retention and a stronger loyalty to the insurance carrier underwriting the policy.",
  poweredby:
    "Powered by : Socotra, GT Software, Instanda, 360GlobalNet, MS Dynamics",
  actionDetails: "Click Here for Technical Documentation",
  solutionImpactBusiness: [
    {
      logo: CommunityFS,
      head: "Capture New Demographics",
      decription:
        "Sustainability is one of the most dominant trends of this decade. Carbon Offsetting allows insurance carriers to introduce an edge into a very price sensitive market.",
      decription2:
        "Capturing a sustainability focused demographic with purpose driven methods allow for building longer-lasting customer relationships."
    },
    {
      logo: CorporatePCFS,
      head: "Enhance Customer Experience",
      decription:
        "Remove cumborsome steps from the quote-to-bind journey. Collect car and personal information with digital capabilities and also enable individuals to offset their environmental impact through a templatized partnership with Green Energy companies.",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "Innovate, with Product Enhancements",
      decription:
        "Allow carriers to tap into new revenue sources by enhancing their existing products... and circumvent the efforts around building & generating new lines of business.",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "White Labelled Quote & Bind Application",
      decription:
        "Bring your custom client branding into a mobile optimized front end application to launch the Carbon Neutral Auto product in a matter of days.",
      decription2: ""
    },
    {
      head: "Carbon Offsetting & Lifestyle Tracking",
      decription:
        "Use prebuilt integrations to estimate a customer’s yearly carbon emissions, provide 3 months of carbon credits for redemption upon policy issuance.",
      decription2:
        "Use the AQ green mobile app to then support the lifestyle tracking and carbon credits redemption activities."
    },
    {
      head: "Local Car Registration Database Integration",
      decription:
        "Provide carriers with the ability to search for the details of any vehicle through a VIN or Licence Plate number.",
      decription2:
        "VIN or License Plate numbers are used across the policy issuance, renewal and claim lifecycles."
    }
  ],
  capAccelerBuilding: [
    {
      head: "Carbon Emission",
      decription:
        "Provide the ability to estimate the yearly carbon emissions of a vehicle based on the make, model and yearly mileage estimates. Issue vouchers for carbon credits redemption."
    },
    {
      head: "Address Validation",
      decription:
        "Provide carriers with the ability to check and validate any customer provided address details both in the US and Internationally."
    },
    {
      head: "Vehicle Information",
      decription:
        "Provide carriers with the ability to search for the details of any vehicle through a VIN or Licence Plate number. VIN or License Plate numbers are used across the policy issuance, renewal and claim lifecycles."
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway."
    },
    {
      head: "Auto Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system."
    },
    {
      head: "Auto Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system."
    },
    {
      head: "Notifications",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly."
    }
  ],
  poweredBy: [
    {
      logo: AGrenn,
      head: "AQ Green",
      decription:
        "Provides Carbon Emission Calculations and a Native Mobile Application to purchase/redeem carbon credits."
    },
    {
      logo: DVLA,
      head: "DVLA",
      decription: "Access vehicle details for the UK market"
    },
    {
      logo: sacotralogo,
      head: "Socotra",
      decription:
        "No-code Policy Management System with preconfigured lines of business products"
    },
    {
      logo: twillio,
      head: "Twilio",
      decription: "Provide multi channel notifications"
    },
    {
      logo: smartySlogo,
      head: "Smarty Streets",
      decription: "Provides address validation services"
    },
    {
      logo: paymentservice,
      head: "Payment Service Provider",
      decription: "Provides ability for customer to digitally pay for policy"
    },
    {
      logo: microblicklogo,
      head: "Microblink",
      decription:
        "Ability to scan a driver license to pre-populate customer data"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Solution Focused Customer Journey",
      decription:
        "Ashley cares deeply about sustainability and is looking for a policy that can help minimize her environmental impact.",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Carbon Offsetting & AQ Green",
      decription:
        "Individual participation in carbon markets to reduce carbon emission is enabled through a green energy investment and technology firm, AQ Green.",
      decription2:
        "For more information on AQ Green and a proposed partnership model between AQ Green & Insurance carrier, please click below.",
      action: "View powerpoint deck"
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the Carbon Neutral Auto Coverage application yourself!",
      action: "Fill Out Application"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script"
    }
  ]
};

const singleView = {
  cardName: "Single View of Customer",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "Life Insurance carriers in Australia struggle with establishing connectivity between multiple disparate core insurance systems that are either inherited through acquisitions or are part of the carrier’s existing IT stack. Because of this, carriers struggle with providing customers and business users with a single view of a Customer’s policy, claims, and marketing data",

  solutionImpactBusiness: [
    {
      logo: CommunityFS,
      head: "Capture New Demographics",
      decription:
        "Customer service representatives are enabled to provide better service through access to the universe of a given customer’s data.",
      decription2: ""
    },
    {
      logo: CorporatePCFS,
      head: "Enhance Customer Experience",
      decription:
        "Customer satisfaction, and consequently retention, are increased due to streamlined customer experiences and the ability to allow customers to manage multiple policies via a single interface",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "Innovate, with Product Enhancements",
      decription:
        "Carriers now have an increased ability to cross-sell tailored insurance products to their customers.",
      decription2:
        "Ease of access to single view data enables enhanced business intelligence capabilities to be used for additional insurance product sales."
    }
  ],
  keyFeatures: [
    {
      head: "Perform Address Changes",
      decription:
        "Ability to update a customer’s contact details across multiple core systems",
      decription2: ""
    },
    {
      head: "Update Multiple Systems of Record",
      decription:
        "Ability to view customer policy information from multiple disparate systems",
      decription2: ""
    },
    {
      head: "Cross-sell Additional Products",
      decription:
        "Ability to obtain an income protection policy via cross-sell activity",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Workflow/Orchestration",
      decription:
        "Single View of Customer Workflows to facilitate cross-sell, payments, and core system updates across digital and legacy core systems"
    },
    {
      head: "Address Validation",
      decription:
        "Provide carriers with the ability to check and validate any customer provided address details both in the US and Internationally."
    },
    {
      head: "Life Policy Specific Domain Services (Legacy)",
      decription:
        "Life Insurance specific domain service to interact with the underlying core system for customer transactions."
    },
    {
      head: "Payment Domain Service & Provider",
      decription:
        "Provide carriers with the most prominent digital payments collection options avaiable in the market, internationally"
    },
    {
      head: "Income Protection Specific Domain Services",
      decription:
        "Submission, Party and Auto Policy domain services to interact with the underlying core system for customer transactions."
    },
    {
      head: "Notifications",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API."
    }
  ],
  poweredBy: [
    {
      logo: AGrenn,
      head: "GT Software",
      decription:
        "Delivers a range of integration, modernization, and migration solutions across a variety of platforms"
    },
    {
      logo: DVLA,
      head: "Instanda",
      decription:
        "No-code SaaS insurance software platform that allows insurance companies to build, configure, and launch products online."
    },
    {
      logo: sacotralogo,
      head: "Socotra",
      decription:
        "No-code Policy Management System with preconfigured lines of business products"
    },
    {
      logo: twillio,
      head: "Twilio",
      decription:
        "Developer platform for communications.Merging the worlds of cloud computing, web services, and telecommunications."
    },
    {
      logo: smartySlogo,
      head: "Precisely",
      decription: "Provides address validation services"
    },
    {
      logo: paymentservice,
      head: "Payment Service Provider",
      decription: "Provides ability for customer to digitally pay for policy"
    },
    {
      logo: microblicklogo,
      head: "MS Dynamics",
      decription:
        "Modular (SaaS) application designed to both transform and enable core customers, employees, and business activities. It combines CRM and ERP"
    },
    {
      logo: Edit,
      head: "Camunda",
      decription: "Worfklow orchestration"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Solution Focused Customer Journey",
      decription:
        "Adam moves from California to New York and after he performs the address change in the customer portal Adam is presented an offer to purchase additional insurance products.",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Mainframe, legacy & No-code pas",
      decription:
        "Clients are continuously searching for ways to make the most out of their existing core systems while also enhancing their current landscape with No-Code Solutions.",
      decription2:
        "GT Software and Instanda provide coverage across this trend and allow clients to both enhance mainframe legacy systems for existing book of business and use No-Code Saas offerings, Instanda, for new business.",
      action: "Click here"
    },
    {
      head: "Third Party Licensisng Cost Estimates & Implementation Project Scope/Price",
      decription: "Channel 2 vendor license costs are available upon request.",
      decription2:
        "If the solution is inclusive of Channel 1 vendors, licensing costs and information need to be discsused between Client and Channel 1 vendor",
      action: ""
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Solution Demo",
      decription:
        "Click on this link to actually fill-out the termlife application yourself!",
      action: "Fill Out Application"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script"
    }
  ]
};

const intelligentDisputes = {
  cardName: "Intelligent Disputes",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Banking"],
  description:
    "Removing cost and friction in the dispute lifecycle through data enrichment and AI-enabled decisioning models",

  solutionImpactBusiness: [
    {
      logo: CommunityFS,
      head: "Deflect unnecessary disputes caused by customer confusion",
      decription:
        "Provide cardholders with enriched transaction data to minimize transaction confusion (such as unclear merchant name and details) and deflect a dispute from being initiated. This in turn provides an overall superior customer experience and minimize the cost to the issuing bank.",
      decription2: ""
    },
    {
      logo: CorporatePCFS,
      head: "Decrease time to process with straight through processing",
      decription:
        "Uses a customer-centric self-service intake process to collect all the necessary details to raise a dispute and deploys ML-models and business-rules to evaluate, processing and determine the case outcome without manual intervention.",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "Decrease in operational costs",
      decription:
        "Our Intelligent Disputes solution uses insights to challenge the inherent nuances within the current dispute-resolution landscape, specifically targeting cost reduction for issuers throughout the claims lifecycle and achieves 30-40% reduction in claims volumes, 40-50% reduction in friendly fraud"
    }
  ],
  keyFeatures: [
    {
      head: "Data Enrichment",
      decription:
        "Transaction data enrichment (supported by 3rd party data providers) allows a cardholder to view clear and easy to understand details, reducing cardholder confusion.",
      decription2: ""
    },
    {
      head: "Optimized Self-service Intake",
      decription:
        "A digital, configurable, intake experience based off network standards providing cardholders and agent a self-service experience; captures all required claim information and uses data to minimize manual inputs.",
      decription2: ""
    },
    {
      head: "Intelligent Processing",
      decription:
        "Use machine learning models and business-driven rules engines to assess claim information and determine case outcome without manual intervention.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Spend and financial wellness insights",
      decription:
        "Visualize recurring transactions, cards on file and shipping status details for users"
    },
    {
      head: "Self-service intake",
      decription:
        "Streamlined, dynamic intake process to easily collect information from a cardholder or agent through a configurable UI that can be altered based on client specific questions or requirements."
    },
    {
      head: "Intelligent routing and decisioning",
      decription:
        "Utilize Camunda rules engine to assign chargeback code assignment, determine next step based ML models output (payback probability, disputes complexity, etc.) and update the dispute record with decision."
    },
    {
      head: "Insights Engine",
      decription:
        "Orchestrate the execution of ML-models to assess the information provided and publish an outcome decision."
    },
    {
      head: "Data Pipeline",
      decription:
        "Mechanism by which disputes data is collected, transformed, and stored to make it consumable in a standard canonical model for downstream business processing."
    },
    {
      head: "Event Engine",
      decription:
        "Defined event maps to choreograph the execution of microservices from the moment a dispute event is creation through downstream processing."
    },
    {
      head: "Nexus Design System",
      decription:
        "UI-start kit containing built-in style guides, libraries of UI components and design patterns and functionality to accelerate front-end development."
    },
    {
      head: "Microservices Accelerator Kit",
      decription:
        "Used to accelerate microservices development using a JHipster platform for CLI and code/container templatization."
    }
  ],
  poweredBy: [
    {
      logo: DVLA,
      head: "Disputes API",
      decription:
        "Run the rules-based decision engine provided by the Nexus Core Platform."
    },
    {
      logo: sacotralogo,
      head: "Pega Case Management",
      decription:
        "Accelerate enterprise app development through visual tools (no coding), support for business/IT collaboration..."
    },
    {
      logo: Edit,
      head: "Camunda",
      decription: "Worfklow orchestration"
    },
    {
      logo: Edit,
      head: "Core Banking Adapters",
      decription:
        "Adapters are designed to plug into core systems to ensure a seamless customer experience and provide accurate issuer data"
    }
  ],
  additionalSalesAssets: [],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo",
      demoUrl:
        "https://eyus.sharepoint.com/sites/FSNexus/Shared%20Documents/General/Training%20&%20Knowledge%20Transfer/Disputes%20Solution/Disputes_Nexus_SCORE4%20(2).mp4"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script",
      demoUrl:
        "https://eyus.sharepoint.com/:b:/r/sites/FSNexus/Shared%20Documents/General/Training%20%26%20Knowledge%20Transfer/Disputes%20Solution/Disputes%20Demo%20Talk.pdf?csf=1&web=1&e=O0iGX5"
    }
  ]
};

const termLife = {
  cardName: "Direct to Customer Term Life Origination",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "Introduce Term Life products for direct distribution in the US market. Quote-to-bind journey is enhanced with data enrichment and alternative data sources to speed up new business acquisition",

  solutionImpactBusiness: [
    {
      logo: Clock,
      head: "Accelerated Time-to-Market",
      decription:
        "Bring your custom application and new line of business to customers in a matter of weeks, not years.",
      decription2: ""
    },
    {
      logo: Card,
      head: "Accelerated Tournaround",
      decription:
        "Remove cumborsome steps from the quote-to-bind journey. Collect personal information, medical history and lifestyle information about a potential customer in less than 10 minutes.",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "Amplified Underwriting Function",
      decription:
        "Process x3 the application volume through auto-underwriting for qualified applicants based on customizable underwriting criteria.",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "Digital Workflow Orchestration",
      decription:
        "Ability to run various processes across the underwriting function and distribution portal for term life products.",
      decription2: ""
    },
    {
      head: "Seamless Handoff & Straight-through Processing",
      decription:
        "Reflexive questions and custom business rules allow the underwriting function to be notified on applications that are not eligible for straight-through processing.",
      decription2:
        "Automatically facilitate payments for policy issuance upon underwriters review."
    },
    {
      head: "Electronic Health Record Data Access for Automated Underwriting",
      decription:
        "Tap into electronic health records through a market leading technology vendor and bring in digital medical records into the underwriting process in order to reduce the time it takes between an application submission and policy issuance.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Term Life Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewals through interactions with a core policy administration system."
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow."
    },
    {
      head: "Facial Analysis",
      decription:
        "Provide carriers with the ability to combine a selfie with facial analytics, biodemographic information and life event data to determine the smoker status, life expectancy and estimate current age of a customer in the underwriting process."
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway."
    },
    {
      head: "Document Signature",
      decription:
        "Provide carriers with the ability to manage agreements electronically, whether it be internal facing approvals or customer facing policy documentation or more."
    },
    {
      head: "Wearables Domain Service",
      decription:
        "Ability to leverage wearables based health data for underwriting, policy lifecycle management or renewal processes."
    },
    {
      head: "Human Health Domain Service",
      decription:
        "Ability to use digital medical health record data tied to the end customer for underwriting, policy lifecycle management and renewal processes."
    },
    {
      head: "Term Life Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system."
    }
  ],
  poweredBy: [
    {
      logo: AGrenn,
      head: "Lapetus",
      decription:
        "Provide selfie image based analytics to determine customer health and life expectancy"
    },
    {
      logo: DVLA,
      head: "Human API",
      decription:
        "Make health data accessible and useable for companies and consumers"
    },
    {
      logo: sacotralogo,
      head: "Unqork",
      decription: "No-code application development platform"
    },
    {
      logo: twillio,
      head: "Twilio",
      decription: "Provide multi channel notifications"
    },
    {
      logo: Edit,
      head: "Docusign",
      decription:
        "Enable electornic signatures in mission critical insurance documentation"
    },
    {
      logo: smartySlogo,
      head: "Smarty Streets",
      decription: "Provides address validation services"
    },
    {
      logo: paymentservice,
      head: "Stripe",
      decription: "Provides ability for customer to digitally pay for policy"
    },
    {
      logo: sacotralogo,
      head: "Socotra",
      decription:
        "No-code Policy Management System with preconfigured lines of business products"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Technology Interactions across the Customer Journey",
      decription:
        "See when and where Nexus integrations are used to reduce the application turnaround time.",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Key Persona Analysis",
      decription:
        "Jack is a recently married millenial who is interested in protecting the financial wellbeing of his family",
      decription2:
        "Jack says: “Time is my most valuable asset. I want to protect my family for the worst case scenario",
      action: "View powerpoint deck"
    },
    {
      head: "Third Party Licensing Cost Estimates",
      decription: "Channel 2 vendor license costs are available upon request.",
      decription2:
        "If the solution is inclusive of Channel 1 vendors, licensing costs and information need to be discsused between Client and Channel 1 vendor",
      action: "View powerpoint deck"
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the Carbon Neutral Auto Coverage application yourself!",
      action: "Fill Out Application"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script"
    }
  ]
};

const smallBusiness = {
  cardName: "Commercial Small Business Insurance",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "“Distribute and service Small Business Insurance for Accountants, Electricians and Plumbers in minutes. Small Business Insurance application allows end users to select coverage according to occupation, add additional insurends, generate certificate of insurance, submit FNOL and perform mid-term adjustments, as needed.",
  solutionImpactBusiness: [
    {
      logo: CommunityFS,
      head: "Generate New Lines of Business",
      decription:
        "With the contemporary technology stack across integrations and core systems, carriers can introduce new lines of business rapidly.",
      decription2: ""
    },
    {
      logo: CorporatePCFS,
      head: "Streamlined User Experience",
      decription:
        "Remove cumborsome steps from the quote-to-bind and FNOL submissions. Provide users with the ability to register, review policy details, generate certificates and perform mid-term adjustments.",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "White Labelled Quote & Bind Application",
      decription:
        "Bring your custom client branding into a mobile optimized front end application to launch the Carbon Neutral Auto product in a matter of days.",
      decription2: ""
    },
    {
      head: "No-code Core System Integration",
      decription:
        "This solution leverages Socotra for Policy, and 360GlobalNet for Claims/FNOL. Both of these core systems are easily configurable through their no-code, analyst friendly interfacesThis solution leverages Socotra for Policy, and 360GlobalNet for Claims/FNOL. Both of these core systems are easily configurable through their no-code, analyst friendly interfaces",
      decription2: ""
    },
    {
      head: "Multi Language Support",
      decription:
        "Ability to browse quote-to-bind and claims experiences in multiple languages (i.e. English, French, German) with relevant back-end system support.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Small Business Insurance Domain Services",
      decription:
        "Submission and Policy domain services to interact with the underlying core systems for customer transactions."
    },
    {
      head: "Address Validation",
      decription:
        "Provide carriers with the ability to check and validate any customer provided address details both in the US and Internationally."
    },
    {
      head: "Payment Domain Service & Provider",
      decription:
        "Provide carriers with the most prominent digital payments collection options avaiable in the market, internationally"
    },
    {
      head: "Notifications",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API."
    }
  ],
  poweredBy: [
    {
      logo: smartySlogo,
      head: "Smarty Streets",
      decription: "Provides address validation services"
    },
    {
      logo: DVLA,
      head: "360GlobalNet",
      decription: "An end-to-end no-code SaaS Claims Management platform"
    },
    {
      logo: sacotralogo,
      head: "Socotra",
      decription:
        "No-code Policy Management System with preconfigured lines of business products"
    },
    {
      logo: twillio,
      head: "Twilio",
      decription:
        "Developer platform for communications.Merging the worlds of cloud computing, web services, and telecommunications."
    },
    {
      logo: paymentservice,
      head: "Payment Service Provider",
      decription: "Provides ability for customer to digitally pay for policy"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Solution Pitch Deck for Client X",
      decription:
        "This Solution was pitched to a carrier in Canada as part of a Digital MGA discussion.",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Third Party Licensisng Cost Estimates & Implementation Project Scope/Price",
      decription: "Channel 2 vendor license costs are available upon request.",
      decription2:
        "If the solution is inclusive of Channel 1 vendors, licensing costs and information need to be discsused between Client and Channel 1 vendor",
      action: ""
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the Carbon neutral Auto Insurance application yourself!",
      action: "Fill Out Application"
    }
  ]
};

const merchantOffers = {
  cardName: "Merchants Offers & Insights",
  bannerLogo: merchantsBanner,
  countryFlag: Flag,
  domain: ["Banking"],
  description:
    "Personalized offers benefit cardholders by saving time & money for preferred experiences, merchants identify new audiences to engage with and draw in more customers, banks bring their card top of wallet and provide enhanced capabilities to the commercial & retail banking customers",
  solutionImpactBusiness: [
    {
      logo: CommunityFS,
      head: "Dynamic segmentation through behavioral science",
      decription:
        "Enabling banks to move beyond standard segmentation and focus on each customer’s individual journey, putting the customer at the center of decisioning. Nexus helps to identify patterns in user behavior to ultimately create better value propositions and identify users who are not well served by the current offering",
      decription2: ""
    },
    {
      logo: CorporatePCFS,
      head: "Offer creation & management",
      decription:
        "Nexus’ offer creation and management tool assists merchants via insightful recommendations in developing impactful offers and campaigns to create compelling consumer offers based on identified business goals. Nexus tools help to identify the audience and provides the mechanism to distribute offers to users ",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "Offer engagement",
      decription:
        "Provide personalized, real-time offers to individual users based on their journey, behaviors, and interests without inundating a user with notifications. Organize and view relevant offers within a users’ banking app experience to engage users with brands they love while introducing new areas of interest",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "Merchant insight dashboard",
      decription:
        "Merchants can analyze, and view campaign insights based on user engagement, offer performance, and in market competition. Enabling a merchant to understand through visualizations potential areas of growth, disruptors, and customer behavior to better inform offer creation and business decisioning",
      decription2: ""
    },
    {
      head: "Learnings & insights from previous offers",
      decription:
        "As a merchant creates offers for cardholders, Nexus can learn from past offers and suggest alterations to future offers to increase visibility and cardholder engagement",
      decription2: ""
    },
    {
      head: "Integrated Payments",
      decription:
        "Customers can seamlessly make purchases, receive rewards while merchants can better realize the potential of the offers & insights ecosystem with a closed loop payment system",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Journey Matching & Nudging",
      decription:
        "The combination of real-time and existing information to provide reminders and incentives for cardholders to complete the next step in the user journey, ultimately providing additional user personalization & value"
    },
    {
      head: "Insights Engine",
      decription:
        "Orchestrates the execution of ML-models to assess the information provided and publish an outcome decision"
    },
    {
      head: "Data Pipeline",
      decription:
        "Mechanism by which consumer data is collected, transformed, and stored to make it consumable in a standard canonical model for downstream business processing"
    },
    {
      head: "Event Engine",
      decription:
        "Defined event maps to choreograph the execution of microservices from the moment an offer is created through cardholder offer redemption"
    },

    {
      head: "Send Out Engine",
      decription:
        "Shares offers with customers in their preferred digital banking channel for a superior user experience"
    },
    {
      head: "Customer Experience Manager",
      decription:
        "Considers a user’s communication preferences to identify the preferred channel and time to reach out to a user"
    },
    {
      head: "Nexus Design System",
      decription:
        "UI-start kit containing built-in style guides, libraries of UI components and design patterns and functionality to accelerate front-end development"
    },
    {
      head: "Microservice Accelerator Kit",
      decription:
        "Used to accelerate microservices development using a JHipster platform for CLI and code/container templatization"
    }
  ],
  poweredBy: [
    {
      logo: sacotralogo,
      head: "Core Banking Adapters",
      decription:
        "Adapters are designed to plug into core systems to ensure a seamless customer experience and provide accurate issuer data"
    },
    {
      logo: Edit,
      head: "Camunda",
      decription: "Worfklow orchestration"
    }
  ],
  additionalSalesAssets: [],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo",
      demoUrl:
        "https://eyus.sharepoint.com/:v:/r/sites/FSNexus/Shared%20Documents/General/Training%20%26%20Knowledge%20Transfer/Merchant%20Offers%20%26%20Insights%20Solution/Merchant-Offers-Insights-Demo-V01%20(4).mov"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the Carbon Neutral Auto Coverage application yourself!",
      action: "Fill Out Application"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script",
      demoUrl:
        "https://eyus.sharepoint.com/:b:/r/sites/FSNexus/Shared%20Documents/General/Training%20%26%20Knowledge%20Transfer/Merchant%20Offers%20%26%20Insights%20Solution/Merchants%20Offers%20%26%20Insights%20Demo%20Talk.pdf?csf=1&web=1&e=FL736s"
    }
  ]
};

const autoQoute = {
  cardName: "Auto Insurance Quote & Bind",
  bannerLogo: AutoQoute,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "Enhancing a traditional auto insurance product quote & bind journey with digital capabilities to enhance the customer journey...",
  solutionImpactBusiness: [
    {
      logo: DemoGraphics,
      head: "Capture New Demographics",
      decription:
        "Introducing a Gen Z friendly interface and customer journey allows carriers to capture new demographics!",
      decription2: ""
    },
    {
      logo: CustomerExperience,
      head: "Enhance Customer Experience",
      decription:
        "Remove cumborsome steps from the quote-to-bind journey. Collect car and personal information with digital capabilities and also enable individuals to pay for their policies through credit, debit, and other payment methods..",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "White Labelled Quote & Bind Application",
      decription:
        "Bring your custom client branding into a mobile optimized front end application to launch the Carbon Neutral Auto product in a matter of days.",
      decription2: ""
    },
    {
      head: "Low-code Core System Configuration",
      decription:
        "Use a modern core policy system to configure basic products through a configuration studio and custom code for further product customizations.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Address Validation",
      decription:
        "Provide carriers with the ability to check and validate any customer provided address details both in the US and Internationally."
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Auto Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system"
    },
    {
      head: "Document Scan",
      decription:
        "Provide financial services firms with the ability to automate personal information collection process by scanning IDs and driver’s licenses."
    },

    {
      head: "Auto Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Document Scan",
      decription:
        "Provide financial services firms with the ability to automate personal information collection process by scanning IDs and driver’s licenses."
    },
    {
      head: "Vehicle Damage",
      decription:
        "Ability to use AI to estimate loss details for vehicle damage through damage scene photos and videos"
    }
  ],
  poweredBy: [
    {
      logo: microblicklogo,
      head: "Microblink",
      decription:
        "Ability to scan a driver license to pre-populate customer data"
    },
    {
      logo: Search,
      head: "Experian",
      decription: "Postcode Lookup"
    },
    {
      logo: sacotralogo,
      head: "Socotra",
      decription:
        "No-code Policy Management System with preconfigured lines of business products"
    },
    {
      logo: Edit,
      head: "Camunda",
      decription: "Worfklow orchestration"
    },
    {
      logo: smartySlogo,
      head: "Smarty Streets",
      decription: "Provides address validation services"
    },
    {
      logo: paymentservice,
      head: "Stripe",
      decription:
        "Provides ability for customers to digitally pay for services"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Customer Journey & Technology Usage",
      decription:
        "Allow clients to see how technology is used at each stage across the quote to bind journey",
      decription2: "",
      action: "View powerpoint deck"
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the termlife application yourself!",
      action: "Fill Out Application"
    }
  ]
};

const petInsurance = {
  cardName: "Pet Insurance Blueprint",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "Introduce Pet health Insurance products for direct distribution in the US market. It covers (reimburses) the costs of treating unexpected injuries and illnesses, so you don't have to worry..",
  solutionImpactBusiness: [
    {
      logo: Clock,
      head: "Accelerated Time-to-Market",
      decription:
        "Bring your custom application and new line of business to customers in a matter of weeks, not years.",
      decription2:
        "Applicants based on customizable underwriting criteria applications in days and not months or weeks."
    },
    {
      logo: Card,
      head: "Accelerated Tournaround",
      decription:
        "Remove cumborsome steps from the quote-to-bind journey. Collect personal information.",
      decription2:
        " Medical history and lifestyle information about a potential customer in less than 10 minutes"
    },
    {
      logo: FinancialDataFS,
      head: "Innovate, with Product Enhancements",
      decription:
        "Allow carriers to tap into new revenue sources by enhancing their existing products... and circumvent the efforts around building & generating new lines of business.",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "White Labelled Quote & Bind Application",
      decription:
        "Bring your custom client branding into a mobile optimized front end application to launch the Carbon Neutral Auto product in a matter of days.",
      decription2: ""
    },
    {
      head: "Low-code Core System Configuration",
      decription:
        "Use a modern core policy system to configure basic products through a configuration studio and custom code for further product customizations.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Pet Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system."
    },
    {
      head: "Address Validation",
      decription:
        "Provide carriers with the ability to check and validate any customer provided address details both in the US and Internationally."
    },
    {
      head: "Pet Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Provide carriers with the most prominent digital payments collection options avaiable in the market, internationally"
    },

    {
      head: "Notifications",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API."
    },
    {
      head: "Pet Domain Service",
      decription:
        "Ability to manipulate pet information across any pet insurance related business process"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    }
  ],
  poweredBy: [
    {
      logo: paymentservice,
      head: "Stripe",
      decription: "Provides ability for customer to digitally pay for policy"
    },
    {
      logo: Edit,
      head: "Camunda",
      decription: "Worfklow orchestration"
    },
    {
      logo: sacotralogo,
      head: "Socotra",
      decription:
        "No-code Policy Management System with preconfigured lines of business products"
    },
    {
      logo: twillio,
      head: "Twilio",
      decription:
        "Developer platform for communications.Merging the worlds of cloud computing, web services, and telecommunications."
    },
    {
      logo: smartySlogo,
      head: "Precisely",
      decription: "Provides address validation services"
    },
    {
      logo: smartySlogo,
      head: "Smarty Streets",
      decription: "Provides address validation services"
    },
    {
      logo: microblicklogo,
      head: "MS Dynamics",
      decription:
        "Modular (SaaS) application designed to both transform and enable core customers, employees, and business activities."
    }
  ],
  additionalSalesAssets: [
    {
      head: "Solution Focused Customer Journey",
      decription:
        "Jack has purchased a pet and is concerned about his pet's well-being.",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Key Persona Analysis & Customer Segmentation",
      decription:
        "Jack has purchased a pet and is concerned about his pet's well-being.",
      decription2:
        "Jack says: “Time is my most valuable asset. I want to protect my Pet in case of sickness and accident”",
      action: "View powerpoint deck"
    },
    {
      head: "Third Party Licensisng Cost Estimates & Implementation Project Scope/Price",
      decription: "Channel 2 vendor license costs are available upon request.",
      decription2:
        "If the solution is inclusive of Channel 1 vendors, licensing costs and information need to be discsused between Client and Channel 1 vendor",
      action: ""
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the termlife application yourself!",
      action: "Fill Out Application"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script"
    }
  ]
};

const mortageBrowse = {
  cardName: "Mortgage Browse to Book",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Banking"],
  description:
    "Insights and event-driven home loan prospecting and application solution that personalizes acquisition strategy for each customer by recognizing their unique customer journey, deepening the relationship between lenders and customers, and reducing the risk of attritio",
  solutionImpactBusiness: [
    {
      logo: Clock,
      head: "Acquisition & Targeting",
      decription:
        "Through leveraging customer and transaction data, Nexus’ Mortgage ‘Browse to Book’ solution empowers traditional & non-traditional mortgage buyers with a personalized experience through preferred channels",
      decription2: ""
    },
    {
      logo: CommunityFS,
      head: "Intake Optimization and Increased Customer Engagement",
      decription:
        "Nexus Mortgage ‘Browse to Book’ uses existing bank data to auto-populate application information for a streamlined and frictionless experience, decreasing manual input required and time spent on each piece of a borrower’s application",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "Dynamic segmentation through behavioral science",
      decription:
        "Enabling banks to move beyond standard segmentation and focus on each customer’s individual journey, putting the customer at the center of decisioning. Nexus helps to identify patterns in user behavior to ultimately create better value propositions and identify users who are not well served by the current offering",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "Self-service dynamic intake",
      decription:
        "Through digital form applications and decreasing the amount of manual inputs a borrower is required to populate, our self-service intake allows borrowers to complete their forms more quickly ultimately reducing a borrower’s mortgage timeline",
      decription2: ""
    },
    {
      head: "Intelligent decisioning engine",
      decription:
        "Our intelligent decisioning engine leverages ML models to assess a borrower’s mortgage eligibility, providing a clear view of how much a borrower can afford based on their mortgage requirements",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Journey Matching & Nudging",
      decription:
        "The combination of real-time and existing information to provide reminders and incentives for prospecting mortgage borrowers to complete the next step in the user journey"
    },
    {
      head: "Insights Engine",
      decription:
        "Orchestrates the execution of ML-models to assess the information provided and publish an outcome decision"
    },
    {
      head: "Data Pipeline",
      decription:
        "Mechanism by which consumer data is collected, transformed, and stored to make it consumable in a standard canonical model for downstream business processing"
    },
    {
      head: "Event Engine",
      decription:
        "Defined event maps to choreograph the execution of microservices from the moment a potential borrower is targeted for a mortgage through the application and closing process"
    },
    {
      head: "Customer Experience Manager",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API."
    },
    {
      head: "Nexus Design System",
      decription:
        "UI-start kit containing built-in style guides, libraries of UI components and design patterns and functionality to accelerate front-end development"
    },
    {
      head: "Microservice Accelerator Kit",
      decription:
        "Used to accelerate microservices development using a JHipster platform for CLI and code/container templatization"
    }
  ],
  poweredBy: [
    {
      logo: sacotralogo,
      head: "Core Banking Adapters",
      decription:
        "Adapters are designed to plug into core systems to ensure a seamless customer experience and provide accurate issuer data"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Go-to-market Pitch Deck",
      decription: "Lorem Ipsom",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Battle Card",
      decription: "Lorem Ipsom",
      decription2: "",
      action: "View powerpoint deck"
    }
  ],
  demoAssets: [
    {
      head: "Mortgage B2B Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo",
      demoUrl:
        "https://eyus.sharepoint.com/:v:/r/sites/FSNexus/Shared%20Documents/General/Training%20%26%20Knowledge%20Transfer/Mortgage%20Browse-to-Book/Mortgage%20UI%20Demo%20v3%20(3)%20(5).mov"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read Demo Script",
      demoUrl:
        "https://eyus.sharepoint.com/:b:/r/sites/FSNexus/Shared%20Documents/General/Training%20%26%20Knowledge%20Transfer/Mortgage%20Browse-to-Book/N4B%20Mortgage%20Demo%20Script%20(4).pdf?csf=1&web=1&e=yJimGY"
    }
  ]
};
const disabilityClaim = {
  cardName: "Disability Claims Intake",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "A modular, cloud-native, api-driven solution to process claims against Disability Insurance policies, which cuts out the paper and the doctor visits.",
  solutionImpactBusiness: [
    {
      logo: Clock,
      head: "Hassle-free Claims filing experience",
      decription:
        "Reduce claim filing errors, accelerate claims processing, issuance, and disbursements, and optimize operational costs by providing customers with a world class digital claims filing experience in a space that is tradionally encumbered by legacy systems and arduous customer journeys.",
      decription2: ""
    },
    {
      logo: Card,
      head: "Paperless Claims",
      decription:
        "Cut out the paper, save some money, while also helping save our environment; with Disability Claims Intake and Nexus Document Signature capabilities, insurers replace traditional paper-based signature requirements with a completely digital and user friendly experience.",
      decription2: ""
    },
    {
      logo: FinancialDataFS,
      head: "On-demand and Interactive forms",
      decription:
        "By leveraging the Nexus Document Generation Connector, the Disability Claims Intake solution provides clients with the capability to generate on-demand, tailored, and interactive forms (powered by OpenText)",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "Digital Workflow Orchestration",
      decription:
        "Ability to run various complex processes across the Disability claims intake journey.",
      decription2: ""
    },
    {
      head: "On-demand and Interactive Forms",
      decription:
        "Generate on-demand, customized, and interactive forms using the Nexus Document Generation Connector, and enhance the interactive nature of those forms by imbedding digital signature capabilities.",
      decription2: ""
    },
    {
      head: "Electronic Health Record Data Access for Claims Filing",
      decription:
        "Get customer medical data without the physician visit; whether your customer is sick or injured, digitized, HIPAA compliant customer medical records can be retrieved at the speed of light.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Human Health",
      decription:
        "Retrieve up-to-date, digital medical records of a human being in the United States."
    },
    {
      head: "Notifications",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API."
    },
    {
      head: "Document Generation",
      decription:
        "On-demand form generation for both interactive and static documents."
    },
    {
      head: "Document Signature",
      decription:
        "Imbed digital signature capabilities into almost any document."
    },
    {
      head: "Disability Claims Intake Domain Service",
      decription:
        "Provide carriers with the ability to send SMS and Email notifications to customers seamlessly through a single Nexus API."
    },
    {
      head: "Disability Policy Domain Service",
      decription:
        "A digital wrapper to retrieve policy information in order to confirm coverage for Disability claims."
    }
  ],
  poweredBy: [
    {
      logo: paymentservice,
      head: "OpenText Exstream",
      decription: "Document generation capabilities"
    },
    {
      logo: Edit,
      head: "Camunda",
      decription: "Worfklow orchestration"
    },
    {
      logo: sacotralogo,
      head: "DocuSign",
      decription: "Digital document signature"
    },
    {
      logo: twillio,
      head: "Twilio",
      decription:
        "Developer platform for communications.Merging the worlds of cloud computing, web services, and telecommunications."
    },
    {
      logo: smartySlogo,
      head: "Unqork",
      decription: "No-code engagement layer"
    },
    {
      logo: smartySlogo,
      head: "HumanAPI",
      decription: "Digitized medical records delivered at lightening speed"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Go-to-market Pitch Deck",
      decription: "Lorem Ipsom",
      decription2: "",
      action: "View powerpoint deck"
    },
    {
      head: "Business Case Cost Benefit Analysis",
      decription: "Lorem Ipsom",
      decription2: "",
      action: "View powerpoint deck"
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Solution Demo Script",
      decription:
        "If you would like to demonstrate this solution to your clients independently, please click on this link to download a copy of the demo script.",
      action: "Read the Demo Script"
    }
  ]
};

const underwritingWorkbench = {
  cardName: "Underwriting Workbench",
  bannerLogo: carbonEmissonFS,
  countryFlag: Flag,
  domain: ["Insurance"],
  description:
    "While technology brings many unprecedented levels of change, underwriters must leverage innovative tools and capabilities to go beyond the market demands in the commercial insurance and reinsurance realms in order to thrive in this digital era.This is the change that Underwriting Workbench brings to the market..",
  solutionImpactBusiness: [
    {
      logo: Clock,
      head: "UX Designed for Underwriters",
      decription:
        "Intuitive UI and user journeys, coupled with risk visualization and classification makes the user experience seemless.",
      decription2:
        "Collaboration tools leveraging email, chat and chatbots drives collaboration and ultimately reduces underwriting risk."
    },
    {
      logo: Card,
      head: "Enhanced Underwriter Productivity",
      decription:
        "Automated intake of exposure, coverage and risk information from ACORD, and non-standard submissions (SoV and Loss Runs) including prefills.",
      decription2:
        "Centralized Submission document storage provides a single repository of truth, and eliminates clutter and dependencies on access to local"
    },
    {
      logo: FinancialDataFS,
      head: "Improved Underwriter Insights",
      decription:
        "Underwriting decision support system powered by EY IIP (risk analysis and cognitive risk evaluation), EY Sight (market and business risk evaluation), and third party data augmentation.",
      decription2: ""
    }
  ],
  keyFeatures: [
    {
      head: "Opportunity Management",
      decription: "Integrated opportunity management, powered by CRM.",
      decription2: ""
    },
    {
      head: "Evaluation",
      decription:
        "Risk insights and cross/upsell via predictive analytics and third party data.",
      decription2: ""
    },
    {
      head: "UW Insights and Analytics",
      decription:
        "Manage quotes through AI/ML capabilities, pricing visualization, submission management, quote management and line setting.",
      decription2: ""
    }
  ],
  capAccelerBuilding: [
    {
      head: "Residential Property Prefill",
      decription:
        "Provides crucial data for underwriting insurance policies on US residential properties"
    },
    {
      head: "Address Validation",
      decription:
        "Provide carriers with the ability to check and validate any customer provided address details both in the US and Internationally"
    },
    {
      head: "Submission Domain Service",
      decription:
        "This domain service serves as an adapter to core policy and submission insurance systems, which allows for the placement of insurance submissions"
    },
    {
      head: "Business Rules Engine",
      decription:
        "Powered by Drools, enabling the validation of core business rules automatically"
    },
    {
      head: "AI/ML Underwriting",
      decription:
        "Powered by IIP, these capabilities span across a wide spectrum of functionality, injecting data science into the underwriting process with the goal of making better decisions."
    },
    {
      head: "Interactive Dashboards",
      decription: "Visualize key underwriting related metrics and KPIs"
    },
    {
      head: "Document Scan",
      decription:
        "Provide financial services firms with the ability to automate personal information collection process by scanning IDs and driver’s licenses."
    }
  ],
  poweredBy: [
    {
      logo: AGrenn,
      head: "EY IIP",
      decription: "EY’s Insurance Insights Platform"
    },
    {
      logo: DVLA,
      head: "ACORD Transcriber",
      decription:
        "Automated download, extraction, and population of data from ACORD forms"
    },
    {
      logo: sacotralogo,
      head: "Carpe Data",
      decription:
        "Real time data and predictive scoring for claims, underwriting, and book assessment"
    },
    {
      logo: twillio,
      head: "Precisely",
      decription:
        "Real time data and predictive scoring for claims, underwriting, and book assessment"
    },
    {
      logo: Edit,
      head: "Smarty Streets",
      decription: "Provides address validation services"
    },
    {
      logo: smartySlogo,
      head: "Camunda",
      decription: "Workflow orchestration"
    },
    {
      logo: smartySlogo,
      head: "Power BI",
      decription: "Dashboard technology"
    },
    {
      logo: smartySlogo,
      head: "EY Sight",
      decription: "Market and business risk evaluation"
    }
  ],
  additionalSalesAssets: [
    {
      head: "Go-to-market Pitch Deck",
      decription:
        "Use this deck to present the UWB offering to prospective customers",
      decription2: "",
      action: "View powerpoint deck"
    }
  ],
  demoAssets: [
    {
      head: "Solution Demo Recording",
      decription:
        "Click on this link for a recording of a actual client pitch for the solution",
      action: "Watch Demo"
    },
    {
      head: "Sample Application",
      decription:
        "Click on this link to actually fill-out the termlife application yourself!",
      action: "Fill Out Application"
    }
  ]
};

export {
  carbonEmmision,
  singleView,
  intelligentDisputes,
  termLife,
  smallBusiness,
  merchantOffers,
  autoQoute,
  petInsurance,
  disabilityClaim,
  mortageBrowse,
  underwritingWorkbench
};
