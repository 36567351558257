import React, { Component } from "react";
import { CheckCircle } from "../../../../../../assets/images";

class FloodRightView extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { areaType, riskLevel } = this.props.result.floodResponse.floodZone;

    return (
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7 flood-rt-view">
          <div>
            <img
              className="verified-icons"
              src={CheckCircle}
              alt="CheckCircle"
            />
          </div>
          <div className="address-matched-text">
            <strong>{riskLevel} Flood Risk Zone</strong>
          </div>
        </div>
      </div>
    );
  }
}

export default FloodRightView;
