import React, { useState } from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  MerchantOffersInsightsDemoV01,
  MOIBannerImage,
  MOIKeyAssets,
  MOIStrategicValue
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";
import ModalComponent from "./ModalComponent";

const MerchantOffersAndInsights = () => {
  const [showModal,
    setShowModal] = useState(false);
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Dynamic segmentation through behavioral science: Nexus helps to identify patterns in user behavior to ultimately create better value propositions and identify users who are not well served by the current offering",
        "Offer creation & management: Nexus tools help to identify the audience and provides the mechanism to distribute offers to users ",
        "Offer engagement: provide personalized, real-time offers to individual users based on their journey, behaviors, and interests without inundating a user with notifications. "
      ],
      img: MOIKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values ",
      desc: [
        "Merchants can analyze, and view campaign insights based on user engagement, offer performance, and in market competition. Enabling a merchant to understand through visualizations potential areas of growth, disruptors, and customer behavior to better inform offer creation and business decisioning",
        "As a merchant creates offers for cardholders, Nexus can learn from past offers and suggest alterations to future offers to increase visibility and cardholder engagement",
        "Customers can seamlessly make purchases, receive rewards while merchants can better realize the potential of the offers & insights ecosystem with a closed loop payment system"
      ],
      img: MOIStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: MOIBannerImage,
    heading: "Merchants Offers & Insights",
    subHeading:
      "Personalized offers benefit cardholders by saving time  and money for preferred experiences, merchants identify new audiences to engage with and draw in more customers, banks bring their card top of wallet and provide enhanced capabilities to commercial and retail banking customers",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white",
    bannerButtonDetails: {
      text: "View Demo",
      isBgColorWhite: true
    }
  };
  const nexusModalBody = <video controls={true} width="800" height="600" autoPlay={true}>
    <source src={MerchantOffersInsightsDemoV01} />
  </video>;

  return (
    <>
      {showModal && <ModalComponent
        modalBody={nexusModalBody}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      }
      <FSSolutionExploreMoreCommonComponent
        pillarContent={pillarContent}
        bannerContent={bannerContent}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default MerchantOffersAndInsights;
