import { AIML } from "./Assets/images";

const readyToUse = [
  {
    title: "Experience",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
  },
  {
    title: "Technology",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
  },
  {
    title: "Value",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
  }
];

const collaboration = [
  {
    title: "Drive Personalized Engagement & Interaction",
    action: "Call to action",
    color: "#FFA464"
  },
  {
    title:
      "Modernize customer experiences, internal processes, and technology together",
    action: "Call to action",
    color: "#809FFF"
  },
  {
    title: "Orchestrate E2E, digital, financial services journeys",
    action: "Call to action",
    color: "#FFDC73"
  }
];

const newTechnologies = [
  {
    image: AIML,
    title: "Bring AI/ML capabilities to optimize processes",
    color: "#FFF"
  },
  {
    title:
      "Introduce realtime intelligence by connecting to the data ecosystem",
    color: "#FFF"
  },
  {
    title:
      "De-risk the implementation and business risk of third party technologies",
    color: "#FFF"
  },
  {
    title:
      "Connect to Best of Breed Technology services to drive growth, efficiency",
    color: "#FFF"
  }
];

const proposition = [
  {
    title: "Build Trust in an Open Finance Market Place",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    action: "Call to action",
    color: "#FFA464"
  },
  {
    title:
      "Inject Insights & Intelligence into Fabric of the business (traditional/legacy Processes)",
    description:
      "Try our app for finanical wellness wherever you go Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat Exercitation veniam consequat sunt nostrud amet. ",
    action: "Call to action",
    color: "#809FFF"
  },
  {
    title:
      "Enhance industry products with new, relevant and differentiating capabilities",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    action: "Call to action",
    color: "#FFDC73"
  }
];

export { readyToUse, collaboration, proposition, newTechnologies };
