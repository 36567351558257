import React, { Component } from "react";
import DisabledInput from "../DisabledInput";

interface InputList {
  labelTestid: string;
  labelTittle: string;
  inputTestid: string;
  value: string;
  isDisabled: boolean;
  placeholder: string;
}
interface Props {
  InputArray: InputList[];
}
class StepOne extends Component<any, Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      InputArray: this.props.InputArray
    };
  }

  render() {
    return (
      <>
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 01
          </div>
          <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
            <div className="form-head">Enter your location</div>
          </div>
        </div>
        <DisabledInput InputArray={this.props.InputArray} />
      </>
    );
  }
}

export default StepOne;
