import React from "react";
import moment from "moment";

const Salvage = (props: any) => {
  const vehicle = props && props?.data?.vehicle ? props.data.vehicle[0] : null;
  const reportedSalvage = vehicle?.reportedSalvages.filter(
    (item: any) => item.type === "Salvage"
  )[0];
  const reportedpriorSalvage = vehicle?.reportedSalvages.filter(
    (item: any) => item.type === "Scrapped/Destroyed"
  )[0];

  return (
    <div>
      {reportedSalvage && <div
        className="test-result-box nexus-p-4 nexus-col-lg-10"
        style={{ width: "422px" }}
      > 
        <div className="vehicle-details-title">Salvage History</div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              VIN
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {vehicle.vin}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Source
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedSalvage.fileSource}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Type
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedSalvage.type}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Date
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {moment(reportedSalvage.date).format("YYYY-MM-DD")}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              City
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedSalvage.city}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              State
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedSalvage.state}
          </div>
        </div>
      </div>
      }
      {reportedpriorSalvage && <div
        className="test-result-box nexus-p-4 nexus-col-lg-10 nexus-mt-4"
        style={{ width: "422px" }}
      >
        <div className="vehicle-details-title">Scrap History</div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Source
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedpriorSalvage.fileSource}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Type
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedpriorSalvage.type}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              Date
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {moment(reportedpriorSalvage.date).format("YYYY-MM-DD")}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              City
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedpriorSalvage.city}
          </div>
        </div>
        <div className="nexus-flex-row nexus-pt-1">
          <div
            className="nexus-pl-3 validated-address-value"
            style={{ width: "100px" }}
          >
              State
          </div>
          <div className="nexus-pl-5 validated-address-text">:</div>
          <div className="nexus-pl-1 validated-address-text">
            {reportedpriorSalvage.state}
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Salvage;
