import React from "react";
import "../CatalogStyle.scss";
import {
  NexusProgressBar,
  NexusFormField,
  NexusInput,
  NexusLabel,
  NexusSelect,
  NexusTextarea
} from "@nexus/react";
import mapsIcMyLocation24px from "@nexus/core/dist/assets/icons/places/ic_location_on_24px.svg";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import {
  apiRequestParams,
  reqBodyForRoofDataByLine,
  reqBodyForRiskData
} from "./inputList";
import TestResultsRoofData from "./TestResults";
import RoofAgeByParts from "./RoofAgeByParts";
import RoofAgeByLine from "./RoofAgeByLine";
import RoofTopRisk from "./RoofTopRisk";
import { ResultAttribute } from "./Models";
import Step from "../Step";

const ADOBE_DATA = {
  pageName: "/platform-accelerators/roof-data-property",
  listName: "/platform-accelerators/roof-data-property"
};

interface IRoofDataDemoState {
  loader: boolean;
  result: any;
  selectedReportType: string;
  roofDataByPartsResponse: any;
  showResults: boolean;
}

const card = {
  title: "Roof Data",
  description: `Nexus Roof Data API enables retrieving key property roof age attributes,
    including the year and a confidence score, for a given address.`,
  subTitle: "Lexis Nexis, Verisk",
  icon: mapsIcMyLocation24px,
  developerDoc: "get-roof-age-by-parts"
};
const service = new Service();

class RoofDataDemo extends React.Component<any, IRoofDataDemoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      result: {},
      selectedReportType: "roofagebyparts",
      roofDataByPartsResponse: {},
      showResults: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  getAttributeValues = (attributesArr: []) => {
    const attributeValues: ResultAttribute = {};
    attributesArr.forEach((attribute: any) => {
      if (attribute.name === "snapdate") {
        attributeValues.snapdate = attribute.value;
      }
      else if (attribute.name === "area_landform_local_relief") {
        attributeValues.localRelief = attribute.value;
      }
      else if (attribute.name === "area_landform_profile_type") {
        attributeValues.profileType = attribute.value;
      }
      else if (attribute.name === "area_landform_slope") {
        attributeValues.slope = attribute.value;
      }
    });

    return attributeValues;
  };

  resetPage = () => {
    this.setState({
      result: {},
      showResults: false,
      loader: false
    });
  };

  processApiResponse = (data: any) => {
    const attributeValues = this.getAttributeValues(
      data?.data?.productResults?.roofTopReportResult?.attributes?.attribute
    );
    const dataObj = {
      processingMessage:
        data?.data?.productResults?.roofTopReportResult?.processingMessage,
      snapdate: attributeValues.snapdate,
      localRelief: attributeValues.localRelief,
      profileType: attributeValues.profileType,
      slope: attributeValues.slope
    };
    this.setState({
      result: dataObj,
      showResults: true,
      loader: false
    });
  };

  roofAgeByPartsApiCall = () => {
    service
      .getRoofData(apiRequestParams, "roofagebyparts")
      .then((res: any) => res.json())
      .then((data: any) => {
        this.setState({
          result: data,
          showResults: true,
          loader: false
        });
      })
      .catch(() => {
        this.resetPage();
      });
  };

  roofAgeByLineApiCall = () => {
    service
      .getRoofData(reqBodyForRoofDataByLine, "roofagebyline")
      .then((res: any) => res.json())
      .then((data: any) => {
        this.setState({
          result: data,
          showResults: true,
          loader: false
        });
      })
      .catch(() => {
        this.resetPage();
      });
  };

  resetAll = () => {
    this.setState({
      result: {},
      showResults: false,
      loader: false,
      selectedReportType: "roofagebyparts"
    });
  };

  submit() {
    this.setState({ loader: true });
    const { selectedReportType } = this.state;
    if (selectedReportType === "roofagebyparts") {
      this.roofAgeByPartsApiCall();

      return;
    }
    if (selectedReportType === "roofagebyline") {
      this.roofAgeByLineApiCall();

      return;
    }
    service
      .getRoofData(reqBodyForRiskData, "getrooftopriskdata")
      .then((res: any) => res.json())
      .then((data: any) => {
        this.processApiResponse(data);
      })
      .catch(() => {
        this.resetPage();
      });
  }

  handleRoofDataSelection = (event: any): void => {
    this.setState({
      result: {},
      showResults: false,
      selectedReportType: event?.target.value
    });
  };

  displayNextSteps = () => {
    const { selectedReportType } = this.state;
    if (selectedReportType === "roofagebyparts") {
      return <RoofAgeByParts />;
    }
    if (selectedReportType === "roofagebyline") {
      return <RoofAgeByLine />;
    }

    return <RoofTopRisk />;
  };

  rightTab = () => {
    const { result, showResults, selectedReportType } = this.state;

    return (
      <>
        {!showResults && <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
              Try Roof Data with Sample address
          </div>
        </div>
        }

        {showResults && <div className="nexus-ml-5 test-result-container">
          <div className="nexus-mb-2 test-result">Test Result</div>
          {selectedReportType === "rooftopriskdata" ? <TestResultsRoofData result={result} isTopRiskData /> : <TestResultsRoofData result={result} />
          }
        </div>
        }
      </>
    );
  };

  leftTab = () => <>
    <Step stepNumber={"Step 01"} header={"Select Report Type"}></Step>
    <div className="nexus-row">
      <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
      <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-6 nexus-mt-1">
        <NexusFormField>
          <NexusLabel key={"reporttype"}>Report Type</NexusLabel>
          <NexusSelect
            key="roofdataselection"
            value={this.state.selectedReportType}
            data-testid="selectedReportType"
            onInput={(event: any) => this.handleRoofDataSelection(event)}
          >
            <option key={"roofagebyparts"} value={"roofagebyparts"}>
                Roof age by parts
            </option>
            <option key={"roofagebyline"} value={"roofagebyline"}>
                Roof age by line
            </option>
            <option key={"rooftopriskdata"} value={"rooftopriskdata"}>
                Roof top risk data
            </option>
          </NexusSelect>
        </NexusFormField>
      </div>
    </div>
    {this.displayNextSteps()}
    <div className="nexus-row">
      <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
      <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
        {this.state.showResults ? <button
          type="button"
          data-testid="reset-btn"
          className="confirm-button"
          onClick={() => {
            this.resetAll();
          }}
        >
              Reset
        </button> : <button
          data-testid="getdata-btn"
          type="button"
          className="confirm-button"
          onClick={() => {
            this.submit();
          }}
        >
              Try with Sample Address
        </button>
        }
      </div>
    </div>
  </>;

  render() {
    const { loader } = this.state;

    return (
      <div className="nexus-container padding-margin-0 updated-design">
        {loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Roof Data"
          leftTab={this.leftTab()}
          rightTab={this.rightTab()}
        />
      </div>
    );
  }
}

export default RoofDataDemo;
