import React, { Component } from "react";
import "./ourTeamStyle.scss";
import {
  RyanBattles,
  DavidConnolly,
  GuyDryden,
  KathleenCalabro,
  RajcanPSurface,
  AlixCheema,
  DavidDeane,
  FabrizioCrocetta,
  GaryMcInnes,
  GlennRogers,
  HanWeeTan,
  ReinierPlantinga,
  SandeepKumar,
  MeetTheTeam
} from "./Assets/images";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "about-our-team",
  listName: "about-our-team"
};

const leaders = [
  {
    name: "Ryan Battles",
    role: "Global Nexus Platform Lead",
    img: RyanBattles,
    client: null
  },
  {
    name: "Kathleen Calabro",
    role: "Global Nexus Product Lead",
    img: KathleenCalabro,
    client: null
  },
  {
    name: "David Connolly",
    role: "Global Nexus Lead",
    img: DavidConnolly,
    client: null
  },
  {
    name: "Guy Dryden",
    role: "Global Nexus Tech Architecture Lead",
    img: GuyDryden,
    client: null
  }
];

const productTeam = [
  {
    name: "David Deane",
    role: "Banking Sector Lead, Americas",
    img: DavidDeane,
    client: null
  },
  {
    name: "Reinier Plantinga",
    role: "Banking Sector Lead, EMEIA",
    img: ReinierPlantinga,
    client: null
  },
  {
    name: "Gary McInnes",
    role: "Banking Sector Lead, APAC",
    img: GaryMcInnes,
    client: null
  },
  // {
  //   name: 'Rajcan P Surface',
  //   role: 'Insurance Sector Lead, Americas',
  //   img: RajcanPSurface,
  //   client: null
  // },
  {
    name: "Fabrizio Crocetta",
    role: "Insurance Sector Lead, EMEIA",
    img: FabrizioCrocetta,
    client: null
  },
  {
    name: "Glenn Rogers",
    role: "Insurance Sector Lead, APAC",
    img: GlennRogers,
    client: null
  },
  {
    name: "Sandeep Kumar",
    role: "WAM Sector Lead, Americas",
    img: SandeepKumar,
    client: null
  },
  {
    name: "Alix Cheema",
    role: "WAM Nexus Lead, EMEIA",
    img: AlixCheema,
    client: null
  },
  {
    name: "Han Wee Tan",
    role: "WAM Nexus Lead, APAC",
    img: HanWeeTan,
    client: null
  }
];

const ourTeamImage = {
  image: MeetTheTeam,
  title: "Meet The Team",
  subTitle:
    "The Nexus platform is optimized for financial services and enables business transformation, accelerates innovation, unlocks value in client ecosystems and powers frictionless, personalized customer experiences"
};
class OurTeam extends Component<any, any> {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  goToEyNexusIdeasPortal() {
    window.open("https://nexus.ideas.aha.io/", "_blank", "noopener");
  }

  render() {
    return (
      <>
        <div className="nexus-banner-container">
          <div
            className="nexus-container px-3"
            style={{ paddingBottom: "0px" }}
          >
            <div
              className="nexus-row mb-4 p-1 our-team-page-header py-4"
              style={{ backgroundImage: `url(${ourTeamImage.image})` }}
            >
              <div
                className={`nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-6 nexus-col-xl-6 nexus-ml-5 nexus-mt-6`}
              >
                <div className="our-team-banner-font-color heading nexus-mb-1">
                  {ourTeamImage.title}
                </div>
                <div className="our-team-banner-font-color sub-heading nexus-mb-2">
                  {ourTeamImage.subTitle}
                </div>
                <div>
                  <button
                    className="work-with-nexus-test nexus-btn work-with-nexus-btn-bg nexus-btn-medium nexus-body"
                    onClick={() => this.goToEyNexusIdeasPortal()}
                  >
                    Submit an idea
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nexus-container our-team-page p-0">
          <div className="nexus-mx-5 nexus-mb-5">
            <div className="nexus-row my-4">
              <div className="col">
                <hr color="#BDBFC1" />
              </div>
              <div className="nexus-h4 nexus-mt-1">Global Nexus Leaders</div>
              <div className="col">
                <hr color="#BDBFC1" />
              </div>
            </div>
            <div className="nexus-row">
              {leaders.map((item) => <div
                key={item.name}
                className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-3 text-center pr-4 mb-4"
              >
                <img
                  src={item.img}
                  alt="logo"
                  className="team-img mb-2"
                  style={{ filter: "grayscale(100%)" }}
                />
                <div className="nexus-subtitle-sm nexus-start-sm nexus-start-md nexus-start-lg">
                  {item.name}
                </div>
                <div
                  className="nexus-subtitle-sm nexus-start-sm nexus-start-md nexus-start-lg"
                  style={{ minHeight: "44px" }}
                >
                  {item.role}
                </div>
              </div>)}
            </div>
            <div className="nexus-row mt-2 mb-5">
              <div className="col">
                <hr color="#BDBFC1" />
              </div>
              <div className="nexus-h4 nexus-mt-1">Nexus Sector Leaders</div>
              <div className="col">
                <hr color="#BDBFC1" />
              </div>
            </div>
            <div className="nexus-row">
              {productTeam.map((item) => <div
                key={item.name}
                className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-3 text-center pr-4 mb-4"
              >
                <img
                  src={item.img}
                  alt="logo"
                  className="team-img mb-2"
                  style={{ filter: "grayscale(100%)" }}
                />
                <div className="nexus-subtitle-sm nexus-start-sm nexus-start-md nexus-start-lg">
                  {item.name}
                </div>
                <div
                  className="nexus-subtitle-sm nexus-start-sm nexus-start-md nexus-start-lg"
                  style={{ minHeight: "44px" }}
                >
                  {item.role}
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurTeam;
