/* eslint-disable no-negated-condition */
import React from "react";
import "../CatalogStyle.scss";
import { NexusLabel, NexusProgressBar, NexusSelect } from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { Human } from "../../../../../assets/images";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/next-best-action",
  listName: "platform-capabilities/next-best-action"
};

interface State {
  loader?: boolean;
  ageGroup: string;
  gender: string;
  bodyPart: string;
  natureOfInjury: string;
  hazardGroup: string;
  priorClaims: string;
  industryGroup: string;
  sourceOfInjury: string;
  result: any;
}

const service = new Service();

class NextBestAction extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      ageGroup: "30 - 40",
      gender: "Female",
      bodyPart: "Ankle",
      natureOfInjury: "Inflammation",
      hazardGroup: "C Below Average Severity",
      priorClaims: "0",
      industryGroup: "Food and Tobacco",
      sourceOfInjury: "Caught In/Under Machine or Machinery",
      result: null
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  submit() {
    if (this.state.result) {
      this.setState({
        ageGroup: "30 - 40",
        gender: "Female",
        bodyPart: "Ankle",
        natureOfInjury: "Inflammation",
        hazardGroup: "C Below Average Severity",
        priorClaims: "0",
        industryGroup: "Food and Tobacco",
        sourceOfInjury: "Caught In/Under Machine or Machinery",
        result: null
      });
    }
    else {
      this.setState(
        (prevState) => ({
          ageGroup: prevState.ageGroup || "30 - 40",
          gender: prevState.gender || "Female",
          bodyPart: prevState.bodyPart || "Ankle",
          natureOfInjury: prevState.natureOfInjury || "Inflammation",
          hazardGroup: prevState.hazardGroup || "C Below Average Severity",
          priorClaims: prevState.priorClaims || "0",
          industryGroup: prevState.industryGroup || "Food and Tobacco",
          sourceOfInjury:
            prevState.sourceOfInjury || "Caught In/Under Machine or Machinery"
        }),
        () => {
          const {
            ageGroup,
            gender,
            bodyPart,
            natureOfInjury,
            hazardGroup,
            priorClaims,
            industryGroup,
            sourceOfInjury
          } = this.state;

          const params = {
            ageGroup,
            bodyPart,
            gender,
            hazardGroup,
            industryGroup,
            natureOfInjury,
            priorClaims,
            sourceOfInjury
          };

          this.setState({
            loader: true
          });

          service
            .nextAction(params)
            .then((response) => response.json())
            .then((data) => {
              if (data.data) {
                this.setState({ result: data.data,
                  loader: false });
              }
              else if (data.error) {
                this.setState({ result: data.error,
                  loader: false });
              }
            })
            .catch(() => {
              this.setState({
                loader: false
              });
            });
        }
      );
    }
  }

  render() {
    const ageGroupOptions = ["30 - 40",
      "40 - 50",
      "50 - 60"];
    const genderOptions = ["Female",
      "Male"];
    const bodyPartOptions = ["Ankle",
      "Chest",
      "Elbow",
      "Knee"];
    const natureOfInjuryOptions = [
      "Inflammation",
      "Contagious Disease",
      "Fracture",
      "Strain"
    ];
    const hazardGroupOptions = [
      "C Below Average Severity",
      "D Average Severity",
      "E Above Average Severity",
      "F High Severity"
    ];
    const priorClaimsOptions = ["0",
      "1",
      "2",
      "3",
      "4+"];
    const industryGroupOptions = [
      "Food and Tobacco",
      "Agriculture",
      "Cartage and Trucking"
    ];
    const sourceOfInjuryOptions = [
      "Caught In/Under Machine or Machinery",
      "Fall/Slip or Trip On Stairs",
      "Struck or Injured By Hand Tool/Machine"
    ];

    const {
      ageGroup,
      gender,
      bodyPart,
      natureOfInjury,
      hazardGroup,
      priorClaims,
      industryGroup,
      sourceOfInjury
    } = this.state;

    const leftTab = () => <>
      <div className="nexus-row mr-4">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
          <div className="form-head">Enter the below details</div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Age Group
            </NexusLabel>
            <NexusSelect
              value={ageGroup}
              data-testid="ageGroup"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ ageGroup: event.target.value })
              }
            >
              {ageGroupOptions.map((item) => <option key={item} value={item} selected>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Gender
            </NexusLabel>
            <NexusSelect
              value={gender}
              data-testid="gender"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ gender: event.target.value })
              }
            >
              {genderOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Body Part
            </NexusLabel>
            <NexusSelect
              value={bodyPart}
              data-testid="bodyPart"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ bodyPart: event.target.value })
              }
            >
              {bodyPartOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Nature of Injury
            </NexusLabel>
            <NexusSelect
              value={natureOfInjury}
              data-testid="natureOfInjury"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ natureOfInjury: event.target.value })
              }
            >
              {natureOfInjuryOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Hazard Group
            </NexusLabel>
            <NexusSelect
              value={hazardGroup}
              data-testid="HazardGroup"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ hazardGroup: event.target.value })
              }
            >
              {hazardGroupOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Prior Claims
            </NexusLabel>
            <NexusSelect
              value={priorClaims}
              data-testid="PriorClaims"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ priorClaims: event.target.value })
              }
            >
              {priorClaimsOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Industry Group
            </NexusLabel>
            <NexusSelect
              value={industryGroup}
              disabled={this.state.result}
              data-testid="IndustryGroup"
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ industryGroup: event.target.value })
              }
            >
              {industryGroupOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
          <div className="radio-container radioLebel">
            <NexusLabel
              data-testid="label-first-name"
              className="italicText"
              style={{ marginBottom: "-10px",
                marginTop: "0px" }}
            >
                Source of Injury
            </NexusLabel>
            <NexusSelect
              value={sourceOfInjury}
              data-testid="sourceOfInjury"
              disabled={this.state.result}
              onInput={(event: React.ChangeEvent<HTMLNexusSelectElement>) => this.setState({ sourceOfInjury: event.target.value })
              }
            >
              {sourceOfInjuryOptions.map((item) => <option key={item} value={item}>
                {item}
              </option>)}
            </NexusSelect>
          </div>
        </div>
      </div>
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4 nexus-pl-1">
          <button
            type="button"
            data-testid="validateAddress"
            className="confirm-button"
            onClick={() => this.submit()}
          >
            {this.state.result ? "Reset" : "Try with Sample Data"}
          </button>
        </div>
      </div>
    </>;
    const rightTab = () => <>
      {!this.state.result && <div className="nexus-row nexus-ml-5" style={{ height: "50%" }}>
        <div className="nexus-col-lg-7 address-sample">
              Try Extracting Next Steps <br />
              with Sample Data
        </div>
      </div>
      }

      {this.state.result && <div className="nexus-row nexus-ml-5">
        <div className="address-head nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result">
              Test Result
        </div>
        <div
          className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-7 nexus-mt-4 nexus-col-flex nexus-ml-2"
          style={{ backgroundColor: "#FFD7BA" }}
        >
          <div className="nexus-p-2 respText">
                This is {this.state.result.segmentation?.toUpperCase()} claim at{" "}
            <br></br> {this.state.result.claimStage?.toUpperCase()} stage
          </div>
          <div className="nexus-p-2">
            <label className="respText">Next Steps:</label>
            <br />
            <label className="notification-matched-text">
              {this.state.result.nextBestAction}
            </label>
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Next Best Action",
      description:
        "The Nexus Next Best Action connector leverages an integration with Pega to provide an indication of the next best action during Disability Claims processing.",
      subTitle: "Pega",
      svg: Human,
      developerDoc: "next-best-actions"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design notification">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Next Best Action Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default NextBestAction;
