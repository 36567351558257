import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Details from "./Details/Details";
import FSSolution from "./FSSolution";
import { paths } from "../../../../utils/PathsUtils";
import AugmentedInteractionManager from "./FSSolutionsExploreMore/AugmentedInteractionManager";
import InteractionUtility from "./FSSolutionsExploreMore/InteractionUtility";
import ProducerLifeCycleManagement from "./FSSolutionsExploreMore/ProducerLifeCycleManagement";
import ActuarialStudio from "./FSSolutionsExploreMore/ActuarialStudio";
import UnderWritingWorkbench from "./FSSolutionsExploreMore/UnderWritingWorkbench";
import ConsentAndPreference from "./FSSolutionsExploreMore/ConsentAndPreference";
import InsuranceInsightsPlatform from "./FSSolutionsExploreMore/InsuranceInsightsPlatform";
import WealthInteroperability from "./FSSolutionsExploreMore/WealthInteroperability";
import MerchantOffersAndInsights from "./FSSolutionsExploreMore/MerchantOffersAndInsights";
import IntelligentDisputes from "./FSSolutionsExploreMore/IntelligentDisputes";
import MarketingInsightsDashboard from "./FSSolutionsExploreMore/MarketingInsightsDashboard";

const {
  nexusXDZone,
  home,
  FsSolutions,
  autoQuoteBind,
  carbonNeutralAauto,
  directCutomerTermLife,
  disablity,
  intligentDispute,
  merchantOffersInsght,
  morgageBrowseBook,
  petInsuranceQuoteBind,
  singleViewCustomer,
  smallBusinessInsurance,
  underwitingWorkBench,
  claimEventHandler,
  nextGenClaim,
  associateWeb,
  augmentedRelationshipManager,
  interactionUtility,
  producerLifeCycle,
  actuarialStudio,
  underWritingWorkbench,
  consentAndPreference,
  insuranceInsightsPlatform,
  wealthInteroperability,
  marketingInsightsDashboard
} = paths;

const NexusXDZoneRoutes = () => <Switch>
  <>
    <Route exact path={`${FsSolutions}`} component={FSSolution} />
    <Route path={`${FsSolutions}${autoQuoteBind}`} component={Details} />
    <Route path={`${FsSolutions}${carbonNeutralAauto}`} component={Details} />
    <Route
      path={`${FsSolutions}${directCutomerTermLife}`}
      component={Details}
    />
    <Route path={`${FsSolutions}${disablity}`} component={Details} />
    <Route
      path={`${FsSolutions}${intligentDispute}`}
      component={IntelligentDisputes}
    />
    <Route
      path={`${FsSolutions}${merchantOffersInsght}`}
      component={MerchantOffersAndInsights}
    />
    <Route path={`${FsSolutions}${morgageBrowseBook}`} component={Details} />
    <Route
      path={`${FsSolutions}${petInsuranceQuoteBind}`}
      component={Details}
    />
    <Route path={`${FsSolutions}${singleViewCustomer}`} component={Details} />
    <Route
      path={`${FsSolutions}${smallBusinessInsurance}`}
      component={Details}
    />
    <Route
      path={`${FsSolutions}${underwitingWorkBench}`}
      component={Details}
    />
    <Route path={`${FsSolutions}${claimEventHandler}`} component={Details} />
    <Route path={`${FsSolutions}${nextGenClaim}`} component={Details} />
    <Route
      path={`${FsSolutions}${associateWeb}`}
      component={InteractionUtility}
    />
    <Route
      path={`${FsSolutions}${augmentedRelationshipManager}`}
      component={AugmentedInteractionManager}
    />
    <Route
      path={`${FsSolutions}${interactionUtility}`}
      component={InteractionUtility}
    />
    <Route
      path={`${FsSolutions}${producerLifeCycle}`}
      component={ProducerLifeCycleManagement}
    />
    <Route
      path={`${FsSolutions}${actuarialStudio}`}
      component={ActuarialStudio}
    />
    <Route
      path={`${FsSolutions}${underWritingWorkbench}`}
      component={UnderWritingWorkbench}
    />
    <Route
      path={`${FsSolutions}${consentAndPreference}`}
      component={ConsentAndPreference}
    />
    <Route
      path={`${FsSolutions}${insuranceInsightsPlatform}`}
      component={InsuranceInsightsPlatform}
    />
    <Route
      path={`${FsSolutions}${wealthInteroperability}`}
      component={WealthInteroperability}
    />
    <Route
      path={`${FsSolutions}${marketingInsightsDashboard}`}
      component={MarketingInsightsDashboard}
    />
  </>
</Switch>;
export default NexusXDZoneRoutes;
