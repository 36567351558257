import {
  Aggregation,
  Consolidator,
  CustomerExperience,
  DataPipeline,
  DecisionEngine,
  DeadLetter,
  EventProcessor,
  EventEngine,
  EventQualifier,
  FiniteStateMachine,
  SimpleEvent,
  InsightEngine,
  NudgeState,
  BuildingBlockBanner,
  Ingestor,
  MapTracker,
  SendoutEnginge,
  StateEngine,
  Publisher,
  Subscriber,
  BatchManager,
  InsightWorkbench
} from "./Assets/image";

const banner = {
  image: BuildingBlockBanner,
  title: "Event Catalogue",
  subTitle:
    "Core Platform Building Blocks are configurable, cloud agnostic, loosely coupled, events-based components used in solving for key business problems in the financial services sector."
};

const list = [
  {
    name: "Aggregation Service",
    description:
      "Ability to take multiple event streams and aggregate them into a single consumable event for processing. Useful for when records might be duplicative.",
    domain: [],
    subDomain: [],
    icon: Aggregation,
    comingSoon: false
  },
  {
    name: "Batch Manager",
    description:
      "Processes inbound data provided in a batch file and converts each record into individual events for streaming into the platform.",
    domain: [],
    subDomain: [],
    icon: BatchManager,
    comingSoon: false
  },
  {
    name: "Complex Event Processor",
    description:
      "Aggregates multiple events within a real-time stream through analysis, identifies and reacts to patterns related to that event to initiate subsequent processing.",
    domain: [],
    subDomain: [],
    icon: EventProcessor,
    comingSoon: false
  },
  {
    name: "Consolidator Module",
    description:
      "Responsible for consuming all relevant topics across the platform and publishing to a single event stream that will be used for tracking and recording state. The consolidator can be added to any event map and redirects to the target topic.",
    domain: [],
    subDomain: [],
    icon: Consolidator,
    comingSoon: false
  },
  {
    name: "Customer Experience Manager",
    description:
      "Intelligently arbitrates nudges and communications for customers to optimise their experience through scoring and prioritisation using contextual data and insights.",
    domain: [],
    subDomain: [],
    icon: CustomerExperience,
    comingSoon: false
  },
  {
    name: "Data Pipeline",
    description:
      "Responsible for validating, cleansing, transforming, and enriching event data payloads, as well as propagating and storing the event data payload into data storage.",
    domain: [],
    subDomain: [],
    icon: DataPipeline,
    comingSoon: false
  },
  {
    name: "Dead Letter Queue",
    description:
      "Event failure mechanism that captures and stores events containing data about failures in the platform. Events are analysed to enable manual intervention and resolution.",
    domain: [],
    subDomain: [],
    icon: DeadLetter,
    comingSoon: false
  },
  {
    name: "Decision Engine",
    description:
      "Engine which allows configuration of business rules and AI/ML to ingest events and process an outcome.  This engine will be configurable for specific business use cases",
    domain: [],
    subDomain: [],
    icon: DecisionEngine,
    comingSoon: false
  },
  {
    name: "Event Engine",
    description:
      "Choreographs the flow of events between individual components in the platform based on the identified event type.",
    domain: [],
    subDomain: [],
    icon: EventEngine,
    comingSoon: false
  },
  {
    name: "Event Qualifier",
    description:
      "Ingests and identifies events from raw signals based on recognised patterns, events are qualified based on defined business rules and routed to the Event Engine.",
    domain: [],
    subDomain: [],
    icon: EventQualifier,
    comingSoon: false
  },
  {
    name: "Finite State Machine",
    description:
      "Track the finite states through all processing stages of an event",
    domain: [],
    subDomain: [],
    icon: FiniteStateMachine,
    comingSoon: false
  },
  {
    name: "Insights Engine",
    description:
      "Processes real-time events through the execution of productionised AI/ML models to infer insights, which can be used for further processing and decisioning.",
    domain: [],
    subDomain: [],
    icon: InsightEngine,
    comingSoon: false
  },
  {
    name: "Nudge State Machine",
    description:
      "Track the finite states through all processing stages of a nudge",
    domain: [],
    subDomain: [],
    icon: NudgeState,
    comingSoon: false
  },
  {
    name: "Simple Event Processor",
    description:
      "SEP module listens to a specific topic and validates the event received based on pre-defined properties. SEP is used when there is requirement to match stateless specific behaviour in the event stream.",
    domain: [],
    subDomain: [],
    icon: SimpleEvent,
    comingSoon: false
  },
  {
    name: "Ingestor",
    description:
      "Enables data ingress, processing, and subsequent downstream publishing.",
    domain: [],
    subDomain: [],
    icon: Ingestor,
    comingSoon: false
  },
  {
    name: "Map Tracker",
    description:
      "Responsible for consuming events in the system and updating maps to track event execution and journey progress.",
    domain: [],
    subDomain: [],
    icon: MapTracker,
    comingSoon: false
  },
  {
    name: "SendOut Engine",
    description:
      "Responsible for ingesting an event stream of raw data and in turn triggering an e-mail or SMS communication.",
    domain: [],
    subDomain: [],
    icon: SendoutEnginge,
    comingSoon: false
  },
  {
    name: "Publisher",
    description:
      "Publishes data to message queues (e.g. Kafka) or data persistence layers (e.g. MongoDB, Cassandra)",
    domain: [],
    subDomain: [],
    icon: Publisher,
    comingSoon: false
  },
  {
    name: "Subscriber",
    description: "Filters incoming message queues for event processing",
    domain: [],
    subDomain: [],
    icon: Subscriber,
    comingSoon: false
  },
  {
    name: "State Manager",
    description:
      "Ability to record and track events and journeys against expected flows",
    domain: [],
    subDomain: [],
    icon: StateEngine,
    comingSoon: false
  },
  {
    name: "Insights Workbench",
    description:
      "UI to enable users to build and optimise AI/ML models and publish them to the Insights Engine component for real-time...",
    domain: [],
    subDomain: [],
    icon: InsightWorkbench,
    comingSoon: false
  }
];

export { list, banner };
