/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-process-env */

import {
  authProvider,
  graphApiTokenReqConfig,
  spApiTokenReqConfig,
} from "../../azureAuth/authConfig";

class SharePointService {
  SharePointSiteURL = process.env.REACT_APP_EY_SHAREPPOINT_SITE_URL;

  // SharePoint List name : Data will be fetched from this all lists
  ListNames = {
    ECO_SYSTEM: "Ecosystem",
    ACCELERATORS: "Accelerators",
    COMPONENTS: "Components",
    INDUSTRY_SOLUTIONS: "Industry solutions",
    FILTERS: "Filters",
    CONNECTORS: "Connectors",
  };

  // This method will generate token for calling SharePoint API.
  async generateTokenForSPApi() {
    let trycount: number;
    trycount = 0;
    // Added retry while token generation as this is failing some time due to timeout
    while (trycount <= 3) {
      try {
        const response = await authProvider.acquireTokenSilent(
          spApiTokenReqConfig
        );
        return response.accessToken;
      } catch (error) {
        trycount += 1;
      }
    }
  }

  // This method will generate token for calling Graph API.
  async generateTokenForGraphApi() {
    let trycount: number;
    trycount = 0;
    // Added retry while token generation as this is failing some time due to timeout
    while (trycount <= 3) {
      try {
        const response = await authProvider.acquireTokenSilent(
          graphApiTokenReqConfig
        );
        return response.accessToken;
      } catch (error) {
        trycount += 1;
      }
    }
  }

  getHeaders(token: any) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const options = {
      method: "GET",
      headers: headers,
    };

    return options;
  }

  async getEcoSystems() {
    const token = await this.generateTokenForSPApi();
    const options = this.getHeaders(token);

    return fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.ECO_SYSTEM}')/items?$select=ID,Title,Description,Category,Icon&$orderby=Title asc`,
      options
    );
  }

  async getAccelerators() {
    const token = await this.generateTokenForSPApi();
    const options = this.getHeaders(token);

    return fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.ACCELERATORS}')/items?$select=ID,Title,Description,Category,Exploremore,Icon&$orderby=Title asc`,
      options
    );
  }

  async getComponents() {
    const token = await this.generateTokenForSPApi();
    const options = this.getHeaders(token);

    return fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.COMPONENTS}')/items?$select=ID,Title,Description,Icon&$orderby=Title asc`,
      options
    );
  }

  async getConnectors() {
    const token = await this.generateTokenForSPApi();
    const options = this.getHeaders(token);

    return fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.CONNECTORS}')/items?$select=ID,Title,Description,Category,Icon&$orderby=Title asc`,
      options
    );
  }

  async getIndustrySolutions() {
    const token = await this.generateTokenForSPApi();
    const options = this.getHeaders(token);

    return fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.INDUSTRY_SOLUTIONS}')/items?$select=ID,Title,Description,Category,Icon&$orderby=Title asc`,
      options
    );
  }

  async getFilters() {
    const token = await this.generateTokenForSPApi();
    const options = this.getHeaders(token);

    return fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.FILTERS}')/items?$select=ID,Title,Filters&$orderby=Filters asc`,
      options
    );
  }

  async getAttachments(listName: any, itemId: any, fileName: any) {
    const token = await this.generateTokenForSPApi();
    const response = await fetch(
      `${this.SharePointSiteURL}/_api/lists/getByTitle('${listName}')/items(${itemId})/AttachmentFiles('${fileName}')/$value`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json;odata=verbose",
        },
      }
    );
    const blob = await response.blob();

    return URL.createObjectURL(blob);
  }

  async getImage(serverRelativePath: any) {
    const token = await this.generateTokenForSPApi();
    const response = await fetch(
      `${this.SharePointSiteURL}/_api/Web/GetFileByServerRelativePath(decodedurl='${serverRelativePath}')/$value`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json;odata=verbose",
        },
      }
    );
    const blob = await response.blob();

    return URL.createObjectURL(blob);
  }
}

export default SharePointService;
