import { merchantOffers } from "../FSSolutions/Details/Const";
import {
  AutoInsurance,
  BusinessOwner,
  CardTransaction,
  GroupBenifits,
  IncomeProtection,
  LifeInsurance,
  PersonalProperty,
  PetInsurance,
  RentersInsurance,
  Mortage,
  acceleratorBannerImg,
  MerchantOffer
} from "./Assets/image";

const acceleratorBanner = {
  image: acceleratorBannerImg,
  title: "Nexus Financial Services Product Accelerators",
  subTitle:
    "Explore the accelerators built for a portfolio of financial services products within the FS Nexus Platform."
};

const acceleratorList = [
  {
    name: "Mortgage",
    URL: "mortgage",
    description:
      "Mortgage loans are used to buy a home or to borrow money against the value of a home you already own...",
    domain: ["Banking"],
    subDomain: [],
    icon: Mortage,
    comingSoon: true
  },
  {
    name: "Business Owners Insurance",
    URL: "business-owners-insurance",
    description:
      "Combines property and liability insurance by packaging these coverages into a single insurance policy...",
    domain: ["Insurance"],
    subDomain: [],
    icon: BusinessOwner,
    comingSoon: false
  },
  {
    name: "Auto Insurance",
    URL: "auto-insurance",
    description:
      "Provide financial protection against physical damage or bodily injury resulting from collisions & against liability that could also arise from incidents",
    domain: ["Insurance"],
    subDomain: [],
    icon: AutoInsurance,
    comingSoon: false
  },
  {
    name: "Life Insurance",
    URL: "life-Insurance",
    description:
      "Life insurance covers financial loss of an Insured upon the death and promises to pay assured sum amount to a designated beneficiary.",
    domain: ["Insurance"],
    subDomain: [],
    icon: LifeInsurance,
    comingSoon: false
  },
  {
    name: "Card Transaction Disputes",
    URL: "card-transaction-disputes",
    description:
      "Provide coverage across the card transaction disputes lifecycle...",
    domain: ["Banking"],
    subDomain: [],
    icon: CardTransaction,
    comingSoon: true
  },
  {
    name: "Personal Property Insurance",
    URL: "personal-property-insurance",
    description:
      "Provide coverage that can compensate you for personal items such as furniture, clothing and electronics that are damaged in a covered loss...",
    domain: ["Insurance"],
    subDomain: [],
    icon: PersonalProperty,
    comingSoon: false
  },
  {
    name: "Renters Insurance",
    URL: "renters-insurance",
    description:
      "Renters insurance protects your personal property in a rented apartment, condo or home from unexpected circumstances such as theft...",
    domain: ["Insurance"],
    subDomain: [],
    icon: RentersInsurance,
    comingSoon: false
  },
  {
    name: "Pet Insurance",
    URL: "pet-insurance",
    description:
      "Pet insurance is health insurance for your pet. It covers (reimburses) the costs of treating unexpected injuries and illnesses, so you don't have to worry...",
    domain: ["Insurance"],
    subDomain: [],
    icon: PetInsurance,
    comingSoon: false
  },
  {
    name: "Income Protection Insurance",
    URL: "income-protection-insurance",
    description:
      "Provide income security for circumstance around inability to work due to sickness, disability, other unexpected accidents or medical issues",
    domain: ["Insurance"],
    subDomain: [],
    icon: IncomeProtection,
    comingSoon: false
  },
  {
    name: "Group Benefits",
    URL: "group-benefits",
    description:
      "Insurance that covers a group of people who are usually the members of societies, employees of a common employer, or professionals in a common group.",
    domain: ["Insurance"],
    subDomain: ["Universal"],
    icon: GroupBenifits,
    comingSoon: true
  },
  {
    name: "Merchant Offers & Insights",
    URL: "merchant-offer-insights",
    description:
      "SendOut Engine enables the ability to ingest the event stream of raw data (in json, avro format) from a specific topic and turn it...",
    domain: ["Banking"],
    subDomain: ["Banking"],
    icon: MerchantOffer,
    comingSoon: true
  }
];

export { acceleratorList, acceleratorBanner };
