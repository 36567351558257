/* eslint-disable max-lines */
import { NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/vehicle-symbol",
  listName: "platform-capabilities/vehicle-symbol"
};

const VehicleTelematics = () => {
  // eslint-disable-next-line global-require
  const [reportType,
    setReportType] = useState("Timeline Events");
  const [userId,
    setUserId] = useState<any>(null);
  const [fromDate,
    setFromDate] = useState<any>(null);
  const [toDate,
    setToDate] = useState<any>(null);
  const [eventId,
    setEventId] = useState<any>(null);

  const [result,
    setResult] = useState<any>(null);

  const [loader,
    setLoader] = React.useState(false);

  const reportTypeList = [
    {
      label: "Aggregated Driving"
    },
    {
      label: "Behavior Score"
    },
    {
      label: "Timeline Events"
    },
    {
      label: "Trajectories"
    }
  ];

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
  }, []);

  const reportTypes = reportTypeList.map((item: any) => <option key={item.label} value={item.label}>
    {item.label}
  </option>);

  const reset = () => {
    setResult(null);
    setFromDate("");
    setToDate("");
    setUserId("");
  };
  const submit = () => {
    if (result) {
      reset();
    }
    else {
      if (reportType === "Timeline Events") {
        setFromDate("2021-05-01");
        setToDate("2021-05-01");
      }

      if (reportType === "Trajectories") {
        setEventId("6075b8688ce0bc07000001f5");
      }

      setUserId("6075b8688ce0bc07000001f5");

      setLoader(true);

      setTimeout(() => {
        setLoader(false);
        setResult(true);
      }, 2000);
    }
  };

  const card = {
    title: "Vehicle Telematics",
    description:
      "Provides carriers with detailed user and driver behavior metrics. Vehicle telematics combines GPS systems, onboard vehicle diagnostics, wireless telematics devices, and black box technologies to record and transmit vehicle data.",
    subTitle: "Sentiance",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "vehicle-telematics"
  };

  const leftTab = () => <>
    <div className="nexus-pr-4">
      <div className="nexus-row">
        <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
        </div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mb-3 font-bold">Select Report Type</div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Report Type</div>
            <div className="input-container">
              {!reportType && <div className="select-placeholder" style={{ width: "auto" }}>
                {"Timeline Events"}
              </div>
              }
              <select
                value={reportType}
                className="line-input-box"
                data-testid="report-type"
                disabled={result}
                onChange={(event) => setReportType(event.target.value)}
              >
                {reportTypes}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-4">
        <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            Step 02
        </div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mb-3 font-bold">Enter User details</div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">User Id</div>
            <div className="input-container">
              <input
                type="text"
                value={userId}
                data-testid="user-id"
                onChange={(event) => setUserId(event.target.value)}
                placeholder={"e.g. 6075b8688ce0bc07000001f5"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          {reportType === "Timeline Events" && <div className="mt-2 mb-3">
            <div className="italicText mb-2">From Date</div>
            <div className="input-container">
              <input
                type="text"
                value={fromDate}
                data-testid="from-date"
                onChange={(event) => setFromDate(event.target.value)}
                placeholder={"e.g. 2020-07-21"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          }
          {reportType === "Timeline Events" && <div className="mt-2 mb-3">
            <div className="italicText mb-2">Till Date</div>
            <div className="input-container">
              <input
                type="text"
                value={toDate}
                data-testid="till-date"
                onChange={(event) => setToDate(event.target.value)}
                placeholder={"e.g. 2020-08-10"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          }
          {reportType === "Trajectories" && <div className="mt-2 mb-3">
            <div className="italicText mb-2">Event Id</div>
            <div className="input-container">
              <input
                type="text"
                value={eventId}
                data-testid="event-id"
                onChange={(event) => setEventId(event.target.value)}
                placeholder={"e.g. 6075b8688ce0bc07000001f5"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          }
          <div>
            <button
              type="button"
              className="confirm-button"
              data-testid="submit-btn"
              onClick={() => {
                submit();
              }}
            >
              {result ? "Reset" : "Try with Sample Data"}
            </button>
          </div>
        </div>
      </div>
    </div>
  </>;
  const rightTab = () => <>
    {!result && <div
      className="nexus-row align-items-center nexus-ml-5"
      style={{ height: "100%" }}
    >
      <div className="address-sample">
            Try Vehicle Telematics with sample data
      </div>
    </div>
    }
    {result && <div className="nexus-ml-5 test-result-container">
      <div className="nexus-my-2 test-result">Test Result</div>
      <div>
        {reportType === "Timeline Events" && <div
          className="test-result-box nexus-mb-2 nexus-pl-3 nexus-py-2"
          style={{ width: "90%" }}
        >
          <div className="nexus-my-2 test-result">Address</div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    City
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
                    Washington
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    Latitude
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
                    38.88101
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    Longitude
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
                    -77.02722
            </div>
          </div>
          <div className="nexus-my-2 test-result">Places Visited</div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    Name
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
                    Maine Avenue Fish Market
            </div>
          </div>
          <div className="nexus-row" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-4 nexus-col-md-4 nexus-col-xs-2">
                    Category
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
                    Marketplace, Shop
            </div>
          </div>
        </div>
        }
        {reportType === "Behavior Score" && <div
          className="test-result-box nexus-mb-2 nexus-pl-3 nexus-py-2"
          style={{ width: "90%" }}
        >
          <div className="nexus-my-2 test-result">
                  Vehicle Behavior Score
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Overall
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.79
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Anticipative
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.86
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Smooth
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.79
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Focus
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    5
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hard Acceleration
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.90
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hard Brake
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.65
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hard Turn
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.955
            </div>
          </div>
        </div>
        }
        {reportType === "Aggregated Driving" && <div
          className="test-result-box nexus-mb-2 nexus-pl-3 nexus-py-2"
          style={{ width: "90%" }}
        >
          <div className="nexus-my-2 test-result">Driving scores</div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Anticipative
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.69
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Attention
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.95
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Focus
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    1
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hands Free Calling
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    1
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hand Held Calling
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    1
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hand Held Call Duration
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    25
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Handling without Calling
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    1
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hard Acceleration
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.95
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hard Brake
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.90
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Hard Events
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.69
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Legal
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.72
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Mounted
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.17
            </div>
          </div>
        </div>
        }
        {reportType === "Trajectories" && <div
          className="test-result-box nexus-mb-2 nexus-pl-3 nexus-py-2"
          style={{ width: "90%" }}
        >
          <div className="nexus-my-2 test-result">
                  Trajectory Waypoint
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Latitude
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.79
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Longitude
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    0.86
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Road Type
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    Residential
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Speed
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    23
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Distance
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    65.5
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Speed Limit
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    50
            </div>
          </div>
          <div className="nexus-row nexus-mb-1" style={{ width: "100%" }}>
            <div className="result-label nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-2">
                    Recorded Date
            </div>
            <div className="result-label">:</div>
            <div className="result-value nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4">
                    2019-03-22
            </div>
          </div>
        </div>
        }
      </div>
    </div>
    }
  </>;

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && <div className="loader-wrapper">
        <NexusProgressBar circle indeterminate className="loader-div" />
      </div>
      }
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Vehicle Telematics"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default VehicleTelematics;
