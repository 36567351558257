/* eslint-disable no-undefined */
import { Component } from "react";
import { banner } from "./Utils";
import { paths } from "../../../../utils/PathsUtils";
import SharedLandingPage from "../SharedComponent/SharedLandingPage";
import EnableAdobeLaunch from "../../../adobeAnalytics/EnableAdobeLaunch";
import SharePointService from "../../service/SharePointService";

const ADOBE_DATA = {
  pageName: "platform-tools",
  listName: "platform-tools",
};
const spService = new SharePointService();

const { tools } = paths;

class Tools extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    this.prepareItems();
  }

  prepareItems = () => {
    spService
      .getAccelerators()
      .then((res) => res.json())
      .then((data) => {
        const items: any = [];
        // Convert SharePoint Object into UI specific object
        data?.value?.map((element: any) => {
          items.push({
            name: element.Title,
            description: element.Description,
            icon: "",
            domain: element.Category === undefined ? [] : element.Category,
            id: element.ID,
          });
          // Check if icon is avaiable. If Yes call sharepoint Api to get the icon
          if (element.Icon) {
            spService
              .getAttachments(
                spService.ListNames.ACCELERATORS,
                element.ID,
                encodeURI(JSON.parse(element.Icon).fileName)
              )
              .then((imgData) => {
                const updatedItems = [...this.state.items];
                const currentItem = updatedItems.find(
                  (item) => item.id === element.ID
                );
                currentItem.icon = imgData;
                this.setState({
                  items: items,
                });
              });
          }

          return items;
        });
        this.setState({
          items: items,
        });
      });
  };

  redirectTo = (url: string) => {
    this.props.history.push(`${tools}/${url}`);
  };

  render() {
    return (
      <SharedLandingPage
        redirectFunction={this.redirectTo}
        bannerDetails={banner}
        cardList={this.state.items}
        showFilters={true}
        hideExploreBtn={true}
      />
    );
  }
}
export default Tools;
