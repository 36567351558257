/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-process-env */
let DYNAMIC_HEADER_FIELD: string;
class Service {
  token = localStorage.getItem("token");

  API_URL = process.env.REACT_APP_BASE_API_URL;

  API_KEY = process.env.REACT_APP_BASE_API_KEY;

  API_ENV = process.env.REACT_APP_BASE_ENV;

  requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "true",
      "access-control-allow-header": "*",
      "Authorization": this.token ? `Bearer ${this.token}` : "",
      "apikey": this.API_KEY ? this.API_KEY : "xkMWwdz3wby200KWM7iCZsNk5mgoVC0z",
      [DYNAMIC_HEADER_FIELD]: ""
    },
    body: ""
  };

  getToken() {
    const request = {
      method: "POST",
      body: JSON.stringify({
        client_id: process.env.REACT_APP_MISC_TOKEN_CLIENT_ID,
        client_secret: process.env.REACT_APP_MISC_TOKEN_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_MISC_TOKEN_GRANT_TYPE,
        resource: process.env.REACT_APP_MISC_TOKEN_RESOURCE
      }),
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(
      `https://nexusforinsurance-${this.API_ENV}.sbp.eyclienthub.com/ni/miscellaneous/app/keyvault/token`,
      request
    )
      .then((res) => res.text())
      .then((data) => {
        localStorage.setItem("token", data.toString());
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }

  // getCatalog() {
  //   this.getMethodParams();

  //   return fetch(`${this.API_URL}/ni/nexus/xd/zone/app/${this.API_ENV}/nexusxdzone/v1/catalog`, this.requestOptions);
  // }

  analyseImage(analyseImage: any) {
    this.postMethodParams(analyseImage);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/facialanalysis/app/v1/uploadimage`,
      this.requestOptions
    );
  }

  validateAddress(address: any) {
    this.postMethodParams(address);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/intladdress/validation/app/v1/validateinternationaladdress`,
      this.requestOptions
    );
  }

  weatherReport(param: any, opt: string) {
    this.postMethodParams(param);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/weather/app/v1/${opt}`,
      this.requestOptions
    );
  }

  crossSellOffering(payload: any) {
    this.postMethodParams(payload);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/crosssellofferings/app/v1/offerings`,
      this.requestOptions
    );
  }

  fraudDetection(payload: any) {
    this.postMethodParams(payload);

    return fetch(
      `${this.API_URL}/ni/frauddetection/app/v1/get`,
      this.requestOptions
    );
  }

  // industrysolution() {
  //   this.getMethodParams();

  //   return fetch(`${this.API_URL}/ni/nexus/xd/zone/app/${this.API_ENV}/nexusxdzone/v1/industrysolution`, this.requestOptions);
  // }

  // roadMap() {
  //   this.getMethodParams();

  //   return fetch(`${this.API_URL}/ni/nexus/xd/zone/app/${this.API_ENV}/nexusxdzone/v1/capability`, this.requestOptions);
  // }

  // ecoSysyem() {
  //   this.getMethodParams();

  //   return fetch(`${this.API_URL}/ni/nexus/xd/zone/app/${this.API_ENV}/nexusxdzone/v1/ecosystem`, this.requestOptions);
  // }

  // getTenenent(params: any) {
  //   this.putMethodParams(params);

  //   return fetch(`${this.API_URL}/nexuscore/nc-tenant/${this.API_ENV}/tenant`, this.requestOptions);
  // }

  // getVehicle(param: any) {
  //   this.getMethodParams();

  //   return fetch(`${this.API_URL}/nexusforinsurance/ni-vehicleinfo-proxy/${this.API_ENV}/vehicleinfo/${param}`, this.requestOptions);
  // }

  // submitDriver(params: any) {
  //   this.postMethodParams(params);

  //   return fetch(`${this.API_URL}/nexusforinsurance/ni-prefill-proxy/${this.API_ENV}/prefill`, this.requestOptions);
  // }

  // addressValidate(params: any, radioOption: any) {
  //   this.requestOptions.method = 'POST';
  //   this.requestOptions.body = JSON.stringify(params);
  //   this.requestOptions.headers.Authorization = `Bearer ${this.token}`;
  //   this.requestOptions.headers['X-InsurCode'] = radioOption;

  //   return fetch(`${this.API_URL}/nexusforinsurance/ni-addressvalidation-proxy/${this.API_ENV}/addressvalidation/v1/validateaddress`, this.requestOptions);
  // }

  getCarbonEmission(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/carbonemission/app/v1/getcarbonemission`,
      this.requestOptions
    );
  }

  scanDocument(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/docscan/app/v1/sendcombinedimagedata`,
      this.requestOptions
    );
  }

  getCommercialPrefilData(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/commercialpropertyprefill/app/commercialproperty/v1/property`,
      this.requestOptions
    );
  }

  fetchLocationAndTowingData(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/locationservice/app/v1/location`,
      this.requestOptions
    );
  }

  getVehicleHistory(params: any, url: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/vehicle/history/app/v1/${url}`,
      this.requestOptions
    );
  }

  // getBrand(params: any) {
  //   this.postMethodParams(params);

  //   return fetch(`${this.API_URL}/ni/vehicle/history/app/${this.API_ENV}/vehicle/v1/brand`, this.requestOptions);
  // }

  getDriverViloation(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/prefill/app/v1/prefill`,
      this.requestOptions
    );
  }

  getPostCodeDetails(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/postalcode/app/v1/retrieve`,
      this.requestOptions
    );
  }

  // addressParsing(params: any) {
  //   this.postMethodParams(params);

  //   return fetch(`${this.API_URL}/ni/address/parsing/app/${this.API_ENV}/parsing/v1/getparsing`, this.requestOptions);
  // }

  validatePhoneOrEmail(params: any, opt: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/emailphone/app/v1/${
        opt === "email" ? "emailverification" : "phonenumberverification"
      }`,
      this.requestOptions
    );
  }

  geoCoding(params: any, endpont: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/geocoding/app/v1/${endpont}`,
      this.requestOptions
    );
  }

  cutomerFeedBack(params: any, endpont: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "COSMOS";

    return fetch(
      `${this.API_URL}/ni/customerfeedback/app/v1/${endpont}`,
      this.requestOptions
    );
  }

  vehicleInfoByVIN(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/vehicle/info/app/v1/vin`,
      this.requestOptions
    );
  }

  vehicleInfoByREG(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/vehicle/info/app/v1/get`,
      this.requestOptions
    );
  }

  /* API by @Gaurav */
  getEarthquakeHazardRisk(params: any, endpont: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/hazardrisks/app/risks/v1/${endpont}/get`,
      this.requestOptions
    );
  }

  /* API by @Amresh */

  getAddressTypeahead(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/addresstypehead/app/v1/search`,
      this.requestOptions
    );
  }

  getHumanHealth(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/humanhealth/records/app/v1/getrecords`,
      this.requestOptions
    );
  }

  // sendSMSOrEmail(params: any, opt: any) {
  //   this.postMethodParams(params);

  //   return fetch(`${this.API_URL}/ni/notification/app/v1/${opt === 'email' ? 'sendemail' : 'sendsms'}`, this.requestOptions);
  // }

  nextAction(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/pcauto/bestaction/app/v1/nextaction`,
      this.requestOptions
    );
  }

  createRating(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/incomeprotection/rating/app/v1/create`,
      this.requestOptions
    );
  }

  // creditReport(params: any) {
  //   this.postMethodParams(params);
  //   this.requestOptions.headers['X-Insurcode'] = 'MOCK';
  //   this.requestOptions.headers['X-Vendor-Config'] = 'CREDREP_002';

  //   return fetch(`${this.API_URL}/ni/creditreport/app/v1/fetchreport`, this.requestOptions);
  // }

  getClueReports(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/cluereport/app/v1/getcluereport`,
      this.requestOptions
    );
  }

  getPropertyPrefill(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/property/prefill/app/v1/getpropertyprefill`,
      this.requestOptions
    );
  }

  getHealthSummary(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/healthscore/app/v1/health/summary`,
      this.requestOptions
    );
  }

  getVo2MaxSummary(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/healthscore/app/v1/vo2max/getsummary`,
      this.requestOptions
    );
  }

  getProducerLookup(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/producer/lookup/app/v1/search`,
      this.requestOptions
    );
  }

  getUnderwrittingReport(params: any) {
    this.postMethodParams(params);

    return fetch(
      `${this.API_URL}/ni/underwriting/app/v1/createunderwriting`,
      this.requestOptions
    );
  }

  getWaterLineReport(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/waterline/app/v1/getdata`,
      this.requestOptions
    );
  }

  getReplacementCostData(params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/replacementcost/app/v1/getreplacementcostdata`,
      this.requestOptions
    );
  }

  getRoofData(params: any, endpoint: string) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/roofdata/app/v1/${endpoint}`,
      this.requestOptions
    );
  }

  locationSuite(endponit: string, params: any) {
    this.postMethodParams(params);
    this.requestOptions.headers["X-Insurcode"] = "MOCK";

    return fetch(
      `${this.API_URL}/ni/location/suite/app/v1/${endponit}`,
      this.requestOptions
    );
  }

  // KEEP API CALL METHOD ABOVE
  postMethodParams(params: any) {
    const token = localStorage.getItem("token");
    this.requestOptions.method = "POST";
    this.requestOptions.headers.Authorization = `Bearer ${token}`;
    this.requestOptions.body = JSON.stringify(params);
  }

  getMethodParams() {
    this.requestOptions.method = "GET";
    this.requestOptions.headers.Authorization = "";
  }

  putMethodParams(params: any) {
    this.requestOptions.method = "PUT";
    this.requestOptions.body = JSON.stringify(params);
  }
}

export default Service;
