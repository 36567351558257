import {
  AutoBg,
  InsuranceBg,
  BusinessOwnerBg,
  IncomeProtectionBg,
  PetInsureBg,
  PersonalInsuranceBg,
  RenInsureBg
} from "../Assets/image";

const lifeInsuranceData = {
  cardName: "Life Insurance",
  bannerLogo: InsuranceBg,
  description:
    "Life insurance covers financial loss of an Insured upon the death and promises to pay assured sum amount to a designated beneficiary.",
  poweredby: "Socotra, GT Software, Host Bridge, 360GlobalNet, MS Dynamics",

  accelerators: [
    {
      head: "Term Life Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewals through interactions with a core policy administration system"
    },
    {
      head: "Term Life Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Whole Life Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement and cancellation through interactions with a legacy policy administration system"
    },
    {
      head: "Whole Life Funds Domain Service",
      decription:
        "Ability to manage the underlying funds based on customer’s investment decisions"
    },
    {
      head: "Endowment Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a workflow"
    },
    {
      head: "Wearables Domain Service",
      decription:
        "Ability to leverage wearables based health data for underwriting, policy lifecycle management or renewal processes"
    },
    {
      head: "Human Health Domain Service",
      decription:
        "Ability to use digital medical health record data tied to the end customer for underwriting, policy lifecycle management and renewal processes"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process the life claim end-to-end"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },

    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process"
    }
  ]
};

const businessOwnerData = {
  cardName: "Business Owners Insurance",
  bannerLogo: BusinessOwnerBg,
  description:
    "Combines property and liability insurance by packaging these coverages into a single insurance policy. BOP insurance helps cover claims of bodily injury or property damage and is often a good choice for small and medium-sized businesses, such as restaurants or retail shops.",
  poweredby: "Socotra",

  accelerators: [
    {
      head: "SME Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement and cancel a policy through interactions with a core policy administration system"
    },
    {
      head: "SME Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Professional Liability Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Professional Liability Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewals in the core system for professionals"
    },
    {
      head: "Endowment Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal in the core system"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow"
    },
    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process an auto claim end-to-end using 360GlobalNet (a no-code claims system)"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },
    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process "
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    }
  ]
};

const autoInsuranceData = {
  cardName: "Auto Coverage",
  bannerLogo: AutoBg,
  description:
    "Personal Auto Insurance across quote, bind, issue and servicing built into core systems across policy and claims.",
  poweredby: "Socotra, Guidewire, 360GlobalNet, MS Dynamics",

  accelerators: [
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Auto Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system"
    },
    {
      head: "Auto Claims Domain Service",
      decription:
        "Ability to manage a FNOL, create a claim, update or close a claims record through interactions with a core claims system"
    },
    {
      head: "360GlobalNet Claims Event Handler",
      decription:
        "Ability to leverage 360GlobalNet (a no-code claims system) for claims lifecycle and extend its core capabilities with data enrichment provided through the insurtech ecosystem"
    },
    {
      head: "Auto Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    },
    {
      head: "Driver Domain Service",
      decription:
        "Ability to manipulate driver information across any auto insurance related business process"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow"
    },

    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process an auto claim end-to-end using 360GlobalNet (a no-code claims system)"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Vehicle Domain Service",
      decription:
        "Ability to manage vehicle information across any auto insurance related business process"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },
    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process "
    }
  ]
};

const incomeProtectionData = {
  cardName: "Income Protection Insurance",
  bannerLogo: IncomeProtectionBg,
  description:
    "Provide income security for circumstance around inability to work due to sickness, disability, other unexpected accidents or medical issues.",
  poweredby: "Instanda, MS Dynamics",

  accelerators: [
    {
      head: "Income Protection Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, and cancel a policy through interactions with a core policy administration system"
    },
    {
      head: "Income Protection Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow"
    },
    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process an auto claim end-to-end using 360GlobalNet (a no-code claims system)"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },

    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    }
  ]
};
const petInsuranceData = {
  cardName: "Pet Insurance",
  bannerLogo: PetInsureBg,
  description:
    "Pet insurance is health insurance for your pet. It covers (reimburses) the costs of treating unexpected injuries and illnesses, so you don't have to worry...",
  poweredby: "Socotra, 360GlobalNet, MS Dynamics",

  accelerators: [
    {
      head: "Pet Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system"
    },
    {
      head: "Pet Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow"
    },
    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process an auto claim end-to-end using 360GlobalNet (a no-code claims system)"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },

    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process"
    }
  ]
};
const personalInsuranceData = {
  cardName: "Personal Property Insurance",
  bannerLogo: PersonalInsuranceBg,
  description:
    "Provide coverage that can compensate you for personal items such as furniture, clothing and electronics that are damaged in a covered loss and provide coverage that can compensate for your condo or your house",
  poweredby: "Socotra, Instanda",
  accelerators: [
    {
      head: "Personal Property Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system"
    },
    {
      head: "Personal Property Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow"
    },
    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process an auto claim end-to-end using 360GlobalNet (a no-code claims system)"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },
    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process "
    }
  ]
};

const renterInsuranceData = {
  cardName: "Renters Insurance",
  bannerLogo: RenInsureBg,
  description:
    "Protects your personal property in a rented apartment, condo or home from unexpected circumstances such as theft, natural calamities...",
  poweredby: "Socotra, Instanda, MS Dynamics",
  accelerators: [
    {
      head: "Renters Policy Domain Service",
      decription:
        "Ability to manage issuance, endorsement, cancellation, reinstatement and policy renewal through interactions with a core policy administration system"
    },
    {
      head: "Renters Submission Domain Service",
      decription:
        "Ability to create a quote, retrieve premium structure and lock-in the proposed premium in the underlying policy administration system"
    },
    {
      head: "Account Domain Service",
      decription:
        "Ability to manage customer information across the insurance value chain"
    },
    {
      head: "Payment Domain Service",
      decription:
        "Ability to manage customer payments across policy administration & claims systems, and record generated invoices using either a payment service provider (PSP) or a payment gateway"
    },
    {
      head: "Payment Workflow",
      decription:
        "Ability to manage inbound and outbound payments across the policy and claims lifecycle"
    },
    {
      head: "Underwriting Domain Service",
      decription:
        "Ability to create a custom task and route a customer applicaton to the underwriting workbench based on predefined business rules. It is usually used in tandem with a policy workflow"
    },
    {
      head: "360GlobalNet Claims Workflow",
      decription:
        "Ability to submit damage/loss details and process an auto claim end-to-end using 360GlobalNet (a no-code claims system)"
    },
    {
      head: "Core System Adapters",
      decription:
        "Adapters built to accelerate the implementation of specific core policy and claims systems for carriers looking to replatform or adopt a modern core system to their IT landscape"
    },
    {
      head: "Policy Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  policy application forms, previously incurred bills, utility bills) that may be required during quote-to-bind or policy issuance processes"
    },

    {
      head: "Claim Documents Domain Service",
      decription:
        "Ability to manage additional paper documents (i.e.  towing bills, police reports, taxi service) that may be required during the claims process"
    }
  ]
};

export {
  lifeInsuranceData,
  businessOwnerData,
  autoInsuranceData,
  incomeProtectionData,
  petInsuranceData,
  personalInsuranceData,
  renterInsuranceData
};
