import React, { Component } from "react";
import DisabledInput from "../DisabledInput";
import Step from "../Step";

interface InputList {
  labelTestid: string;
  labelTittle: string;
  inputTestid: string;
  value: string;
  isDisabled: boolean;
  placeholder: string;
}
interface Props {
  InputArray: InputList[];
}
class StepOne extends Component<any, Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      InputArray: this.props.InputArray
    };
  }

  render() {
    return (
      <>
        <Step stepNumber="Step 02" header="Enter your location" />
        <DisabledInput InputArray={this.props.InputArray} />
      </>
    );
  }
}

export default StepOne;
