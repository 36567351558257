import React from "react";
import "../CatalogStyle.scss";
import {
  NexusInput,
  NexusLabel,
  NexusProgressBar,
  NexusSelect
} from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import {
  CheckCircle,
  paymentIcon,
  Warning
} from "../../../../../assets/images/index";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/payment",
  listName: "platform-capabilities/payment"
};

interface State {
  result: any;
  buttonValue: string;
  amount: string;
  cardholderName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  loader: boolean;
  error: any;
  disableFields: boolean;
}

class Payment extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      result: null,
      buttonValue: "Pay Now",
      amount: "",
      cardholderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      loader: false,
      error: null,
      disableFields: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    // service.getToken();
  }

  onCardNumberChange(val: any) {
    let rawNumbers;
    rawNumbers = val.split("-").join("");
    if (rawNumbers.length > 0 && rawNumbers.length <= 16) {
      rawNumbers = rawNumbers.match(new RegExp(".{1,4}", "g")).join("-");
      this.setState({
        cardNumber: rawNumbers
      });
    }
  }

  onExpiryDateChange(val: any) {
    let rawNumbers;
    rawNumbers = val.split("/").join("");
    if (rawNumbers.length > 0 && rawNumbers.length <= 4) {
      rawNumbers = rawNumbers.match(new RegExp(".{1,2}", "g")).join("/");
      this.setState({
        expiryDate: rawNumbers
      });
    }
  }

  onCVVChange(val: string) {
    if (val.length > 0 && val.length <= 3) {
      this.setState({
        cvv: val
      });
    }
  }

  apiCall() {
    const { amount, cardholderName, cardNumber, expiryDate, cvv } = this.state;
    if (this.state.buttonValue === "Reset") {
      this.setState({
        buttonValue: "Pay Now",
        result: null,
        amount: "",
        cardholderName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        error: false,
        disableFields: false
      });
    }
    else if (
      cardNumber.length < 19 || cvv.length < 3 || expiryDate.length < 5 || cardholderName.length < 1 || amount.length < 1
    ) {
      this.setState({ error: true,
        loader: true });
      setTimeout(() => {
        this.setState({
          loader: false,
          result: "Not Null"
        });
      }, 1000);
    }
    else {
      this.setState({
        buttonValue: "Reset",
        amount: amount || "100",
        cardholderName: cardholderName || "Nexus Insurance",
        cardNumber: cardNumber || "1111-2222-3333-4444",
        expiryDate: expiryDate || "12/29",
        cvv: cvv || "111",
        loader: true,
        disableFields: true
      });
      setTimeout(() => {
        this.setState({
          loader: false,
          result: "Not Null",
          error: false
        });
      }, 1000);
    }
  }

  buttonSubmit() {
    return (
      <div className="nexus-row">
        <div className="nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2" />
        <div className="nexus-col-sm-3 nexus-col-md-6 nexus-col-lg-10 nexus-mt-4">
          <button
            type="button"
            data-testid="validateAddress"
            className="confirm-button"
            onClick={() => this.apiCall()}
          >
            {this.state.buttonValue}
          </button>
        </div>
      </div>
    );
  }

  commonResponse() {
    return (
      <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
          Try with Sample <br></br>Card details
        </div>
      </div>
    );
  }

  render() {
    const leftTab = () => {
      const {
        amount,
        cardholderName,
        cardNumber,
        expiryDate,
        cvv,
        disableFields
      } = this.state;

      return (
        <>
          <div className="nexus-row">
            <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
              Step 01
            </div>
            <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
              <div className="form-head">Input The Amount</div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  data-testid="label-first-name"
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  Amount
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="number"
                  data-testid="amount"
                  attrId="addressLine1"
                  placeholder="e.g. 100"
                  value={amount}
                  disabled={disableFields}
                  onInput={(event: any) => this.setState({ amount: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="nexus-row nexus-mt-3">
            <div className="result-label nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2">
              Step 02
            </div>
            <div className="nexus-col-sm-2 nexus-col-md-4 nexus-col-lg-6 nexus-flex-col">
              <div className="form-head">Input Card Details</div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  data-testid="label-first-name"
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "0px" }}
                >
                  Cardholder Name
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="cardHolderName"
                  attrId="addressLine1"
                  placeholder="e.g. Nexus Insurance"
                  value={cardholderName}
                  disabled={disableFields}
                  onInput={(event: any) => this.setState({ cardholderName: event.target.value })
                  }
                />
              </div>
              <div className="radio-container radioLebel">
                <NexusLabel
                  data-testid="label-first-name"
                  className="italicText"
                  style={{ marginBottom: "-10px",
                    marginTop: "-4px" }}
                >
                  Card Number
                </NexusLabel>
                <NexusInput
                  className="inputFieldText"
                  type="text"
                  data-testid="cardNumber"
                  attrId="addressLine1"
                  placeholder="e.g. 1111-2222-3333-4444"
                  value={cardNumber}
                  minLength={19}
                  maxLength={19}
                  disabled={disableFields}
                  onInput={(event: any) => this.onCardNumberChange(event.target.value)
                  }
                />
              </div>
              <div className="nexus-row">
                <div className="nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-6">
                  <div className="radio-container radioLebel">
                    <NexusLabel
                      data-testid="label-first-name"
                      className="italicText"
                      style={{ marginBottom: "-10px",
                        marginTop: "-4px" }}
                    >
                      Expiray Date
                    </NexusLabel>
                    <NexusInput
                      className="inputFieldText"
                      type="text"
                      data-testid="expiryDate"
                      placeholder="e.g. 05/24"
                      value={expiryDate}
                      minLength={5}
                      maxLength={5}
                      disabled={disableFields}
                      onInput={(event: any) => this.onExpiryDateChange(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-6">
                  <div className="radio-container radioLebel">
                    <NexusLabel
                      data-testid="label-first-name"
                      className="italicText"
                      style={{ marginBottom: "-10px",
                        marginTop: "-4px" }}
                    >
                      CVV
                    </NexusLabel>
                    <NexusInput
                      className="inputFieldText"
                      type="password"
                      data-testid="cvv"
                      placeholder="e.g. 235"
                      minLength={3}
                      maxLength={3}
                      disabled={disableFields}
                      value={cvv}
                      onInput={(event: any) => this.onCVVChange(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.buttonSubmit()}
        </>
      );
    };

    const rightTab = () => <>
      {this.state.result && <div className="nexus-row nexus-ml-5">
        <div className="address-head nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 test-result">
              Test Result
        </div>
        <div
          className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-8 nexus-col-flex nexus-mt-4 nexus-ml-2 nexus-pt-1 nexus-pb-4"
          style={{ backgroundColor: "#FFD7BA" }}
        >
          <div className="nexus-row nexus-pl-2 result-label">
            {this.state.error ? <div className="address-status-row">
              <div>
                <img
                  className="verified-icons"
                  src={Warning}
                  alt="CheckCircle"
                />
              </div>
              <div className="notification-matched-text nexus-ml-1">
                      Check your card details
              </div>
            </div> : <div className="address-status-row">
              <div>
                <img
                  className="verified-icons"
                  src={CheckCircle}
                  alt="CheckCircle"
                />
              </div>
              <div className="notification-matched-text nexus-ml-1">
                      Payment was successfull!
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      }

      {!this.state.result && this.commonResponse()}
    </>;
    const card = {
      title: "Payments",
      description:
        "Provide carriers with the ability to streamline and automate payment issuance and receival across multiple countries, currencies and with any payment provider.",
      subTitle: "Imburse, [Restricted entity]",
      svg: paymentIcon,
      developerDoc: "payment-gateway"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design notification">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Payments"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default Payment;
