import {
  IndustrySolBannerImage,
  ActuarialStudio,
  MarketingInsights,
  MerchantsOffersInsights,
  InsuranceInsightEnginge,
  AugmentedInteractionManager,
  IntelligentDisputes,
  InteractionUtility,
  UnderwritingWorkbench,
  WealthInteroperability,
  ConsentPreferenceUtility,
  ProducerLifecycleManagement
} from "./Assets/image";

const FSsolutionBanner = {
  image: IndustrySolBannerImage,
  title: "Industry Solutions Catalogue",
  subTitle:
    "Nexus solutions target the most sought after business problems and help our clients reach their growth and operational efficiency goals."
};
const solutionList = [
  // {
  //   name: 'Aperture',
  //   description: 'The EY Aperture solution addresses the problem of Catastrophic modeling for the Property Insurance market. Slip and SOV file information...',
  //   domain: ['Insurance'],
  //   subDomain: [],
  //   icon: Aperture,
  //   comingSoon: true,

  // },
  // {
  //   name: 'Connected Home',
  //   URL: '',
  //   description: 'As carriers begin to embrace Low Code/No Code core insurance platforms as viable options for their product offerings, new challenges...',
  //   domain: ['Insurance'],
  //   subDomain: [],
  //   icon: ConnectedHome,
  //   comingSoon: true
  // },
  // {
  //   name: 'Disability Claims Intake',
  //   URL: 'disability-claims-intake',
  //   description: 'A modular, cloud-native, api-driven solution to process claims against Disability Insurance policies, which cuts out the paper and the doctor visits...',
  //   domain: ['Insurance'],
  //   subDomain: [],
  //   icon: DisabilityClaims,
  //   comingSoon: false
  // },
  // {
  //   name: 'Customer Communications Management',
  //   URL: '',
  //   description: 'Nexus Customer Communication Management (CCM) Solution set out to solve for the disability claims intake process which involves...',
  //   domain: ['Insurance'],
  //   subDomain: [],
  //   icon: CustomerCommunicationsManagement,
  //   comingSoon: true
  // },
  {
    name: "Merchants Offers & Insights",
    URL: "merchants-offers-insights",
    description:
      "Personalized offers benefit cardholders by saving time & money for preferred experiences, merchants identify new audiences to engage with and draw...",
    domain: ["Banking"],
    subDomain: ["Banking"],
    icon: MerchantsOffersInsights,
    comingSoon: false
  },
  {
    name: "Intelligent Disputes",
    URL: "intelligent-disputes",
    description:
      "Helps issuers combat growing cost of transaction disputes by enriching the customer experience with third party data and using persona clustering...",
    domain: ["Banking"],
    subDomain: ["Banking"],
    icon: IntelligentDisputes,
    comingSoon: false
  },
  {
    name: "Augmented Interaction Manager",
    URL: "augmented-relationship-manager",
    description:
      "Solution to delivers insights and recommendations to the RM in real-time, based on the content of the client conversation, helping the RM to provide...",
    domain: ["Banking",
      "Wealth & Asset"],
    subDomain: ["Banking"],
    icon: AugmentedInteractionManager,
    comingSoon: false
  },
  {
    name: "Actuarial Studio",
    URL: "actuarial-studio",
    description:
      "Proprietary design studio built only for Actuaries to leverage AI/ML capabilities in actuarial sciences.",
    domain: ["Insurance"],
    subDomain: [],
    icon: ActuarialStudio,
    comingSoon: false
  },
  {
    name: "Consent & Preference Utility",
    URL: "consent-and-preference",
    description:
      "Single source of truth for customer consent and preference data and the tools necessary to make intelligent decisions in customers (and regulators) best interest",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: ConsentPreferenceUtility,
    comingSoon: false
  },
  {
    name: "Insurance Insights Platform",
    URL: "insurance-insights-platform",
    description:
      "Configurable operational insights and analytics solution that provides insights and analytics across functions of an insurance company",
    domain: ["Insurance"],
    subDomain: [],
    icon: InsuranceInsightEnginge,
    comingSoon: false
  },
  {
    name: "Interaction Utility",
    URL: "interaction-utility",
    description:
      "Identifies journeys to place a customer based on identified event patterns and generates personalized communications to customers...",
    domain: ["Banking"],
    subDomain: [],
    icon: InteractionUtility,
    comingSoon: false
  },
  {
    name: "Producer Lifecycle Management",
    URL: "producer-lifecycle",
    description:
      "Producer lifecycle and compliance management solution streamlines producer onboarding and regulatory risk management in distribution.",
    domain: ["Insurance"],
    subDomain: [],
    icon: ProducerLifecycleManagement,
    comingSoon: false
  },
  {
    name: "Underwriting Workbench",
    URL: "under-writing-workbench",
    description:
      "Streamlines processes, distributes work, accesses digitalized documents or emails, and brings in data streams and analysis for underwriters.",
    domain: ["Insurance"],
    subDomain: ["Insurance"],
    icon: UnderwritingWorkbench,
    comingSoon: false
  },
  {
    name: "Wealth Interoperability",
    URL: "wealth-interoperability",
    description:
      "Aggregates previously siloed data on a single platform and helps financial advisors access client data across multiple applications.",
    domain: ["Wealth & Asset"],
    subDomain: ["Insurance"],
    icon: WealthInteroperability,
    comingSoon: false
  },
  {
    name: "Marketing Insights Dashboard",
    URL: "marketing-insights",
    description:
      "The Marketing Insights Dashboard is a targeted performance reporting solution that enables effective end-to-end reporting.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: MarketingInsights,
    comingSoon: false
  }
];

export { solutionList, FSsolutionBanner };
