import React from "react";

const ColorCard = (props: any) => <div
  style={{ backgroundColor: props.data.color }}
  className="color-card nexus-flex-col"
>
  {props.data.image && <img src={props.data.image} alt="logo" className="card-image" />
  }
  <div className="color-card-text">{props.data.title}</div>
  {props.data.description && <div className="color-card-description">{props.data.description}</div>
  }
  {props.data.action && <div className="nexus-btn-primary nexus-btn-medium nexus-mt-4">
    {props.data.action}
  </div>
  }
</div>;
export default ColorCard;
