import React from "react";
import "../Catalog.scss";
import { NexusProgressBar } from "@nexus/react";
import CardDescription from "../../../CardDescriptions/CardDescription";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import {
  vehicleFront,
  vehicleFront1,
  VehicleDamageLogo
} from "../../../../../assets/images/index";
import Service from "../../../service/Service";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/Vehicle-Damage",
  listName: "platform-capabilities/Vehicle-Damage"
};

const { description } = new CardDescription();
const service = new Service();
class VehicleDamage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      result: null,
      buttonValue: "Try with Sample Data",
      loader: false
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    if (this.state.buttonValue === "Reset") {
      this.setState({ buttonValue: "Try with Sample Data" });
      this.setState({ result: null });
    }
    else {
      this.setState({ loader: true });
      this.setState({ buttonValue: "Reset" });
      setTimeout(() => {
        this.setState({ loader: false });
        this.setState({ result: "Not Null" });
      }, 1000);
    }
  }

  buttonSubmit() {
    return (
      <div style={{ marginTop: "137px" }}>
        <button
          type="button"
          data-testid="tryVehicleDamage"
          className="confirm-button"
          onClick={() => this.apiCall()}
        >
          {this.state.buttonValue}
        </button>
      </div>
    );
  }

  vehicleDamageImages() {
    return (
      <>
        <div className="row">
          <div className="form-head">Vehicle Damage images</div>
        </div>
        <div className="row row-cols-1 m-4">
          <div className="col pl-5">
            <img
              data-testid="vehicle-front-image"
              src={vehicleFront}
              alt="front-image"
              className=""
            />
          </div>
          <div className="col pl-5 mt-4">
            <img
              data-testid="vehicle-front-image-one"
              src={vehicleFront1}
              alt="front-image-one"
            />
          </div>
        </div>
        {this.buttonSubmit()}
      </>
    );
  }

  commonResponse() {
    const VehicleDamageResponse = [
      {
        value: "TOYOTA",
        text: "Make"
      },
      {
        value: "COROLLA",
        text: "Model"
      },
      {
        value: "5NPD84LF6JH214772",
        text: "VIN"
      },
      {
        value: "2020",
        text: "Loss Year"
      },
      {
        value: "Deployed",
        text: "Air Bags"
      },
      {
        value: "3006.71",
        text: "Repair Cost Estimate"
      },
      {
        value: "Repairable",
        text: "Decision"
      },
      {
        value: "Not a Total Loss",
        text: "Is Total Loss?"
      }
    ];

    return (
      <div>
        <div data-testid="headerTextId" className="headerText">
          Repair Estimates:
        </div>
        <div style={{ marginTop: "32px" }}>
          {VehicleDamageResponse.map((item) => <div key={item.value}>
            <div className="ValueText">{item.value}</div>
            <div className="underValue">{item.text}</div>
          </div>)}
        </div>
      </div>
    );
  }

  render() {
    const leftTab = () => <div className="nexus-ml-1">
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-10">
          <div className="form-head font-bold">Vehicle Damage images</div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mt-2">
            <img src={vehicleFront} alt="" className="" />
          </div>
          <div className="nexus-mt-2">
            <img src={vehicleFront1} alt="" />
          </div>
        </div>
      </div>
      <div className="nexus-row nexus-mt-1">
        <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2"></div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          {this.buttonSubmit()}
        </div>
      </div>
    </div>;
    const rightTab = () => <>
      {!this.state.result && <div
        className="row justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <div className="address-sample" style={{ maxWidth: "289px" }}>
              Try Vehicle Damage Estimation with a Sample image
        </div>
      </div>
      }

      {this.state.result && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="nexus-col-lg-10"
          style={{
            background: "#FFD7BA",
            minWidth: "457px",
            minHeight: "137px",
            padding: 0
          }}
        >
          <div className="align-items-start rating-response p-4">
            {this.commonResponse()}
          </div>
        </div>
      </div>
      }
    </>;
    const card = {
      title: "Vehicle Damage Connector",
      description: description["Vehicle Damage Recognition"],
      subTitle: "Claim Genius",
      svg: VehicleDamageLogo,
      developerDoc: "vehicle-damage"
    };

    return (
      <div className="nexus-container padding-margin-0 updated-design vehicleDamage">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Vehicle Damage Estimation"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default VehicleDamage;
