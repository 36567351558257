import React from "react";
import { NexusRadio, NexusProgressBar, NexusSelect } from "@nexus/react";
import CardDescription from "../../../CardDescriptions/CardDescription";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import Service from "../../../service/Service";
import { geocoding } from "../../../../../assets/images";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/address-typeahead",
  listName: "platform-capabilities/address-typeahead"
};

interface Address {
  address: string;
  country: string;
}
interface BodyRequest {
  country: string;
  maxCandidates: number;
  returnAdminAreasOnly: string;
  searchText: string;
}

interface State {
  result: any;
  buttonValue: string;
  forward: boolean;
  loader: boolean;
  usAddress: Address;
  nonUsAddress: Address;
  ukBodyRequest: BodyRequest;
  usBodyRequest: BodyRequest;
}

const service = new Service();
const { description } = new CardDescription();

class AddressTypehead extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      usAddress: {
        address: "1 Infinite Loop, Cupertino, CA",
        country: "us"
      },
      nonUsAddress: {
        address: "Ta-verne Lane Dunfermline",
        country: "UK"
      },
      usBodyRequest: {
        country: "US",
        maxCandidates: 10,
        returnAdminAreasOnly: "N",
        searchText: "1451 times sq"
      },
      ukBodyRequest: {
        country: "GBR",
        maxCandidates: 10,
        returnAdminAreasOnly: "N",
        searchText: "Birmingham"
      },
      result: null,
      buttonValue: "Try with Sample Data",
      forward: true,
      loader: false
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  setButtonText(text: string) {
    this.setState({ buttonValue: text });
  }

  apiCall() {
    if (this.state.buttonValue === "Reset") {
      this.setButtonText("Try with Sample Data");
      this.setState({ result: null });
    }
    else {
      this.setState({ loader: true });
      this.setButtonText("Reset");
      // const endpont = this.state.forward ? 'create' : 'get';
      const requestParam = this.state.forward ? this.state.usBodyRequest : this.state.ukBodyRequest;
      service
        .getAddressTypeahead(requestParam)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            this.setState({ result: data.data });
            this.setState({ loader: false });
          }
          if (data.error) {
            this.setState({ loader: false });
          }
          // setTimeout(() => {}, 100);
        })
        .catch();
    }
  }

  buttonSubmit() {
    return (
      <div className="nexus-mb-3">
        <button
          type="button"
          className="confirm-button"
          onClick={() => this.apiCall()}
          data-testid="submit-btn"
        >
          {this.state.buttonValue}
        </button>
      </div>
    );
  }

  changeRirectionOption(option: boolean) {
    if (this.state.forward !== option) {
      this.setButtonText("Try with Sample Data");
      // this.state.result = null;
    }
    this.setState({ forward: option });
  }

  directionOption() {
    return (
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="form-head">Pick One</div>
          <div className="nexus-row radio-container nexus-ml-1">
            <NexusRadio
              name="option"
              id="forward"
              checked={this.state.forward}
              disabled={this.state.buttonValue === "Reset"}
              onInput={() => this.changeRirectionOption(true)}
              data-testid="country-us"
            >
              US
            </NexusRadio>
            <NexusRadio
              className="ml-5"
              name="option"
              id="reverse"
              data-testid="country-non-us"
              checked={!this.state.forward}
              disabled={this.state.buttonValue === "Reset"}
              onInput={() => this.changeRirectionOption(false)}
            >
              Non US
            </NexusRadio>
          </div>
        </div>
      </div>
    );
  }

  usAddress() {
    const { address } = this.state.usAddress;

    return (
      <>
        {this.directionOption()}
        <div className="nexus-row  nexus-mt-2">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
            Step 02
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="form-head">Enter Address</div>
            <div className="address-form">
              <div className="nexus-mt-1 nexus-mb-2">
                <div className="italicText nexus-mb-1">Address Text:</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    data-testid="address"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g. ${address}` : "1451 times sq"
                    }
                    disabled
                  />
                </div>
              </div>
              {this.buttonSubmit()}
            </div>
          </div>
        </div>
      </>
    );
  }

  commonRes() {
    const { searchResponse } = this.state.result;

    return (
      searchResponse && <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="nexus-col-lg-7 padding-margin-0">
          {searchResponse.map((item: any, index: number) => <div
            key={index}
            className="test-result-box nexus-mb-2 nexus-p-2"
            style={{ width: "auto" }}
          >
            <div className="nexus-mb-1 result-label">
              {item.address.addressLine1}
            </div>
            <div className="nexus-mb-1 result-label">
              {item.address.city}
              {" , "}
              {item.address.state}
            </div>
            <div className="result-label">{item.address.postCode}</div>
          </div>)}
        </div>
      </div>

    );
  }

  nonUsAddress() {
    const { address } = this.state.nonUsAddress;
    const countries = [
      {
        name: "UK",
        value: "UK"
      }
    ];

    return (
      <>
        {this.directionOption()}
        <div className="nexus-row nexus-mt-2">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
            Step 02
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="form-head">Enter Address</div>
            <div className="address-form">
              <div className="nexus-mt-1 nexus-mb-2">
                <div className="italicText nexus-mb-1">Country :</div>
                <div className="input-container">
                  <NexusSelect
                    value={this.state.nonUsAddress.country}
                    disabled
                    data-testid="country"
                  >
                    {countries.map((item) => <option key={item.value} value={item.value}>
                      {item.name}
                    </option>)}
                  </NexusSelect>
                </div>
              </div>
              <div className="nexus-mt-1 nexus-mb-2">
                <div className="italicText nexus-mb-1">Address Text :</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    data-testid="adress-non-us"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g. ${address}` : "Birmingham"
                    }
                    disabled
                  />
                </div>
              </div>
              {this.buttonSubmit()}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const leftTab = () => {
      if (this.state.forward) {
        return this.usAddress();
      }

      return this.nonUsAddress();
    };

    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try Extracting Address with Sample Data
        </div>
      </div>
      }

      {this.state.result && this.commonRes()}
    </>;
    const card = {
      title: "Address Typeahead",
      description: description["Address Typeahead"],
      subTitle: "Smarty Streets, Precisely",
      svg: geocoding,
      developerDoc: "address-type-head"
    };

    return (
      <div className="nexus-container updated-design padding-margin-0">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Address Typeahead Tool"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default AddressTypehead;
