import React, { Component } from "react";
import "./style.scss";
import { contactUs } from "../CorePlatformPricing/Assets";
import { NexusTextarea, NexusInput, NexusLabel } from "@nexus/react";

class ContactUs extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      details: ""
    };
  }

  render() {
    return (
      <div>
        <div className="nexus-col-xs-4 nexus-my-5">
          <div className="nexus-row">
            <div className="nexus-col-xs-4 nexus-center-xs">
              <div className="nexus-row">
                <div className="nexus-col-xs-4">
                  <span className="contact-us-txt">Contact Our Team</span>
                </div>
                <div className="nexus-col-xs-4 nexus-mt-3">
                  Enter your details to get support on Core Platform Sales
                </div>
              </div>
            </div>
            <div
              className="nexus-col-xs-4 nexus-mt-4 contact-us-background"
              style={{ backgroundImage: `url(${contactUs})`,
                height: "700px" }}
            >
              <div className="nexus-row">
                <div className="nexus-col-xs-2">
                  <div className="nexus-row">
                    <div className="nexus-col-xs-4 welcome-text">
                      Welcome to Nexus
                    </div>
                    <div className="nexus-col-xs-4 contact-us-txt-heading">
                      Contact Us
                    </div>
                  </div>
                </div>
                <div className="nexus-col-xs-2 contact-us-form-container">
                  <div className="nexus-row contact-us-form">
                    <div className="nexus-col-xs-4 contact-field">
                      <NexusLabel>email</NexusLabel>
                      <NexusInput
                        data-testid="email"
                        placeholder="Enter your email"
                        style={{ backgroundColor: "#F7F8FA" }}
                        value={this.state.email}
                        onInput={(event: any) => this.setState({ email: event.target.value })
                        }
                      ></NexusInput>
                    </div>
                    <div className="nexus-col-xs-4 contact-field contact-us-desc">
                      <NexusLabel>
                        Enter details around the client opportunity
                      </NexusLabel>
                      <NexusTextarea
                        data-testid="details"
                        value={this.state.details}
                        style={{ backgroundColor: "#F7F8FA" }}
                        onInput={(event: any) => this.setState({ details: event.target.value })
                        }
                      ></NexusTextarea>
                    </div>
                    <div className="nexus-col-xs-4 nexus-mt-2">
                      <button className="nexus-btn-primary cpp-contact-submit-button">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactUs;
