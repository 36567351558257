const InputArray = [
  {
    labelTestid: "addressLine",
    labelTittle: "Address Line",
    inputTestid: "addressLine-input",
    value: "600 CRANDON BLVD",
    isDisabled: true,
    placeholder: "600 CRANDON BLVD",
  },
  {
    labelTestid: "city",
    labelTittle: "City",
    inputTestid: "city-input",
    value: "Key Biscayne",
    isDisabled: true,
    placeholder: "Key Biscayne",
  },
  {
    labelTestid: "state",
    labelTittle: "State",
    inputTestid: "state-input",
    value: "FL",
    isDisabled: true,
    placeholder: "FL",
  },
  {
    labelTestid: "postalcode",
    labelTittle: "Postal Code",
    inputTestid: "postalcode-input",
    value: "33149",
    isDisabled: true,
    placeholder: "33149",
  },
  {
    labelTestid: "addresstypecode",
    labelTittle: "Address Type Code",
    inputTestid: "addresstypecode-input",
    value: "Risk Address",
    isDisabled: true,
    placeholder: "Risk Address",
  },
];
const apiRequestParams = {
  pInputRequest: {
    cdata: {
      signonRq: {
        signonPswd: {
          custId: {
            spName: "com.iso",
          },
          custPswd: {
            encryptionTypeCd: "NONE",
          },
        },
        custLangPref: "en-US",
      },
      passportSvcRq: {
        quoteback: "TEST REQUEST",
        spName: "com.iso",
        passportInqRq: {
          products: {
            productCd: "PRMNHZ00",
          },
          header: {
            resellerId: 102141,
            reportDataType: "02",
            returnReportsAsync: "N",
            userId: "QATESTER",
            returnOption: "Y",
            orderingCustomerOrgId: 102481,
            returnPartialResponse: "C",
            shippingCustomerOrgId: 102481,
          },
          propertyRequest: {
            addresses: {
              addr: {
                unparsedAddress: {
                  addr1: "600 CRANDON BLVD",
                  postalCode: 33149,
                  city: "KEY BISCAYNE",
                  stateProvCd: "FL",
                  addrTypeCd: "RiskAddress",
                },
              },
            },
          },
        },
      },
    },
  },
};
const formatedResData = {
  distanceToRiverUnit: "",
  flashFloodHazardWeightage: "",
  distanceFrom100yrInlandFloodHazard: "",
  distanceFrom100yrInlandFloodHazardUnit: "",
  distanceFrom100yrStormSurgeHazard: "",
  address: {
    zip: "",
    state: "",
    postalCity: "",
    zip4: "",
    city: "",
    county: "",
    streetAddress1: "",
  },
  relativeElevationFromNearestRiverOrCoastline: "",
  staticMapUrl: "",
  distanceToCoastUnit: "",
  relativeElevation100yrInlandFloodHazard: "",
  floodHazardScore: "",
  stormSurgeHazardWeightage: null,
  frequencyOfHurricanes: "",
  elevation: "",
  precipitation: "",
  fEMA: {
    communityNumber: "",
    communityEffectiveDate: "",
    floodZone: "",
    distanceToSFHA: null,
    communityName: "",
    baseFloodElevation: "",
  },
  distanceToRiver: "",
  riverineHazardWeightage: "",
  xmlnsi: null,
  standardOfProtection: "",
  relativeElevation100yrStormSurgeHazard: "",
  distanceToCoast: "",
  distanceFrom100yrStormSurgeHazardUnit: "",
};
export { InputArray, apiRequestParams, formatedResData };
