/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Warning } from "../../../../../../assets/images";
import { camel2title } from "../../../../../../utils/FunctionsUtils";

class CrimeHazardRiskRightView extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { themes } = this.props.result.crimeResponse;

    return (
      <div className="hazard-risk-crime-ctr">
        <strong>Test Result</strong>
        <div className="hazard-risk-crime">
          <div style={{ display: "flex" }}>
            <div>
              <img className="verified-icons" src={Warning} alt="warning" />
            </div>
            <div className="result-label" style={{ marginLeft: "15px" }}>
              Overall Hazard Risk score is{" "}
              <strong>
                {themes[0].crimeIndexTheme.indexVariable[0].score}{" "}
              </strong>{" "}
              and zone falls under{" "}
              <strong>
                {" "}
                {themes[0].crimeIndexTheme.indexVariable[0].category}{" "}
              </strong>{" "}
              risk category
            </div>
          </div>

          <div style={{ margin: "20px 0px 0px 45px" }}>
            <div className="address-matched-text">
              <strong>Hazard Risk Crime Scores </strong>
            </div>
          </div>

          <div className="container">
            <div className="row m-3">
              {themes[0]?.crimeIndexTheme?.indexVariable.map(
                (item: any, index: number) => <div key={index} className="col-12">
                  <div className="p-1">
                    <label style={{ fontWeight: "bold" }}>
                      {camel2title(item.name)} Zone:
                    </label>{" "}
                    {item.category}
                  </div>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CrimeHazardRiskRightView;
