import React, { ReactElement, useState } from "react";
import { NexusModal, NexusModalBody, NexusModalHeader } from "@nexus/react";

interface IModalComponent {
  modalBody: ReactElement<any, any>;
  setShowModal: (val: boolean) => void;
  showModal: boolean;
}

const ModalComponent = ({
  modalBody,
  setShowModal,
  showModal
}: IModalComponent) => <NexusModal
  data-testid="overlay"
  id="modal"
  fullscreen={false}
  size={"lg"}
  show={showModal}
>
  <NexusModalHeader
    data-testid="modal-header"
    onCloseEvent={() => setShowModal(false)}
  />
  <NexusModalBody>{modalBody}</NexusModalBody>
</NexusModal>;
export default ModalComponent;
