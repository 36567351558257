import React from "react";
import { NexusRadio, NexusProgressBar, NexusTextarea } from "@nexus/react";
import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import Service from "../../../service/Service";
import { CheckCircle, feedBack } from "../../../../../assets/images";
import {
  formatDate,
  dateInDMYformate
} from "../../../../../utils/FunctionsUtils";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-capabilities/customer-feedback",
  listName: "platform-capabilities/customer-feedback"
};

interface Feedback {
  customerRating?: string;
  comment?: string;
  createdDate?: any;
  system: string;
}

interface State {
  CFeedback: Feedback;
  RFeedback: Feedback;
  result: any;
  feedbacks: any;
  buttonValue: string;
  forward: boolean;
  loader: boolean;
}

const service = new Service();

class customerFeedBack extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      CFeedback: {
        customerRating: "FOUR",
        comment: "Great customer support from beginning to end of the process",
        system: "policyAdmin"
      },
      RFeedback: {
        system: "policyAdmin",
        createdDate: "2022-07-22T00:00:00Z"
      },
      result: null,
      feedbacks: null,
      buttonValue: "Try with Sample Data",
      forward: true,
      loader: false
      // error: null,
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }

  apiCall() {
    if (this.state.buttonValue === "Reset") {
      this.setState({
        buttonValue: "Try with Sample Data",
        result: null,
        feedbacks: null
      });
    }
    else {
      this.setState({ loader: true });
      this.setState({ buttonValue: "Reset" });
      const endpont = this.state.forward ? "create" : "get";
      const requestParam = this.state.forward ? this.state.CFeedback : this.state.RFeedback;
      service
        .cutomerFeedBack(requestParam, endpont)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            this.setState({ result: data.data });
            this.setState({ loader: false });
            if (!this.state.forward) {
              const feedbacks = data.data.feedback.reverse();
              this.setState({ feedbacks: feedbacks });
            }
          }
          if (data.error) {
            this.setState({ loader: false });
          }
          // setTimeout(() => {}, 100);
        })
        .catch();
    }
  }

  buttonSubmit() {
    return (
      <div className="nexus-my-4">
        <button
          type="button"
          className="confirm-button"
          onClick={() => this.apiCall()}
          data-testid="submit-btn"
        >
          {this.state.buttonValue}
        </button>
      </div>
    );
  }

  changeRirectionOption(option: boolean) {
    if (this.state.forward !== option) {
      this.setState({ buttonValue: "Try with Sample Data" });
      // this.state.result = null;
    }
    this.setState({ forward: option });
  }

  directionOption() {
    return (
      <div className="nexus-row">
        <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 01
        </div>
        <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="form-head">Pick One</div>
          <div className="nexus-row nexus-mt-2">
            <div className="nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
              <NexusRadio
                name="option"
                id="forward"
                checked={this.state.forward}
                disabled={this.state.result}
                data-testid="create-feedback"
                onInput={() => this.changeRirectionOption(true)}
              >
                Create Feedback
              </NexusRadio>
            </div>
            <div className="nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4">
              <NexusRadio
                name="option"
                id="reverse"
                checked={!this.state.forward}
                disabled={this.state.result}
                data-testid="receive-feedback"
                onInput={() => this.changeRirectionOption(false)}
              >
                Receive Feedback
              </NexusRadio>
            </div>
          </div>
        </div>
      </div>
    );
  }

  createFeedBack() {
    const { customerRating, comment } = this.state.CFeedback;

    return (
      <>
        {this.directionOption()}
        <div className="nexus-row nexus-mt-2">
          <div className="form-head step nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step">
            Step 02
          </div>
          <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="form-head">Enter Feedback</div>
            <div className="address-form">
              <div className="nexus-mt-1 nexus-mb-2">
                <div className="italicText nexus-mb-1">Customer Rating:</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="line-input-box"
                    data-testid="customer-rating"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${customerRating}` : customerRating
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="nexus-mt-1 nexus-mb-2">
                <div className="italicText nexus-mb-1">Comment:</div>
                <div className="input-container">
                  <NexusTextarea
                    data-testid="comments"
                    value={
                      this.state.buttonValue === "Try with Sample Data" ? `e.g.${comment}` : comment
                    }
                    disabled
                  />
                </div>
              </div>
              {this.buttonSubmit()}
            </div>
          </div>
        </div>
      </>
    );
  }

  createResponse() {
    const { createdDate } = this.state.result;
    const date = dateInDMYformate(formatDate(createdDate));

    return (
      <div className="test-result-container nexus-ml-5">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-row nexus-p-2"
          style={{ width: "auto" }}
        >
          <img className="verified-icons" src={CheckCircle} alt="CheckCircle" />
          <div className="result-label nexus-col-lg-10 nexus-col-md-6 nexus-col-xs-3">
            Customer Feedback of rating FOUR is successfully received on {date}
          </div>
        </div>
      </div>
    );
  }

  receiveResponse() {
    return (
      <div className="test-result-container nexus-ml-5">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div className="nexus-pb-4 test-result-box" style={{ width: "auto" }}>
          {this.state.feedbacks && this.state.result.feedback
            .slice(0, 6)
            .map((data: any, index: number) => <div
              className="nexus-px-4 nexus-pt-4"
              key={1 + data.createdDate}
            >
              <div className="test-result nexus-mb-1">{`Feedback ${
                index + 1
              }:`}</div>
              <div className="nexus-row">
                <div className="nexus-col-lg-5 nexus-col-md-8 nexus-col-xs-4 result-label">
                      Customer Rating
                </div>
                <div className="nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4 result-value">
                      : {data.customerRating}
                </div>
                <div className="nexus-col-lg-5 nexus-col-md-8 nexus-col-xs-4 result-label mt-2">
                      Created Date
                </div>
                <div className="nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4 result-value mt-2">
                      : {formatDate(data.createdDate)}
                </div>
                <div className="nexus-col-lg-5 nexus-col-md-8 nexus-col-xs-4 result-label mt-2">
                      Comments
                </div>
                <div className="nexus-col-lg-7 nexus-col-md-8 nexus-col-xs-4 result-value mt-2">
                      : {data.comment}
                </div>
              </div>
            </div>)}
        </div>
      </div>
    );
  }

  receiveFeeBack() {
    return (
      <>
        {this.directionOption()}
        <div className="nexus-ml-7">{this.buttonSubmit()}</div>
      </>
    );
  }

  render() {
    const leftTab = () => {
      if (this.state.forward) {
        return this.createFeedBack();
      }

      return this.receiveFeeBack();
    };

    const rightTab = () => <>
      {!this.state.result && <div
        className="nexus-row align-items-center nexus-ml-5"
        style={{ height: "100%" }}
      >
        <div className="address-sample">
              Try with Sample Feedback Rating
        </div>
      </div>
      }

      {this.state.result && <div>
        {this.state.forward ? this.createResponse() : this.receiveResponse()}
      </div>
      }
    </>;
    const card = {
      title: "Customer Feedback",
      description:
        "Provide carriers with the ability to receive and process valuable feedbacks and comments on their customer services.",
      subTitle: "TBD",
      svg: feedBack,
      developerDoc: "customer-feedback"
    };

    return (
      <div className="nexus-container customer-feedback updated-design padding-margin-0">
        {this.state.loader && <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
        }
        <CatalogHeader card={card} />
        <CatalogDetailsContainer
          title="Customer Feedback"
          leftTab={leftTab()}
          rightTab={rightTab()}
        />
      </div>
    );
  }
}

export default customerFeedBack;
