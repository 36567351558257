import { NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import Service from "../../../service/Service";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { CheckCircle } from "../../../../../assets/images";

const ADOBE_DATA = {
  pageName: "platform-capabilities/notification-binding",
  listName: "platform-capabilities/notification-binding",
};

const NotificationBinding = () => {
  const [deviceId, setDeviceId] = useState("");
  const [bindingType, setBindingType] = useState("");
  const [identity, setIdentity] = useState("");

  const [result, setResult] = useState<any>(null);
  const [loader, setLoader] = React.useState(false);

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);
  const reset = () => {
    setResult(null);
    setDeviceId("");
    setIdentity("");
    setBindingType("");
  };
  const submit = () => {
    if (result) {
      reset();
    } else {
      setDeviceId("IS91a0bc98796ca4cb524598607e426c77");
      setBindingType("FCN");
      setIdentity("Nexus");
      setResult(true);
    }
  };

  const card = {
    title: "Notification Binding",
    description:
      "Enables carrier to control their notifications workflow from their server and binds an identity to the address used by a notification channel.",
    subTitle: "Twilio",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "notification-binding",
  };

  const leftTab = () => (
    <>
      <div className="nexus-pr-4">
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
            Step 01
          </div>
          <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
            <div className="nexus-mb-3 font-bold">Enter service Id</div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Device Id</div>
              <div className="input-container">
                <input
                  type="text"
                  value={deviceId}
                  data-testid="device-id"
                  onChange={(event) => setDeviceId(event.target.value)}
                  placeholder={"e.g. IS91a0bc98796ca4cb524598607e426c77"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Binding Type</div>
              <div className="input-container">
                <input
                  type="text"
                  value={bindingType}
                  data-testid="binding-type"
                  onChange={(event) => setBindingType(event.target.value)}
                  placeholder={"e.g. APN"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 mb-3">
              <div className="italicText mb-2">Identity</div>
              <div className="input-container">
                <input
                  type="text"
                  value={identity}
                  data-testid="identity"
                  onChange={(event) => setIdentity(event.target.value)}
                  placeholder={"e.g. Nexus"}
                  className="line-input-box"
                  disabled
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className="confirm-button"
                data-testid="submit-btn"
                onClick={() => {
                  submit();
                }}
              >
                {result ? "Reset" : "Try with Sample Data"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const rightTab = () => (
    <>
      {!result && (
        <div
          className="nexus-row align-items-center nexus-ml-5"
          style={{ height: "100%" }}
        >
          <div className="address-sample">
            Try Notification Binding with sample data
          </div>
        </div>
      )}
      {result && (
        <div className="nexus-ml-5 test-result-container">
          <div className="nexus-my-2 test-result">Test Result</div>
          <div className="mobile-frame" />
        </div>
      )}
    </>
  );

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && (
        <div className="loader-wrapper">
          <NexusProgressBar circle indeterminate className="loader-div" />
        </div>
      )}
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Notification Binding"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default NotificationBinding;
