import React, { Component } from "react";
import Service from "../../../../service/Service";
import { arapiReqParams } from "../inputList";
import ResponseView from "./ResponseView";

interface ArrayType {
  rName: string;
  rValue: any;
}
interface State {
  sinkholeReport: ArrayType[];
  Isresponse: boolean;
}
interface Props {
  disableLoader: (value: boolean) => void;
}
const service = new Service();
class Sinkhole extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sinkholeReport: [],
      Isresponse: false
    };
  }

  componentDidMount() {
    service
      .locationSuite("sinkhole", arapiReqParams)
      .then((_res: any) => _res.json())
      .then((data: any) => {
        this.props.disableLoader(false);
        this.extractCitizenRecord(data.data.sinkHoles[0]);
        this.setState({ Isresponse: true });
      })
      .catch(() => {
        this.props.disableLoader(false);
      });
  }

  extractCitizenRecord(report: any) {
    const _sReport = [
      {
        rName: "Placement Type",
        rValue: report.placementType
      },
      {
        rName: "Sinkhole Risk score",
        rValue: report.riskScore
      },
      {
        rName: "Area Score",
        rValue: report.areaScore
      },
      {
        rName: "County Name",
        rValue: report.countyName
      }
    ];

    this.setState({ sinkholeReport: _sReport });
  }

  render() {
    const { Isresponse, sinkholeReport } = this.state;

    return (
      <div
        className="nexus-ml-5 test-result-container"
        style={{ display: Isresponse === true ? "" : "none" }}
      >
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-2 nexus-pb-4"
          style={{ width: "90%" }}
        >
          {sinkholeReport.length > 0 && <ResponseView tittle="Sinkhole Report" loop={sinkholeReport} />
          }
        </div>
      </div>
    );
  }
}

export default Sinkhole;
