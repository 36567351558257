import React, { Component } from "react";
import ComingSoon from "../../SharedComponent/ComingSoon/ComingSoon";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "resources-technical-messaging-guide",
  listName: "resources-technical-messaging-guide"
};

class TechnicalMessagingGuide extends Component {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  render() {
    return <ComingSoon />;
  }
}
export default TechnicalMessagingGuide;
