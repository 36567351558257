/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Warning } from "../../../../../../assets/images";

class DistanceToFloodHazardRiskRightView extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { waterBody } = this.props.result.distanceToFloodResponse;

    return (
      <div className="dist-to-flood-ctr">
        <strong>Test Result</strong>
        <div className="dist-to-flood">
          <div>
            <img className="verified-icons" src={Warning} alt="Warning" />
          </div>
          <div className="dist-to-flood-text ml-2 mt-1">
            {waterBody[0].name} is located within {waterBody[0].distance.value}{" "}
            {waterBody[0].distance.unit}
          </div>
        </div>
      </div>
    );
  }
}

export default DistanceToFloodHazardRiskRightView;
