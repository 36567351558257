import React, { Component } from "react";
import Service from "../../../../service/Service";
import { arapiReqParams } from "../inputList";
import ResponseView from "./ResponseView";

interface ArrayType {
  rName: string;
  rValue: any;
}
interface State {
  distanceToCoast: ArrayType[];
  Isresponse: boolean;
}
interface Props {
  disableLoader: (value: boolean) => void;
}
const service = new Service();
class CitizenTerritory extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      distanceToCoast: [],
      Isresponse: false
    };
  }

  componentDidMount() {
    service
      .locationSuite("citizenterritory", arapiReqParams)
      .then((_res: any) => _res.json())
      .then((data: any) => {
        this.props.disableLoader(false);
        this.extractCitizenRecord(data.data);
        this.setState({ Isresponse: true });
      })
      .catch(() => {
        this.props.disableLoader(false);
      });
  }

  extractCitizenRecord(risk: any) {
    const _riskData = [
      {
        rName: "Citizen code",
        rValue: risk.citizenRecords[0].citizenCode
      },
      {
        rName: "FIPS County code",
        rValue: risk.citizenRecords[0].countyFIPSCode
      },
      {
        rName: "County Name",
        rValue: risk.citizenRecords[0].countyName
      }
    ];
    this.setState({ distanceToCoast: _riskData });
  }

  render() {
    const { Isresponse, distanceToCoast } = this.state;

    return (
      <div
        className="nexus-ml-5 test-result-container"
        style={{ display: Isresponse === true ? "" : "none" }}
      >
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-2 nexus-pb-4"
          style={{ width: "90%" }}
        >
          {distanceToCoast.length > 0 && <ResponseView tittle="Citizen Record" loop={distanceToCoast} />
          }
        </div>
      </div>
    );
  }
}

export default CitizenTerritory;
