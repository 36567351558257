import React, { Component } from "react";
import "./style.scss";
import {
  Estpac,
  Sloan,
  Adnic,
  David,
  Ryan,
  Trent,
  Kathleen,
  Report1,
  Report2,
  Report3,
  Email,
  Arrow,
  Introduction,
  Mick,
  Paul,
  TrustedEcosystemHomePage,
  MarketReadinessHomePageImage,
  CoreAcceleratorsHomePageImage
} from "./Assets/images";
import Service from "../../service/Service";
import EnableAdobeLaunch from "../../../adobeAnalytics/EnableAdobeLaunch";
// import { Link } from 'react-router-dom';
import {
  NexusCard,
  NexusCarousel,
  NexusCarouselItem,
  NexusCardBody
} from "@nexus/react";
import ModalComponent from "./HomeModal/bannerModal";

const ADOBE_DATA = {
  pageName: "Nexus Home",
  listName: "Nexus Home"
};

const introContent = [
  {
    title: "Evolving customer expectations",
    desc: "Evolving customer expectations are putting pressure on our clients to innovate, yet our clients’ complex legacy systems can make it nearly impossible to experiment and bring new ideas to market quickly. This barrier to innovate turns into missed opportunities that hit the client’s bottom line."
  },
  {
    title: "A transformational platform",
    desc: "Enter FS Nexus, a transformation platform that helps clients innovate through a reusable technology foundation, so they can bring those new ideas and services to market faster and focus more time on the use case(s) and less time on the technology implementation."
  },
  {
    title: "Highly modular, scalable, open architecture",
    desc: "It does this by providing a highly modular, scalable, open architecture optimized for multi-cloud, a trusted ecosystem of curated partners and products, loosely coupled reusable services that integrate with a client’s existing technology stack, and development accelerators to quickly design, build and launch new products and digital services."
  }
];
const pillarContent = [
  {
    title: "Market Readiness",
    desc: "Nexus helps bring real-time experiences to market quickly by removing the gap between the customer needs and what the business can deliver. It does this by combining intelligent insights with predictive analytics and behavioral science to offer dynamic, personalized services.",
    img: MarketReadinessHomePageImage,
    bgColor: null,
    controls: [
      {
        text: "Our solutions",
        type: "internal",
        link: "/fs-solutions",
        width: null
      }
    ]
  },
  {
    title: "Core Platform",
    desc: "Nexus is built on an open, modular architecture. It features pre-built tools, accelerators and utilities that can be configured for unique business use cases and will evolve with the client’s business needs.",
    img: CoreAcceleratorsHomePageImage,
    bgColor: "tile-bg-color",
    controls: [
      {
        text: "Our platform",
        type: "internal",
        link: "/platform-building-blocks",
        width: null
      }
    ]
  },
  {
    title: "Trusted Ecosystem",
    desc: "Nexus brings together transformational technologies, data and services from trusted partners in a system that is designed for ease of application and incorporates EY’s financial services capabilities to drive business performance and long-term value.",
    img: TrustedEcosystemHomePage,
    bgColor: null,
    controls: [
      {
        text: "Our ecosystem",
        type: "internal",
        link: "/platform-ecosystem",
        width: null
      }
    ]
  }
];
const customerStories = [
  {
    title: "Introduce New Products to Market, Rapidly! ",
    desc: "“We introduced a market leading retail proposition that will enable the rapid growth of our consumer business lines. With Nexus, our time to market was reduced to 4 months from approximately 12 months!“",
    client: "ADNIC Chief Product Officer",
    logo: Adnic
  },
  {
    title: "Insights Driven Business Proposition",
    desc: "“We embarked on a journey to create a world where the bank could provide more customer-centric propositions, delivered in real-time, using rich, data-driven insights, underpinned by sophisticated technology and architecture.”",
    client: "Westpac CIO, Craig Bright",
    logo: Estpac,
    controls: [
      {
        text: "Learn More",
        type: "external",
        link: "https://www.ey.com/en_us/banking-capital-markets/why-real-time-customer-journey-curation-is-the-future-of-banking"
      }
    ]
  },
  {
    title: "Industry Utility for Corporate Lending",
    desc: "“Nexus platform provided our users with real-time information and tools to help modernize and simplify the complex servicing processes, increase liquidity and enable accelerated market expansion.”",
    client: "SLOAN Head of Commercial Banking",
    logo: Sloan
  }
];
const reports = [
  {
    title: "How to transform product development to outperform the competition",
    desc: "EY Nexus for Banking made this global bank‘s digital solution more reliable, more scalable, and more personalized, fast. ",
    logo: Report2,
    client: null,
    controls: [
      {
        text: "Learn More",
        type: "external",
        link: "https://www.ey.com/en_gl/banking-capital-markets/how-to-transform-bank-product-and-service-development"
      }
    ]
  },
  {
    title: "How curating ecosystems enables self-disruption",
    desc: "Nationwide Insurance required a new brand and a new technology platform to access a customer segment that may otherwise have been unobtainable.",
    logo: Report1,
    client: null,
    controls: [
      {
        text: "Learn More",
        type: "external",
        link: "https://www.ey.com/en_gl/insurance/how-curating-ecosystems-enables-self-disruption"
      }
    ]
  },
  {
    title:
      "How privacy and consent built customer trust for a financial institution",
    desc: "A privacy initiative at a financial institution helped it go beyond regulatory requirements, build brand loyalty and increase market share.",
    logo: Report3,
    client: null,
    controls: [
      {
        text: "Learn More",
        type: "external",
        link: "https://www.ey.com/en_pl/banking-capital-markets/how-privacy-and-consent-build-customer-trust"
      }
    ]
  }
];
const team = [
  {
    name: "David J Deane",
    role: "Global Lead",
    desc: "Ask me about the platform direction and what Nexus means to our firm and our clients",
    img: David,
    client: null
  },
  {
    name: "Ryan Battles",
    role: "Global Platform Lead",
    desc: "Ask me about platform commercialization, licensing and pricing strategies for potential client engagements",
    img: Ryan,
    client: null
  },
  {
    name: "Mick Smothers",
    role: "Architecture Lead",
    desc: "Ask me about the architectural principles and patterns for the platform, the architecture working group and governance processes, the tech stack and options, and architectural vision.",
    img: Mick,
    client: null
  }
];

const teamwithCenter = [
  {
    name: "Paul Gilmore",
    role: "Platform Technical Delivery Manager",
    desc: "Ask me about our platform delivery approach and development teams across sectors, industry products ",
    img: Paul,
    client: null
  },
  {
    name: "Kathleen Calabro",
    role: "Platform Product Manager",
    desc: "Ask me about our global product strategy & roadmap, industry-specific products, and client case studies",
    img: Kathleen,
    client: null
  }
];

const service = new Service();

class XDHome extends Component<any, any> {
  navigate(url: string, type: string) {
    if (type === "internal") {
      this.props.history.push(url);
    }
    else {
      window.open(url, "_blank", "noopener");
    }
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  render() {
    return (
      <div className="nexus-container home-page p-0">
        {/* <div className="nexus-row">
          <ModalComponent />
        </div> */}
        <div className="top-container nexus-p-6 m-0">
          <div className="nexus-row">
            <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-8 nexus-col-xl-8">
              <div className="top-title">Powering Frictionless Business</div>
              <div className="top-sub-title nexus-mb-4 nexus-mt-3">
                The Nexus platform is optimized for financial services and
                enables business transformation, accelerates innovation, unlocks
                value in client ecosystems and powers frictionless, personalized
                customer experiences
              </div>
            </div>
          </div>
          <span className="inro-title intro-title-color">Introducing </span>
          <span className="inro-title nexus-title-color">Nexus</span>
          <div className="nexus-row mt-4">
            {introContent.map((item) => <div
              key={item.title}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4"
            >
              <div className="intro-card p-4 mb-4">
                <div className="intro-card-title mb-3">{item.title}</div>
                <div className="intro-card-desc">{item.desc}</div>
              </div>
            </div>)}
          </div>
        </div>
        {/* <div className="intro-img"></div> */}
        <div>
          {pillarContent.map((item, index) => <div
            className="nexus-row m-0"
            key={item.title}
            style={{ backgroundColor: index % 2 > 0 ? "#F7F9FC" : "#ffffff" }}
          >
            {index % 2 > 0 && <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 p-0">
              <img src={item.img} alt="img" className="keypillar-img" />
            </div>
            }
            <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 nexus-middle-xs">
              <div className="nexus-p-4">
                <div className="key-pillar-title nexus-mb-2">
                  {item.title}
                </div>
                <div className="key-pillar-content">{item.desc}</div>
                {item.controls.map((control) => <div key={control.text} className="mt-4">
                  <button
                    type="button"
                    data-testid={`tstNexusKeyPillar${control.text}`}
                    className="nexus-btn btn-black"
                    style={{
                      minWidth: control.width ? control.width : "auto"
                    }}
                    onClick={() => control.link && this.navigate(control.link, control.type)
                    }
                  >
                    {control.text}
                    <img src={Arrow} alt="img" className="btn-img" />
                  </button>
                </div>)}
              </div>
            </div>
            {index % 2 === 0 && <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-6 p-0">
              <img src={item.img} alt="img" className="keypillar-img" />
            </div>
            }
          </div>)}
        </div>
        <div className="nexus-pt-5 nexus-pb-5">
          <div className="article-bg nexus-p-5 nexus-pt-5">
            <div className="card-title mb-5">Articles & Reports</div>
            <div className="nexus-row m-1">
              <NexusCarousel
                options={[
                  {
                    slidesToShow: 1,
                    overlapSize: 60
                  },
                  {
                    breakpoint: 768,
                    slidesToShow: 2,
                    overlapSize: 60
                  }
                ]}
              >
                {reports.map((item) => <NexusCarouselItem key={item.title} className="pe-4 p-0 mb-4">
                  <NexusCard
                    className="article-card p-0"
                    style={{ minHeight: "346px" }}
                  >
                    <NexusCardBody>
                      <img src={item.logo} alt="img" />
                      <div className="nexus-p-2">
                        <h2
                          className="article-title mb-2"
                          style={{ minHeight: "48px" }}
                        >
                          {item.title}
                        </h2>
                        <div style={{ minHeight: "75px" }}>{item.desc}</div>
                        <div>{item.client}</div>
                      </div>
                      {item.controls.map((control) => <div key={control.text} className="nexus-px-2">
                        <button
                          type="button"
                          className="nexus-btn btn-black"
                          onClick={() => control.link && this.navigate(control.link, control.type)
                          }
                        >
                          {control.text}
                        </button>
                      </div>)}
                    </NexusCardBody>
                  </NexusCard>
                </NexusCarouselItem>)}
              </NexusCarousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default XDHome;
