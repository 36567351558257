import React, { Component } from "react";
import Service from "../../../../service/Service";
import { arapiReqParams } from "../inputList";
import ResponseView from "./ResponseView";

interface ArrayType {
  rName: string;
  rValue: any;
}
interface State {
  publicProtection: ArrayType[];
  futureProtectionClass: ArrayType[];
  Isresponse: boolean;
}
interface Props {
  disableLoader: (value: boolean) => void;
}
const service = new Service();
class PublicProtection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      publicProtection: [],
      futureProtectionClass: [],
      Isresponse: false
    };
  }

  componentDidMount() {
    const apiReqParam = { ...arapiReqParams,
      futurePPC: false };
    service
      .locationSuite("publicprotection", apiReqParam)
      .then((_res: any) => _res.json())
      .then((data: any) => {
        this.props.disableLoader(false);
        this.extractCitizenRecord(data.data);
        this.setState({ Isresponse: true });
      })
      .catch(() => {
        this.props.disableLoader(false);
      });
  }

  extractCitizenRecord(risk: any) {
    const _publicProtection = [
      {
        rName: "PPC Code",
        rValue: risk.publicProtectionClasses[0].ppc
      },
      {
        rName: "Alternate PPC",
        rValue: risk.publicProtectionClasses[0].alternatePPC
      },
      {
        rName: "Fire Protection Area",
        rValue: risk.publicProtectionClasses[0].fireProtectionArea
      },
      {
        rName: "Fire Station Distance",
        rValue: risk.publicProtectionClasses[0].fireStationDistanceRange
      },
      {
        rName: "Responding Fire Station",
        rValue: risk.publicProtectionClasses[0].respondingFireStation
      },
      {
        rName: "Water Supply",
        rValue: `${risk.publicProtectionClasses[0].waterSupplyType} - ${risk.publicProtectionClasses[0].waterSupplyName}`
      },
      {
        rName: "County Name",
        rValue: risk.futureEffectivePublicProtectionClasses[0].countyName
      }
    ];
    const _futureProtectionClass = [
      {
        rName: "FEPPC Code",
        rValue: risk.futureEffectivePublicProtectionClasses[0].feppc
      },
      {
        rName: "Fire Protection Area",
        rValue:
          risk.futureEffectivePublicProtectionClasses[0].fireProtectionArea
      }
    ];
    this.setState({
      publicProtection: _publicProtection,
      futureProtectionClass: _futureProtectionClass
    });
  }

  render() {
    const { Isresponse, publicProtection, futureProtectionClass } = this.state;

    return (
      <div
        className="nexus-ml-5 test-result-container"
        style={{ display: Isresponse === true ? "" : "none" }}
      >
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-2 nexus-pb-4"
          style={{ width: "90%" }}
        >
          {publicProtection.length > 0 && <ResponseView
            tittle="Public Protection Classes"
            loop={publicProtection}
          />
          }
          {futureProtectionClass.length > 0 && <ResponseView
            tittle="Future Public Protection Classes"
            loop={futureProtectionClass}
          />
          }
        </div>
      </div>
    );
  }
}

export default PublicProtection;
