import React, { Component } from "react";

interface Props {
  stepNumber: string;
  header: string;
}
class Step extends Component<any, Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { stepNumber, header } = this.props;

    return (
      <>
        <div className="nexus-row">
          <div className="form-head nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-2 step-0">
            {stepNumber}
          </div>
          <div className="form-head nexus-col-sm-2 nexus-col-md-6 nexus-col-lg-10">
            <div className="form-head">{header}</div>
          </div>
        </div>
      </>
    );
  }
}

export default Step;
