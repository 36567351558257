import React, { Component } from "react";
import {
  lifeInsuranceData,
  businessOwnerData,
  autoInsuranceData,
  incomeProtectionData,
  petInsuranceData,
  personalInsuranceData,
  renterInsuranceData
} from "./Const";
import "./AccelatorsDetails.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";

class DetailsPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      cardDetails: null
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
    const urlParts = window.location.href.split("/");
    const cardName = decodeURIComponent(urlParts[urlParts.length - 1]);
    const ADOBE_DATA = {
      pageName: `platform-product-accelerators/${cardName}`,
      listName: `platform-product-accelerators/${cardName}`
    };
    EnableAdobeLaunch(ADOBE_DATA);
    switch (cardName) {
    case "life-Insurance":
      this.setState({ cardDetails: lifeInsuranceData });
      break;
    case "business-owners-insurance":
      this.setState({ cardDetails: businessOwnerData });
      break;
    case "auto-insurance":
      this.setState({ cardDetails: autoInsuranceData });
      break;
    case "income-protection-insurance":
      this.setState({ cardDetails: incomeProtectionData });
      break;
    case "pet-insurance":
      this.setState({ cardDetails: petInsuranceData });
      break;
    case "personal-property-insurance":
      this.setState({ cardDetails: personalInsuranceData });
      break;
    case "renters-insurance":
      this.setState({ cardDetails: renterInsuranceData });
      break;
    default:
    }
  }

  slateDoc() {
    window.open(
      "https://nexusforinsurance-dev.sbp.eyclienthub.com/nexusforinsurance/ni-apidocumentation-slate/dev/index.html#getting-started",
      "_blank",
      "noopener"
    );
  }

  render() {
    const { cardDetails } = this.state;

    return (
      cardDetails && <>
        <div
          className="nexus-container px-3"
          style={{ paddingBottom: "0px" }}
        >
          <div className="nexus-row px-2 mb-3">
            <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-12">
              <a
                data-testid="back-btn"
                style={{
                  textDecoration: "none",
                  color: "#2E2E38",
                  fontFamily: "EYInterstate Light",
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.props.history.push("/platform-product-accelerators");
                }}
              >
                  Nexus Product Accelerators{" "}
              </a>
              <strong className="nexus-m-1">{" > "}</strong>
              <strong
                style={{
                  fontFamily: "EYInterstate Regular",
                  fontWeight: "bold"
                }}
              >
                {cardDetails.cardName}
              </strong>
            </div>
          </div>

          <div
            className="nexus-row px-2 py-5 accelators-image"
            style={{
              paddingTop: "5%",
              paddingBottom: "5%",
              backgroundImage: `url(${cardDetails.bannerLogo})`
            }}
          >
            <div
              data-testid="tstHeading"
              className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-12 mb-4 accelarator-heading "
            >
              {cardDetails.cardName}
            </div>
            <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-12 mt-2 sub-heading ">
              {cardDetails.description}
            </div>
            <div className="nexus-col-xs-4 nexus-col-md-8 nexus-col-lg-12 mt-2 sub-heading ">
              <label
                className="font-weight-bold"
                style={{ fontFamily: "EYInterstate Regular" }}
              >
                  Powered by:
              </label>{" "}
              <label>{cardDetails.poweredby}</label>
            </div>
          </div>
        </div>

        <div
          className="nexus-container px-3"
          style={{ paddingTop: "32px",
            background: "#F7F8FA" }}
        >
          <div className="nexus-row px-2 pb-5">
            <div className="nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2">
              <label className="accelarator-title mt-3">Accelerators</label>
            </div>
            <div className="nexus-col-md-2 nexus-col-lg-6" />
            <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 nexus-end-lg ">
              <button
                type="button"
                className="nexus-btn nexus-btn-fluid"
                onClick={() => this.slateDoc()}
              >
                  Click Here for Technical Documentation
              </button>
            </div>
          </div>

          <div className="nexus-row px-2 pb-5">
            {cardDetails.accelerators.map((accelarator: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 nexus-p-1"
            >
              <div className="card" style={{ minHeight: "200px" }}>
                <div className="card-body">
                  <h5 className="accelarator-card-title mb-3 mt-2">
                    {accelarator.head}
                  </h5>
                  <p className="card-text accelarator-card-description">
                    {accelarator.decription}
                  </p>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </>

    );
  }
}
export default DetailsPage;
