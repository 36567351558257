import React from "react";
import "../Catalog.scss";
import { NexusInput } from "@nexus/react";
import moment from "moment";

class OwnershipPremium extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};

    return (
      <div>
        {vehicleData && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
          <div className="nexus-row mt-2 mb-3">
            <div className="nexus-col-xs-2 validated-address-value">
                VIN Number:{" "}
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicleData.vin}
            </div>
          </div>
          <div className="nexus-row mt-2 mb-3">
            <div className="nexus-col-xs-2 validated-address-value">
                First Title Registered Date:
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicleData.firstTitleRegDate.replace("T00:00:00Z", "")}
            </div>
          </div>
          <div className="nexus-row mt-2 mb-3">
            <div className="nexus-col-xs-2 validated-address-value">
                Last Title Registered Date:
            </div>
            <div className="nexus-col-xs-2 validated-address-text">
              {vehicleData.lastTitleRegDate.replace("T00:00:00Z", "")}
            </div>
          </div>
          <div className="nexus-row d-flex mt-5 vehicle-details-title">
              Vehicle Ownership History
          </div>
          <div className="flex-column d-flex mt-4 mb-3">
            {vehicleData?.ownershipDetails && vehicleData.ownershipDetails
              .map((item: any) => <div
                className="flex-column d-flex mb-3"
                key={item.ownerSeqNum}
              >
                <div className="nexus-row d-flex vehicle-details-title ml-2">
                  <div>Vehicle Owner</div>
                  <div className="ml-1">{`${item.ownerSeqNum}:`}</div>
                </div>
                <div className="nexus-row mt-2 ml-2">
                  <div className="nexus-col-xs-2 validated-address-value">
                          Ownership Start Date:
                  </div>
                  {item.startDate && <div className="nexus-col-xs-2 validated-address-text">
                    {moment(item.startDate).format("yyyy-MM-DD")}
                  </div>
                  }
                </div>
                <div className="nexus-row mt-1 ml-2">
                  <div className="nexus-col-xs-2 validated-address-value">
                          Ownership End Date:
                  </div>
                  {item.endDate && <div className="nexus-col-xs-2 validated-address-text">
                    {moment(item.endDate).format("yyyy-MM-DD")}
                  </div>
                  }
                </div>
                <div className="nexus-row mt-1 ml-2">
                  <div className="nexus-col-xs-2 validated-address-value">
                    {" "}
                          Registered State:
                  </div>
                  <div className="nexus-col-xs-2 validated-address-text">
                    {item.state}
                  </div>
                </div>
              </div>)
              .reverse()}
          </div>
        </div>
        }
      </div>
    );
  }
}

export default OwnershipPremium;
