import { NexusProgressBar } from "@nexus/react";
import React, { useState, useEffect } from "react";
import "../Catalog.scss";
import actionIcBookmarkBorder24px from "@nexus/core/dist/assets/icons/action/ic_bookmark_24px.svg";
import Service from "../../../service/Service";

import CatalogHeader from "../Header";
import CatalogDetailsContainer from "../DetailsContainer";
import "../CatalogStyle.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import { Warning } from "../../../../../assets/images";
import { hourlyforcast, dayforcast } from "./Forcast";
import weatherForcast from "./weatherforcast";
import currentCondition from "./currentCondtion";

const ADOBE_DATA = {
  pageName: "platform-capabilities/address-validation",
  listName: "platform-capabilities/address-validation"
};

const WeatherReport = () => {
  const [weatherReportType,
    setReportType] = useState("currentcondition");
  const [state,
    setState] = useState("");
  const [city,
    setCity] = useState("");
  const [latitude,
    setLatitude] = useState("");
  const [longitude,
    setLongitude] = useState("");

  const [deststate,
    setDestState] = useState("");
  const [destcity,
    setDestCity] = useState("");
  const [destlatitude,
    setDestLatitude] = useState("");
  const [destlongitude,
    setDestLongitude] = useState("");

  const [result,
    setResult] = useState<any>(null);
  const [loader,
    setLoader] = React.useState(false);

  const reportTypeList = [
    {
      label: "Current Condition",
      endpoint: "currentcondition"
    },
    {
      label: "Daily/Hourly Condition",
      endpoint: "hourlydaily"
    },
    {
      label: "Severe Weather Alerts",
      endpoint: "severeweatheralerts"
    },
    {
      label: "Weather Along Route",
      endpoint: "weatheralongroute"
    }
  ];

  const service = new Service();
  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA);
    service.getToken();
  }, []);
  const reset = () => {
    setResult(null);
    setReportType("currentcondition");
    setLongitude("");
    setCity("");
    setState("");
    setLatitude("");
    setDestCity("");
    setDestState("");
    setDestLatitude("");
    setDestLongitude("");
  };

  const submit = () => {
    let params;
    let res;
    if (result) {
      reset();
    }
    else {
      setCity("Liberty Hill");
      setState("Texas");
      setLatitude("30.66137610");
      setLongitude("-97.90218064");

      const location = {
        city: "Liberty Hill",
        state: "Texas",
        country: "US",
        postalCode: "100",
        longitude: "-97.90218064",
        latitude: "30.66137610"
      };

      const dstlocation = {
        city: "Chicago",
        state: "Illinois",
        country: "US",
        postalCode: "100",
        longitude: "41.95528",
        latitude: "-87.6279"
      };

      const locationsrc = {
        city: "Cincinnati",
        state: "Ohio",
        country: "US",
        postalCode: "100",
        longitude: "39.151235",
        latitude: "-84.48587"
      };

      switch (weatherReportType) {
      case "hourlydaily":
        params = {
          forecastType: "DAILY",
          interval: 5,
          units: "DAYS",
          location
        };
        break;
      case "severeweatheralerts":
        params = {
          alertType: "DEFAULT",
          limit: 5,
          location
        };
        break;
      case "currentcondition":
        params = {
          location
        };
        break;
      case "weatheralongroute":
        setDestCity("Chicago");
        setDestState("Illinois");
        setDestLatitude("41.95528");
        setDestLongitude("-87.6279");

        setCity("Cincinnati");
        setState("Ohio");
        setLatitude("39.151235");
        setLongitude("-84.48587");

        params = {
          destination: dstlocation,
          source: locationsrc,
          etaInMinutes: "string",
          waypoints: [
            {
              ...locationsrc,
              etaInMinutes: "string"
            }
          ]
        };
        break;
      default:
      }

      setLoader(true);
      service
        .weatherReport(params, weatherReportType)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setResult(data.data);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };
  const reportTypes = reportTypeList.map((item: any) => <option key={item.label} value={item.endpoint}>
    {item.label}
  </option>);
  const card = {
    title: "Weather Report",
    description:
      "Nexus Weather API gets the hourly or daily weather forecasts, the current weather conditions, the severe weather alerts, and the weather along a route.",
    subTitle: "Azure Weather",
    icon: actionIcBookmarkBorder24px,
    developerDoc: "check-code-verification"
  };

  const dailyOrHourlyCondition = () => <>
    <div>
      {dayforcast(result.dailyForecastResponse.forecasts[0])}
      {hourlyforcast(result.hourlyForecastResponse.forecasts[0])}
    </div>
  </>;
  const weatherAlongRoute = () => <>
    {result.weatherForecasts.map((item: any, index: number) => <div key={index}>
      {weatherForcast(item.forecast, item.location.city)}
    </div>)}
  </>;
  const severeWealthAlert = () => <>
    <div
      className="test-result-box nexus-p-4 nexus-mr-2"
      style={{ width: "auto" }}
    >
      <div className="result-value">
        <img
          className="verified-icons nexus-mr-1"
          src={Warning}
          alt="warning"
        />
        {result.alerts[0].alertAreas[0].summary.split("Source")[0]}
      </div>
      <div className="test-result interstate-light nexus-mt-4">
          Alert Details
      </div>
      <div
        className="result-value"
        dangerouslySetInnerHTML={{
          __html: result.alerts[0].alertAreas[0].alertDetails.replaceAll(
            "\n",
            "<br />"
          )
        }}
      ></div>
    </div>
  </>;
  const leftTab = () => <>
    <div className="nexus-row">
      <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0 nexus-mt-1">
          Step 01
      </div>
      <div className="nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
        <div className="nexus-mb-2">
          <div className="italicText mb-2">Report Type</div>
          <div className="input-container">
            {!weatherReportType && <div className="select-placeholder" style={{ width: "auto" }}>
              {"Current Condition"}
            </div>
            }
            <select
              value={weatherReportType}
              className="line-input-box"
              data-testid="report-type"
              disabled={result}
              onChange={(event) => setReportType(event.target.value)}
            >
              {reportTypes}
            </select>
          </div>
        </div>
      </div>
    </div>
    <div className="nexus-row nexus-mt-4">
      <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
          Step 02
      </div>
      <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
        <div className="nexus-mb-3 font-bold">
          {weatherReportType === "weatheralongroute" ? "Enter your Source location" : "Enter your location"}
        </div>
        <div className="mt-2 mb-3">
          <div className="italicText mb-2">City</div>
          <div className="input-container">
            <input
              type="text"
              value={city}
              data-testid="city"
              onChange={(event) => setCity(event.target.value)}
              placeholder={
                weatherReportType === "weatheralongroute" ? "e.g. Cincinnati" : "e.g. Liberty Hill"
              }
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="mt-2 mb-3">
          <div className="italicText mb-2">State</div>
          <div className="input-container">
            <input
              type="text"
              value={state}
              data-testid="state"
              onChange={(event) => setState(event.target.value)}
              placeholder={
                weatherReportType === "weatheralongroute" ? "e.g Illinois" : "e.g. Texas"
              }
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="mt-2 mb-3">
          <div className="italicText mb-2">Latitude</div>
          <div className="input-container">
            <input
              type="text"
              data-testid="latitude"
              value={latitude}
              onChange={(event) => setLatitude(event.target.value)}
              placeholder={
                weatherReportType === "weatheralongroute" ? "e.g. 39.151235" : "e.g. 30.66137610"
              }
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        <div className="mt-2 mb-3">
          <div className="italicText mb-2">Longitude</div>
          <div className="input-container">
            <input
              type="text"
              data-testid="longitude"
              value={longitude}
              onChange={(event) => setLongitude(event.target.value)}
              placeholder={
                weatherReportType === "weatheralongroute" ? "e.g. -84.48587" : "e.g. -97.90218064"
              }
              className="line-input-box"
              disabled
            />
          </div>
        </div>
        {weatherReportType !== "weatheralongroute" && <div>
          <button
            type="button"
            className="confirm-button"
            data-testid="submit"
            onClick={() => {
              submit();
            }}
          >
            {result ? "Reset" : "Try with Sample Address"}
          </button>
        </div>
        }
      </div>
    </div>
    {weatherReportType === "weatheralongroute" && <div className="step2 nexus-pr-4">
      <div className="nexus-row">
        <div className="form-head nexus-col-sm-4 nexus-col-md-2 nexus-col-lg-2 step step-0">
              Step 03
        </div>
        <div className="form-head nexus-col-sm-4 nexus-col-md-6 nexus-col-lg-7">
          <div className="nexus-mb-3 font-bold">
                Enter your destination location
          </div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">City</div>
            <div className="input-container">
              <input
                type="text"
                value={destcity}
                data-testid="dst-city"
                onChange={(event) => setDestCity(event.target.value)}
                placeholder={"e.g. Chicago"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">State</div>
            <div className="input-container">
              <input
                type="text"
                value={deststate}
                data-testid="dst-state"
                onChange={(event) => setDestState(event.target.value)}
                placeholder={"e.g. Illinois"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Latitude</div>
            <div className="input-container">
              <input
                type="text"
                data-testid="dst-latitude"
                value={destlatitude}
                onChange={(event) => setDestLatitude(event.target.value)}
                placeholder={"e.g. 41.95528"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div className="mt-2 mb-3">
            <div className="italicText mb-2">Longitude</div>
            <div className="input-container">
              <input
                type="text"
                data-testid="dst-longitude"
                value={destlongitude}
                onChange={(event) => setDestLongitude(event.target.value)}
                placeholder={"e.g. -87.6279"}
                className="line-input-box"
                disabled
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="confirm-button"
              data-testid="submit-dst"
              onClick={() => {
                submit();
              }}
            >
              {result ? "Reset" : "Try with Sample Address"}
            </button>
          </div>
        </div>
      </div>
    </div>
    }
  </>;
  const rightTab = () => <>
    {!result && <div
      className="nexus-row align-items-center nexus-ml-5"
      style={{ height: "100%" }}
    >
      <div className="address-sample">
            Try Weather Report with sample data
      </div>
    </div>
    }
    {result && <div className="nexus-ml-5 test-result-container">
      <div className="nexus-mb-2 test-result">Test Result</div>
      <div>
        {weatherReportType === "hourlydaily" && dailyOrHourlyCondition()}
        {weatherReportType === "weatheralongroute" && weatherAlongRoute()}
        {weatherReportType === "currentcondition" && currentCondition(result?.forecasts[0])}
        {weatherReportType === "severeweatheralerts" && severeWealthAlert()}
      </div>
    </div>
    }
  </>;

  return (
    <div className="nexus-container padding-margin-0 updated-design">
      {loader && <div className="loader-wrapper">
        <NexusProgressBar circle indeterminate className="loader-div" />
      </div>
      }
      <CatalogHeader card={card} />
      <CatalogDetailsContainer
        title="Weather Report"
        leftTab={leftTab()}
        rightTab={rightTab()}
      />
    </div>
  );
};

export default WeatherReport;
