import React, { Component } from "react";
import {
  carbonEmmision,
  singleView,
  intelligentDisputes,
  termLife,
  smallBusiness,
  merchantOffers,
  autoQoute,
  petInsurance,
  disabilityClaim,
  mortageBrowse,
  underwritingWorkbench
} from "./Const";
import "./FSSultionDetails.scss";
import { getDomainColor } from "../../SharedComponent/SharedUtils";
import "../../SharedComponent/SharedLandingPage.scss";
import EnableAdobeLaunch from "../../../../adobeAnalytics/EnableAdobeLaunch";
import backLink from "@nexus/core/dist/assets/icons/action/ic_arrow_left_alt_24px.svg";
import PlayDemoVideo from "../PlaydemoVideo";
import { string } from "prop-types";
import { NexusIcon } from "@nexus/react";

class SolutionDetails extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      solutionImpactBusiness: [],
      keyFeatures: [],
      capAccelerBuilding: [],
      poweredBy: [],
      additionalSalesAssets: [],
      demoAssets: [],
      cardName: "",
      countryFlag: "",
      description: "",
      bannerLogo: "",
      domain: [],
      playdemovideo: false,
      demoUrl: ""
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
    const urlParts = window.location.href.split("/");
    const cardName = decodeURIComponent(urlParts[urlParts.length - 1]);
    const ADOBE_DATA = {
      pageName: `fs-solutions/${cardName}`,
      listName: `fs-solutions/${cardName}`
    };
    EnableAdobeLaunch(ADOBE_DATA);
    switch (cardName) {
    case "carbon-neutral-auto-coverage":
      this.setObject(carbonEmmision);
      break;
    case "single-view-customer":
      this.setObject(singleView);
      break;
    case "intelligent-disputes":
      this.setObject(intelligentDisputes);
      break;
    case "small-business-insurance":
      this.setObject(smallBusiness);
      break;
    case "merchants-offers-insights":
      this.setObject(merchantOffers);
      break;
    case "direct-customer-term-life-origination":
      this.setObject(termLife);
      break;
    case "auto-quote-bind":
      this.setObject(autoQoute);
      break;
    case "pet-insurance-quote-bind":
      this.setObject(petInsurance);
      break;
    case "disability-claims-intake":
      this.setObject(disabilityClaim);
      break;
    case "mortgage-browse-bBook":
      this.setObject(mortageBrowse);
      break;
    case "underwriting-workbench":
      this.setObject(underwritingWorkbench);
      break;
    default:
    }
  }

  setObject(obejectName: any) {
    this.setState({
      solutionImpactBusiness: obejectName.solutionImpactBusiness,
      keyFeatures: obejectName.keyFeatures,
      capAccelerBuilding: obejectName.capAccelerBuilding,
      poweredBy: obejectName.poweredBy,
      additionalSalesAssets: obejectName.additionalSalesAssets,
      demoAssets: obejectName.demoAssets,
      cardName: obejectName.cardName,
      bannerLogo: obejectName.bannerLogo,
      countryFlag: obejectName.countryFlag,
      description: obejectName.description,
      domain: obejectName.domain
    });
  }

  OpenDemoUrl(actionType: string, demourl: string) {
    if (actionType.toLocaleLowerCase() === "watch demo") {
      this.setState({
        playdemovideo: true,
        demoUrl: typeof demourl === "undefined" ? "" : demourl
      });
    }
    else if (typeof demourl !== "undefined") {
      window.open(demourl);
    }
  }

  setdiableDemovideo() {
    this.setState({ playdemovideo: false });
  }

  backLinkCallBack() {
    this.props.history.push("/fs-solutions");
  }

  render() {
    const {
      solutionImpactBusiness,
      keyFeatures,
      capAccelerBuilding,
      poweredBy,
      additionalSalesAssets,
      demoAssets,
      cardName,
      countryFlag,
      description,
      domain,
      bannerLogo,
      playdemovideo,
      demoUrl
    } = this.state;

    return (
      <>
        <div className="nexus-container px-3 fs-solution-details">
          <div
            className="nexus-row mb-4 p-1 intelligent-disputes-page-header py-4"
            style={{ backgroundImage: `url(${bannerLogo})` }}
          >
            <div
              data-testid="back-btn"
              className={`id-content-white nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-12 nexus-col-xl-12 nexus-mt-1 nexus-ml-5 back-link`}
              onClick={() => this.backLinkCallBack()}
            >
              <NexusIcon src={backLink} />
              <span className="nexus-pl-2">Back</span>
            </div>
            <div
              className={`id-content-white nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-4 nexus-col-xl-4 nexus-ml-5 nexus-mt-4`}
            >
              <div data-testid="tstHeading" className={`nexus-h2 id-header`}>
                {cardName}
              </div>
              <div className={`nexus-mt-3 nexus-body id-sub-header`}>
                {description}
              </div>
            </div>
          </div>
        </div>
        <div className="nexus-container px-3 fs-solution-details">
          <div className="nexus-row">
            <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 mb-4 descriptionHeader px-4">
              Solution & Impact on Business
            </div>
            {solutionImpactBusiness.map((solution: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 px-2 mb-5"
            >
              <div
                className="card"
                style={{ height: "350px",
                  border: "1px solid #636569" }}
              >
                <img
                  src={solution.logo}
                  className="card-img-top"
                  alt="..."
                  style={{
                    width: "40px",
                    height: "40px",
                    margin: "5% 0% 0% 5%"
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title descriptionKey"
                    style={{ minHeight: "50px" }}
                  >
                    {solution.head}
                  </h5>
                  <p className="card-text description">
                    {solution.decription}
                  </p>
                  <p className="card-text description">
                    {solution.decription2}
                  </p>
                </div>
              </div>
            </div>)}
          </div>

          <div
            className="nexus-row mt-4"
            style={{ background: "#F7F9FC",
              marginBottom: "0px" }}
          >
            <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 mb-4 mt-4 descriptionHeader px-4">
              Key Features
            </div>
            {keyFeatures.map((feature: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 px-2 mb-5"
            >
              <div
                className="card"
                style={{ height: "250px",
                  background: "#D4DDF7" }}
              >
                <div className="card-body">
                  <h5
                    className="card-title descriptionKey"
                    style={{ minHeight: "50px" }}
                  >
                    {feature.head}
                  </h5>
                  <p className="card-text description">
                    {feature.decription}
                  </p>
                  <p className="card-text description">
                    {feature.decription2}
                  </p>
                </div>
              </div>
            </div>)}
          </div>

          <div className="nexus-row" style={{ background: "#FFF6D9" }}>
            <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 mb-4 mt-4 descriptionHeader  px-4">
              Capabilities & Accelerators & Building Blocks
            </div>
            {capAccelerBuilding.map((building: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-3 px-2 mb-5"
            >
              <div
                className="card"
                style={{ height: "238px",
                  background: "#FFEBB3" }}
              >
                <div className="card-body">
                  <h5
                    className="card-title descriptionKey"
                    style={{ minHeight: "50px" }}
                  >
                    {building.head}
                  </h5>
                  <p className="card-text description">
                    {building.decription}
                  </p>
                </div>
              </div>
            </div>)}
          </div>

          <div className="nexus-row" style={{ background: "#FFF0E5" }}>
            <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 mb-4 mt-4 descriptionHeader  px-4">
              Powered By
            </div>
            {poweredBy.map((powerby: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-3 px-2 mb-5 card-container"
            >
              <div
                className="card"
                style={{ height: "210px",
                  background: "#FFD7BA" }}
              >
                <img
                  src={powerby.logo}
                  className="card-img-top"
                  alt="..."
                  style={{
                    width: "40px",
                    height: "40px",
                    margin: "5% 0% 0% 5%"
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title descriptionKey"
                    style={{ minHeight: "unset" }}
                  >
                    {powerby.head}
                  </h5>
                  <p className="card-text description">
                    {powerby.decription}
                  </p>
                  <p className="card-text description">
                    {powerby.decription2}
                  </p>
                </div>
              </div>
            </div>)}
          </div>

          <div className="nexus-row mt-4">
            {additionalSalesAssets.length > 0 && <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 mb-4 mt-4 descriptionHeader px-4 nexus-start-lg">
                Additional Sales Assets
            </div>
            }
            {additionalSalesAssets.map((additionalAsset: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 px-2 mb-5 nexus-start-lg"
            >
              <div
                className="card"
                style={{ height: "375px",
                  background: "#BACAF7" }}
              >
                <div className="card-body">
                  <h5
                    className="card-title descriptionKey"
                    style={{ minHeight: "50px" }}
                  >
                    {additionalAsset.head}
                  </h5>
                  <p className="card-text description">
                    {additionalAsset.decription}
                  </p>
                  <p className="card-text description">
                    {additionalAsset.decription2}
                  </p>
                </div>
                <div className="mb-3 ml-3">
                  {additionalAsset.action && <button
                    type="button"
                    className="AdditionalSalesAssetsButton"
                  >
                    {" "}
                    {additionalAsset.action}
                  </button>
                  }
                </div>
              </div>
            </div>)}
          </div>

          <div className="nexus-row mt-4">
            <div className="nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-12 mb-4 descriptionHeader px-4 nexus-start-lg">
              Demo Assets
            </div>
            {demoAssets.map((demoAsset: any, index: any) => <div
              key={index}
              className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 px-2 mb-5 nexus-start-lg"
            >
              <div
                className="card"
                style={{ height: "243px",
                  background: "#FFDC73" }}
              >
                <div className="card-body">
                  <h5
                    className="card-title descriptionKey"
                    style={{ minHeight: "50px" }}
                  >
                    {demoAsset.head}
                  </h5>
                  <p className="card-text description">
                    {demoAsset.decription}
                  </p>
                  <p className="card-text description">
                    {demoAsset.decription2}
                  </p>
                </div>
                <div className="ml-3 mb-5">
                  {demoAsset.action && <button
                    type="button"
                    onClick={() => this.OpenDemoUrl(demoAsset.action, demoAsset.demoUrl)
                    }
                    className="AdditionalSalesAssetsButton"
                  >
                    {" "}
                    {demoAsset.action}
                  </button>
                  }
                </div>
              </div>
            </div>)}
          </div>
        </div>
        {playdemovideo && <div className="nexus-container px-3 fs-solution-details">
          <PlayDemoVideo
            showmodel={playdemovideo}
            videoUrl={demoUrl}
            title={cardName}
            closeModel={() => this.setdiableDemovideo()}
          />
        </div>
        }
      </>
    );
  }
}
export default SolutionDetails;
