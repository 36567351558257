import React, { Component } from "react";
import "./style.scss";

class PricingHeading extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="nexus-col-xs-4 cpp-heading">{this.props.title}</div>
        <div className="nexus-col-xs-4 nexus-mt-2 cpp-description">
          {this.props.description}
        </div>
      </div>
    );
  }
}
export default PricingHeading;
