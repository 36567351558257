import React from "react";
import paths from "../../../../../utils/PathsUtils";
import {
  keyAssetsFeature,
  strategicValue,
  augmentedBanner
} from "../Assets/image";
import FSSolutionExploreMoreCommonComponent from "./FSSolutionExploreMoreCommonComponent";

const AugmentedInteractionManager = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​",
      desc: [
        "Real-time insights including access to relevant materials and news articles",
        "Real-time voice to text transcription with sentiment analysis",
        "Market insights based on client industry",
        "NLP and call library analysis to identify growth drivers, sector alignment, and client alignment",
        "Edit and update summary and actions and push back to CRM or alternative system of record"
      ],
      img: keyAssetsFeature,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Augmented Interaction Manager enables RMs to be more customer-centric with resources at their fingertips, ultimately enabling a superior customer experience and relationship",
        "Microsoft UI Design Kit accelerates front-end development by providing UI component libraries, built-in style guides and other front-end functionality",
        "NLP Models can be trained and optimized to business needs and will evolve with growth of the customer base",
        "Reduced cost to service existing customers and provide new channels to grow the business by increasing RM efficiency",
        "Improved new business conversion rates by offering intelligent insights in the conversation"
      ],
      img: strategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: augmentedBanner,
    heading: "Augmented Interaction Manager",
    subHeading:
      "Augmented Interaction Manager enables Relationship Managers to have better client relationships by providing real-time insights and access to relevant materials, sentiment analysis of client calls, and connectivity to CRM tools for a more seamless and efficient experience.",
    previousLinkPath: paths.FsSolutions,
    contentClass: "banner-content-white"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default AugmentedInteractionManager;
