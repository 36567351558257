import React, { Component } from "react";
import { list, banner } from "./Utils";
import SharedLandingPage from "../SharedComponent/SharedLandingPage";
import EnableAdobeLaunch from "../../../adobeAnalytics/EnableAdobeLaunch";
import { authProvider } from '../../../azureAuth/authConfig';
import SharePointService from '../../service/SharePointService';

const ADOBE_DATA = {
  pageName: "platform-building-blocks",
  listName: "platform-building-blocks"
};
const spService = new SharePointService();

class BuildingBlocks extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
    if (authProvider.getAccount()) {
      this.prepareItems();
    }
  }

  prepareItems = () => {
    spService.getComponents().then((res) => res.json())
      .then((data) => {        
        const items:any = [];
        // Convert SharePoint Object into UI specific object
        data?.value?.map((element:any) => {
          items.push({
            name: element.Title,
            description: element.Description,
            icon: '',
            domain: [],
            id: element.ID
          });
          // Check if icon is avaiable. If Yes call sharepoint Api to get the icon
          if (element.Icon) {
            spService.getAttachments(spService.ListNames.COMPONENTS, element.ID, encodeURI(JSON.parse(element.Icon).fileName)).then((imgData) => {            
              const updatedItems = [...this.state.items];
              const currentItem = updatedItems.find((item) => item.id === element.ID);
              currentItem.icon = imgData;
              this.setState({
                items: items
              });
            });
          }

          return items;
        });
        this.setState({
          items: items
        });
      });
  };


  render() {
    return (
      <SharedLandingPage
        bannerDetails={banner}
        cardList={this.state.items}
        hideExploreBtn={true}
        showFilters={false}        
      />
    );
  }
}
export default BuildingBlocks;
