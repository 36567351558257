/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React from "react";
import "../Catalog.scss";
import { Warning } from "../../../../../assets/images";

class BuybackProtection extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const vehicle = this.props.data && this.props.data.vehicle ? this.props.data.vehicle : {};
    const vehicleData = vehicle ? vehicle[0] : {};
    // const map1 = new Map();
    // map1.set(
    //   ['rebuilt',
    //     'Vehicle Rebuit History'],
    //   ['flood',
    //     'Flood History'],
    //   ['fire',
    //     'Fire History'],
    //   ['hail',
    //     'Hail History'],
    //   ['manufacturerBuyback',
    //     'Manufacturer buyback History'],
    //   ['odometer',
    //     'Odometer Reading History']
    // );

    return (
      <div>
        {vehicleData && <div className="test-result-box nexus-p-4 nexus-col-lg-10">
          <div className="nexus-row nexus-mb-4 align-items-center">
            <div>
              <img className="verified-icons" src={Warning} alt="Warning" />
            </div>
            <div className="validated-address-value ml-2">
                Vehicle is not eligible for Buyback Protection
            </div>
          </div>
          <div className="flex-row d-flex">
            <p className="vehicle-details-title">Salvage History</p>
          </div>
          {vehicleData.salvage.details.map((data: any, index: number) => <div key={1 + index}>
            <div className="flex-row d-flex vehicle-details-desc">
              <p
                className="vehicle-details-title"
                style={{ marginLeft: "10px",
                  marginTop: "10px" }}
              >
                    Salvage {index + 1}:
              </p>
            </div>
            <div
              className="row row-cols-2 ml-3"
              style={{ marginTop: "-10px",
                fontWeight: "normal" }}
            >
              <div className="col-7 validated-address-value">
                    Salvage Reported Date
              </div>
              <div className="col-5 validated-address-text">
                    : {data.date}
              </div>

              <div className="col-7 validated-address-value mt-2">
                    Salvage Reported Type
              </div>
              <div className="col-5 validated-address-text mt-2">
                    : {data.type}
              </div>

              <div className="col-7 validated-address-value mt-2">
                    Salvage Reported City
              </div>
              <div className="col-5 validated-address-text mt-2">
                    : {data.city}
              </div>

              <div className="col-7 validated-address-value mt-2 mb-3">
                    Salvage Reported State
              </div>
              <div className="col-5 validated-address-text mt-2">
                    : {data.state}
              </div>
            </div>
          </div>)}

          <div className="flex-row d-flex vehicle-details-title mt-4">
            <p className="vehicle-details-title">Vehicle Rebuit History</p>
          </div>
          {vehicleData.rebuilt.rebuiltCheck === "N" && <div className="flex-row d-flex">
            <p
              className="validated-address-value"
              style={{ marginLeft: "40px" }}
            >
                  Rebuilt Hisotry not found
            </p>
          </div>
          }

          <div className="flex-row d-flex vehicle-details-title">
            <p className="vehicle-details-title">Flood History</p>
          </div>

          {vehicleData.flood.floodCheck === "N" && <div className="flex-row d-flex vehicle-details-desc">
            <p
              style={{ marginLeft: "40px" }}
              className="validated-address-value"
            >
                  Flood hisotry not found
            </p>
          </div>
          }

          <div className="flex-row d-flex vehicle-details-title">
            <p className="vehicle-details-title">Fire History</p>
          </div>
          {vehicleData.fire.fireCheck === "N" && <div className="flex-row d-flex vehicle-details-desc">
            <p
              style={{ marginLeft: "40px" }}
              className="validated-address-value"
            >
                  Fire hisotry not found
            </p>
          </div>
          }

          <div className="flex-row d-flex vehicle-details-title">
            <p className="vehicle-details-title">Hail History</p>
          </div>
          {vehicleData.hail.hailCheck === "N" && <div className="flex-row d-flex vehicle-details-desc">
            <p
              style={{ marginLeft: "40px" }}
              className="validated-address-value"
            >
                  Hail hisotry not found
            </p>
          </div>
          }

          <div className="flex-row d-flex vehicle-details-title">
            <p className="vehicle-details-title">
                Manufacturer buyback History
            </p>
          </div>
          {vehicleData.manufacturerBuyback.manufacturerBuybackCheck === "N" && <div className="flex-row d-flex vehicle-details-desc">
            <p
              style={{ marginLeft: "40px" }}
              className="validated-address-value"
            >
                  Manufacturer buyback hisotry not found
            </p>
          </div>
          }

          <div className="flex-row d-flex vehicle-details-title">
            <p className="vehicle-details-title">Odometer Reading History</p>
          </div>
          {vehicleData.odometer.odometerCheck === "N" && <div className="flex-row d-flex vehicle-details-desc">
            <p
              style={{ marginLeft: "40px" }}
              className="validated-address-value"
            >
                  Odometer Reading hisotry not found
            </p>
          </div>
          }
        </div>
        }
      </div>
    );
  }
}

export default BuybackProtection;
