import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./modules/azureAuth/authConfig";
import NexusXDZoneRoutes from "./modules/NexusXDZoneModule/routes/NexusXDZoneRoutes";
import jwtDecode from "jwt-decode";
import './styles/index.scss';
import { parse } from "query-string";

function App() {
  const [isValid, setIsvalid] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    const response = parse(hash);
    if (Object.keys(response).length) {
      const token = response.access_token as string;
      if(token === undefined) {
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
        setIsvalid(true);
      }
      else {
        const decodedToken: any = jwtDecode(token);
        if (decodedToken.preferred_username) {
          localStorage.setItem("loggedInUser", decodedToken.preferred_username);
          window.history.pushState(
            "",
            document.title,
            window.location.pathname + window.location.search
          );
          sessionStorage.setItem("IsloggedIn", "true");
        }
      }
    }
    else if (!sessionStorage.getItem("IsloggedIn")) {
      setIsvalid(true);
    }
  });

  return (
    <>
      <BrowserRouter>
        {isValid ? (
          <AzureAD provider={authProvider} forceLogin>
            {AuthenticationState.Authenticated
              ? (accountInfo: any) => {
                  if (accountInfo.accountInfo) {
                    localStorage.setItem(
                      "loggedInUser",
                      accountInfo.accountInfo.account.userName
                    );
                  }

                  return <NexusXDZoneRoutes />;
                }
              : ""}
          </AzureAD>
        ) : (
          <NexusXDZoneRoutes />
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
