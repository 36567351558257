/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "./SharedLandingPage.scss";
import {
  NexusCard,
  NexusChipGroup,
  NexusCardBody,
  NexusInput,
} from "@nexus/react";
import ActionIcSearch24px from "@nexus/core/dist/assets/icons/action/ic_search_24px.svg";
import { simpleChipFilterNames, getDomainColor, sortData } from "./SharedUtils";
import { authProvider } from "../../../azureAuth/authConfig";
import SharePointService from "../../service/SharePointService";

const spService = new SharePointService();

class SharedLandingPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      sortedData: sortData(this.props.cardList, "name"),
      fsSolutionNameList: sortData(this.props.cardList, "name"),
      selectedChipfilter: [],
      filterNames: [],
      searchValue: "",
      isModalVisible: false,
      offeringArea: {
        customerAndGrowth: false,
        dataAndAnalytics: false,
        processOptimization: false,
      },
      advanceFilterOptions: [],
      openOfferingAreaAccordion: false,
      openBusinessLineAccordion: false,
      openProductAccordion: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.setState({
      selectedChipfilter: ["All"],
    });
    this.prepareFilters();
    this.resetFilter();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.cardList !== this.props.cardList) {
      window.scrollTo({ top: 0 });
      this.setState({
        sortedData: sortData(this.props.cardList, "name"),
        fsSolutionNameList: sortData(this.props.cardList, "name"),
      });
    }
  }

  prepareFilters = () => {
    spService
      .getFilters()
      .then((res) => res.json())
      .then((data) => {
        const filters = ["All"];
        data?.value?.map((element: any) => {
          filters.push(element.Filters);
        });
        this.setState({
          filterNames: filters,
        });
      });
  };

  handleSearch = (event: any) => {
    const { sortedData } = this.state;
    const { value } = event.target;
    const filteredSoultion = sortedData.filter((obj: any) => {
      if (
        obj.name.toLowerCase().includes(value.toLowerCase())
        // || obj.domain.toString().toLowerCase().includes(value.toLowerCase())
        // || obj.subDomain.toString().toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
    this.setState({
      searchValue: value,
      fsSolutionNameList: filteredSoultion,
    });
  };

  setSearchInputValue = (val: any) => {
    this.setState({
      searchValue: val,
    });
  };

  // setModalVisivility(val: any) {
  //   this.setState({
  //     isModalVisible: val
  //   });
  // }

  // setCheckbox(key: any, name: any, val: any) {
  //   const { offeringArea } = this.state;
  //   // eslint-disable-next-line react/no-access-state-in-setstate
  //   const newFilterCriteria = this.state.advanceFilterOptions || [];
  //   const newData = {
  //     ...offeringArea,
  //     [key]: val
  //   };

  //   const index = newFilterCriteria.indexOf(name);
  //   if (index > -1) {
  //     newFilterCriteria.splice(index, 1);
  //   }
  //   else {
  //     newFilterCriteria.push(name);
  //   }
  //   this.setState({
  //     offeringArea: newData,
  //     advanceFilterOptions: newFilterCriteria
  //     // }, () => this.filterSoultionCards());
  //   });
  //  }

  setSimpleFilter(val: any) {
    if (val === "Advanced Filters") {
      // this.setModalVisivility(true);
      this.setState({ selectedChipfilter: ["Advanced Filters"] });
    } else if (val === "All") {
      this.setState({ selectedChipfilter: ["All"] });
      this.resetFilter();
    } else {
      // if user selected other option than All or Advanced Filters
      // eslint-disable-next-line react/no-access-state-in-setstate
      const filters = this.state.selectedChipfilter || [];
      const index = filters.indexOf(val);
      if (index > -1) {
        filters.splice(index, 1);
      } else {
        filters.push(val);
      }
      const indexAll = filters.indexOf("All");
      if (indexAll > -1) {
        filters.splice(indexAll, 1);
      }
      const indexAF = filters.indexOf("Advanced Filters");
      if (indexAF > -1) {
        filters.splice(indexAF, 1);
      }
      this.setState(
        {
          selectedChipfilter: filters,
          advanceFilterOptions: [],
        },
        () => this.filterSoultionCards()
      );
    }
    if (this.state.selectedChipfilter.length === 0) {
      this.setState({ selectedChipfilter: ["All"] });
      this.resetFilter();
    }
  }

  // setOfferingAreaOpen(val: any) {
  //   this.setState({ openOfferingAreaAccordion: val });
  // }

  // setBusinessLineOpen(val: any) {
  //   this.setState({ openBusinessLineAccordion: val });
  // }

  // setProductOpen(val: any) {
  //   this.setState({ openProductAccordion: val });
  // }

  // applyFilter() {
  //   this.setModalVisivility(false);
  //   this.filterSoultionCards();
  // }

  resetFilter() {
    const { sortedData } = this.state;
    this.setState({
      fsSolutionNameList: sortedData,
    });
  }

  isFilterSelected(name: any) {
    const { selectedChipfilter } = this.state;
    const index = selectedChipfilter.indexOf(name);
    if (index > -1) {
      return "nexus-selected-chip";
    }

    return "nexus-common-chip";
  }

  descriptionForCard(name: any) {
    if (name) {
      return this.props.hideExploreBtn
        ? name.length > 290
          ? `${name.substring(0, 290)}...`
          : name
        : name.length > 115
        ? `${name.substring(0, 115)}...`
        : name;
    }

    return "";
  }

  redirectTo(URL: string) {
    this.props.redirectFunction(URL);
  }

  filterSoultionCards() {
    const { selectedChipfilter, advanceFilterOptions } = this.state;
    if (selectedChipfilter.length === 1) {
      if (selectedChipfilter[0] === "All") {
        this.resetFilter();
      } else if (selectedChipfilter[0] === "Advanced Filters") {
        this.filterData(advanceFilterOptions);
      } else {
        this.filterData(selectedChipfilter);
      }
    } else {
      this.filterData(selectedChipfilter);
    }
  }

  filterData(data: any) {
    // data is an array []
    // this.state.sortedData is an array of object []
    const filteredSoultion = this.state.sortedData.filter((item: any) => {
      if (data.includes(item.name)) {
        return true;
      }

      return item.domain.some((element: any) => data.includes(element));
    });
    this.setState({
      fsSolutionNameList: filteredSoultion,
    });
  }

  // cancelModalVisivility() {
  //   this.setState(
  //     {
  //       // isModalVisible: val,
  //       selectedChipfilter: ['All'],
  //       offeringArea: {
  //         customerAndGrowth: false,
  //         dataAndAnalytics: false,
  //         processOptimization: false
  //       }
  //     },
  //     () => this.filterSoultionCards()
  //   );
  // }

  render() {
    const { fsSolutionNameList, searchValue } = this.state;
    const { bannerDetails, hideExploreBtn, showFilters } = this.props;
    const bannerColNum = 7;

    return (
      <>
        <div className="nexus-banner-container">
          <div
            className="nexus-container px-3"
            style={{ backgroundColor: "#F7F8FA" }}
          >
            <div
              className="nexus-row mb-4 p-1 page-header py-4"
              style={{ backgroundImage: `url(${bannerDetails.image})` }}
            >
              <div
                className={`nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-${bannerColNum} nexus-col-xl-${bannerColNum} nexus-pt-6 nexus-pl-5`}
              >
                <div className="heading">{bannerDetails.title}</div>
                <div className="mt-1 sub-heading">{bannerDetails.subTitle}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`nexus-container px-3 ${
            hideExploreBtn ? "landing-without-explore" : ""
          }`}
          style={{ backgroundColor: "#F7F8FA" }}
        >
          {showFilters && (
            <div className="nexus-row">
              <div className="nexus-col-sm-4 pl-4 nexus-col-md-8 nexus-col-lg-12  filter-title">
                Filters
              </div>
            </div>
          )}
          <div className="nexus-row mt-2 pl-2">
            <div className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-8 filter-chips">
              {showFilters && (
                <NexusChipGroup>
                  {this.state.filterNames.map((item: any) => (
                    <button
                      type="button"
                      data-testid={`tstfilter${item}`}
                      key={item}
                      onClick={() => this.setSimpleFilter(item)}
                      className={`${`btn mb-2 ${this.isFilterSelected(
                        item
                      )} me-2`}`}
                    >
                      {item}
                    </button>
                  ))}
                </NexusChipGroup>
              )}
            </div>
            <div className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 search-container">
              <NexusInput
                data-testid="tstSearch"
                placeholder="Search"
                style={{ width: "100%" }}
                value={searchValue}
                onInput={(event) => this.handleSearch(event)}
              />
              <img className="search-field" src={ActionIcSearch24px} alt="" />
            </div>
          </div>
          <div
            className="nexus-row mt-3 pt-4 px-2"
            style={{
              margin: "",
              backgroundColor: "#F7F8FA",
            }}
          >
            {fsSolutionNameList.length ? (
              fsSolutionNameList.map((item: any) => (
                <div
                  className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4"
                  key={item.name}
                >
                  <NexusCard className="solution-cards mb-4">
                    <NexusCardBody>
                      <div className="nexus-row ">
                        <div className="nexus-col-xs-4">
                          <img
                            src={item.icon}
                            alt=""
                            className="product-icon"
                          />
                        </div>
                      </div>
                      <div className="nexus-row" style={{ height: "75px" }}>
                        <div className="nexus-col-xs-4 mt-4 card-heading">
                          {item.name}
                        </div>
                      </div>
                      <div className="nexus-row">
                        <div className="nexus-col-xs-4 card-description ">
                          {this.descriptionForCard(item.description)}
                        </div>
                      </div>
                      <div className="nexus-row mt-1 domain-container">
                        {item.domain.length > 0 ? (
                          item.domain.map((domainName: any) => (
                            <span
                              key={domainName}
                              className="nexus-col-sm-auto nexus-col-md-auto nexus-col-lg-auto pr-1"
                            >
                              <button
                                type="button"
                                className={`btn domainButton mt-3 ${getDomainColor(
                                  domainName
                                )}`}
                              >
                                {domainName || ""}
                              </button>
                            </span>
                          ))
                        ) : (
                          <div style={{ height: "50px" }} />
                        )}
                      </div>
                      {!hideExploreBtn && (
                        <div className="nexus-row">
                          <div className="nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4">
                            <button
                              data-testid={`tstredirect${item.name}`}
                              type="button"
                              disabled={item.comingSoon}
                              onClick={() => this.redirectTo(item.URL)}
                              className="nexus-btn nexus-btn-md confirm-button"
                            >
                              Explore more
                            </button>
                          </div>
                        </div>
                      )}
                      {item.comingSoon && (
                        <div className="ribbon">
                          <div className="ribbon-content">Coming Soon!</div>
                        </div>
                      )}
                    </NexusCardBody>
                  </NexusCard>
                </div>
              ))
            ) : (
              <div className="nexus-col-xs-4 mb-4">
                <div className="nexus-center-xs nexus-mt-4 nexus-h6">
                  No data available
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default SharedLandingPage;
