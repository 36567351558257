import {
  BuildingBlockBanner,
  StarterKit,
  DesignSystem,
  TestingPlatform,
  AcceleratorProject,
  ConfigurationStudio
} from "./Assets/image";

const banner = {
  image: BuildingBlockBanner,
  title: "Adopt Catalogue",
  subTitle:
    "Platform Accelerators allow our clients and engagement teams to achieve consistency, increase speed & quality in the application development lifecycle across cloud infrastructure, back end, middleware, front end, and integration components."
};

const list = [
  {
    name: "Configuration Studio",
    description:
      "A comprehensive user interface to enable business and technical users to define and configure platform components, create experiences...",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: ConfigurationStudio,
    comingSoon: false,
    URL: "configuration-studio"
  },
  {
    name: "Microservices Accelerator Project",
    description:
      "Ability to create microservices using a command-line interface that generates a project scaffolding based on code/container templatization.",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: AcceleratorProject,
    comingSoon: false,
    URL: "microservices-accelerator-platform"
  },
  {
    name: "NextGen Testing Platform",
    description:
      "The NextGen Testing Platform UI is a desktop application which can be set up with user’s existing NGTP (also known as Next Gen eTAF)...",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: TestingPlatform,
    comingSoon: false,
    URL: "nextGen-testing-platform"
  },
  {
    name: "Nexus Design System",
    description:
      "A starter application, containing a built-in style guide, comprehensive library of UI components and design patterns and...",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: DesignSystem,
    comingSoon: false,
    URL: "nexus-design-system"
  },
  {
    name: "Web Starter Kit",
    description:
      "Web Starter Kit enables users to quickly get started with Web development via the Nexus Design System",
    domain: ["Cross Sector"],
    subDomain: [],
    icon: StarterKit,
    comingSoon: false,
    URL: "web-starter-kit"
  }
];

export { list, banner };
