import React, { Component } from "react";
import { ResponseData } from "../Utils";

interface State {}
interface Props {
  responseData: ResponseData;
}
class ResponseComponent extends Component<Props, State> {
  render() {
    const responseData = this.props.responseData;

    return (
      <div className="nexus-ml-5 test-result-container">
        <div className="nexus-mb-2 test-result">Test Result</div>
        <div
          className="test-result-box nexus-p-2 nexus-pb-4"
          style={{ width: "90%" }}
        >
          <div className="nexus-mb-4 validated-address-value">
            <b>Waterline Personal Lines Network Report</b>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Flood Hazard score : {responseData.floodHazardScore}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Flash Flood Hazard Weightage :{" "}
              {responseData.flashFloodHazardWeightage}
            </div>
          </div>
          <div className="nexus-mb-1 nexus-mt-3 validated-address-value">
            <b>Distance to Coast Report:</b>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Distance to River : {responseData.distanceToRiver}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Distance to Cost : {responseData.distanceToCoast}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Distance from 100yr Inland Marine :{" "}
              {responseData.distanceFrom100yrInlandFloodHazard}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Distance from 100yr Storm Surge :{" "}
              {responseData.distanceFrom100yrStormSurgeHazard}
            </div>
          </div>
          <div className="nexus-mb-1 nexus-mt-3 validated-address-value">
            <b>Relative Elevation Report:</b>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Elevation from nearest river or coastline :{" "}
              {responseData.relativeElevationFromNearestRiverOrCoastline}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Elevation 100yr Inland Marine :{" "}
              {responseData.relativeElevation100yrInlandFloodHazard}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Elevation 100yr Storm Surge :{" "}
              {responseData.relativeElevation100yrStormSurgeHazard}
            </div>
          </div>
          <div className="nexus-mb-1 nexus-mt-3 validated-address-value">
            <b>FEMA Report:</b>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Community Number : {responseData.fEMA.communityNumber}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Community effective date :{" "}
              {responseData.fEMA.communityEffectiveDate.substring(0, 10)}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Community Name : {responseData.fEMA.communityName}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Flood Zone : {responseData.fEMA.floodZone}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Distance to SFHA :{" "}
              {responseData.fEMA.distanceToSFHA ? null : "N/A"}
            </div>
          </div>
          <div className="nexus-row">
            <div className="result-label nexus-col-lg-12 nexus-col-lg-8 nexus-col-lg-4">
              Base Flood Elevation : {responseData.fEMA.baseFloodElevation}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResponseComponent;
