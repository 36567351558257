import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import BusinessAccelerator from "./BusinessAccelerator";

import { paths } from "../../../../utils/PathsUtils";

const { businessAccelarator } = paths;

const CatalogRoutes = () => <Switch>
  <>
    <Route
      exact
      path={`${businessAccelarator}`}
      component={BusinessAccelerator}
    />
  </>
</Switch>;
export default CatalogRoutes;
