/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { acceleratorList, acceleratorBanner } from "./Utils";
import SharedLandingPage from "../SharedComponent/SharedLandingPage";
import { paths } from "../../../../utils/PathsUtils";
import { AnyRecord } from "dns";
import EnableAdobeLaunch from "../../../adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
  pageName: "platform-product-accelerators",
  listName: "platform-product-accelerators"
};

const { accelerators } = paths;
class Accelators extends Component<any, any> {
  componentDidMount() {
    EnableAdobeLaunch(ADOBE_DATA);
  }

  redirectTo = (url: string) => {
    this.props.history.push(`${accelerators}/${url}`);
  };

  render() {
    return (
      <SharedLandingPage
        redirectFunction={this.redirectTo}
        bannerDetails={acceleratorBanner}
        cardList={acceleratorList}
        showFilters={true}
      />
    );
  }
}
export default Accelators;
