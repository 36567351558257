import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import FSSolutionRoutes from "../components/FSSolutions/FSSolutionRoutes";
import ActiveCampaigns from "../components/FSSolutions/ActiveCampaigns/ActiveCampaigns";
import AccelatorsRoutes from "../components/Accelerators/AccelatorsRoutes";
import EcoSystem from "../components/Ecosystem/Ecosystem";
import CatalogRoutes from "../components/Catalog/CatalogRoutes";
import SupportRoutes from "../components/Support/SupportRoutes";
import ResourceRoutes from "../components/Resources/ResourceRoutes";
import AboutRoutes from "../components/About/AboutRoutes";
import BuildingBlocks from "../components/BuildingBlocks/BuildingBlocks";
import BusinessEnablementRoutes from "../components/BusinessEnablement/BusinessEnablementRoutes";
import BusinessAccelerator from "../components/BusinessAccelerator/BusinessAcceleratorRoutes";
import XDHome from "../components/XDHome";
import { paths } from "../../../utils/PathsUtils";
import Header from "../components/SharedComponent/Header";
import Footer from "../components/SharedComponent/Footer";
// import Tools from '../components/Tools/Tools';
import ToolsRoutes from "../components/Tools/ToolsRoutes";
// About
import TechnologyStack from "../components/About/TechnologyStack/TechnologyStack";
import NexusComponent from "../components/About/NexusComponent/NexusComponent";
import OurTeamComponent from "../components/About/OurTeam/OurTeam";
// Bussiness Enablement
import ClientOnboarding from "../components/BusinessEnablement/ClientOnboarding/ClientOnboarding";
import CommercialNPricing from "../components/BusinessEnablement/CommercialNPricing/CommercialNPricing";
import SowNIPLanguage from "../components/BusinessEnablement/SowNIPLanguage/SowNIPLanguage";
import CorePlatformPricingComp from "../components/BusinessEnablement/CorePlatformPricing/CorePlatformPricing";
import InsuranceProductsPricingComp from "../components/BusinessEnablement/InsuranceProductsPricing/InsuranceProductsPricing";
// Support
import FAQ from "../components/Support/FAQ/FAQ";
import Contact from "../components/Support/Contact/Contact";
// Resources
import CaseStudies from "../components/Resources/CaseStudies/CaseStudies";
import MarketingMeterials from "../components/Resources//MarketMeterials/MarketMeterials";
import TechnicalMessagingGuide from "../components/Resources//TechnicalMessagingGuide/TechnicalMessagingGuide";
import PlatformAccRoutes from "../components/Catalog/PlatformAccRoutes";

const {
  nexusXDZone,
  catalog,
  accelerators,
  FsSolutions,
  home,
  support,
  resources,
  businessEnablement,
  about,
  buildingBlocks,
  activeCampaigns,
  ecosystem,
  tools,
  nexusComponent,
  technologyStack,
  ourTeam,
  clientOnboarding,
  sowNIPLanguage,
  commercialNPricing,
  faq,
  contact,
  caseStudies,
  marketingMeterials,
  technicalMessagingGuide,
  CorePlatformPricing,
  InsuranceProductsPricing,
  businessAccelarator,
  platformaccelerators
} = paths;

const NexusXDZoneRoutes = () => {
  const location = useLocation();

  return (
    <Switch>
      <>
        <Header />
        <Route exact path={`${"/"}`} component={XDHome} />
        <Route path={`${catalog}`} component={CatalogRoutes} />
        <Route path={`${platformaccelerators}`} component={PlatformAccRoutes} />
        <Route path={`${FsSolutions}`} component={FSSolutionRoutes} />
        <Route path={`${activeCampaigns}`} component={ActiveCampaigns} />
        <Route path={`${accelerators}`} component={AccelatorsRoutes} />
        <Route
          path={`${businessAccelarator}`}
          component={BusinessAccelerator}
        />
        <Route path={`${ecosystem}`} component={EcoSystem} />
        {/* <Route path={`${nexusXDZone}${support}`} component={SupportRoutes} /> */}
        <Route path={`${faq}`} component={FAQ} />
        <Route path={`${contact}`} component={Contact} />
        {/* <Route path={`${resources}`} component={ResourceRoutes} /> */}
        <Route path={`${caseStudies}`} component={CaseStudies} />
        <Route path={`${marketingMeterials}`} component={MarketingMeterials} />
        <Route
          path={`${technicalMessagingGuide}`}
          component={TechnicalMessagingGuide}
        />
        {/* <Route path={`${businessEnablement}`} component={BusinessEnablementRoutes} /> */}
        <Route path={`${clientOnboarding}`} component={ClientOnboarding} />
        <Route path={`${commercialNPricing}`} component={CommercialNPricing} />
        <Route path={`${sowNIPLanguage}`} component={SowNIPLanguage} />
        <Route
          path={`${CorePlatformPricing}`}
          component={CorePlatformPricingComp}
        />
        <Route
          path={`${InsuranceProductsPricing}`}
          component={InsuranceProductsPricingComp}
        />
        {/* <Route path={`${about}`} component={AboutRoutes} /> */}
        <Route path={`${nexusComponent}`} component={NexusComponent} />
        <Route path={`${technologyStack}`} component={TechnologyStack} />
        <Route path={`${ourTeam}`} component={OurTeamComponent} />
        <Route path={`${buildingBlocks}`} component={BuildingBlocks} />
        {/* <Route path={`${nexusXDZone}${tools}`} component={Tools} /> */}
        <Route path={`${tools}`} component={ToolsRoutes} />
        {!location.pathname.includes(`${clientOnboarding}`) && !location.pathname.includes(`${sowNIPLanguage}`) && !location.pathname.includes(`${faq}`) && !location.pathname.includes(`${contact}`) && !location.pathname.includes(`${marketingMeterials}`) && !location.pathname.includes(`${technicalMessagingGuide}`) && !location.pathname.includes(`${caseStudies}`) && !location.pathname.includes(`${technologyStack}`) && !location.pathname.includes(`${activeCampaigns}`) && <Footer />}
        {/*  <Footer /> */}
      </>
    </Switch>
  );
};

export default NexusXDZoneRoutes;
