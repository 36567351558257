import React from "react";
import paths from "../../../../../utils/PathsUtils";
import FSSolutionExploreMoreCommonComponent from "../../FSSolutions/FSSolutionsExploreMore/FSSolutionExploreMoreCommonComponent";
import { WSKbanner, WSKKeyAssets, WSKStrategicValue } from "../Assets/image";

const WebStarterKit = () => {
  const pillarContent = [
    {
      title: "Key Asset Features​​",
      desc: [
        "Application shell which imports reusable component library from the Design System​",
        "Modern approach (TypeScript, SCSS, Env Vars, Formatting, Linting, Testing, and Code Coverage)​",
        "Recipes for core patterns (Authentication, API Service Layer, Persistence, i18n, and L10n)​​",
        "Cookbook for frontend strategies around Error Handling and Logging​",
        "State Management with Observables and Redux​"
      ],
      img: WSKKeyAssets,
      bgColor: null
    },
    {
      title: "Strategic Values",
      desc: [
        "Enforce standards for coding, quality and security best practices​​",
        "Opinionated project workspace for uniformity, compatibility, and scalability​​",
        "Enable advanced code sharing patterns such as Micro-Frontends and Mono-Repos​"
      ],
      img: WSKStrategicValue,
      bgColor: "tile-bg-color"
    }
  ];
  const bannerContent = {
    img: WSKbanner,
    heading: "Web Starter Kit​​",
    subHeading:
      "To accelerate web development, single page apps are created using a command-line interface, which scaffolds an opinionated framework project structure with first-class support for modern development standards and performance best practices built-in.​​",
    previousLinkPath: paths.tools,
    contentClass: "banner-content-black"
  };

  return (
    <FSSolutionExploreMoreCommonComponent
      pillarContent={pillarContent}
      bannerContent={bannerContent}
    />
  );
};

export default WebStarterKit;
