import React, { Component } from "react";
import { CheckCircle } from "../../../../../../assets/images";

class EarthquakeHazardRiskRightView extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { eventsCount, grid, riskLevel } = this.props.result.earthquakeResponse;

    return (
      <div
        className="nexus-flex-col justify-content-center"
        style={{ marginLeft: "40px " }}
      >
        <strong>Test Result</strong>
        <div className="hazard-earth-quick mt-4">
          <div className="address-status-row">
            <div style={{ marginTop: "-10px" }}>
              <img src={CheckCircle} alt="CheckCircle" />
            </div>
            <div className="address-matched-text">
              Geometry type is{" "}
              <strong>
                {grid.geometry.type ? grid.geometry.type : "Polygon"}
              </strong>{" "}
              and
              <br />
              Earthquake Risk Level is <strong>{riskLevel || "Low"}</strong>
            </div>
          </div>
          <div className="address-status-row" style={{ marginLeft: "37px" }}>
            <div className="address-matched-text">
              <strong>Earthquake Events </strong>
            </div>
          </div>
          <div>
            <div className="row my-3" style={{ marginLeft: "22px" }}>
              <div className="col-12">
                <div className="p-1">
                  <label style={{ fontWeight: "bold" }}>Richter 0:</label>{" "}
                  {eventsCount.r0} Events
                </div>
              </div>
              <div className="col-12">
                <div className="p-1">
                  <label style={{ fontWeight: "bold" }}>Richter 1:</label>{" "}
                  {eventsCount.r1} Events
                </div>
              </div>
              <div className="col-12">
                <div className="p-1">
                  <label style={{ fontWeight: "bold" }}>Richter 2:</label>{" "}
                  {eventsCount.r2} Events
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EarthquakeHazardRiskRightView;
